import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function piechart({
    Title,
    Categories,
    Series,
    unit = '',
}) {
    const options = {
        title: {
            text: Title,
        },
        chart: {
            type: 'pie',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: Series,
    }
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}