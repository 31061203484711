export const mobile_info_details = [
	{
		id: 201,
		no: "2.3.1",
		label: "อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าที่กำหนด",
		targeted_text: "สำหรับ 2G ร้อยละ 80 ไม่เกิน 1,000 ms",
		subLabel_1: "สำหรับ 2G ร้อยละ 80 ไม่เกิน 1,000 ms",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 8,
		priority: 201
	},
	{
		id: 202,
		no: "2.3.1",
		label: "อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าที่กำหนด",
		targeted_text: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 500 มิลลิวินาที",
		subLabel_1: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 500 มิลลิวินาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 12,
		priority: 202
	},
	{
		id: 327,
		no: "2.3.1",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 3G",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 14,
		priority: 202.1
	},
	{
		id: 203,
		no: "2.3.1",
		label: "อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าที่กำหนด",
		targeted_text: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 150 มิลลิวินาที",
		subLabel_1: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 150 มิลลิวินาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 16,
		priority: 203
	},
	{
		id: 328,
		no: "2.3.1",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 4G",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 18,
		priority: 203.1
	},
	{
		id: 337,
		no: "2.3.1",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 20,
		priority: 203.2
	},
	{
		id: 302,
		no: "2.3.1",
		label: "อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าที่กำหนด",
		targeted_text: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 150 มิลลิวินาที",
		subLabel_1: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 150 มิลลิวินาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 22,
		priority: 203.3
	},
	{
		id: 329,
		no: "2.3.1",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 24,
		priority: 203.4
	},
	{
		id: 303,
		no: "2.3.1",
		label: "อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าที่กำหนด",
		targeted_text: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 110 มิลลิวินาที",
		subLabel_1: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 โดยกำหนดให้ RTT มีค่า 110 มิลลิวินาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 26,
		priority: 203.5
	},
	{
		id: 330,
		no: "2.3.1",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 28,
		priority: 203.6
	},
	{
		id: 204,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 31,
		priority: 204
	},
	{
		id: 206,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 35,
		priority: 205
	},
	{
		id: 205,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 39,
		priority: 206
	},
	{
		id: 207,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 43,
		priority: 207
	},
	{
		id: 338,
		no: "2.3.2",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 47,
		priority: 207.1
	},
	{
		id: 304,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 51,
		priority: 207.2
	},
	{
		id: 305,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 55,
		priority: 207.3
	},
	{
		id: 306,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 80 สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 59,
		priority: 207.4
	},
	{
		id: 307,
		no: "2.3.2",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ(FTP success ratio)",
		targeted_text: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 70 สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 63,
		priority: 207.5
	},
	{
		id: 208,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 750 กิโลบิตต่อวินาที สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 750 กิโลบิตต่อวินาที สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 68,
		priority: 208
	},
	{
		id: 223,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 3G สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 72,
		priority: 208.1
	},
	{
		id: 210,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 300 กิโลบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 300 กิโลบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 76,
		priority: 209
	},
	{
		id: 225,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 3G สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 80,
		priority: 209.1
	},
	{
		id: 209,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 2.5 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 2.5 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 84,
		priority: 210
	},
	{
		id: 224,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 4G สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 88,
		priority: 210.1
	},
	{
		id: 211,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 500 กิโลบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 500 กิโลบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 92,
		priority: 211
	},
	{
		id: 226,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 4G สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 96,
		priority: 211.1
	},
	{
		id: 339,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 100,
		priority: 211.2
	},
	{
		id: 308,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 2.5 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 2.5 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 104,
		priority: 211.3
	},
	{
		id: 309,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 108,
		priority: 211.4
	},
	{
		id: 310,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 500 กิโลบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 500 กิโลบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 112,
		priority: 211.5
	},
	{
		id: 311,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 116,
		priority: 211.6
	},
	{
		id: 312,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 5 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 5 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 120,
		priority: 211.7
	},
	{
		id: 313,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 5G (ที่ไม่ใช้คลื่นความถี่ 2600 MHz) สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 124,
		priority: 211.8
	},
	{
		id: 314,
		no: "2.3.3",
		label: "อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด (FTP ratio subjected to specified bit rate)",
		targeted_text: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 1.25 เมกะบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 75 โดยกำหนดให้อัตราบิต (Bitrate) มีค่า 1.25 เมกะบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 75,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 128,
		priority: 211.9
	},
	{
		id: 315,
		no: "2.3.3",
		label: "",
		targeted_text: "",
		subLabel_1: "ความเร็วเฉลี่ย (Mbps) เทคโนโลยี 5G (ที่ใช้คลื่นความถี่ 2600 MHz) สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 132,
		priority: 211.91
	},
	{
		id: 212,
		no: "2.3.4",
		label: "อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด (HTTP ratio subjected to specified time duration)",
		targeted_text: "เทคโนโลยี 2G ไม่น้อยกว่าร้อยละ 80 โดยมีเวลาที่กำหนดเท่ากับ 10 นาที",
		subLabel_1: "เทคโนโลยี 2G ไม่น้อยกว่าร้อยละ 80 โดยมีเวลาที่กำหนดเท่ากับ 10 นาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 137,
		priority: 212
	},
	{
		id: 213,
		no: "2.3.4",
		label: "อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด (HTTP ratio subjected to specified time duration)",
		targeted_text: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 3 นาที",
		subLabel_1: "เทคโนโลยี 3G ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 3 นาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 141,
		priority: 213
	},
	{
		id: 331,
		no: "2.3.4",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 3G",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 143,
		priority: 213.1
	},
	{
		id: 214,
		no: "2.3.4",
		label: "อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด (HTTP ratio subjected to specified time duration)",
		targeted_text: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 1 นาที",
		subLabel_1: "เทคโนโลยี 4G ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 1 นาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 145,
		priority: 214
	},
	{
		id: 332,
		no: "2.3.4",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 4G",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 147,
		priority: 214.1
	},
	{
		id: 340,
		no: "2.3.4",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 149,
		priority: 214.2
	},
	{
		id: 316,
		no: "2.3.4",
		label: "อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด (HTTP ratio subjected to specified time duration)",
		targeted_text: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 1 นาที",
		subLabel_1: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 1 นาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 151,
		priority: 214.3
	},
	{
		id: 333,
		no: "2.3.4",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 153,
		priority: 214.4
	},
	{
		id: 317,
		no: "2.3.4",
		label: "อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด (HTTP ratio subjected to specified time duration)",
		targeted_text: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 25 วินาที",
		subLabel_1: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz) ไม่น้อยกว่าร้อยละ 90 โดยมีเวลาที่กำหนดเท่ากับ 25 วินาที",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 155,
		priority: 214.5
	},
	{
		id: 334,
		no: "2.3.4",
		label: "",
		targeted_text: "",
		subLabel_1: "ค่าเฉลี่ย ms เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 157,
		priority: 214.6
	},
	{
		id: 215,
		no: "2.3.5",
		label: "อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตรีมมิ่ง (Streaming service accessibility)",
		targeted_text: "เทคโนโลยี 3G (Resolution 360p) ไม่น้อยกว่าร้อยละ 80",
		subLabel_1: "เทคโนโลยี 3G (Resolution 360p) ไม่น้อยกว่าร้อยละ 80",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 160,
		priority: 215
	},
	{
		id: 216,
		no: "2.3.5",
		label: "อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตรีมมิ่ง (Streaming service accessibility)",
		targeted_text: "เทคโนโลยี 4G (Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_1: "เทคโนโลยี 4G (Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 85,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 164,
		priority: 216
	},
	{
		id: 341,
		no: "2.3.5",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 168,
		priority: 216.1
	},
	{
		id: 318,
		no: "2.3.5",
		label: "อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตรีมมิ่ง (Streaming service accessibility)",
		targeted_text: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_1: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 85,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 172,
		priority: 216.2
	},
	{
		id: 319,
		no: "2.3.5",
		label: "อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตรีมมิ่ง (Streaming service accessibility)",
		targeted_text: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz Resolution 1080p) ไม่น้อยกว่าร้อยละ 90",
		subLabel_1: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz Resolution 1080p) ไม่น้อยกว่าร้อยละ 90",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 176,
		priority: 216.3
	},
	{
		id: 217,
		no: "2.3.6",
		label: "อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio)",
		targeted_text: "เทคโนโลยี 3G (Resolution 360p) ไม่น้อยกว่าร้อยละ 80",
		subLabel_1: "เทคโนโลยี 3G (Resolution 360p) ไม่น้อยกว่าร้อยละ 80",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 80,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 181,
		priority: 217
	},
	{
		id: 218,
		no: "2.3.6",
		label: "อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio)",
		targeted_text: "เทคโนโลยี 4G (Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_1: "เทคโนโลยี 4G (Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 85,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 185,
		priority: 218
	},
	{
		id: 342,
		no: "2.3.6",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: false,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 189,
		priority: 218.1
	},
	{
		id: 320,
		no: "2.3.6",
		label: "อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio)",
		targeted_text: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_1: "เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz Resolution 720p) ไม่น้อยกว่าร้อยละ 85",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 85,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 193,
		priority: 218.2
	},
	{
		id: 321,
		no: "2.3.6",
		label: "อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio)",
		targeted_text: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz Resolution 1080p) ไม่น้อยกว่าร้อยละ 90",
		subLabel_1: "เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz Resolution 1080p) ไม่น้อยกว่าร้อยละ 90",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 197,
		priority: 218.3
	},
	{
		id: 219,
		no: "2.3.7",
		label: "ระยะเวลาที่ใช้ในการส่ง SMS จากฝั่งส่งไปยังฝั่งรับ (SMS end-to-end delivery time)",
		targeted_text: "ไม่เกิน 90 วินาที สำหรับร้อยละ 80 ของ SMS ที่ฝั่งรับได้รับสำเร็จภายใน Timeout ที่ 175 วินาที",
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: null,
		filling_condition: "ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 0",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "int",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 286,
		priority: 219
	},
	{
		id: 220,
		no: "2.3.8",
		label: "อัตราส่วนจำนวน SMS ที่ไปถึงฝั่งรับได้สำเร็จ (SMS completion success ratio)",
		targeted_text: "ไม่ต่ำกว่าร้อยละ 90 ของการทดสอบทั้งหมด จะต้องได้รับสำเร็จที่ฝั่งรับ ภายใน Timeout ที่ 175 วินาที",
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: null,
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ(มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 295,
		priority: 220
	},
	{
		id: 221,
		no: "2.3.9",
		label: "ระยะเวลาที่ใช้ในการส่ง MMS จากฝั่งส่งไปยังฝั่งรับ (MMS end-to-end delivery time)",
		targeted_text: "ภายใน 5 นาที สำหรับร้อยละ 70 ของ MMS ที่ฝั่งรับได้รับสำเร็จภายใน Timeout ที่ 13 นาที",
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: null,
		filling_condition: "ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 0",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "int",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 304,
		priority: 221
	},
	{
		id: 222,
		no: "2.3.10",
		label: "อัตราส่วนจำนวน MMS ที่ไปถึงฝั่งรับได้สำเร็จ (MMS completion success ratio)",
		targeted_text: "ภายใน 5 นาที สำหรับร้อยละ 70 ของ MMS ที่ฝั่งรับได้รับสำเร็จภายใน Timeout ที่ 13 นาที",
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: null,
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E",
			"F",
			"G"
		],
		excelValueRow: 313,
		priority: 222
	},
	{
		id: 343,
		no: "2.3.11",
		label: "",
		targeted_text: "",
		subLabel_1: "เทคโนโลยี 5G (พื้นที่)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 202,
		priority: 223
	},
	{
		id: 335,
		no: "2.3.11",
		label: "ค่าอัตราบิตเฉลี่ยของการรับส่งข้อมูลแบบ FTP (Average FTP Bitrate)",
		targeted_text: "เทคโนโลยี 5G ที่ใช้คลื่นความถี่ย่าน 2600 MHz ไม่น้อยกว่า 20 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_1: "เทคโนโลยี 5G ที่ใช้คลื่นความถี่ย่าน 2600 MHz ไม่น้อยกว่า 20 เมกะบิตต่อวินาที สำหรับกรณี Download",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 20,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นทศนิยม",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 206,
		priority: 223.1
	},
	{
		id: 336,
		no: "2.3.11",
		label: "ค่าอัตราบิตเฉลี่ยของการรับส่งข้อมูลแบบ FTP (Average FTP Bitrate)",
		targeted_text: "เทคโนโลยี 5G ที่ใช้คลื่นความถี่ย่าน 2600 MHz ไม่น้อยกว่า 5 เมกะบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_1: "เทคโนโลยี 5G ที่ใช้คลื่นความถี่ย่าน 2600 MHz ไม่น้อยกว่า 5 เมกะบิตต่อวินาที สำหรับกรณี Upload",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 5,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นทศนิยม",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"D",
			"E"
		],
		excelValueRow: 210,
		priority: 223.2
	}
]