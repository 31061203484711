import { Card, Grid, Divider, InputBase } from '@material-ui/core'
import { Settings } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
	return {
		disabled_text_field: {
			color: '#000',
		},
	}
})

export default function BrandNameCard({
	mountType,
	inputModel = {
		brandName: '',
		productModel: '',
		productDescription: '',
	},
	onChangeInputModel,
}) {
	const classes = useStyles()

	return (
		<Card className={`border-t-4 border-card_top_border mb-5 p-5`}>
			<Grid className="flex flex-row justify-between items-center w-full">
				<Grid className="flex flex-row mt-4 mb-2">
					<Settings style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						2.รายละเอียดของเครื่องโทรคมนาคมและอุปกรณ์
					</Grid>
				</Grid>
			</Grid>
			<Grid className="mt-3 mb-1 w-full">
				<Divider />
			</Grid>
			<Grid className="flex flex-row mt-4 mb-2 gap-x-2">
				<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
					<Grid className="flex flex-row">
						<Grid>ตราอักษร (Brand Name)</Grid>
						<Grid className="text-red-500">*</Grid>
					</Grid>
					<Grid
						className={` text-xs flex justify-start items-center rounded h-10  w-full border border-gray-300`}>
						<InputBase
							className={`w-full h-full px-2 ${
								(mountType && mountType !== "ModifyInfo") ? classes.disabled_text_field : ''
							}`}
							name="brandName"
							value={inputModel.brandName}
							onChange={onChangeInputModel}
							disabled={(mountType && mountType !== "ModifyInfo")}
						/>
					</Grid>
				</Grid>
				<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
					<Grid className="flex flex-row">
						<Grid>แบบ/รุ่น (model)</Grid>
						<Grid className="text-red-500">*</Grid>
					</Grid>
					<Grid
						className={` text-xs flex justify-start items-center rounded h-10  w-full border border-gray-300`}>
						<InputBase
							className={`w-full h-full px-2  ${
								(mountType && mountType !== "ModifyInfo") ? classes.disabled_text_field : ''
							}`}
							name="productModel"
							value={inputModel.productModel}
							onChange={onChangeInputModel}
							disabled={(mountType && mountType !== "ModifyInfo")}
						/>
					</Grid>
				</Grid>

				<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
					<Grid className="flex flex-row">
						<Grid>
							ประเภท / ชนิดของเครื่องโทรคมนาคมและอุปกรณ์ (Product Description)
						</Grid>
						<Grid className="text-red-500">*</Grid>
					</Grid>
					<Grid
						className={` text-xs flex justify-start items-center rounded h-10  w-full border border-gray-300`}>
						<InputBase
							className={`w-full h-full px-2  ${
								(mountType && mountType !== "ModifyInfo") ? classes.disabled_text_field : ''
							}`}
							name="productDescription"
							value={inputModel.productDescription}
							onChange={onChangeInputModel}
							placeholder="เช่น Access Point / Wireless Earphone / Notebook Computer"
							disabled={(mountType && mountType !== "ModifyInfo")}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	)
}
