import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
export default function CompareBar({
    Title,
    Categories,
    Series,
    Max = 100,
    MaxMS = 70,
    unit = '%',
    unit2 = 'ms',
}) {
    const options = {
        title: {
            text: Title,
        },
        chart: {
            type: 'column',
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{y}' + unit,
                    inside: true,
                    verticalAlign: 'top',
                    align: 'center',
                    color: 'black',
                }
            },
            spline: {
                dataLabels: {
                    enabled: true,
                    format: '{y}' + unit2,
                    inside: true,
                    verticalAlign: 'top',
                    align: 'center',
                    color: 'black',
                }
            }
        },
        xAxis: {
            categories: Categories,
        },
        yAxis: [{
            min: 0,
            max: Max,
            title: {
                text: unit
            }
        },
        {
            min: 0,
            max: MaxMS,
            title: {
                text: unit2
            },
            opposite: true,
        }],
        series: Series,
    };

    return (

        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />

    )
}
