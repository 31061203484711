import { useEffect } from 'react'
import { Card, CardContent, Grid, Divider } from '@material-ui/core'
import { Place, DirectionsWalk } from '@material-ui/icons'

import StandardReportForm from './excel'

export default function ReportCard({
	// Main Component's State
	flags,
	type,
	preField,
	onFillForm,
	// Standard Report Answers
	fixedGeneralAnswers,
	fixedInfoAnswers,
	fixedSoundAnswers,
	mobileGeneralAnswers,
	mobileInfoAnswers,
	mobileSoundAnswers,
	// Validation
	currentSelectedError,
	openValidationModal,
	onOpenValidationModal,
	onCloseValidationModal,
	// Document Status ID
	documentStatusID,
}) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [type])

	return (
		<Card className="border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0">
			<CardContent className="flex flex-col gap-y-3 w-full">
				<Grid component="div" className="flex flex-row gap-x-3">
					<Grid
						component="div"
						className="font-bold flex flex-row items-center">
						{type?.includes('fixed') ? (
							<Place style={{ fontSize: 22 }} className="mr-1" />
						) : (
							<DirectionsWalk style={{ fontSize: 22 }} className="mr-1" />
						)}
						{type?.includes('fixed') ? (
							<Grid className="font-bold text-sm text-card_header_color">
								แบบประจำที่ (Fixed)
							</Grid>
						) : (
							<Grid className="font-bold text-sm text-card_header_color">
								แบบเคลื่อนที่ (Mobile)
							</Grid>
						)}
					</Grid>
				</Grid>
				<Divider />
				<Grid className="py-5">
					<StandardReportForm
						flags={flags}
						type={type}
						preField={preField}
						onFillForm={onFillForm}
						fixedGeneralAnswers={fixedGeneralAnswers}
						fixedInfoAnswers={fixedInfoAnswers}
						fixedSoundAnswers={fixedSoundAnswers}
						mobileGeneralAnswers={mobileGeneralAnswers}
						mobileInfoAnswers={mobileInfoAnswers}
						mobileSoundAnswers={mobileSoundAnswers}
						// Validation
						currentSelectedError={currentSelectedError}
						openValidationModal={openValidationModal}
						onOpenValidationModal={onOpenValidationModal}
						onCloseValidationModal={onCloseValidationModal}
						// Document Status ID
						documentStatusID={documentStatusID}
					/>
				</Grid>
			</CardContent>
		</Card>
	)
}
