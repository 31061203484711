import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, Button, FormControlLabel, Switch } from '@material-ui/core';
import { ListAlt, Dashboard } from '@material-ui/icons';



import { sdocAdmin, admin, adminQoS } from 'api/index'

export default function Index() {
	const [tableauTicket, setTableauTicket] = useState('');
	const [tableauMode, setTableauMode] = useState(true);

	const toggleTableauMode = () => {
		setTableauMode(!tableauMode);
	};

	const initialize = async () => {
		await getTableauTicket();
		await loadScripts();
	};

	const getTableauTicket = async () => {
		try {
			const response = await sdocAdmin.getTableauTicket();
			if (response.data && response.data.tableau_ticket) {
				if (tableauTicket !== response.data.tableau_ticket) {
					setTableauTicket(response.data.tableau_ticket);
				}
			} else {
				console.error('Error getting Tableau ticket:', response.data);
			}
		} catch (error) {
			console.error('Error getting Tableau ticket:', error);
		}
	};

	const getTableauTicket2 = async () => {
		try {
			const response = await sdocAdmin.getTableauTicket();
			if (response.data && response.data.tableau_ticket) {
				return response.data.tableau_ticket;
			} else {
				console.error('Error getting Tableau ticket:', response.data);
				return '';
			}
		} catch (error) {
			console.error('Error getting Tableau ticket:', error);
		}
	};


	const loadScripts = async () => {
		try {
			// Load Tableau JavaScript API
			const tableauScript = document.createElement('script');
			tableauScript.src = 'https://dataviz.nbtc.go.th/javascripts/api/viz_v1.js';
			tableauScript.async = true;
			document.head.appendChild(tableauScript);

			// Load Axios
			const axiosScript = document.createElement('script');
			axiosScript.src = 'https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js';
			axiosScript.async = true;
			document.head.appendChild(axiosScript);
		} catch (error) {
			console.error('Error loading scripts:', error);
		}
	};

	useEffect(() => {
		// initialize();
	}, []);

	const updateTableau = async () => {
		// Get the new Tableau ticket
		// await getTableauTicket();
		const ticket = await getTableauTicket2();
		console.log(ticket);
		if (ticket !== '') {
			const tableauGrid = document.querySelector('.tableauGrid');
			if (tableauGrid) {
				tableauGrid.innerHTML = '';
				// Add a new object to the element with class 'tableauGrid'
				const newTableauViz = document.createElement('object');
				newTableauViz.className = 'tableauViz';
				newTableauViz.width = '100%';
				newTableauViz.height = '670';
				newTableauViz.style.display = 'none';
				newTableauViz.innerHTML = `
		<param name="path" value="trusted/${ticket}/t/StdTelAndTech/views/sDoC_for_Web/DB_SDoC${tableauMode ? '' : '_1'}" />
		<param name='tabs' value='no' />
		<param name='toolbar' value='no' />
		<param name='showAppBanner' value='false' />
	  `;

				tableauGrid.appendChild(newTableauViz);
			}

			const existingTableauScripts = document.querySelectorAll('script[src*="dataviz.nbtc.go.th"]');
			existingTableauScripts.forEach(script => script.remove());

			const existingAxiosScripts = document.querySelectorAll('script[src*="cdn.jsdelivr.net"]');
			if (existingAxiosScripts) {
				existingAxiosScripts.forEach(script => script.remove());
			}

			await loadScripts();
		}

	};

	useEffect(() => {
		updateTableau();
	}, [tableauMode]);


	return (
		<>
			<Grid className="flex flex-col">
				<Grid className="py-10 px-3 sm:px-10">
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									{tableauMode ? "ภาพรวมการขอแบบรับรองตนเองของผู้ประกอบการ (SDoC)" : "ตารางรายการเครื่องโทรคมนาคมและอุปกรณ์ที่ผ่านการรับรองตนเองของผู้ประกอบการ (SDoC)"}
								</Grid>
							</Grid>
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Button
									className={`w-48`}
									color="primary"
									variant="contained"
									onClick={toggleTableauMode}>
									<Dashboard style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>{tableauMode ? "แสดงรายการเครื่องฯ" : "แสดงภาพรวม"}</Grid>
								</Button>

							</Grid>
							<Grid className="md:px-5 tableauGrid" style={{ height: '100vh', width: '100%' }}>
								<object className="tableauViz" width='100%' height='670' style={{ display: 'none' }}>
									{tableauMode ?
										<param name="path" value={`trusted/${tableauTicket}/t/StdTelAndTech/views/sDoC_for_Web/DB_SDoC`} />
										:
										<param name="path" value={`trusted/${tableauTicket}/t/StdTelAndTech/views/sDoC_for_Web/DB_SDoC_1`} />
									}
									<param name='tabs' value='no' />
									<param name='toolbar' value='no' />
									<param name='showAppBanner' value='false' />
								</object>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>

		</>
	);
}
