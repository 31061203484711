import { forwardRef, useRef, useEffect, useState } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Slide,
	IconButton,
	useMediaQuery,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export function CancelSDoCModal({
	open,
	onClose,
	header_icon,
	content,
	SDoCID,
	handleCancel,
	handleButton,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		setIsLoading(false)
	}, [open])

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{header_icon}
						<Grid className="text-white">ยกเลิกคำขอยื่นแบบรับรองตนเอง</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					ref={descriptionElementRef}>
					<Grid className="w-full py-10 text-center">คุณต้องการยกเลิกคำขอที่ <span style={{ fontWeight: "bold" }}>{content}</span> ใช่หรือไม่<br />หากท่านยกเลิกแล้วรายการคำขอนี้จะไม่สามารถดำเนินการต่อได้อีก</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					className="w-8/12 mx-auto flex flex-row justify-between items-center"
					component="div">
					<Button
						className="w-5/12"
						onClick={handleCancel}
						color="secondary"
						variant="outlined">
						ยกเลิก
					</Button>
					<Button
						className="w-5/12"
						onClick={e => { handleButton(SDoCID); setIsLoading(true) }}
						color="primary"
						variant="contained"
						disabled={isLoading}>
						{isLoading ? 'กำลังดำเนินการ...' : 'ตกลง'}
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
