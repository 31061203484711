import { forwardRef, useRef } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	IconButton,
	Button,
	useMediaQuery,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export default function NotifyModal({
	open,
	onClose,
	title,
	header_icon,
	content
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	return (
		<Dialog
			className="w-full"
			open={open}
			onClose={onClose}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{header_icon}
						<Grid className="text-white" style={{marginLeft:20}}>{title}</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					component="div"
					ref={descriptionElementRef}>
					<div className="w-full py-10 text-center" style={{color:"#3e3e3e"}} component="div">{content}</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					className="w-full mx-auto flex flex-row justify-center items-center"
					component="div">
					<Button
						className="w-5/12"
						onClick={onClose}
						color="primary"
						variant="contained">
						ตกลง
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
