import { useState } from 'react'
import { Card, Grid, Divider, TextField, Button } from '@material-ui/core'
import { Assignment, Archive } from '@material-ui/icons'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'

// import File from './file'

export default function SDoCLogsCard({
	SDoCLogs,
	isDataLoading = false,
}) {
	const [sortModel, setSortModel] = useState([
		{
			field: 'createDate',
			sort: 'desc',
		},
	]);

	const columnsSDoCLogs = [
		{
			field: 'createDate',
			headerName: 'วันเวลากิจกรรม',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy  HH:mm') : ''}
				</Grid>
			),
		},
		{
			field: 'beforeActionStatus',
			headerName: 'สถานะก่อนการกระทำ',
			width: 230,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'afterActionStatus',
			headerName: 'สถานะหลังการกระทำ',
			width: 230,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'remark',
			headerName: 'รายละเอียด',
			width: 700,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<div
					style={{ fontSize: "14px", lineHeight: "normal", overflow: "auto", maxHeight: "100px", whiteSpace: "pre-line" }}
					dangerouslySetInnerHTML={{ __html: makeLinksClickable(params.value) }}
				/>
			),
		}

	]

	function makeLinksClickable(text) {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
	}
	return (
		<Card className={`border-t-4 border-card_top_border mb-5`}>
			<Grid className="flex flex-col justify-center items-start px-5 mb-5">
				{/* Header */}
				<Grid className="flex flex-row mt-4 mb-2">
					<Assignment style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						Memo Logs
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>

				{/* Files Section */}
				<Grid className="flex flex-col items-start my-2 w-full gap-y-3">
					{/* Heading */}
					<Grid>รายละเอียดกิจกรรมต่าง ๆ </Grid>

					<Grid className="md:px-5" style={{ height: 750, width: '100%' }}>
						<DataGrid
							rows={SDoCLogs}
							columns={columnsSDoCLogs}
							pageSize={5}
							rowsPerPageOptions={[5]}
							disableSelectionOnClick
							sortModel={sortModel}
							rowHeight={125}
							components={{
								NoRowsOverlay: () => {
									if (!isDataLoading) {
										return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
									} else {
										return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
									}
								}
							}}
						// whiteSpace="normal"
						// onSortModelChange={(model) => {
						// 	handleSortChange(model)
						// }}
						/>
					</Grid>
					<Grid className="flex flex-row gap-x-3 items-end w-full">
						<Grid className="flex flex-col">
						</Grid>
					</Grid>

				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>
				{/* Uploaded File */}
			</Grid>
		</Card>
	)
}
