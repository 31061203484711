import { forwardRef, useRef, useEffect, useState } from 'react'
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Slide,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	TextField,
	Divider,
	Checkbox,
	FormControlLabel,
	FormControl,
	Select,
	MenuItem,
} from '@material-ui/core'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'
import { Close, PersonAdd, Edit } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'
import { org_duty } from '../../../../data/organization-user/organization-user-duty'
import { org_status } from '../../../../data/organization-user/organization-user-status'

export default function OrganizationUserModal({
	open,
	handleButton,
	onClose,
	userModalType,
	// Adding States
	nationalCardIDNo,
	userInfo,
	activeDate,
	expireDate,
	fixedTypePermission,
	mobileTypePermission,
	// Editing States
	editNationalCardIDNo,
	editUserInfo,
	editActiveDate,
	editExpireDate,
	editFixedTypePermission,
	editMobileTypePermission,
	// Adding Handler
	onFillNationalCardIDNo,
	onCheckFormTypePermission,
	onChangeUserRoleID,
	onSelectActiveDate,
	onSelectExpireDate,

	organizationData,
	// Editing Handler
	onChangeEditUserRoleID,
	onFillEditNationalCardIDNo,
	onCheckEditFormTypePermission,
	onSelectEditActiveDate,
	onSelectEditExpireDate,
	onFillEditUserInfo,
	onChangeEditUserStatus,
	// Save Button Logic
	saveButton,

}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)
	const [isOpenDate, setIsOpenDate] = useState(null)

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [open])

	const typeGenerator = _userModalType => {
		if (_userModalType === 'add')
			return {
				label: 'เพิ่มผู้ใช้งาน',
				icon: <PersonAdd htmlColor="#fff" className="mr-1" />,
			}
		if (_userModalType === 'edit')
			return {
				label: 'แก้ไขข้อมูลผู้ใช้งาน',
				icon: <Edit htmlColor="#fff" className="mr-1" />,
			}

		return { label: '', icon: '' }
	}

	return (
		<Dialog
			onBackdropClick={onClose}
			className="w-full"
			open={open}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle className="bg-card_top_border">
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center">
						{typeGenerator(userModalType).icon}
						<Typography variant="h6" className="text-white">
							{typeGenerator(userModalType).label}
						</Typography>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent className="h-full" dividers>
				<Grid className="my-3">
					<Typography>ข้อมูลเจ้าหน้าที่</Typography>
				</Grid>
				<Grid>
					<Divider />
				</Grid>
				<Grid className="flex flex-col mt-5">
					<Grid className="w-full lg:w-8/12">
						<Grid className="flex flex-row">
							<Typography>เลขประจำตัวประชาชน</Typography>
							<Typography className="text-red-500">*</Typography>
						</Grid>
						<Grid className="gap-x-3 flex flex-row">
							{userModalType === 'add' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									inputProps={{ maxlength: 13 }}
									// placeholder="เลขประจำตัวประชาชน"
									value={nationalCardIDNo}
									onChange={onFillNationalCardIDNo}
								/>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									// placeholder="เลขประจำตัวประชาชน"
									value={editNationalCardIDNo}
									onChange={onFillEditNationalCardIDNo}
								/>
							)}
						</Grid>
					</Grid>
					<Grid className="flex flex-col lg:flex-row items-center w-8/12 gap-y-5 gap-x-8 lg:gap-y-0 mt-5 ">
						<Grid className="w-full">
							<Grid className="flex flex-row">
								<Typography>ชื่อ</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<Grid
									className={`w-auto ${userInfo.firstname === 'ชื่อ'
											? 'text-gray-400'
											: 'text-black'
										} border border-gray-300 p-2 rounded-md mt-1 h-10`}>
									{userInfo.firstname}
								</Grid>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="firstname"
									value={editUserInfo.firstname}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
						<Grid className="w-full">
							<Grid className="flex flex-row">
								<Typography>นามสกุล</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<Grid
									className={`w-auto ${userInfo.lastname === 'นามสกุล'
											? 'text-gray-400'
											: 'text-black'
										} border border-gray-300 p-2 rounded-md mt-1 h-10`}>
									{userInfo.lastname}
								</Grid>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="lastname"
									value={editUserInfo.lastname}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
					</Grid>
					<Grid className="flex flex-col lg:flex-row items-center gap-x-8 gap-y-5 lg:gap-y-0 mt-5">
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>ประเภทเจ้าหน้าที่</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							<Grid className="w-full py-2 mx-auto">
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										value={
											userModalType === 'add'
												? userInfo.userRoleID
												: editUserInfo.userRoleID
										}
										onChange={
											userModalType === 'add'
												? onChangeUserRoleID
												: onChangeEditUserRoleID
										}>
										{Object.values(org_duty).map((item, index) => {
											return (
												<MenuItem key={index} value={item.value}>
													{item.name}
												</MenuItem>
											)
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>อีเมล</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<Grid
									className={`w-auto ${userInfo.email === 'อีเมล' ? 'text-gray-400' : 'text-black'
										} border border-gray-300 p-2 rounded-md mt-1 h-10`}>
									{userInfo.email}
								</Grid>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="email"
									value={editUserInfo.email}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>เบอร์โทรศัพท์</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<Grid
									className={`w-auto ${userInfo.mobile === 'เบอร์โทรศัพท์'
											? 'text-gray-400'
											: 'text-black'
										} border border-gray-300 p-2 rounded-md mt-1 h-10`}>
									{userInfo.mobile}
								</Grid>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="mobile"
									value={editUserInfo.mobile}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
				{(userModalType === 'add'
					? userInfo.userRoleID === 2
					: editUserInfo.userRoleID === 2) && (
						<>
							<Grid className="my-3 mt-10">
								<Typography>ประเภทสิทธิ์ในการเพิ่ม / แก้ไข</Typography>
							</Grid>
							<Grid>
								<Divider />
							</Grid>
							<Grid className="flex flex-col lg:flex-row lg:gap-3 gap-x-10">
								{organizationData.operatorQOS.find(e => e.id === 2) && (
									<>
										{userModalType === 'add' && (
											<FormControlLabel
												checked={fixedTypePermission}
												onChange={e =>
													onCheckFormTypePermission(2, e.target.checked)
												}
												control={
													<Checkbox
														color="primary"
														inputProps={{ 'aria-label': 'secondary checkbox' }}
													/>
												}
												label="ชุดข้อมูลแบบประจำที่"
											/>
										)}
										{userModalType === 'edit' && (
											<FormControlLabel
												checked={editUserInfo.operatorUserQOSTypes.fixed === true}
												onChange={e =>
													onCheckEditFormTypePermission(2, e.target.checked)
												}
												control={
													<Checkbox
														color="primary"
														inputProps={{ 'aria-label': 'secondary checkbox' }}
													/>
												}
												label="ชุดข้อมูลแบบประจำที่ แก้ไข"
											/>
										)}
									</>
								)}

								{organizationData.operatorQOS.find(e => e.id === 1) && (
									<>
										<>
											{userModalType === 'add' && (
												<FormControlLabel
													checked={mobileTypePermission}
													onChange={e =>
														onCheckFormTypePermission(1, e.target.checked)
													}
													control={
														<Checkbox
															color="primary"
															inputProps={{ 'aria-label': 'secondary checkbox' }}
														/>
													}
													label="ชุดข้อมูลแบบเคลื่อนที่"
												/>
											)}
											{userModalType === 'edit' && (
												<FormControlLabel
													checked={editUserInfo.operatorUserQOSTypes.mobile}
													onChange={e =>
														onCheckEditFormTypePermission(1, e.target.checked)
													}
													control={
														<Checkbox
															value={editUserInfo.operatorUserQOSTypes.mobile}
															color="primary"
															inputProps={{ 'aria-label': 'secondary checkbox' }}
														/>
													}
													label="ชุดข้อมูลแบบเคลื่อนที่ แก้ไข"
												/>
											)}
										</>
									</>
								)}
							</Grid>
						</>
					)}
				{/* <Grid className="my-3 mt-10 flex flex-row items-center">
					<Typography>ระยะเวลาการใช้งาน</Typography>
				</Grid> */}
				{/* <Grid>
					<Divider />
				</Grid> */}
				{!editActiveDate && !editExpireDate && (
					<Grid className="mt-10">
						<FormControlLabel
							control={
								<Checkbox
									value={isOpenDate}
									onChange={e => setIsOpenDate(e.target.checked)}
									color="primary"
								/>
							}
							label="กำหนดระยะเวลาการใช้งาน"
						/>
					</Grid>
				)}
				{(isOpenDate || (editActiveDate && editExpireDate)) && (
					<Grid className="flex flex-row">
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid className="flex flex-row gap-x-10">
								<KeyboardDatePicker
									margin="normal"
									label="วันที่เริ่มต้นใช้งาน"
									views={['date', 'month', 'year']}
									format="dd/MM/yyyy"
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									value={userModalType === 'add' ? activeDate : editActiveDate}
									onChange={
										userModalType === 'add'
											? onSelectActiveDate
											: onSelectEditActiveDate
									}
								/>
								<KeyboardDatePicker
									margin="normal"
									label="วันที่หมดอายุใช้งาน"
									views={['year', 'month', 'date']}
									format="dd/MM/yyyy"
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									value={userModalType === 'add' ? expireDate : editExpireDate}
									onChange={
										userModalType === 'add'
											? onSelectExpireDate
											: onSelectEditExpireDate
									}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>
				)}
				{userModalType === 'edit' && (
					<>
						<Grid className="my-3 mt-10">
							<Typography>สถานะของผู้ใช้งาน</Typography>
						</Grid>
						<Grid>
							<Divider />
						</Grid>
						<Grid className="w-full md:w-3/12 py-2">
							<FormControl variant="outlined" fullWidth size="small">
								{editUserInfo.userStatus.id !== 4 && (
									<Select
										name={'user_status'}
										value={editUserInfo.userStatus.id}
										onChange={onChangeEditUserStatus}>
										{Object.keys(org_status)
											.filter(e => parseInt(e) !== 4 && parseInt(e) !== 3)
											.map((item, index) => {
												return (
													<MenuItem key={index} value={item}>
														{org_status[item]}
													</MenuItem>
												)
											})}
									</Select>
								)}

								{editUserInfo.userStatus.id === 4 && `-- รอเปิดใช้งาน --`}
							</FormControl>
						</Grid>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="flex-end" alignItems="center">
					<Grid item className="p-3 w-3/12">
						<Button
							fullWidth
							onClick={handleButton}
							color="primary"
							variant="contained"
							disabled={saveButton}
						>
							บันทึก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
