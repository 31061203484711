import { forwardRef, useRef, useState, useEffect } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	IconButton,
	Button,
	useMediaQuery,
	LinearProgress,
	Box,
	Typography,
	CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types';

import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

function LinearProgressWithLabel(props) {
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography variant="body2" color="textSecondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate and buffer variants.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
};

export default function SureModal({
	open,
	onClose,
	title,
	header_icon,
	content,
	handleCancel,
	handleButton,
	ProgressValue,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		setIsLoading(false)
	}, [open])

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{header_icon}
						<Grid className="text-white">{title}</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					ref={descriptionElementRef}>
					<Grid className="w-full py-10 text-center">{content}</Grid>
					<Grid className="w-full py-10 text-center"
						hidden={!isLoading}
					>
						{ProgressValue ? (
							<>
								ความคืบหน้าในการดำเนินการ
								<LinearProgressWithLabel value={ProgressValue} />
							</>
						)
							:
							(
								// show loading spinner
								<Box display="flex" justifyContent="center">
									<Box m={1}>
										<CircularProgress />
									</Box>
								</Box>
							)}
						โปรดรอสักครู่ ห้ามปิดหน้าต่างนี้ในขณะที่กำลังดำเนินการ
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					className="w-8/12 mx-auto flex flex-row justify-between items-center"
					component="div">
					<Button
						className="w-5/12"
						onClick={handleCancel}
						color="secondary"
						variant="outlined"
						disabled={isLoading}
						hidden={isLoading}>
						ยกเลิก
					</Button>
					<Button
						className="w-5/12"
						onClick={e => { handleButton(e); setIsLoading(true) }}
						color="primary"
						variant="contained"
						disabled={isLoading}>
						{isLoading ? 'กำลังดำเนินการ...' : 'ตกลง'}
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
