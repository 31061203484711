import Axios from 'axios'
import { API_BASE_END_POINT } from '.'
import store from '../store'

// Instance Configuration

const instance = Axios.create({ baseURL: 'https://api.ap-southeast-a.apiconnect.ibmappdomain.cloud/nbtc-apigateway/nbtc' })

instance.interceptors.request.use(config => {
	const appType = store.getState().appModel.app_type

	let token = ''
	if (appType === 'users') {
		token = store.getState().userModel.user
			? `Bearer ${store.getState().userModel.user.token}`
			: ''
	}
	else if (appType === 'admin')
		token = store.getState().QoSAdminModel.admin
			? `Bearer ${store.getState().QoSAdminModel.admin.token}`
			: ''
	else if (appType === 'sdoc')
		token = store.getState().sdocUserModel.userSDOC
			? `Bearer ${store.getState().sdocUserModel.userSDOC.token}`
			: ''
	else if (appType === 'sdoc-admin')
		token = store.getState().sdocAdminModel.adminSDOC
			? `Bearer ${store.getState().sdocAdminModel.adminSDOC.token}`
			: ''
	else if (appType === 'main-admin')
		token = store.getState().mainAdminModel.admin
			? `Bearer ${store.getState().mainAdminModel.admin.token}`
			: ''

	config.headers.common['Authorization'] = token
	config.headers.common['X-IBM-Client-Id'] = '552ae0956efbab0ebbf72afa71fb89e8'

	return config
})

instance.interceptors.response.use(
	response => {
		const app_type = store.getState().appModel.app_type
		const userSDOC = store.getState().sdocUserModel.userSDOC
		const signOutUser = store.getActions().sdocUserModel.signOutUser
		const expireDate = store.getState().sdocUserModel.userLoginExpireDate
		// alert(expireDate + " - " + new Date().getTime())
		if (app_type === 'sdoc') {
			if ((expireDate != null) && new Date().getTime() > expireDate) {
				signOutUser()
				alert("หมดเวลาใช้งาน กรุณาเข้าสู่ระบบใหม่")
				.then(() => window.location.href = '/sdoc/sign-in')
			}
			if(userSDOC !== null && expireDate == null){
				signOutUser()
				alert("กรุณาเข้าสู่ระบบใหม่")
				.then(() => window.location.href = '/sdoc/sign-in')
			}
		}
		return response
	},
	error => {
		const setUser = store.getActions().userModel.set
		const setAdmin = store.getActions().QoSAdminModel.setAdmin
		const setSDOCUser = store.getActions().sdocUserModel.setUserSDOC
		const setSDOCAdmin = store.getActions().sdocAdminModel.setAdminSDOC
		const app_type = store.getState().appModel.app_type

		if (error.response.status === 401) {
			if (app_type === 'users') setUser(null)
			else if (app_type === 'admin') setAdmin(null)
			else if (app_type === 'sdoc') setSDOCUser(null)
			else if (app_type === 'sdoc-admin') setSDOCAdmin(null)
			alert('ไม่มีสิทธิใช้งานระบบ')
		}

		// const expireDate = store.getState().sdocUserModel.expireDate
		// if (expireDate && new Date().getTime() > expireDate) {
		// 	setSDOCUser(null)
		// 	alert('ไม่มีสิทธิใช้งานระบบ')
		// }



		return Promise.reject(error)
	}
)

// Instance Utilities

export const adminHeader = {
	'Admin-Request': 1,
}

export default instance
