import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import {
	Grid,
	Typography,
	Step,
	Stepper,
	StepConnector,
	StepLabel,
	Button,
} from '@material-ui/core'
import { Description, Spellcheck, Check, Assignment } from '@material-ui/icons'
import { convertNumber } from 'utils/convertExcelNumber'
import SomethingWentWrongModal from '@components/users/standard-report-validation/something-went-wrong-modal'
import SendingStandardReportModalSuccess from '../../../../components/admin/standard-report/modal'

import { useParams } from 'react-router-dom'
import getStepContent from '../../../../components/admin/standard-report/index'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useStoreRehydrated } from '../../../../store'
import { datasetRecord } from '../../../../api/index'
import { useLoaded } from '../../../../utils/useLoaded'
import { format } from 'date-fns'
const nonFloatDataSetItemIDs = [
	337,
	338,
	339,
	340,
	341,
	342,
	343,
];
const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	non_approve_button: {
		backgroundColor: '#c33939',
		color: 'white',
		'&:hover': {
			backgroundColor: '#a33e3e',
		},
	},
}))

const Connector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundColor: '#a74345',
		},
	},
	completed: {
		'& $line': {
			backgroundColor: '#a74345',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector)

const useStepStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundColor: '#a74345',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundColor: '#a74345',
	},
})

function getSteps() {
	return ['รายละเอียดการส่ง', 'รายละเอียดรายงาน', 'อนุมัติข้อมูล']
}

export default function StandardReportValidationDetails({ match }) {
	const isReHydrated = useStoreRehydrated()
	const history = useHistory()
	const { id } = useParams()
	const classes = useStyles()
	const steps = getSteps()
	const [activeStep, setActiveStep] = useState(0)
	const [validateStep3, setValidateStep3] = useState(false)
	const [openStandardReportSuccessModal, setOpenStandardReportSuccessModal] =
		useState(false)
	// Type of Standard Report
	const [reportTypes, setReportTypes] = useState([])
	// Current Page of Required Filled Standard Report Form
	const [currentFilledFormIndex, setCurrentFilledFormIndex] = useState(0)
	// Standard Report Form Answers
	const [fixedGeneralAnswers, setFixedGeneralAnswers] = useState([])
	const [fixedInfoAnswers, setFixedInfoAnswers] = useState([])
	const [fixedSoundAnswers, setFixedSoundAnswers] = useState([])
	const [mobileGeneralAnswers, setMobileGeneralAnswers] = useState([])
	const [mobileInfoAnswers, setMobileInfoAnswers] = useState([])
	const [mobileSoundAnswers, setMobileSoundAnswers] = useState([])
	const loaded = useLoaded()
	const [dataSetFilesUpload, setDataSetFilesUpload] = useState({})
	const [signature, setSignature] = useState(null)

	const [operatorFlag, setOperatorFlag] = useState({
		isPayphone: null,
		is2G: null,
	})
	const [currentSelectedError, setCurrentSelectedError] = useState({
		dataSetItemID: '',
		reason: '',
		solution: '',
		periodSolution: '',
		validateFileUrl: null,
		no: '',
		label: '',
	})
	const [openValidationModal, setOpenValidationModal] = useState(false)
	const [openSomethingWentWrongModal, setSomethingWentWrongModal] =
		useState(false)
	const [documentStatusID, setDocumentStatusID] = useState(0)

	const [rejectReason, setRejectReason] = useState('')

	/** ---------------------------------------------- */

	/**  Main Handler Function */

	const onOpenStandardReportSuccessModal = () =>
		setOpenStandardReportSuccessModal(!openStandardReportSuccessModal)

	const onAgreeStandardReportSuccessModalCTA = () => {
		onOpenStandardReportSuccessModal()
		history.push({
			pathname: '/admin/standard-report',
		})
	}

	const changeValidationStep3 = tempValidation => {
		setValidateStep3(tempValidation)
	}

	const selectSignature = ID => {
		setSignature(ID)
	}

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const changeStatusReportHead = async () => {
		// if(!signature){
		// 	alert("กรุณาแนบลายเซนต์");
		// 	return false;
		// }
		const { status } = await datasetRecord.changeStatusReportHeadAdmin(
			id,
			5,
			signature,
			rejectReason
		)
		if (status === 200) onOpenStandardReportSuccessModal()
	}

	function StepperIcon(props) {
		const classes = useStepStyles()
		const { active, completed } = props

		const icons = {
			1: <Assignment />,
			2: <Description />,
			3: <Spellcheck />,
		}

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed,
				})}>
				{completed ? <Check /> : icons[String(props.icon)]}
			</div>
		)
	}

	StepperIcon.propTypes = {
		active: PropTypes.bool,
		completed: PropTypes.bool,
		icon: PropTypes.node,
	}

	const [rowData, setRowData] = useState({})

	const onLanding = useCallback(async () => {
		const res = await datasetRecord.getListDetailsAdmin(id)

		const tempFixedGeneralAnswers = []
		const tempFixedSoundAnswers = []
		const tempFixedInfoAnswers = []
		const tempMobileGeneralAnswers = []
		const tempMobileSoundAnswers = []
		const tempMobileInfoAnswers = []

		/**
		 * Answer Object Example:
		 *    {
		 *    	dataSetItemID: Integer,
		 *      dataValueMonth1: String,
		 *      dataValueMonth2: String,
		 *      dataValueMonth3: String,
		 *      dataValueMonthAvg: String,
		 * 		reason: String,
		 * 		solution: String,
		 * 		periodSolution: String,
		 * 		validateFileUrl: String
		 *    }
		 */

		if (res) {
			// Flags
			setOperatorFlag({
				isPayphone: res?.info?.isPayphone,
				is2G: res?.info?.is2G,
			})

			// ANSWERS

			res.data.forEach(item => {
				var newitem = {};
				if (nonFloatDataSetItemIDs.includes(item.dataSetItemID)) {
					newitem = {
						dataSetItemID: item.dataSetItemID,
						dataValueMonth1: item.dataValueMonth1
							? item.dataValueMonth1
							: '',
						dataValueMonth2: item.dataValueMonth2
							? item.dataValueMonth1
							: '',
						dataValueMonth3: item.dataValueMonth3
							? item.dataValueMonth3
							: '',
						dataValueMonthAvg: item.dataValueMonthAvg
							? item.dataValueMonthAvg
							: '',
						dataValueExtra: item.dataValueExtra
							? item.dataValueExtra
							: '',
					}
				} else {
					newitem = {
						...item,
						dataSetItemID: item.dataSetItemID,
						dataValueMonth1: item.dataValueMonth1
							? convertNumber(item.dataValueMonth1, true)
							: '',
						dataValueMonth2: item.dataValueMonth2
							? convertNumber(item.dataValueMonth2, true)
							: '',
						dataValueMonth3: item.dataValueMonth3
							? convertNumber(item.dataValueMonth3, true)
							: '',
						dataValueMonthAvg: item.dataValueMonthAvg
							? convertNumber(item.dataValueMonthAvg, true)
							: '',
					}
				}
				if (item.dataSetItemID <= 10) tempFixedGeneralAnswers.push(newitem)
				else if (item.dataSetItemID <= 20) tempFixedSoundAnswers.push(newitem)
				else if (item.dataSetItemID <= 30) tempFixedInfoAnswers.push(newitem)
				else if (item.dataSetItemID <= 40)
					tempMobileGeneralAnswers.push(newitem)
				else if (item.dataSetItemID <= 200) tempMobileSoundAnswers.push(newitem)
				else if (item.dataSetItemID > 200) tempMobileInfoAnswers.push(newitem)
			})

			let tempReportTypes = []

			if (tempFixedGeneralAnswers.length !== 0)
				tempReportTypes.push('fixed-general')

			if (tempFixedSoundAnswers.length !== 0)
				tempReportTypes.push('fixed-sound')

			if (tempFixedInfoAnswers.length !== 0) tempReportTypes.push('fixed-info')

			if (tempMobileGeneralAnswers.length !== 0)
				tempReportTypes.push('mobile-general')

			if (tempMobileSoundAnswers.length !== 0)
				tempReportTypes.push('mobile-sound')

			if (tempMobileInfoAnswers.length !== 0)
				tempReportTypes.push('mobile-info')

			// setFormAmount(tempFormAmount)
			setReportTypes(tempReportTypes)

			setFixedGeneralAnswers(tempFixedGeneralAnswers)
			setFixedSoundAnswers(tempFixedSoundAnswers)
			setFixedInfoAnswers(tempFixedInfoAnswers)
			setMobileGeneralAnswers(tempMobileGeneralAnswers)
			setMobileSoundAnswers(tempMobileSoundAnswers)
			setMobileInfoAnswers(tempMobileInfoAnswers)

			// DATA SET FILES UPLOAD
			const tempFiles = {}

			res.files.forEach(file => {
				if (!tempFiles[file.category]) tempFiles[file.category] = []

				tempFiles[file.category].push(file)
			})

			if (res?.files?.length === 0 && res?.data?.length === 0)
				setSomethingWentWrongModal(true)

			setDataSetFilesUpload(tempFiles)

			let rowDataTemp = {
				name: res.info.operatorUserName,
				companyName: res.info.operatorName,
				submit_date: format(new Date(res.info.sendToAdminAt), 'dd/MM/yyyy'),
				status: res.info.status,
				dataTypeList: res.info.reportType,
				attachSendToAdminFile: res.info.attachSendToAdminFile,
			}
			setRowData(rowDataTemp)

			setDocumentStatusID(res.info.statusID)
		}
	}, [id])

	/** ---------------------------------------------- */

	/** Step 2 */

	const onIncrementCurrentFilledFormIndex = () =>
		setCurrentFilledFormIndex(currentFilledFormIndex + 1)

	const onDecrementCurrentFilledFormIndex = () =>
		setCurrentFilledFormIndex(currentFilledFormIndex - 1)

	const onOpenValidationModal = (
		dataSetItemID,
		reason,
		solution,
		periodSolution,
		validateFileUrl,
		no,
		label
	) => {
		setCurrentSelectedError({
			dataSetItemID,
			reason,
			solution,
			periodSolution,
			validateFileUrl,
			no,
			label,
		})

		setOpenValidationModal(true)
	}

	const onCloseValidationModal = () => setOpenValidationModal(false)

	/** ---------------------------------------------- */

	/**  Main Rendering */

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if (isReHydrated) onLanding()
		// eslint-disable-next-line
	}, [isReHydrated])

	const isRenderCTARight = useMemo(() => {
		if (
			documentStatusID === 1 ||
			documentStatusID === 2 ||
			documentStatusID === 3 ||
			documentStatusID === 5
		) {
			if (
				activeStep === steps.length - 1 &&
				currentFilledFormIndex === reportTypes.length - 1
			)
				return false
			else return true
		}

		return true
	}, [documentStatusID, currentFilledFormIndex, activeStep, steps, reportTypes])

	useEffect(() => {
		if (documentStatusID === 5) history.push('/admin/standard-report')
	}, [documentStatusID, history])

	/** ---------------------------------------------- */

	return (
		loaded && (
			<>
				<Grid className="min-h-screen flex flex-col pb-10 px-2 lg:px-10">
					<Grid className={`${classes.root} h-full`}>
						<Stepper
							style={{ backgroundColor: 'transparent' }}
							alternativeLabel
							activeStep={activeStep}
							connector={<Connector />}>
							{steps.map((label, index) => (
								<Step alternativeLabel key={label + index}>
									<StepLabel StepIconComponent={StepperIcon}>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<Grid>
							{activeStep === steps.length ? (
								<Grid>
									<Typography className={classes.instructions}>
										All steps completed - you&apos;re finished
									</Typography>
								</Grid>
							) : (
								<Grid
									container
									direction="row"
									justifyContent="center"
									className="pb-14 ">
									<Grid container item>
										<Grid
											className={`${classes.instructions} w-full pb-4`}
											component="div">
											{getStepContent(
												activeStep,
												changeValidationStep3,
												selectSignature,
												rowData,
												// Standard Report State,
												reportTypes,
												currentFilledFormIndex,
												// Standard Report Answers
												fixedGeneralAnswers,
												fixedInfoAnswers,
												fixedSoundAnswers,
												mobileGeneralAnswers,
												mobileInfoAnswers,
												mobileSoundAnswers,
												dataSetFilesUpload,
												// Validation
												currentSelectedError,
												openValidationModal,
												onOpenValidationModal,
												onCloseValidationModal,
												// Flags
												operatorFlag,
												// Reject Reason
												rejectReason,
												setRejectReason
											)}
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										justifyContent="space-between"
										alignItems="baseline"
										className="w-full">
										<Grid item>
											<Button
												disabled={activeStep === 0}
												onClick={() => {
													if (activeStep === 1 && currentFilledFormIndex !== 0)
														onDecrementCurrentFilledFormIndex()
													else handleBack()
												}}
												variant="contained"
												className="w-36">
												ย้อนกลับ
											</Button>
										</Grid>
										{isRenderCTARight && (
											<Grid item>
												<Button
													className={
														currentFilledFormIndex === reportTypes.length - 1 &&
															activeStep !== 2
															? 'w-max'
															: 'w-36'
													}
													variant="contained"
													color="secondary"
													disabled={validateStep3 && activeStep === 2}
													onClick={() => {
														if (activeStep === 2) {
															changeStatusReportHead()
														} else {
															if (
																activeStep === 1 &&
																currentFilledFormIndex < reportTypes.length - 1
															)
																onIncrementCurrentFilledFormIndex()
															else if (activeStep !== steps.length - 1)
																handleNext()
														}
													}}>
													<>
														{activeStep !== steps.length - 1
															? activeStep === 0
																? 'ถัดไป'
																: currentFilledFormIndex ===
																	reportTypes.length - 1
																	? 'ไปหน้ายืนยันการตรวจสอบข้อมูล'
																	: 'รายงานถัดไป'
															: 'ยืนยัน'}
													</>
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>

				<SendingStandardReportModalSuccess
					open={openStandardReportSuccessModal}
					handleButton={onAgreeStandardReportSuccessModalCTA}
				/>

				<SomethingWentWrongModal
					open={openSomethingWentWrongModal}
					handleButton={() => history.goBack()}
				/>
			</>
		)
	)
}
