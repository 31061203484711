import { useState, useEffect, useCallback } from 'react'
import { Grid, Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';

import ProfileCardV3 from '@components/sdoc/profile-cardV3'
import SureModal from '@components/SureModal'
import SuccessModal from '@components/modal/SuccessModal'

import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'utils/useQuery'
import {
	// operators,
	sdocUser,
	sdoc,
} from 'api/index'

import { frequency, powerUnit, type_equipment } from 'data/sdoc'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'

import { useStoreState } from 'store/index'

// Intitial State
const CONFIRMATION_INFO_STORAGE_INITIAL_STATE = {
	normal_person: {
		type: 'normal-person',
		prefix: '',
		firstname: '',
		lastname: '',
		name: '',
		cardNo: '',
		address: '',
		addr: '',
		email: '',
		mobile: '',
		district: '',
		amphoe: '',
		province: '',
		zipcode: '',
	},
	organization: {
		type: 'organization',
		branchNo: '',
		prefix: '',
		name: '',
		cardNo: '',
		address: '',
		email: '',
		mobile: '',
		district: '',
		amphoe: '',
		province: '',
		zipcode: '',
	},
}



// Page Description: ข้อมูลผู้ใช้งาน
export default function SDOCProfile() {
	const history = useHistory()
	const location = useLocation()
	const query = useQuery()

	const [operatorId, setOperatorId] = useState('')

	const [AddressList, setAddressList] = useState('')

	// Confirmation Info. Status
	const [currentSenderState, setCurrentSenderState] = useState('normal_person')
	const [confirmationInfoStorage, setConfirmationInfoStorage] = useState(
		CONFIRMATION_INFO_STORAGE_INITIAL_STATE
	)

	// GET POA DATA
	const userSDOC = useStoreState(s => s.sdocUserModel.userSDOC)
	/** <---------------------------------------------------------------------> */

	// Organization Detail Function

	const onChangeSenderType = e => {
		const { value } = e.target
		setCurrentSenderState(value)
	}

	const onChangeUserInfo = e => {
		setConfirmationInfoStorage(prev => ({ ...prev, normal_person: e }))
	}

	const onChangeCompanyInfo = e => {
		setConfirmationInfoStorage(prev => ({ ...prev, organization: e }))
	}

	const onSubmitNormalPerson = async () => {
		const res = await sdocUser.updateMyProfile({
			poa: userSDOC.poa,
			...confirmationInfoStorage.normal_person,
		})

		if (res && res?.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'บันทึกข้อมูลสำเร็จ',
				showConfirmButton: false,
				timer: 1500,
			})
		}
	}

	const onSubmitOrganization = async () => {
		// console.log(confirmationInfoStorage.organization);
		if (confirmationInfoStorage.organization.prefixother !== '' && confirmationInfoStorage.organization.prefix === 'อื่นๆ') {
			confirmationInfoStorage.organization.prefix = confirmationInfoStorage.organization.prefixother;
		}
		const res = await sdocUser.updateOrganizationV2({
			operatorId,
			branchNo: confirmationInfoStorage.organization.branchNo,
			...confirmationInfoStorage.organization,
		})
		if (res && res?.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'บันทึกข้อมูลสำเร็จ',
				showConfirmButton: false,
				timer: 1500,
			})
		}
	}

	const onSubmitOrganizationInfo = async () => {
		console.log(confirmationInfoStorage.organization);
		if (confirmationInfoStorage.organization.prefixother !== '' && confirmationInfoStorage.organization.prefix === 'อื่นๆ') {
			confirmationInfoStorage.organization.prefix = confirmationInfoStorage.organization.prefixother;
		}
		const res = await sdocUser.updateOrganizationInfo({
			operatorId,
			branchNo: confirmationInfoStorage.organization.branchNo,
			...confirmationInfoStorage.organization,
		})
		if (res && res?.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'บันทึกข้อมูลสำเร็จ',
				showConfirmButton: false,
				timer: 1500,
			})
		}
	}

	const onSubmitOrganizationBranch = async () => {
		// console.log(confirmationInfoStorage.organization);
		if (confirmationInfoStorage.organization.prefixother !== '' && confirmationInfoStorage.organization.prefix === 'อื่นๆ') {
			confirmationInfoStorage.organization.prefix = confirmationInfoStorage.organization.prefixother;
		}
		const res = await sdocUser.updateOperatorInfoBranch({
			operatorId,
			branchNo: confirmationInfoStorage.organization.branchNo,
			...confirmationInfoStorage.organization,
		})
		if (res && res?.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'บันทึกข้อมูลสำเร็จ',
				showConfirmButton: false,
				timer: 1500,
			})
		}
	}

	const onSubmitUpdateAgentInfo = async () => {
		// console.log(confirmationInfoStorage.organization);
		if (confirmationInfoStorage.organization.prefixother !== '' && confirmationInfoStorage.organization.prefix === 'อื่นๆ') {
			confirmationInfoStorage.organization.prefix = confirmationInfoStorage.organization.prefixother;
		}
		const res = await sdocUser.updateOperatorAgentInfo({
			operatorId,
			branchNo: confirmationInfoStorage.organization.branchNo,
			...confirmationInfoStorage.organization,
		})
		if (res && res?.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'บันทึกข้อมูลสำเร็จ',
				showConfirmButton: false,
				timer: 1500,
			})
		}
	}

	const getUserProfile = async () => {

		const res = await sdocUser.getMyProfile(userSDOC.poa)
		if (res && res?.status === 200) {
			const data = res.data

			if (data.operators?.length > 0) {
				setOperatorId(data.operators[0].id);
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						normal_person: {
							type: 'normal-person',
							prefix: data.user[0].prefix ?? '',
							firstname: data.user[0].firstname,
							lastname: data.user[0].lastname,
							name: data.user[0].firstname + ' ' + data.user[0].lastname,
							cardNo: data.user[0].nationalIDCardNo,
							branchNo: data.user[0].branchNo ?? '00000',
							address: data.user[0].address ?? '',
							addr: data.user[0].addr ?? '',
							email: data.user[0].email ?? '',
							mobile: data.user[0].mobile ?? '',
							district: data.user[0].district ?? '',
							amphoe: data.user[0].amphoe ?? '',
							province: data.user[0].province ?? '',
							zipcode: data.user[0].zipcode ?? '',
							agentEmail: data.operators[0].agentEmail ?? '',
							agentMobile: data.operators[0].agentMobile ?? '',
						},
						organization: {
							type: 'organization',
							name: data.operators[0].operatorName ?? '',
							cardNo: data.operators[0].taxNo ?? '',
							prefix: data.operators[0].prefix ?? '',
							prefix: (data.operators[0].prefix !== 'บริษัท' &&
								data.operators[0].prefix !== 'ห้างหุ้นส่วน' &&
								data.operators[0].prefix !== 'ห้างหุ้นส่วนจำกัด') ? 'อื่นๆ' : data.operators[0].prefix,
							prefixother: (data.operators[0].prefix !== 'บริษัท' &&
								data.operators[0].prefix !== 'ห้างหุ้นส่วน' &&
								data.operators[0].prefix !== 'ห้างหุ้นส่วนจำกัด') ? data.operators[0].prefix : '',
							branchNo: data.operators[0].operatorInfos.branchNo ?? '00000',
							address: data.operators[0].operatorInfos.address ?? '',
							email: data.operators[0].operatorInfos.email ?? '',
							mobile: data.operators[0].operatorInfos.mobile ?? '',
							district: data.operators[0].operatorInfos.district ?? '',
							amphoe: data.operators[0].operatorInfos.amphoe ?? '',
							province: data.operators[0].operatorInfos.province ?? '',
							zipcode: data.operators[0].operatorInfos.zipcode ?? '',
						},
					}
				})
			} else {
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						normal_person: {
							type: 'normal-person',
							prefix: data.user[0].prefix ?? '',
							firstname: data.user[0].firstname,
							lastname: data.user[0].lastname,
							name: data.user[0].firstname + ' ' + data.user[0].lastname,
							cardNo: data.user[0].nationalIDCardNo,
							branchNo: data.user[0].branchNo ?? '00000',
							address: data.user[0].address ?? '',
							addr: data.user[0].addr ?? '',
							email: data.user[0].email ?? '',
							mobile: data.user[0].mobile ?? '',
							district: data.user[0].district ?? '',
							amphoe: data.user[0].amphoe ?? '',
							province: data.user[0].province ?? '',
							zipcode: data.user[0].zipcode ?? '',
						},
					}
				})
			}
		}
	}

	const getAddressList = async () => {
		const res = await sdocUser.getAddressList()
		if (res && res?.status === 200) {
			const data = res.data
			setAddressList(data)
		}
	}



	/** <---------------------------------------------------------------------> */

	// Initial Fetch
	useEffect(() => {
		getUserProfile()
		getAddressList()
		// eslint-disable-next-line
	}, [])

	// On Unmounting
	useEffect(() => {
		return () => (location.state = null)
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<Grid className="p-10">
				<ProfileCardV3
					confirmationInfo={confirmationInfoStorage}
					addressList={AddressList}
					onChangeUserInfo={onChangeUserInfo}
					onChangeCompanyInfo={onChangeCompanyInfo}
					onChangeSenderType={onChangeSenderType}
					onSubmitNormalPerson={onSubmitNormalPerson}
					onSubmitOrganizationInfo={onSubmitOrganizationInfo}
					onSubmitOrganizationBranch={onSubmitOrganizationBranch}
					onSubmitUpdateAgentInfo={onSubmitUpdateAgentInfo}
					senderState={currentSenderState}
				/>

			</Grid>
		</>
	)
}
