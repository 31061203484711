import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core/'

// import SearchBox from '../../components/organization-user/search-box'
import OrgSearchResult from '../../../components/users/organization-user/search-result/index'
import OrganizationUserModal from '../../../components/users/organization-user/user-modal/index'
import { DeleteUserModal } from '../../../components/users/organization-user/delete-user-modal'
import NotFoundModal from '../../../components/users/organization-user/not-found-modal'
import AddingSuccessModal from '../../../components/users/organization-user/adding-success-modal'
import AddingFailedModal from '../../../components/users/organization-user/adding-failed-modal'
import EditingSuccessModal from '../../../components/users/organization-user/editing-success-modal'
import EditingFailedModal from '../../../components/users/organization-user/editing-failed-modal'
import DeletedSuccessModal from '../../../components/users/organization-user/deleted-success-modal'
import DeletedFailedModal from '../../../components/users/organization-user/deleted-failed-modal/index'

import { useStoreRehydrated } from '../../../store'
import { format } from 'date-fns'
import { useLoaded } from '../../../utils/useLoaded'
import { users, operators } from '../../../api/index'

import { org_status } from '../../../data/organization-user/organization-user-status'

const OrganizationUser = () => {
	const loaded = useLoaded()
	const isRehydrated = useStoreRehydrated()
	// Main Users Data
	const [user, setUser] = useState([])
	const [userData, setUserData] = useState([])
	// Modal State
	const [currentEditUserID, setCurrentEditUserID] = useState(null)
	const [openUserModal, setOpenUserModal] = useState(false)
	const [userModalType, setUserModalType] = useState('add')
	const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
	const [openAddingSuccessModal, setOpenAddingSuccessModal] = useState(false)
	const [openAddingFailedModal, setOpenAddingFailedModal] = useState(false)
	const [openNotFoundModal, setOpenNotFoundModal] = useState(false)
	const [openEditingSuccessModal, setOpenEditingSuccessModal] = useState(false)
	const [openEditingFailedModal, setOpenEditingFailedModal] = useState(false)
	const [openDeletedSuccessModal, setOpenDeletedSuccessModal] = useState(false)
	const [openDeletedFailedModal, setOpenDeletedFailedModal] = useState(false)
	// Adding User State
	const [nationalIDCardNo, setNationalIDCardNo] = useState('')
	const [userInfo, setUserInfo] = useState({
		userRoleID: 1,
		prefix: '',
		firstname: '',
		lastname: '',
		mobile: '',
		email: '',
	})
	const [activeDate, setActiveDate] = useState(null)
	const [expireDate, setExpireDate] = useState(null)
	const [fixedTypePermission, setFixedTypePermission] = useState(false)
	const [mobileTypePermission, setMobileTypePermission] = useState(false)
	const [organizationData, setOrganizationData] = useState(null)
	// Editing User State
	const [editNationalIDCardNo, setEditNationalIDCardNo] = useState('')
	const [editUserInfo, setEditUserInfo] = useState({
		userRoleID: 1,
		prefix: '',
		firstname: '',
		lastname: '',
		mobile: '',
		email: '',
		userStatus: {
			id: '',
			name: '',
		},
		operatorUserQOSTypes: {
			fixed: false,
			mobile: false,
		},
	})
	const [editActiveDate, setEditActiveDate] = useState(null)
	const [editExpireDate, setEditExpireDate] = useState(null)
	const [editFixedTypePermission, setEditFixedTypePermission] = useState(false)
	const [editMobileTypePermission, setEditMobileTypePermission] =
		useState(false)
	// Save Button Logic
	const [saveButtonAdd, setSaveButtonAdd] = useState(true)
	const [saveButtonEdit, setSaveButtonEdit] = useState(true)

	/** -----------------------------------------------------------> */

	// Main Function

	const onOpenUserModal = () => {
		setOpenUserModal(!openUserModal)
	}

	const onOpenDeleteUserModal = () => {
		setOpenDeleteUserModal(!openDeleteUserModal)
	}

	const onOpenNotFoundModal = () => {
		setOpenNotFoundModal(!openNotFoundModal)
	}

	const onOpenAddingSuccessModal = () => {
		setOpenAddingSuccessModal(!openAddingSuccessModal)
	}

	const onOpenAddingFailedModal = () => {
		setOpenAddingFailedModal(!openAddingFailedModal)
	}

	const onOpenEditingSuccessModal = () => {
		setOpenEditingSuccessModal(!openEditingSuccessModal)
	}

	const onOpenEditingFailedModal = () => {
		setOpenEditingFailedModal(!openEditingFailedModal)
	}

	const onOpenDeletedSuccessModal = () => {
		setOpenDeletedSuccessModal(!openDeletedSuccessModal)
	}

	const onOpenDeletedFailedModal = () => {
		setOpenDeletedFailedModal(!openDeletedFailedModal)
	}

	// const onSearhResult = e => {
	// 	const { value } = e.target
	// 	const searchedName = userData.filter(user => {
	// 		const name = user.firstname + ' ' + user.lastname
	// 		return name.includes(value)
	// 	})
	// 	const searchedCitizenID = userData.filter(user =>
	// 		user.nationalIDCardNo.includes(value)
	// 	)

	// 	const mergedSearched = [...searchedName, ...searchedCitizenID]
	// 	const searchedResults = []
	// 	let searchID = 1
	// 	mergedSearched.forEach(i => {
	// 		const find = searchedResults.find(item => item.id === i.id)
	// 		if (!find)
	// 			searchedResults.push({
	// 				id: searchID++,
	// 				userId: i.id ?? '',
	// 				name: i.firstname + ' ' + i.lastname,
	// 				email: i.email ?? '',
	// 				nationalIDCardNo: i.nationalIDCardNo ?? '',
	// 				mobile: i.mobile ?? '',
	// 				userRoleID: i.userRoleID ?? '',
	// 				status: parseInt(i.userStatus.id) ?? '',
	// 			})
	// 	})

	// 	setUser(searchedResults)
	// }
	const getOrganizationData = async () => {
		const res = await operators.getOrganizationData()
		const status = res.status
		const data = res.data
		if (status === 200) {
			setOrganizationData(data)
		}
	}
	const onLanding = async () => {
		const res = await users.getUsers()
		getOrganizationData()
		if (res) {
			if (res.status === 200) {
				const fetchedUsers = res.data

				const preprocessedArr = fetchedUsers.map((item, index) => {
					const tempOperatorUserQOSTypes = []

					if (item.operatorUserQOSTypes) {
						item.operatorUserQOSTypes.forEach(i => {
							tempOperatorUserQOSTypes.push(i.qosTypeID)
						})
					}

					return {
						id: index + 1,
						accessId: item.accessID ?? '',
						userId: item.id ?? '',
						name: (item.firstname ?? '') + ' ' + (item.lastname ?? ''),
						firstname: item.firstname ?? '',
						lastname: item.lastname ?? '',
						email: item.email ?? '',
						nationalIDCardNo: item.nationalIDCardNo ?? '',
						mobile: item.mobile ?? '',
						userRoleID: item?.userRole?.id ?? '',
						userRole: item?.userRole?.userRoleName ?? '',
						activeDate: item.activeDate,
						expireDate: item.expireDate,
						periodActivate: `${item.activeDate
							? format(new Date(item.activeDate), 'dd/MM/yyyy')
							: ''
							}  ${item.expireDate
								? '- ' + format(new Date(item.expireDate), 'dd/MM/yyyy')
								: ''
							} `,
						statusID: item.userStatusID,
						status: item.userStatus?.userStatusName ?? '',
						operatorUserQOSTypes: tempOperatorUserQOSTypes,
					}
				})

				setUserData(fetchedUsers)
				setUser(preprocessedArr)
			}
		}
	}

	/** <----------------------------------------------------------- */

	// Adding User

	const onFillNationalCardIDNo = e => {
		var pattern = /^[0-9\b]+$/
		const { value } = e.target
		if (pattern.test(value) || value === '') {
			setNationalIDCardNo(value)
			setSaveButtonAdd(true)
		}
	}

	const onCheckFormTypePermission = (type, checked) => {
		if (type === 2) setFixedTypePermission(checked)
		else if (type === 1) setMobileTypePermission(checked)
	}

	const onChangeUserRoleID = e => {
		const { value } = e.target
		setUserInfo({ ...userInfo, userRoleID: value })
	}

	const onSelectActiveDate = val => setActiveDate(val)

	const onSelectExpireDate = val => setExpireDate(val)

	const onAddUser = async () => {
		let operatorUserQOSTypes = []
		if (fixedTypePermission && mobileTypePermission)
			operatorUserQOSTypes = [1, 2]
		else if (fixedTypePermission) operatorUserQOSTypes = [2]
		else if (mobileTypePermission) operatorUserQOSTypes = [1]

		await users
			.addUser({
				userRoleID: userInfo.userRoleID,
				// userInfo.firstname,
				// userInfo.lastname,
				// userInfo.mobile,
				nationalIDCardNo: nationalIDCardNo,
				// userInfo.email,
				activeDate: activeDate,
				expireDate: expireDate,
				operatorUserQOSTypes: operatorUserQOSTypes,
			})
			.then(e => {
				onOpenAddingSuccessModal()
				setNationalIDCardNo('')
			})
			.catch(e => {
				onOpenAddingFailedModal()
			})
	}

	// Editing User

	const onFindUserToEdit = () => {
		const finded = user.find(item => item.accessId === currentEditUserID)

		if (finded) {
			const tempOperatorUserQOSTypes = {
				fixed: false,
				mobile: false,
			}

			finded.operatorUserQOSTypes.forEach(item => {
				if (item === 2) tempOperatorUserQOSTypes['fixed'] = true
				if (item === 1) tempOperatorUserQOSTypes['mobile'] = true
			})

			setEditUserInfo({
				userRoleID: finded.userRoleID,
				firstname: finded.firstname,
				lastname: finded.lastname,
				email: finded.email,
				mobile: finded.mobile,
				userStatus: {
					id: finded.statusID,
					name: finded.status,
				},
				operatorUserQOSTypes: tempOperatorUserQOSTypes,
			})
			setEditNationalIDCardNo(finded.nationalIDCardNo)

			finded.operatorUserQOSTypes.forEach(item => {
				if (item === 1) setEditMobileTypePermission(true)
				if (item === 2) setEditFixedTypePermission(true)
			})
			if (finded.activeDate) setEditActiveDate(new Date(finded.activeDate))
			else setEditActiveDate(null)
			if (finded.expireDate) setEditExpireDate(new Date(finded.expireDate))
			else setEditExpireDate(null)
		}
	}

	const onFillEditNationalCardIDNo = e => {
		var pattern = /^[0-9\b]+$/
		const { value } = e.target
		if (pattern.test(value) || value === '') {
			setEditNationalIDCardNo(value)
		}
	}

	const onFillEditUserInfo = e => {
		const { name, value } = e.target
		if (name === 'mobile') {
			var pattern = /^[0-9-\b]+$/
			if (pattern.test(value) || value === '') {
				setEditUserInfo({ ...editUserInfo, [name]: value })
			}
		} else setEditUserInfo({ ...editUserInfo, [name]: value })
	}

	const onChangeEditUserRoleID = e => {
		const { value } = e.target

		setEditUserInfo({ ...editUserInfo, userRoleID: value })
	}

	const onChangeEditUserStatus = e => {
		const { value } = e.target
		setEditUserInfo({
			...editUserInfo,
			userStatus: {
				id: value,
				name: org_status[value],
			},
		})
	}

	const onCheckEditFormTypePermission = (type, checked) => {
		if (type === 1)
			setEditUserInfo(prevState => ({
				...prevState,
				operatorUserQOSTypes: {
					mobile: checked,
					fixed: prevState.operatorUserQOSTypes.fixed,
				},
			}))
		else if (type === 2)
			setEditUserInfo(prevState => ({
				...prevState,
				operatorUserQOSTypes: {
					fixed: checked,
					mobile: prevState.operatorUserQOSTypes.mobile,
				},
			}))
	}

	const onSelectEditActiveDate = val => setEditActiveDate(val)

	const onSelectEditExpireDate = val => setEditExpireDate(val)

	const onEditUser = async () => {
		// operatorUserQOSTypes
		let operatorUserQOSTypes = []
		if (
			editUserInfo.operatorUserQOSTypes.fixed &&
			editUserInfo.operatorUserQOSTypes.mobile
		)
			operatorUserQOSTypes = [1, 2]
		else if (editUserInfo.operatorUserQOSTypes.fixed) operatorUserQOSTypes = [2]
		else if (editUserInfo.operatorUserQOSTypes.mobile)
			operatorUserQOSTypes = [1]

		const res = await users.editUser(
			currentEditUserID,
			editUserInfo.firstname,
			editUserInfo.lastname,
			editUserInfo.mobile,
			editNationalIDCardNo,
			editUserInfo.email,
			editActiveDate,
			editExpireDate,
			operatorUserQOSTypes,
			editUserInfo.userStatus.id,
			editUserInfo.userRoleID
		)
		if (res) {
			if (res.status !== 400) onOpenEditingSuccessModal()
			else onOpenEditingFailedModal()
		}
	}

	// Delete User
	const deleteUserID = async () => {
		onOpenDeleteUserModal()

		const finded = userData.find(item => item.accessID === currentEditUserID)
		if (finded) {
			const res = await users.deleteUser(currentEditUserID)
			const status = res.status
			if (status === 200) {
				onOpenDeletedSuccessModal()
			} else {
				onOpenDeletedFailedModal()
			}
		} else {
			onOpenDeletedFailedModal()
		}
	}

	/** -----------------------------------------------------------> */

	// Main Rendering Function

	// eslint-disable-next-line
	useEffect(() => onFindUserToEdit(), [currentEditUserID, openUserModal])

	useEffect(() => {
		if (isRehydrated) onLanding()
		// eslint-disable-next-line
	}, [isRehydrated])

	useEffect(() => {
		if (openUserModal)
			if (userModalType === 'edit') {
				if (
					!editNationalIDCardNo ||
					!editUserInfo.userRoleID ||
					!editUserInfo.firstname ||
					!editUserInfo.lastname ||
					!editUserInfo.mobile ||
					!editUserInfo.email
				) {
					setSaveButtonEdit(true)
				} else {
					setSaveButtonEdit(false)
				}
			}
	}, [openUserModal, userModalType, editUserInfo, editNationalIDCardNo])

	/** <----------------------------------------------------------- */
	// check input field
	const [isDisabled, setIsDisabled] = useState(true)
	useEffect(() => {
		if (userModalType == 'add') {
			if (nationalIDCardNo == '' || nationalIDCardNo.length < 13) {
				setIsDisabled(true)
			} else {
				setIsDisabled(false)
			}
		} else {
			if (editNationalIDCardNo == '' || editUserInfo.firstname == '' || editUserInfo.lastname == '' || editUserInfo.mobile == '' || editUserInfo.email == '') {
				setIsDisabled(true)
			} else {
				setIsDisabled(false)
			}
		}
	}, [nationalIDCardNo, userInfo, editNationalIDCardNo, editUserInfo])
	return (
		loaded && (
			<>
				<Grid className="min-h-screen flex flex-col px-3 lg:px-10 pb-10">
					{/* <SearchBox /> */}
					<OrgSearchResult
						user={user}
						onOpenUserModal={onOpenUserModal}
						setUserModalType={setUserModalType}
						onOpenDeleteUserModal={onOpenDeleteUserModal}
						// onSearch={onSearhResult}
						setCurrentEditUserID={setCurrentEditUserID}
					/>

					<OrganizationUserModal
						open={openUserModal}
						onClose={onOpenUserModal}
						userModalType={userModalType}
						// Adding States
						nationalCardIDNo={nationalIDCardNo}
						userInfo={userInfo}
						activeDate={activeDate}
						expireDate={expireDate}
						fixedTypePermission={fixedTypePermission}
						mobileTypePermission={mobileTypePermission}
						// Adding Handler
						onFillNationalCardIDNo={onFillNationalCardIDNo}
						onCheckFormTypePermission={onCheckFormTypePermission}
						onChangeUserRoleID={onChangeUserRoleID}
						onSelectActiveDate={onSelectActiveDate}
						onSelectExpireDate={onSelectExpireDate}
						organizationData={organizationData}
						// Edit States
						editNationalCardIDNo={editNationalIDCardNo}
						editUserInfo={editUserInfo}
						editActiveDate={editActiveDate}
						editExpireDate={editExpireDate}
						editFixedTypePermission={editFixedTypePermission}
						editMobileTypePermission={editMobileTypePermission}
						// Editing Handler
						onFillEditNationalCardIDNo={onFillEditNationalCardIDNo}
						onCheckEditFormTypePermission={onCheckEditFormTypePermission}
						onSelectEditActiveDate={onSelectEditActiveDate}
						onSelectEditExpireDate={onSelectEditExpireDate}
						onFillEditUserInfo={onFillEditUserInfo}
						onChangeEditUserRoleID={onChangeEditUserRoleID}
						onChangeEditUserStatus={onChangeEditUserStatus}
						// Main Handler
						handleButton={userModalType === 'add' ? onAddUser : onEditUser}
						// Save Button Logic
						saveButtonAdd={saveButtonAdd}
						saveButtonEdit={saveButtonEdit}
						saveButton={isDisabled}

					/>
					<DeleteUserModal
						open={openDeleteUserModal}
						onClose={onOpenDeleteUserModal}
						onDelete={deleteUserID}
					/>
					<NotFoundModal
						open={openNotFoundModal}
						handleButton={onOpenNotFoundModal}
					/>
					<AddingSuccessModal
						open={openAddingSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenAddingSuccessModal()
							onOpenUserModal()
						}}
					/>
					<AddingFailedModal
						open={openAddingFailedModal}
						handleButton={onOpenAddingFailedModal}
						message="เพิ่มผู้ใช้งานไม่สำเร็จ"
					/>
					<EditingSuccessModal
						open={openEditingSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenEditingSuccessModal()
							onOpenUserModal()
						}}
					/>
					<EditingFailedModal
						open={openEditingFailedModal}
						handleButton={onOpenEditingFailedModal}
						message="แก้ไขข้อมูลผู้ใช้ไม่สำเร็จ"
					/>
					<DeletedSuccessModal
						open={openDeletedSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenDeletedSuccessModal()
						}}
					/>
					<DeletedFailedModal
						open={openDeletedFailedModal}
						handleButton={onOpenDeletedFailedModal}
						message="ลบข้อมูลผู้ใช้ไม่สำเร็จ"
					/>
				</Grid>
			</>
		)
	)
}

export default OrganizationUser
