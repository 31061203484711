import { Grid, IconButton } from '@material-ui/core'
import { Archive } from '@material-ui/icons'
import { AuthenticatedLink } from 'components/AuthenticatedLink'

export default function File({ originalFileName, downloadUrl }) {
	return (
		<Grid className="flex flex-row gap-x-3 items-center">
			<Grid>ชื่อเอกสาร: {originalFileName ?? ''}</Grid>
			<Grid>
				<AuthenticatedLink
					url={downloadUrl.replace('/api', '')}
					filename={originalFileName}>
					<IconButton color="primary">
						<Archive />
					</IconButton>
				</AuthenticatedLink>
			</Grid>
		</Grid>
	)
}
