import { Grid, Button, useMediaQuery } from '@material-ui/core'
import { FormatAlignCenter } from '@material-ui/icons'
import { useTheme, makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
	container: {
		width: '500px',
	},
	success : {
		background:"#009900"
	}
}))

export default function UnqualifiedListItem({
	question_number,
	label,
	handleButton,
	errorMessage,
	isFillAnswer
}) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Grid className={`flex flex-row p-3 ${isMobile ? classes.container : ''}`}>
			<Grid className=" w-1/12 px-5">{question_number}</Grid>
			<Grid className=" w-6/12 px-5">{label}</Grid>
			<Grid className=" w-3/12 px-5">{errorMessage}</Grid>
			<Grid className="w-2/12 px-5 text-center">
				<Button className={isFillAnswer?classes.success:''} color="secondary" variant="contained" onClick={handleButton}>
					<FormatAlignCenter htmlColor="#fff" />
					ระบุเหตุผล
				</Button>
			</Grid>
		</Grid>
	)
}
