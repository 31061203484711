import React, { useState, useEffect } from 'react'
import {
	Grid,
	TextField,
	Card,
	Button,
	Divider,
	FormControlLabel,
	Checkbox,
	Snackbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import logo from 'assets/images/NBTC-logo.png'

import { useStoreState } from 'store'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function RegisterCompany(props) {
	const { goBack, onSubmit: submitRegisterCompany } = props

	const { servicePortalToken } = useStoreState(s => s.userModel)
	const [company, setCompany] = useState({ qosType: [], address: '' })
	const dataDBD = useStoreState(s => s.poa.dataDBD)
	const [openSnack, setOpenSnack] = useState(false)
	const handleCloseSnack = () => {
		setOpenSnack(false)
	}

	useEffect(() => {
		let address = ''
		if (dataDBD.AddressInformations[0]) {
			let addressInfo = dataDBD.AddressInformations[0]
			if (addressInfo.Province === 'กรุงเทพมหานคร') {
				address = `${addressInfo.FullAddress} แขวง ${addressInfo.Tumbol} เขต ${addressInfo.Ampur} ${addressInfo.Province}`
			} else {
				address = `${addressInfo.FullAddress} ตำบล ${addressInfo.Tumbol} อำเภอ ${addressInfo.Ampur} จังหวัด ${addressInfo.Province}`
			}
		}
		let newValue = { ...company, address: address }
		setCompany({ ...newValue, ...dataDBD })
		// eslint-disable-next-line
	}, [])

	const handleFormSubmit = () => {
		// if (company.qosType.length === 0) {
		// 	setOpenSnack(true)
		// 	return false
		// }
		submitRegisterCompany({ _token: servicePortalToken, company: company })
	}

	const handleCheckboxType = e => {
		let newVal = []
		const selectVal = parseInt(e.target.value)
		if (e.target.checked) {
			if (selectVal === 2) {
				newVal = [...company.qosType, 2]
			} else if (selectVal === 1) {
				newVal = [...company.qosType, 1]
			}
		} else if (!e.target.checked) {
			if (selectVal === 2) {
				newVal = company.qosType.filter(e => e !== 2)
			} else if (selectVal === 1) {
				newVal = company.qosType.filter(e => e !== 1)
			}
		}
		setCompany({ ...company, qosType: newVal })
	}
	// <-------------------------------------

	// ------------------------------------->
	// Searching Users

	return (
		<>
			<Card
				className="flex flex-col items-center py-6 px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5"
				style={{ minHeight: 700 }}>
				<Grid className="">
					<img src={logo} alt="NBTC" width="140" />
				</Grid>
				<Grid className="secondary text-2xl" style={{ color: '#a74345' }}>
					ลงทะเบียนระบบ Data Portal
				</Grid>
				<Grid className="w-full mt-6">
					<TextField
						fullWidth
						type="number"
						label="หมายเลขประจำตัวผู้เสียภาษี"
						autoFocus={true}
						variant="filled"
						size="small"
						value={company.JuristicID}
					/>
				</Grid>

				<Grid className="w-full my-4">
					<Divider />
				</Grid>
				<Grid className="w-full h-72">
					<>
						<TextField
							fullWidth
							type="text"
							label="ชื่อบริษัท*"
							variant="outlined"
							size="small"
							readOnly={true}
							value={company.JuristicName_TH}
							className="w-full h-16 mt-2"
						/>
						<TextField
							fullWidth
							type="text"
							label="ที่อยู่บริษัท*"
							variant="outlined"
							size="small"
							readOnly={true}
							multiline
							rows={5}
							value={company.address}
							className="w-full h-36 mt-2"
						/>

						{/* <>
							<Grid>ชุดข้อมูล</Grid>
							<Grid className="w-full">
								<FormControlLabel
									control={
										<Checkbox
											checked={company.qosType.includes(2)}
											value="2"
											onClick={handleCheckboxType}
										/>
									}
									label={'ชุดข้อมูลแบบประจำที่'}
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={company.qosType.includes(1)}
											value="1"
											onClick={handleCheckboxType}
										/>
									}
									label={'ชุดข้อมูลแบบเคลื่อนที่'}
								/>
							</Grid>
						</> */}
					</>
				</Grid>

				<Grid className="flex sm:flex-row flex-col items-center justify-between w-full mt-4 gap-y-4">
					<Grid className="sm:w-5/12 w-full">
						<Button
							className="text-white w-full"
							variant="outlined"
							color="secondary"
							onClick={goBack}
							size="medium">
							ย้อนกลับ
						</Button>
					</Grid>
					<Grid className="sm:w-5/12 w-full">
						<Button
							className="text-white w-full"
							variant="contained"
							color="secondary"
							onClick={handleFormSubmit}
							size="medium">
							ยืนยันการลงทะเบียน
						</Button>
					</Grid>
				</Grid>

				{/* <Snackbar
					open={openSnack}
					autoHideDuration={6000}
					onClose={handleCloseSnack}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert onClose={handleCloseSnack} severity="warning">
						ต้องเลือกอย่างน้อย 1 ชุดข้อมูล
					</Alert>
				</Snackbar> */}
			</Card>
		</>
	)
}
