import API from '../config/axios'

const sDOCUser = {
	getMe: () => {
		return API.get('/SDocUsers/me')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getMyProfile: async (taxNo) => {
		// console.log(taxNo);
		const form = new FormData()
		form.append('taxNo', taxNo)
		return API({
			url: `/SDocUsers/profile`,
			method: 'POST',
			data: form,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	updateMyProfile: async (payload) => {
		return API.post(`/SDocUsers/profile/selfupdate`, payload)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	updateOrganization: (payload) => {
		return API.post(`/SDocUsers/profile/${payload.operatorId}/update`, payload)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			}
			)
	},
	updateOrganizationV2: (payload) => {
		console.log(payload);
		return API.post(`/SDocUsers/profile/update`, payload)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			}
			)
	},
	getMeAdmin: () => {
		return API.get('/SDocAdmins/me')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	addUser: (firstname, lastname, mobile, nationalIDCardNo, email) => {
		return API.post('/SDocUsers', {
			firstname,
			lastname,
			mobile,
			nationalIDCardNo,
			email,
		})
	},
	signInSDOCUser: async (payload) => {
		const res = await API.post('/SDocUsers/authenticate', payload)

		if (res) {
			if (res.status === 200) {
				return res
			} else {
				console.error(res)
				return false
			}
		}
	},
	signInSDOCAdmin: async (email, password) => {
		const res = await API.post('/SDocAdmins/authenticate', {
			email,
			password,
		})

		if (res) {
			if (res.status === 200) {
				return res
			} else {
				console.error(res)
				return false
			}
		}
	},
	getAddressList: () => {
		return API.get('/SDocUsers/getAddressList')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	updateOrganizationInfo: (payload) => {
		console.log(payload);
		return API.post(`/SDocUsers/profile/updateOperatorInfo`, payload)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			}
			)
	},
	updateOperatorInfoBranch: (payload) => {
		console.log(payload);
		return API.post(`/SDocUsers/profile/updateOperatorInfoBranch`, payload)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			}
			)
	},
	updateOperatorAgentInfo: (payload) => {
		console.log(payload);
		return API.post(`/SDocUsers/profile/updateOperatorAgentInfo`, payload)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			}
			)
	},
}

export default sDOCUser
