// Main Libraries
import React, { useEffect, useCallback, useState } from 'react'
import { Grid, Card, Select, MenuItem, InputLabel, Input as InputBox } from '@material-ui/core'
import { ListAlt, Menu, SearchOutlined } from '@material-ui/icons'
// Components
import InfoBox from 'components/admin/dashboard/InfoBox'

// Hooks and Functions
import { useStoreState, useStoreActions } from 'store'
import { useStoreRehydrated } from '../../../store'
import { format } from 'date-fns'
// Configuration
import { InfoBoxConfigs } from './config'

import CompareBar from 'components/admin/sent-chart/comparebar'
import CompareBarWithLine from 'components/admin/sent-chart/comparebarwithline'

import { makeStyles } from '@material-ui/core/styles'

import { datasetRecord } from '../../../api/'
import Swal from 'sweetalert2'
import { Input } from '../../../../node_modules/@material-ui/icons/index'
import { Button } from '../../../../node_modules/@material-ui/core/index'
const useStyles = makeStyles(theme => {
	return {
		card_styles: {
			height: 'auto',
		},
		type_of_report: {
			backgroundColor: '#c8e8ff',
			color: '#028BFF',
			borderColor: '#84BFF0',
		},
	}
})
export default function SentChart() {
	const isRehydrated = useStoreRehydrated()
	const [rows, setRows] = useState([
		// Work Status 0 = รอตรวจสอบ, 1 = ตรวจสอบแล้ว, 2 = ไม่อนุมัติ
		// Data Type List 0 = ประจำที่, 1 = เคลื่อนที่
	])
	const [rowsData, setRowsData] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	useEffect(() => {
		const swalModal = Swal.fire({
			title: 'Loading',
			text: 'Please wait...',
			allowOutsideClick: false,
			allowEscapeKey: false,
			showCancelButton: false,
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		if (isDataLoading) {
			// swalModal.showLoading();
		} else {
			swalModal.close();
		}
		return () => {
			// Close the Swal modal when the component unmounts
			swalModal.close();
		};

	}, [isDataLoading]);
	const [statusIDList, setStatusIDList] = useState([])

	const yearList = [2020, 2021, 2022, 2023, 2024];
	const quarterList = [1, 2, 3, 4];
	const [selectedYear, setSelectedYear] = useState(2023);
	const [selectedQuarter, setSelectedQuarter] = useState(2);
	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
	}
	const handleQuarterChange = (event) => {
		setSelectedQuarter(event.target.value);
	}

	const getDatasetRecordList = async () => {
		setIsDataLoading(true)
		const operatorToReport = [185, 224];
		const res = await datasetRecord.getListAdmin()

		if (res) {
			if (res.status === 200) {
				setRowsData(res.data)
				const rowsTemp = []
				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].dataSetReport.year !== selectedYear || res.data[i].dataSetReport.quarter !== selectedQuarter) continue;
					if (!operatorToReport.includes(res.data[i].dataSetReport.operator.id)) continue;
					if (res.data[i].status === 5) {
						const qosType = []

						if (res.data[i].isFixed === 'y') qosType.push('fixed')
						if (res.data[i].isMobile === 'y') qosType.push('mobile')
						const dataSetReportDetail = await datasetRecord.getlistdetails(res.data[i].id)
						const tempObject = {
							id: res.data[i].id,
							name:
								(res.data[i].operatorApproveUser?.firstname ?? '') +
								' ' +
								(res.data[i].operatorApproveUser?.lastname ?? ''),
							organization: res.data[i].dataSetReport.operator.operatorName,
							submit_date: res.data[i].sendToAdminAt
								? format(new Date(res.data[i].sendToAdminAt), 'dd/MM/yyyy')
								: '',
							sendToAdminAt: res.data[i].sendToAdminAt,
							submitType: res.data[i].sendMethod,
							report_detials: res.data[i].id,
							dataTypeList: qosType,
							actions: res.data[i].id,
							yearNQuarter: res.data[i].dataSetReport.year + '/' + res.data[i].dataSetReport.quarter,
							operatorID: res.data[i].dataSetReport.operator.id,
							detail: dataSetReportDetail.data,
						}

						let tempStatusIDList = statusIDList
						tempStatusIDList[i] = {
							report_id: res.data[i].id,
							workStatus: res.data[i].dataSetRecordHeadStatus.id,
							workStatusName: res.data[i].dataSetRecordHeadStatus.statusName,
						}
						setStatusIDList(tempStatusIDList)
						rowsTemp.push(tempObject)
					}
				}

				setRows(rowsTemp)
			}
		}
		setIsDataLoading(false)
	}

	const updateGraphData = async () => {
		setIsDataLoading(true)
		const operatorToReport = [185, 224];
		const res = rowsData
		console.log(selectedYear + " " + selectedQuarter)
		const rowsTemp = []
		for (let i = 0; i < res.length; i++) {
			if (res[i].dataSetReport.year !== selectedYear || res[i].dataSetReport.quarter !== selectedQuarter) continue;
			if (!operatorToReport.includes(res[i].dataSetReport.operator.id)) continue;
			if (res[i].status === 5) {
				const qosType = []
				if (res[i].isFixed === 'y') qosType.push('fixed')
				if (res[i].isMobile === 'y') qosType.push('mobile')
				const dataSetReportDetail = await datasetRecord.getlistdetails(res[i].id)
				const tempObject = {
					id: res[i].id,
					name:
						(res[i].operatorApproveUser?.firstname ?? '') +
						' ' +
						(res[i].operatorApproveUser?.lastname ?? ''),
					organization: res[i].dataSetReport.operator.operatorName,
					submit_date: res[i].sendToAdminAt
						? format(new Date(res[i].sendToAdminAt), 'dd/MM/yyyy')
						: '',
					sendToAdminAt: res[i].sendToAdminAt,
					submitType: res[i].sendMethod,
					report_detials: res[i].id,
					dataTypeList: qosType,
					actions: res[i].id,
					yearNQuarter: res[i].dataSetReport.year + '/' + res[i].dataSetReport.quarter,
					operatorID: res[i].dataSetReport.operator.id,
					detail: dataSetReportDetail.data,
				}

				let tempStatusIDList = statusIDList
				tempStatusIDList[i] = {
					report_id: res[i].id,
					workStatus: res[i].dataSetRecordHeadStatus.id,
					workStatusName: res[i].dataSetRecordHeadStatus.statusName,
				}
				setStatusIDList(tempStatusIDList)
				rowsTemp.push(tempObject)
			}
		}
		setRows(rowsTemp)
		setIsDataLoading(false)
	}



	const classes = useStyles()
	const { adminStat } = useStoreState(s => s.mainAdminModel)
	const getDashboardStat = useStoreActions(
		a => a.mainAdminModel.getAdminDashboardStat
	)

	const initFetch = useCallback(() => {
		getDashboardStat()
	}, [getDashboardStat])

	// eslint-disable-next-line
	useEffect(() => initFetch(), [])
	const [Categories, setCategories] = useState([
		"AWN",
		"TUC",
		"DTN",
		"NT",
	]);
	const CategoriesMOS = [
		"ภาคเหนือ โทรเข้า กรุงเทพฯ",
		"ภาคใต้ โทรเข้า กรุงเทพฯ",
		"ภาคกลาง โทรเข้า กรุงเทพฯ",
		"ภาคตะวันออกเฉียงเหนือ โทรเข้า กรุงเทพฯ",
	];
	const [province1, setProvince1] = useState("ขอนแก่น");
	const [province2, setProvince2] = useState("นครราชสีมา");
	const [province1box, setProvince1box] = useState("ขอนแก่น");
	const [province2box, setProvince2box] = useState("นครราชสีมา");
	const handleProvince1Change = (event) => {
		setProvince1box(event.target.value);
	}
	const handleProvince2Change = (event) => {
		setProvince2box(event.target.value);
	}

	const handleProvinceChange = (event) => {
		setProvince1(province1box);
		setProvince2(province2box);
		updateGraphData();
		prepareData();
	}

	const [SeriesSingle, setSeriesSingle] = useState(Array.from({ length: 13 }, () => ({ data: [], color: "#e56c0a" })));
	const [SeriesCompare, setSeriesCompare] = useState(Array.from({ length: 13 }, (_, index) => {
		return [
			{
				name: 'อัตราส่วน RTT (เส้นทางสายหลักและสายรอง)',
				data: [],
				color: "#30859b",
			},
			{
				name: 'อัตราส่วน RTT (เส้นทางในพื้นที่ชุมชน)',
				data: [],
				color: "#e56c0a",
			}
		];
	}));

	const [SeriesMOS, setSeriesMOS] = useState([
		{
			name: 'AWN',
			data: [100, 100, 100, 100],
			color: "#9fde59",
		}, {
			name: 'TUC',
			data: [100, 100, 100, 100],
			color: "#cc5956",
		}, {
			name: 'DTN',
			data: [100, 100, 100, 100],
			color: "#55b8d7",
		}, {
			name: 'NT',
			data: [92.6, 98.8, 98.5, 93.4],
			color: "#fdcb03",
		}
	]);

	const [SeriesWithLine, setSeriesWithLine] = useState(Array.from({ length: 2 }, (_, index) => {
		return [
			{
				name: 'อัตราส่วน RTT (เส้นทางสายหลักและสายรอง)',
				type: 'column',
				yAxis: 0,
				data: [],
				color: "#30859b",
			}, {
				name: 'อัตราส่วน RTT (เส้นทางในพื้นที่ชุมชน)',
				type: 'column',
				yAxis: 0,
				data: [],
				color: "#e56c0a",
			},
			{
				name: 'RTT (' + province1 + ')',
				type: 'spline',
				yAxis: 1,
				data: [],
				color: "#9dbc53",
			},
			{
				name: 'RTT (' + province2 + ')',
				type: 'spline',
				yAxis: 1,
				data: [],
				color: "#8064a2",
			},
		]
	}));

	const [SeriesWithLine2, setSeriesWithLine2] = useState(Array.from({ length: 20 }, (_, index) => {
		return [
			{
				name: 'อัตราส่วน FTP (เส้นทางสายหลักและสายรอง)',
				type: 'column',
				yAxis: 0,
				data: [],
				color: "#30859b",
			}, {
				name: 'อัตราส่วน FTP (เส้นทางในพื้นที่ชุมชน)',
				type: 'column',
				yAxis: 0,
				data: [],
				color: "#e56c0a",
			},
			{
				name: 'ค่า FTP (สายหลักและสายรอง)',
				type: 'spline',
				yAxis: 1,
				data: [],
				color: "#9dbc53",
			},
			{
				name: 'ค่า FTP (ชุมชน)',
				type: 'spline',
				yAxis: 1,
				data: [],
				color: "#8064a2",
			},
		];
	}));

	const prepareData = () => {
		const CategoriesToSet = [];
		const SeriesSingleToSet = Array.from({ length: 13 }, () => ({ data: [], color: "#e56c0a" }));
		const PreSeriesMOSToSet = ([
			{
				name: 'AWN',
				data: [100, 100, 100, 100],
				color: "#9fde59",
			}, {
				name: 'TUC',
				data: [100, 100, 100, 100],
				color: "#cc5956",
			}, {
				name: 'DTN',
				data: [100, 100, 100, 100],
				color: "#55b8d7",
			}, {
				name: 'NT',
				data: [92.6, 98.8, 98.5, 93.4],
				color: "#fdcb03",
			}
		]);
		const SeriesMOSToSet = PreSeriesMOSToSet.slice(0, rows.length).map((item, index) => ({
			name: item.name,
			data: [],
			color: item.color,
		}));
		const setSeriesCompareToSet = Array.from({ length: 32 }, (_, index) => {
			return [
				{
					name: 'อัตราส่วน RTT (เส้นทางสายหลักและสายรอง)',
					data: [],
					color: "#30859b",
				},
				{
					name: 'อัตราส่วน RTT (เส้นทางในพื้นที่ชุมชน)',
					data: [],
					color: "#e56c0a",
				}
			];
		});
		const SeriesWithLineToSet = Array.from({ length: 2 }, (_, index) => {
			return [
				{
					name: 'อัตราส่วน RTT (เส้นทางสายหลักและสายรอง)',
					type: 'column',
					yAxis: 0,
					data: [],
					color: "#30859b",
				}, {
					name: 'อัตราส่วน RTT (เส้นทางในพื้นที่ชุมชน)',
					type: 'column',
					yAxis: 0,
					data: [],
					color: "#e56c0a",
				},
				{
					name: 'RTT (' + province1 + ')',
					type: 'spline',
					yAxis: 1,
					data: [],
					color: "#9dbc53",
				},
				{
					name: 'RTT (' + province2 + ')',
					type: 'spline',
					yAxis: 1,
					data: [],
					color: "#8064a2",
				},
			]
		});
		const SeriesWithLine2ToSet = Array.from({ length: 20 }, (_, index) => {
			return [

				{
					name: 'อัตราส่วน FTP (เส้นทางสายหลักและสายรอง)',
					type: 'column',
					yAxis: 0,
					data: [],
					color: "#30859b",
				}, {
					name: 'อัตราส่วน FTP (เส้นทางในพื้นที่ชุมชน)',
					type: 'column',
					yAxis: 0,
					data: [],
					color: "#e56c0a",
				},
				{
					name: 'ค่า FTP (สายหลักและสายรอง)',
					type: 'spline',
					yAxis: 1,
					data: [],
					color: "#9dbc53",
				},
				{
					name: 'ค่า FTP (ชุมชน)',
					type: 'spline',
					yAxis: 1,
					data: [],
					color: "#8064a2",
				},
			]
		});
		rows.forEach((row, corekey) => {
			switch (row.operatorID) {
				case 185:
					CategoriesToSet.push("TUC");
					break;
				case 224:
					CategoriesToSet.push("DTN");
					break;
				default:
					break;
			}
			let setSeriesCompareToSetIndex = 0;
			let setSeriesCompareToSetIndex2 = 16;
			row.detail?.forEach((detail, key) => {
				switch (detail.dataSetItemID) {
					case 31:
					case 32:
					case 33:
					case 34:
					case 35:
					case 36:
					case 37:
					case 41:
					case 42:
					case 43:
					case 44:
					case 45:
					case 46:
						SeriesSingleToSet[key].data.push(parseFloat(detail.dataValueMonthAvg));
						break;
					// case 47 to 51
					case 47:
					case 48:
					case 49:
					case 50:
						SeriesMOSToSet[corekey]?.data.push(parseFloat(detail.dataValueMonthAvg));
						break;
					case 202:
					case 203:
					case 204:
					case 205:
					case 304:
					case 306:
					case 206:
					case 207:
					case 305:
					case 307:
						if (detail.dataValueMonth1) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex][0].data.push(parseFloat(detail.dataValueMonth1));
						} else {
							// setSeriesCompareToSet[setSeriesCompareToSetIndex][0].data.push(0);
						}
						if (detail.dataValueMonth2) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex][1].data.push(parseFloat(detail.dataValueMonth2));
						} else {
							// setSeriesCompareToSet[setSeriesCompareToSetIndex][1].data.push(0);
						}
						setSeriesCompareToSetIndex++;
						if (detail.dataSetItemID !== 304 && detail.dataSetItemID !== 305 && detail.dataSetItemID !== 306 && detail.dataSetItemID !== 307) {
							if (detail.dataValueMonth3) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex][0].data.push(parseFloat(detail.dataValueMonth3));
							} else {
								// setSeriesCompareToSet[setSeriesCompareToSetIndex][0].data.push(0);
							}
							if (detail.dataValueMonthAvg) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex][1].data.push(parseFloat(detail.dataValueMonthAvg));
							} else {
								// setSeriesCompareToSet[setSeriesCompareToSetIndex][1].data.push(0);
							}
							setSeriesCompareToSetIndex++;
						}
						break;
					case 213:
					case 214:
					case 215:
					case 216:
					case 217:
					case 218:
					case 316:
					case 317:
					case 318:
					case 319:
					case 320:
					case 321:
					case 335:
					case 336:
						if (detail.dataSetItemID == 215 || detail.dataSetItemID == 216 || detail.dataSetItemID == 217 || detail.dataSetItemID == 218) {
							if (detail.dataValueMonth2) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].data.push(parseFloat(detail.dataValueMonth2));
							}
							if (detail.dataValueMonth3) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].data.push(parseFloat(detail.dataValueMonth3));
							}
						} else {
							if (detail.dataValueMonth1) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].data.push(parseFloat(detail.dataValueMonth1));
							}
							if (detail.dataValueMonth2) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].data.push(parseFloat(detail.dataValueMonth2));
							}
						}
						// change Series name

						if (detail.dataSetItemID == 213 || detail.dataSetItemID == 214) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].name = "อัตราส่วน HTTP (เส้นทางสายหลักและสายรอง)";
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].name = "อัตราส่วน HTTP (เส้นทางในพื้นที่ชุมชน)";
						}

						if (detail.dataSetItemID == 215 || detail.dataSetItemID == 216 || detail.dataSetItemID == 217 || detail.dataSetItemID == 218) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].name = "อัตราส่วน Streaming Services (เส้นทางสายหลักและสายรอง)";
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].name = "อัตราส่วน Streaming Services (เส้นทางในพื้นที่ชุมชน)";
						}

						if (detail.dataSetItemID == 316 || detail.dataSetItemID == 317 || detail.dataSetItemID == 320 || detail.dataSetItemID == 321) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].name = "อัตราส่วน HTTP (" + province1 + ")";
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].name = "อัตราส่วน HTTP (" + province2 + ")";
						}

						if (detail.dataSetItemID == 318 || detail.dataSetItemID == 319 || detail.dataSetItemID == 320 || detail.dataSetItemID == 321) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].name = "อัตราส่วน Streaming Services (" + province1 + ")";
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].name = "อัตราส่วน Streaming Services (" + province2 + ")";
						}

						if (detail.dataSetItemID == 335 || detail.dataSetItemID == 336) {
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].name = "ค่าเฉลี่ย FTP (" + province1 + ")";
							setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].name = "ค่าเฉลี่ย FTP (" + province2 + ")";
						}
						setSeriesCompareToSetIndex2++;

						if (detail.dataSetItemID == 213 || detail.dataSetItemID == 214) {
							if (detail.dataValueMonth3) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].data.push(parseFloat(detail.dataValueMonth3));
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][0].name = "อัตราส่วน HTTP (เส้นทางสายหลักและสายรอง)";
							}
							if (detail.dataValueMonthAvg) {
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].data.push(parseFloat(detail.dataValueMonthAvg));
								setSeriesCompareToSet[setSeriesCompareToSetIndex2][1].name = "อัตราส่วน HTTP (เส้นทางในพื้นที่ชุมชน)";
							}
							setSeriesCompareToSetIndex2++;

						}
						break;
					case 302:
						if (detail.dataValueMonth1) {
							SeriesWithLineToSet[0][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLineToSet[0][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 329:
						if (detail.dataValueMonth1) {
							SeriesWithLineToSet[0][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLineToSet[0][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 303:
						if (detail.dataValueMonth1) {
							SeriesWithLineToSet[1][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLineToSet[1][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 330:
						if (detail.dataValueMonth1) {
							SeriesWithLineToSet[1][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLineToSet[1][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 208:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[0][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[0][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[1][0].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[1][1].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 223:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[0][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[0][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[1][2].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[1][3].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 209:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[2][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[2][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[3][0].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[3][1].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 224:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[2][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[2][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[3][2].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[3][3].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 308:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[4][0].name = "อัตราส่วน FTP (" + province1 + ")";
							SeriesWithLine2ToSet[4][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[4][1].name = "อัตราส่วน FTP (" + province2 + ")";
							SeriesWithLine2ToSet[4][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 309:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[4][2].name = "ค่า FTP (" + province1 + ")";
							SeriesWithLine2ToSet[4][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[4][3].name = "ค่า FTP (" + province2 + ")";
							SeriesWithLine2ToSet[4][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 312:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[5][0].name = "อัตราส่วน FTP (" + province1 + ")";
							SeriesWithLine2ToSet[5][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[5][1].name = "อัตราส่วน FTP (" + province2 + ")";
							SeriesWithLine2ToSet[5][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 313:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[5][2].name = "ค่า FTP (" + province1 + ")";
							SeriesWithLine2ToSet[5][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[5][3].name = "ค่า FTP (" + province2 + ")";
							SeriesWithLine2ToSet[5][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 210:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[6][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[6][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[7][0].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[7][1].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 225:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[6][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[6][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[7][2].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[7][3].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 211:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[8][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[8][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[9][0].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[9][1].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 226:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[8][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[8][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						if (detail.dataValueMonth3) {
							SeriesWithLine2ToSet[9][2].data.push(parseFloat(detail.dataValueMonth3));
						}
						if (detail.dataValueMonthAvg) {
							SeriesWithLine2ToSet[9][3].data.push(parseFloat(detail.dataValueMonthAvg));
						}
						break;
					case 310:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[10][0].name = "อัตราส่วน FTP (" + province1 + ")";
							SeriesWithLine2ToSet[10][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[10][1].name = "อัตราส่วน FTP (" + province2 + ")";
							SeriesWithLine2ToSet[10][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 311:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[10][2].name = "ค่า FTP (" + province1 + ")";
							SeriesWithLine2ToSet[10][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[10][3].name = "ค่า FTP (" + province2 + ")";
							SeriesWithLine2ToSet[10][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 314:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[11][0].name = "อัตราส่วน FTP (" + province1 + ")";
							SeriesWithLine2ToSet[11][0].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[11][1].name = "อัตราส่วน FTP (" + province2 + ")";
							SeriesWithLine2ToSet[11][1].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					case 315:
						if (detail.dataValueMonth1) {
							SeriesWithLine2ToSet[11][2].name = "ค่า FTP (" + province1 + ")";
							SeriesWithLine2ToSet[11][2].data.push(parseFloat(detail.dataValueMonth1));
						}
						if (detail.dataValueMonth2) {
							SeriesWithLine2ToSet[11][3].name = "ค่า FTP (" + province2 + ")";
							SeriesWithLine2ToSet[11][3].data.push(parseFloat(detail.dataValueMonth2));
						}
						break;
					default:
						break;
				}
			});

		});
		setCategories(CategoriesToSet);
		setSeriesSingle(SeriesSingleToSet);
		setSeriesMOS(SeriesMOSToSet);
		console.log(isDataLoading);
		setSeriesCompare(setSeriesCompareToSet);
		setSeriesWithLine(SeriesWithLineToSet);
		setSeriesWithLine2(SeriesWithLine2ToSet);
		setIsDataLoading(false);
	}

	useEffect(() => {
		if (isRehydrated) getDatasetRecordList()
		// eslint-disable-next-line
	}, [isRehydrated])

	useEffect(() => {
		updateGraphData();
	}, [selectedYear, selectedQuarter])

	useEffect(() => {
		prepareData();
	}, [rows])



	return (
		<Grid className="lg:p-10">
			<Card
				className={`${classes.card_styles} lg:border-t-4 border-card_top_border`}>
				<Grid className="flex flex-col justify-center items-start overflow-auto">
					<Grid className="flex flex-row items-center justify-between mt-4 mb-3 px-5 w-full">

						<Grid className="w-full">
							<Grid className="flex flex-row items-center">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									กราฟสรุปรายงานผลประจำไตรมาส
								</Grid>
								<InputLabel className="ml-2">ปี</InputLabel>
								<Select
									className="ml-2"
									value={selectedYear}
									onChange={handleYearChange}
								// input={<BootstrapInput />}
								>
									{yearList.map((item, index) => (
										<MenuItem key={index} value={item}>
											{item}
										</MenuItem>
									))}
								</Select>
								<InputLabel className="ml-2">ไตรมาส</InputLabel>
								<Select
									className="ml-2"
									value={selectedQuarter}
									onChange={handleQuarterChange}
								// input={<BootstrapInput />}
								>
									{quarterList.map((item, index) => (
										<MenuItem key={index} value={item}>
											{item}
										</MenuItem>
									))}
								</Select>
								<InputLabel className="ml-2">พื้นที่เทคโนโลยี 5G </InputLabel>
								<InputBox
									className="ml-2"
									value={province1box}
									onChange={handleProvince1Change}
								/>
								<InputBox
									className="ml-2"
									value={province2box}
									onChange={handleProvince2Change}
								/>
								<Button
									className="ml-2"
									variant="contained"
									color="primary"
									onClick={handleProvinceChange}
								>
									อัพเดทกราฟ
								</Button>
							</Grid>
							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ระยะเวลาสำหรับการขอเริ่มเปิดใช้บริการ PRE Paid (Service activation time)"}
									Categories={Categories}
									Series={SeriesSingle[0]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ระยะเวลาสำหรับการขอเริ่มเปิดใช้บริการ POST Paid (Service activation time)"}
									Categories={Categories}
									Series={SeriesSingle[1]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราข้อร้องเรียนที่เกี่ยวกับข้อผิดพลาดในการเรียกเก็บค่าบริการ (Billing inaccuracy)"}
									Categories={Categories}
									Max={0.1}
									Series={SeriesSingle[2]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ระยะเวลาที่ต้องรอในการขอใช้บริการดูแลลูกค้าจากศูนย์ตอบรับโทรศัพท์ (Response time for accessing customer-service call center)"}
									Categories={Categories}
									Series={SeriesSingle[3]}
									Max={60}
									unit={""}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"จำนวนครั้งที่หน่วยรับ-ส่ง สัญญาณวิทยุย่อย (Cell) ภายในสถานีฐาน ไม่สามารถให้บริการได้ ติดต่อกันเกิน 4 ชั่วโมง ใน 1 เดือนต่อจำนวน Cell ทั้งหมดในทุกสถานี (network unavailability : number of cell outages continuously over 4 hours in a month)"}
									Categories={Categories}
									Series={SeriesSingle[4]}
									Max={10}
									unit={""}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ร้อยละของจำนวนหน่วยรับ-ส่ง สัญญาณวิทยุย่อย (Cell) ภายในสถานีฐานที่หยุดทำงานสะสมเกินกว่า 24 ชั่วโมง ภายใน 1 เดือน (network unavailability : number of cell outages continuously over 24 hours in a month)"}
									Categories={Categories}
									Series={SeriesSingle[5]}
									Max={2}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ร้อยละของเวลารวมที่ทุกหน่วยรับ-ส่ง สัญญาณวิทยุย่อย (Cell) ภายในสถานีฐานไม่สามารถให้บริการได้ใน 1 เดือน ของเวลาที่ต้องให้บริการทั้งหมด (network unavailability : cumulative cell outage time in a month)"}
									Categories={Categories}
									Series={SeriesSingle[6]}
									Max={0.5}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนการเรียกสำเร็จ (Successful call ratio) 10.00 - 13.00 น. กรณีที่เป็นการโทรศัพท์ภายในโครงข่ายของผู้ประกอบการเดียวกัน"}
									Categories={Categories}
									Series={SeriesSingle[7]}
									Max={100}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนการเรียกสำเร็จ (Successful call ratio) 16.00 - 19.00 น. กรณีที่เป็นการโทรศัพท์ภายในโครงข่ายของผู้ประกอบการเดียวกัน"}
									Categories={Categories}
									Series={SeriesSingle[8]}
									Max={100}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนการเรียกสำเร็จ (Successful call ratio) 10.00 - 13.00 น. กรณีที่เป็นการโทรข้ามโครงข่ายต่างผู้ประกอบการ"}
									Categories={Categories}
									Series={SeriesSingle[9]}
									Max={100}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนการเรียกสำเร็จ (Successful call ratio) 16.00 - 19.00 น. กรณีที่เป็นการโทรข้ามโครงข่ายต่างผู้ประกอบการ"}
									Categories={Categories}
									Series={SeriesSingle[10]}
									Max={100}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) 10.00 - 13.00 น."}
									Categories={Categories}
									Series={SeriesSingle[11]}
									Max={0.3}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) 16.00 - 19.00 น."}
									Categories={Categories}
									Series={SeriesSingle[12]}
									Max={0.3}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"คุณภาพของเสียง (Mean Opinion Score (MOS))"}
									Categories={CategoriesMOS}
									Series={SeriesMOS}
									Max={100}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ค่า Round Trip Time (RTT) เทคโนโลยี 3G สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[0]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ค่า Round Trip Time (RTT) เทคโนโลยี 3G สถานที่ในเขตกรุงเทพฯ และปริมณฑล"}
									Categories={Categories}
									Series={SeriesCompare[1]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ค่า Round Trip Time (RTT) เทคโนโลยี 4G สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[2]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ค่า Round Trip Time (RTT) เทคโนโลยี 4G สถานที่ในเขตกรุงเทพฯ และปริมณฑล"}
									Categories={Categories}
									Series={SeriesCompare[3]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าค่าที่กำหนด เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									MaxMS={200}
									Series={SeriesWithLine[0]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนจำนวนครั้งที่ค่า Round Trip Time (RTT) ต่ำกว่าค่าที่กำหนด เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHz) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									MaxMS={200}
									Series={SeriesWithLine[1]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 3G กรณี Download (FTP success ratio) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[4]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 3G กรณี Download (FTP success ratio) สถานที่ในเขตกรุงเทพฯ และปริมณฑล"}
									Categories={Categories}
									Series={SeriesCompare[5]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 4G กรณี Download (FTP success ratio) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[6]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 4G กรณี Download (FTP success ratio) สถานที่ในเขตกรุงเทพฯ และปริมณฑล"}
									Categories={Categories}
									Series={SeriesCompare[7]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ (FTP success ratio) เทคโนโลยี 5G ที่ไม่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Download ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[12]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ (FTP success ratio) เทคโนโลยี 5G ที่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Download ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[14]}
								/>
							</Grid>

							--------------------

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 3G กรณี Upload (FTP success ratio) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[8]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 3G กรณี Upload (FTP success ratio) สถานที่ในเขตกรุงเทพฯ และปริมณฑล"}
									Categories={Categories}
									Series={SeriesCompare[9]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 4G กรณี Upload (FTP success ratio) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[10]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ เทคโนโลยี 4G กรณี Upload (FTP success ratio) สถานที่ในเขตกรุงเทพฯ และปริมณฑล"}
									Categories={Categories}
									Series={SeriesCompare[11]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ (FTP success ratio) เทคโนโลยี 5G ที่ไม่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Upload ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[13]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่รับส่งข้อมูลแบบ FTP ได้สำเร็จ (FTP success ratio) เทคโนโลยี 5G ที่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Upload ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[15]}
								/>
							</Grid>

							--------------------

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 3G กรณี Download (FTP success ratio subjected to specified data rate) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesWithLine2[0]}
									MaxMS={8}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 3G กรณี Download (FTP success ratio subjected to specified data rate) สถานที่ในเขตกรุงเทพฯ และปริมลฑล"}
									Categories={Categories}
									Series={SeriesWithLine2[1]}
									MaxMS={8}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 4G กรณี Download (FTP success ratio subjected to specified data rate) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesWithLine2[2]}
									MaxMS={70}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 4G กรณี Download (FTP success ratio subjected to specified data rate) สถานที่ในเขตกรุงเทพฯ และปริมลฑล"}
									Categories={Categories}
									Series={SeriesWithLine2[3]}
									MaxMS={70}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด เทคโนโลยี 5G ที่ไม่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Download ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesWithLine2[4]}
									MaxMS={140}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด เทคโนโลยี 5G ที่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Download ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesWithLine2[5]}
									MaxMS={140}
									unit2={'Mbps'}
								/>
							</Grid>

							-----------------

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 3G กรณี Upload (FTP success ratio subjected to specified data rate) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesWithLine2[6]}
									MaxMS={10}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 3G กรณี Upload (FTP success ratio subjected to specified data rate) สถานที่ในเขตกรุงเทพฯ และปริมลฑล"}
									Categories={Categories}
									Series={SeriesWithLine2[7]}
									MaxMS={10}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 4G กรณี Upload (FTP success ratio subjected to specified data rate) สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesWithLine2[8]}
									MaxMS={35}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP สำเร็จ ตามความเร็วเฉลี่ยในการส่งข้อมูลที่กําหนด เทคโนโลยี 4G กรณี Upload (FTP success ratio subjected to specified data rate) สถานที่ในเขตกรุงเทพฯ และปริมลฑล"}
									Categories={Categories}
									Series={SeriesWithLine2[9]}
									MaxMS={35}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด เทคโนโลยี 5G ที่ไม่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Upload ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesWithLine2[10]}
									MaxMS={40}
									unit2={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBarWithLine
									Title={"อัตราส่วนของการรับส่งข้อมูลแบบ FTP ได้ไม่ต่ำกว่าอัตราบิตที่กำหนด เทคโนโลยี 5G ที่ใช้คลื่นความถี่ 2600 MHz สำหรับกรณี Upload ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesWithLine2[11]}
									MaxMS={50}
									unit2={'Mbps'}
								/>
							</Grid>

							---------------------

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้สำเร็จ (HTTP success ratio) เทคโนโลยี 3G สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[16]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้สำเร็จ (HTTP success ratio) เทคโนโลยี 3G สถานที่ในเขตกรุงเทพฯ และปริมลฑล"}
									Categories={Categories}
									Series={SeriesCompare[17]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้สำเร็จ (HTTP success ratio) เทคโนโลยี 4G สถานที่ในส่วนภูมิภาค"}
									Categories={Categories}
									Series={SeriesCompare[18]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้สำเร็จ (HTTP success ratio) เทคโนโลยี 4G สถานที่ในเขตกรุงเทพฯ และปริมลฑล"}
									Categories={Categories}
									Series={SeriesCompare[19]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด เทคโนโลยี 5G (์NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHz) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[24]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่ HTTP โหลดได้ไม่ต่ำกว่าเวลาที่กำหนด เทคโนโลยี 5G (์SA ที่ใช้คลื่นความถี่ 2600 MHz) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[25]}
								/>
							</Grid>

							-----------------------

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตีมมิ่ง (Streaming Service accessiblity) เทคโนโลยี 3G"}
									Categories={Categories}
									Series={SeriesCompare[20]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตีมมิ่ง (Streaming Service accessiblity) เทคโนโลยี 4G"}
									Categories={Categories}
									Series={SeriesCompare[21]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตีมมิ่ง (Streaming Service accessiblity) เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHZ) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[26]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งที่สามารถเข้าถึงบริการสตีมมิ่ง (Streaming Service accessiblity) เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHZ) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[27]}
								/>
							</Grid>

							------------------------

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio) เทคโนโลยี 3G"}
									Categories={Categories}
									Series={SeriesCompare[22]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio) เทคโนโลยี 4G"}
									Categories={Categories}
									Series={SeriesCompare[23]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio) เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHZ) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[28]}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"อัตราส่วนจำนวนครั้งการแสดงวีดีทัศน์แบบสตรีมมิ่งได้อย่างสมบูรณ์ (Streaming reproduction success ratio) เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHZ) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[29]}
								/>
							</Grid>

							------------

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ค่าอัตราบิตเฉลี่ยของการรับส่งข้อมูลแบบ FTP (Average FTP Bitrate)  เทคโนโลยี 5G (NSA และ SA ที่ไม่ใช้คลื่นความถี่ 2600 MHZ) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[30]}
									Max={180}
									unit={'Mbps'}
								/>
							</Grid>

							<Grid className="w-full h-full mt-10">
								<CompareBar
									Title={"ค่าอัตราบิตเฉลี่ยของการรับส่งข้อมูลแบบ FTP (Average FTP Bitrate)  เทคโนโลยี 5G (SA ที่ใช้คลื่นความถี่ 2600 MHZ) ในพื้นที่ภาคตะวันออกเฉียงเหนือ"}
									Categories={Categories}
									Series={SeriesCompare[31]}
									Max={50}
									unit={'Mbps'}
								/>
							</Grid>


						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	)

}
