import React, { useState, useEffect } from 'react'

import { Grid, Card, Button, Tooltip, Dialog, DialogTitle, IconButton, DialogContent, TextField, Select, MenuItem, Checkbox, ListItemText, Switch, DialogActions } from '@material-ui/core'
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridOverlay } from '@mui/x-data-grid'
import { ListAlt, Menu, Description, SaveAlt, Refresh, GetApp, Build, Search, Close } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import { useStoreRehydrated } from '@store'

import { format } from 'date-fns'

import { sdoc, sdocAdmin, admin } from '@api'
import Swal from 'sweetalert2/dist/sweetalert2.js'


// Page Description: สถานะการยื่นแบบหนังสือรับรองตนเอง
export default function SDOCSent() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const [rowsApproveStatus, setRowsApproveStatus] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	// eslint-disable-next-line
	// eslint-disable-next-line

	const [sortModel, setSortModel] = useState([
		{
			field: 'sDocNo',
			sort: 'desc',
		},
	]);
	// filter Dialog / Modal
	const [RowsData, setRowsData] = useState([])
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [searchCreate, setSearchCreate] = useState('');
	const [searchLastUpdated, setSearchLastUpdated] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const [isSaveSearch, setIsSaveSearch] = useState(true);

	const statusOptions = [
		// { value: '0', label: 'ทั้งหมด' },
		{ value: '1', label: 'รอมอบหมาย' },
		{ value: '2', label: 'รอตรวจสอบ/เพิ่มเอกสารแล้ว/ปรับปรุงข้อมูลแล้ว' },
		{ value: '3', label: 'ขอเอกสารเพิ่ม/ขอปรับปรุงเอกสาร' },
		{ value: '4', label: 'รอออกใบรับรอง' },
		{ value: '5', label: 'อนุมัติ' },
		{ value: '6', label: 'ไม่อนุมัติ' },
		{ value: '8', label: 'รอชำระเงิน' },
		{ value: '82', label: 'เลยกำหนดชำระ' },
		{ value: '9', label: 'รอออกใบเสร็จรับเงิน' },
		{ value: '10', label: 'ยกเลิกคำขอ' },
		{ value: '11', label: 'เลยระยะเวลา' },
	];
	const toggleDialog = () => {
		setIsDialogOpen(!isDialogOpen);
	};
	const handleSearchChange = (event) => {
		const { id, value } = event.target;
		setSearchQuery((prevSearchQuery) => {
			const updatedQuery = [...prevSearchQuery];
			const existingItemIndex = updatedQuery.findIndex((item) => item.id === id);
			if (existingItemIndex !== -1) {
				updatedQuery[existingItemIndex] = { id, value };
			} else {
				updatedQuery.push({ id, value });
			}
			return updatedQuery;
		});
		// console.log(searchQuery);
	};

	const handleStatusChange = (event) => {
		setSelectedStatus(event.target.value);
	};
	const handleCreateChange = (event) => {
		setSearchCreate(event.target.value);
	};
	const handleLastUpdatedChange = (event) => {
		setSearchLastUpdated(event.target.value);
	};
	const handleSaveSearch = () => {
		setIsSaveSearch(!isSaveSearch);
	};



	var columnsApproveStatus = [
		{
			field: 'sDocNoExport',
			headerName: 'sDoc No.',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			filterable: false,
			editable: false,
			hide: true,
			valueGetter: params => {
				return params.row.sDocNo;
			}
		},
		{
			field: 'sDocNo',
			headerName: 'sDoc No.',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				return params.row.sDocNo;
			},
			valueGetter: params => {
				if (params.row.sDocNo) {
					return params.row.sDocNo.slice(8, 10) + '-' + params.row.sDocNo.slice(2, 7)
				}
			}
		},
		{
			field: 'runningNumber', // ERROR MARK
			headerName: 'เลขที่คำขอ',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'brandName',
			headerName: 'ตราอักษร (Brand Name)',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'productModel',
			headerName: 'รุ่นของผลิตภัณฑ์',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'operator',
			headerName: 'ผู้ยื่นเอกสาร',
			width: 200,
			headerAlign: 'left',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{/* {params.row.operator}<br />{params.row.operatorInfo && ('แทน ' + params.row.operatorInfo)} */}
					{params.row.operator}
				</Grid>
			),
			// valueGetter: (params) => {
			// 	if (params.row.operatorInfo) {
			// 		return params.value + ' แทน ' + params.row.operatorInfo + ')'
			// 	}
			// },
		},
		{
			field: 'operatorInfo',
			headerName: 'ยื่นในนาม',
			width: 200,
			headerAlign: 'left',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{params.row.operatorInfo && (params.row.operatorInfo)}
				</Grid>
			),
		},
		{
			field: 'approver',
			headerName: 'ผู้ได้รับมอบหมายงาน',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'signatureAdmin',
			headerName: 'ผู้ลงนาม',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'approveDateExport',
			headerName: 'วันที่ได้รับอนุมัติ',
			width: 170,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			filterable: false,
			editable: false,
			hide: true,
			renderCell: params => (
				<Grid className="">
					{params.value ? format(new Date(params.row.approveDateExport), 'MM/dd/yyyy HH:mm') : ''}
					{/* {params.value ? params.value : ''} */}
				</Grid>
			),
			valueGetter: params => {
				if (params.row.approveDateExport) {
					return format(new Date(params.value), 'MM/dd/yyyy HH:mm')
				}
			}
		},
		{
			field: 'invoiceDate',
			headerName: 'วันที่ได้รับใบเสร็จรับเงิน',
			width: 170,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			filterable: false,
			editable: false,
			hide: true,
			renderCell: params => (
				<Grid className="">
					{params.value ? format(new Date(params.row.invoiceDate), 'MM/dd/yyyy HH:mm') : ''}
					{/* {params.value ? params.value : ''} */}
				</Grid>
			),
			valueGetter: params => {
				if (params.row.approveDateExport) {
					return format(new Date(params.value), 'MM/dd/yyyy HH:mm')
				}
			}
		},
		{
			field: 'approveDate',
			headerName: 'วันที่ได้รับอนุมัติ',
			width: 170,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{/* {params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : ''} */}
					{params.value ? params.value : ''}
				</Grid>
			),
			valueGetter: params => {
				if (params.row.approveDate) {
					return format(new Date(params.value), 'dd/MM/yyyy HH:mm')
				}
			}
		},
		{
			field: 'amount',
			headerName: 'เงินที่ได้รับ',
			width: 170,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			hide: true,
			renderCell: params => (
				<Grid className="">
					{params.value ? params.value.toFixed(2) + " บาท" : ''}
				</Grid>
			),
		},
		{
			field: 'activity',
			headerName: 'กิจกรรม',
			width: 800,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-start items-center gap-x-2">
						<Button
							className={`w-24`}
							color="primary"
							variant="contained"
							onClick={() => {
								history.push(
									`/admin/sdoc/sent/${params.row.id}?mountType=readOnly`
								)
							}}>
							<Menu style={{ fontSize: 16 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>

						<a
							href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocPaper/${params.row.id}`}
							target="_blank"
							rel="noreferrer">
							<Button className={`w-40`} color="primary" variant="contained">
								<GetApp style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ดาวน์โหลดหนังสือรับรอง</Grid>
							</Button>
						</a>
						<a
							href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/AdminGetInvoice`}
							target="_blank"
							rel="noreferrer">
							<Button className={`w-40`} color="primary" variant="contained">
								<Description style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
							</Button>
						</a>
						{/* <a
							href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/repairCredential`}
							target="_blank"
							rel="noreferrer">
							<Button className={`w-64`} color="primary" variant="contained">
								<Build style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ซ่อมแซมหนังสือรับรอง(กรณีดาวน์โหลดไม่ได้)</Grid>
							</Button>
						</a> */}
						<Button
							className={`w-64`}
							style={{ marginLeft: 10 }}
							variant="contained"
							color="primary"
							onClick={() => {
								confirmRepairCredential(params.row.id)
							}}>
							<Build style={{ fontSize: 16 }} className="mr-1" />
							<Grid className={`text-xs`}>ซ่อมแซมหนังสือรับรอง(กรณีดาวน์โหลดไม่ได้)</Grid>
						</Button>
					</Grid>
				)
			},
		},
	]
	const handleSortChange = (model) => {
		if (isRehydrated && model[0] !== sortModel[0])
			setSortModel(model);
	};
	const createApproveRow = (dataSDocList, i) => {
		const rowData = {
			id: dataSDocList[i].id,
			brandName: dataSDocList[i].brandName,
			operator:
				(dataSDocList[i].sDocUser.prefix ?? '') +
				dataSDocList[i].sDocUser.firstname +
				' ' +
				dataSDocList[i].sDocUser.lastname,
			approver: dataSDocList[i].approver
				?
				(dataSDocList[i].approver.prefixname ?? '') +
				dataSDocList[i].approver.firstname +
				' ' +
				dataSDocList[i].approver.lastname
				: 'ยังไม่ได้มอบหมาย',
			approveDate: dataSDocList[i].approveDate,
			approveDateExport: dataSDocList[i].approveDate,
			invoiceDate: dataSDocList[i].sDocTransactions?.receiveDate,
			signatureAdmin: dataSDocList[i].signatureAdmin
				?
				(dataSDocList[i].signatureAdmin.prefixname ?? '') +
				dataSDocList[i].signatureAdmin.firstname +
				' ' +
				dataSDocList[i].signatureAdmin.lastname
				: 'ยังไม่ได้ลงนาม',
			statusID: dataSDocList[i].sDocStatusID,
			statusName: dataSDocList[i].sDocStatus.statusName,
			productModel: dataSDocList[i].productModel,
			sDocNo: dataSDocList[i].sDocNo,
			operatorInfo: dataSDocList[i].senderType === 'organization' ? dataSDocList[i].operatorTitle : null,
			runningNumber: dataSDocList[i].runningNo, // ERROR MARK
			amount: dataSDocList[i].sDocTransactions.amount * 1.07,
		}
		return rowData
	}

	const getSDocStatusList = async () => {
		// Get Status List
		const resStatusList = await sdoc.getListsStatus()
		const dataStatusList = resStatusList.data
		const statusListTemp = []
		if (resStatusList) {
			if (resStatusList.status === 200) {
				for (let i = 0; i < dataStatusList.length; i++) {
					statusListTemp.push({
						id: dataStatusList[i].id,
						statusName: dataStatusList[i].statusName,
					})
				}
			}
		}
		return statusListTemp
	}

	const getCheckerAdminList = async () => {
		const resAdminList = await sdocAdmin.getAdminList()
		const adminListTemp = []
		if (resAdminList) {
			if (resAdminList.status === 200) {
				const dataAdminList = resAdminList.data
				for (let i = 0; i < dataAdminList.length; i++) {
					if (dataAdminList[i].adminRoleID === 4) {
						adminListTemp.push({
							id: dataAdminList[i].id,
							name:
								dataAdminList[i].firstname + ' ' + dataAdminList[i].lastname,
						})
					}
				}
			}
		}
	}

	const openDB = () => {
		return new Promise((resolve, reject) => {
			// const deleteRequest = indexedDB.deleteDatabase('MyDatabase');
			const request = window.indexedDB.open('NBTCSDoCApproved', 1);

			request.onerror = (event) => {
				console.error("Error opening database", event.target.error);
				reject(event.target.error);
			};

			request.onupgradeneeded = (event) => {
				const db = event.target.result;

				// Create an object store (similar to a table in relational databases)
				if (!db.objectStoreNames.contains('SDocList')) {
					db.createObjectStore('SDocList', { keyPath: 'id' });
				}

				if (!db.objectStoreNames.contains('SDocListTimeout')) {
					db.createObjectStore('SDocListTimeout', { keyPath: 'id' });
				}

				console.log("Database upgrade complete");
			};

			request.onsuccess = (event) => {
				const db = event.target.result;
				resolve(db);
			};
		});
	};

	const addDataToDBWithTimeout = (data, timeout) => {
		openDB().then((db) => {
			const transaction = db.transaction(['SDocList'], 'readwrite');
			const transaction2 = db.transaction(['SDocListTimeout'], 'readwrite');
			const objectStore = transaction.objectStore('SDocList');
			const objectStore2 = transaction2.objectStore('SDocListTimeout');

			transaction.oncomplete = () => {
				console.log("Data added to IndexedDB with timeout");
			};

			transaction.onerror = (event) => {
				console.error("Error adding data to IndexedDB", event.target.error);
			};

			transaction.onabort = (event) => {
				console.error("Transaction aborted", event.target.error);
			};
			const vtimeout = new Date().getTime() + timeout;
			let vtimeoutData = {
				id: 1,
				timeout: vtimeout
			}
			objectStore2.add(vtimeoutData);
			console.log("Data Timeout Set to:", vtimeoutData)
			data.forEach((item) => {
				try {
					const request = objectStore.add(item);

					request.onsuccess = () => {
						// console.log("Data added:", item);
					};

					request.onerror = (event) => {
						console.error("Error adding data to IndexedDB", event.target.error);
					};
				} catch (error) {
					console.error("Error within transaction:", error);
					transaction.abort();
				}
			});
		}).catch((error) => {
			console.error("Error opening database", error);
		});
	};

	const getDataFromDBWithTimeout = () => {
		return new Promise((resolve, reject) => {
			openDB().then((db) => {
				const transaction = db.transaction(['SDocList'], 'readonly');
				const transaction2 = db.transaction(['SDocListTimeout'], 'readonly');
				const objectStore = transaction.objectStore('SDocList');
				const objectStore2 = transaction2.objectStore('SDocListTimeout');
				const currentTime = Date.now();
				const request2 = objectStore2.getAll();
				request2.onsuccess = (event) => {
					let data2 = event.target.result;
					if (data2.length <= 0) {
						return [];
					}
					data2 = data2[0].timeout;
					if (currentTime > data2) {
						console.log("Data Expired, clearing DB");
						clearSDocList();
						return [];
					}
				};

				const request = objectStore.getAll();

				request.onsuccess = (event) => {
					const data = event.target.result;
					resolve(data);
				};

				request.onerror = (event) => {
					console.error("Error retrieving data from IndexedDB", event.target.error);
					reject(event.target.error);
				};
			}).catch((error) => {
				console.error("Error opening database", error);
				reject(error);
			});
		});
	};

	const clearSDocList = () => {
		openDB().then((db) => {
			const transaction = db.transaction(['SDocList'], 'readwrite');
			const objectStore = transaction.objectStore('SDocList');
			const transaction2 = db.transaction(['SDocListTimeout'], 'readwrite');
			const objectStore2 = transaction2.objectStore('SDocListTimeout');

			const request = objectStore.clear();
			const request2 = objectStore2.clear();

			request.onsuccess = () => {
				console.log("SDocList cleared successfully");
			};

			request.onerror = (event) => {
				console.error("Error clearing SDocList", event.target.error);
			};
		}).catch((error) => {
			console.error("Error opening database", error);
		});
	};
	const forceSDOCWaitStatusList = async () => {
		setIsDataLoading(true);
		setRowsApproveStatus([]);
		await admin.me().then(async event => {
			if (event) {
				const dataMe = event.data
				const timeout = 15 * 60 * 1000;

				let dataSDoc = [];
				const resList = await sdoc.getListsApproved()
				let dataSDocList = resList.data
				if (resList) {
					if (resList.status === 200) {
						dataSDoc = resList.data
						clearSDocList();
						addDataToDBWithTimeout(dataSDoc, timeout);
					}
				}

				if (dataSDoc && dataSDoc.length > 0) {
					const rowsApproveTemp = []
					// Role === 'ผู้ตรวจสอบข้อมูล'
					if (dataMe.adminRoleID === 4) {
						for (let i = 0; i < dataSDocList.length; i++) {
							if (
								// Show only own approver && status !== 'รอมอบหมาย'
								dataMe.id === dataSDocList[i].approverID &&
								dataSDocList[i].sDocStatusID !== 1
							) {
								// ---> Add Data to Array
								if (dataSDocList[i].sDocStatusID === 5)
									rowsApproveTemp.push(createApproveRow(dataSDocList, i))
								// <--- Add Data to Array
							}
						}
					}

					// Role === 'ผู้ออกใบรับรอง'
					else if (dataMe.adminRoleID === 2) {
						for (let i = 0; i < dataSDocList.length; i++) {
							if (
								// Show only status === 'รอออกใบรับรอง' && status === 'สำเร็จ' && status === 'ยกเลิก'
								dataSDocList[i].sDocStatusID === 4 ||
								dataSDocList[i].sDocStatusID === 5 ||
								dataSDocList[i].sDocStatusID === 6
							) {
								// ---> Add Data to Array
								if (dataSDocList[i].sDocStatusID === 5)
									rowsApproveTemp.push(createApproveRow(dataSDocList, i))
								// <--- Add Data to Array
							}
						}
					}

					// Role === 'ผู้มอบหมายงาน'
					else {
						for (let i = 0; i < dataSDocList.length; i++) {
							// ---> Add Data to Array
							if (dataSDocList[i].sDocStatusID === 5)
								rowsApproveTemp.push(createApproveRow(dataSDocList, i))
							// <--- Add Data to Array
						}
					}
					setRowsApproveStatus(rowsApproveTemp)
				}
			}
		})
		setIsDataLoading(false)
		getCheckerAdminList()
	}
	const getSDOCWaitStatusList = async () => {
		// Get Status List
		setIsDataLoading(true)
		getSDocStatusList()

		await admin.me().then(async event => {
			if (event) {
				const dataMe = event.data
				const timeout = 15 * 60 * 1000;
				let dataSDocList = await getDataFromDBWithTimeout();
				let dataSDoc = [];
				if (dataSDocList && dataSDocList.length > 0) {
					dataSDoc = dataSDocList;
				} else {
					const resList = await sdoc.getListsApproved()
					dataSDocList = resList.data
					if (resList) {
						if (resList.status === 200) {
							dataSDoc = resList.data
							clearSDocList();
							addDataToDBWithTimeout(dataSDoc, timeout);
						}
					}
				}
				if (dataSDoc && dataSDoc.length > 0) {
					const rowsApproveTemp = []
					// Role === 'ผู้ตรวจสอบข้อมูล'
					if (dataMe.adminRoleID === 4) {
						for (let i = 0; i < dataSDocList.length; i++) {
							if (
								// Show only own approver && status !== 'รอมอบหมาย'
								dataMe.id === dataSDocList[i].approverID &&
								dataSDocList[i].sDocStatusID !== 1
							) {
								// ---> Add Data to Array
								if (dataSDocList[i].sDocStatusID === 5)
									rowsApproveTemp.push(createApproveRow(dataSDocList, i))
								// <--- Add Data to Array
							}
						}
					}

					// Role === 'ผู้ออกใบรับรอง'
					else if (dataMe.adminRoleID === 2) {
						for (let i = 0; i < dataSDocList.length; i++) {
							if (
								// Show only status === 'รอออกใบรับรอง' && status === 'สำเร็จ' && status === 'ยกเลิก'
								dataSDocList[i].sDocStatusID === 4 ||
								dataSDocList[i].sDocStatusID === 5 ||
								dataSDocList[i].sDocStatusID === 6
							) {
								// ---> Add Data to Array
								if (dataSDocList[i].sDocStatusID === 5)
									rowsApproveTemp.push(createApproveRow(dataSDocList, i))
								// <--- Add Data to Array
							}
						}
					}

					// Role === 'ผู้มอบหมายงาน'
					else {
						for (let i = 0; i < dataSDocList.length; i++) {
							// ---> Add Data to Array
							if (dataSDocList[i].sDocStatusID === 5)
								rowsApproveTemp.push(createApproveRow(dataSDocList, i))
							// <--- Add Data to Array
						}
					}
					setRowsData(rowsApproveTemp);
					setRowsApproveStatus(rowsApproveTemp)
				}
			}
		})
		setIsDataLoading(false)
		getCheckerAdminList()
	}

	// filter 
	const onSearchResult = () => {
		let searchedData = RowsData;
		const savedFilters = {
			searchQuery: searchQuery,
			searchCreate: searchCreate,
			searchLastUpdated: searchLastUpdated,
			selectedStatus: selectedStatus
		};

		// Save filters to localStorage if isSaveSearch is true
		if (isSaveSearch) {
			localStorage.setItem('savedFiltersApproved', JSON.stringify(savedFilters));
		} else {
			localStorage.removeItem('savedFiltersApproved');
		}
		if (searchQuery)
			// console.log("searchQuery >> ", searchQuery)
			searchQuery.forEach((query) => {
				const { id, value } = query;
				if (value !== '' && value !== null && value !== undefined && value.length > 0) {
					// console.log("id >> ", id, " value >> ", value)
					searchedData = searchedData.filter((item) => {
						const itemValue = item[id];
						// console.log("itemValue >> ", itemValue);
						// console.log("typeof itemValue >> ", typeof itemValue);
						if (typeof itemValue === 'string') {
							return itemValue.toLowerCase().includes(value.toLowerCase());
						} else if (typeof itemValue === 'object') {
							return false;
						} else {
							return itemValue.toString().includes(value);
						}
					});
				}
			});

		if (searchCreate !== '') {
			searchedData = searchedData.filter((item) => {
				const itemDate = new Date(item.approveDate.split('T')[0]);
				const searchDate = new Date(searchCreate);
				return itemDate.getTime() === searchDate.getTime();
			});
		}
		if (searchLastUpdated !== '') {
			searchedData = searchedData.filter((item) => {
				const itemDate = new Date(item.lastUpdate.split('T')[0]);
				const searchDate = new Date(searchLastUpdated);
				return itemDate.getTime() === searchDate.getTime();
			});
		}
		if (selectedStatus.length > 0 && selectedStatus[0] !== '0') {
			searchedData = searchedData.filter(
				(item) => selectedStatus.includes(item.statusID.toString())
			);
		}

		const mergedSearched = searchedData;
		setRowsApproveStatus(mergedSearched);
		setIsDialogOpen(false);
		setIsSearching(true);
		return;
	};

	// Function to apply saved filters on component mount
	const applySavedFilters = () => {
		const savedFilters = localStorage.getItem('savedFiltersApproved');

		if (savedFilters) {
			const { searchQuery, searchCreate, searchLastUpdated, selectedStatus } = JSON.parse(savedFilters);
			// Apply the saved filters to your state variables
			setSearchQuery(searchQuery);
			setSearchCreate(searchCreate);
			setSearchLastUpdated(searchLastUpdated);
			setSelectedStatus(selectedStatus);
			setIsSearching(true);
			setIsSaveSearch(true);
		}
	};

	// Call applySavedFilters when the component mounts
	useEffect(() => {
		applySavedFilters();
	}, [isSearching]);
	useEffect(() => {
		if (RowsData && isSearching) {
			onSearchResult();
		}
	}, [RowsData, isSearching]); // Call onSearchResult when rowsData or isSearching changes



	const onSearchResultClear = () => {
		setSearchQuery([]);
		setSearchCreate('');
		setSearchLastUpdated('');
		setSelectedStatus([]);
		setRowsApproveStatus(RowsData);
		setIsSearching(false);
		setIsSaveSearch(false);

		// Remove the saved filters from localStorage
		localStorage.removeItem('savedFiltersApproved');

		return;
	};

	// repairCredential
	const confirmRepairCredential = async (sDocID) => {
		const shouldRepair = await Swal.fire({
			title: 'คุณต้องการที่จะดำเนินการซ่อมแซมหนังสือรับรองนี้หรือไม่?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ใช่',
			cancelButtonText: 'ไม่',
		});

		if (shouldRepair.isConfirmed) {
			onRepairCredential(sDocID);
		}
	};

	// Call confirmRepairCredential when you want to initiate the repair

	const onRepairCredential = async sDocID => {
		const res = await sdocAdmin.repairCredential(sDocID)
		if (res && res.status === 200 && res.data.status == "success") {
			getSDOCWaitStatusList()
			Swal.fire({
				title: 'ซ่อมแซมหนังสือรับรองสำเร็จ',
				text: 'ซ่อมแซมหนังสือรับรองสำเร็จ',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
		} else if (res && res.status === 200 && res.data.status == "fail") {
			Swal.fire({
				title: 'ซ่อมแซมหนังสือรับรองไม่สำเร็จ',
				text: res.data.message,
				icon: 'error',
				confirmButtonText: 'ปิด'
			})
		}
	}

	useEffect(() => {
		if (isRehydrated) {
			getSDOCWaitStatusList()
		}
		// eslint-disable-next-line
	}, [isRehydrated])
	const handleDownloadClick = async () => {
		try {
			handleCloseDialogRangeDate();
			// Display a loading popup
			const loadingPopup = Swal.fire({
				title: 'กำลังดาวน์โหลดไฟล์',
				text: 'โปรดรอสักครู่...',
				icon: 'info',
				showCancelButton: false,
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				onBeforeOpen: () => {
					Swal.showLoading();
				},
			});
			const encodedParams = new URLSearchParams({
				startdate: formatDate(startDate),
				enddate: formatDate(endDate),
			}).toString();
			const response = await fetch(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/SDoCSummaryExcel`,
				{
					method: 'POST',
					headers: {
						'Authorization': `Basic bmJ0YyNkYXRhY2VudGVyOmRhdGFjZW50ZXJzZG9jQDY1`,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: encodedParams,
				}
			);

			if (response.ok) {
				const blob = await response.blob();

				// Close the loading popup
				loadingPopup.close();

				// Create a download link
				const downloadLink = document.createElement('a');
				downloadLink.href = URL.createObjectURL(blob);
				downloadLink.download = 'SDoCSummary.xlsx';

				// Trigger a click event on the download link
				downloadLink.click();

				// Cleanup: Remove the download link
				URL.revokeObjectURL(downloadLink.href);
				downloadLink.remove();
			} else {
				// Close the loading popup
				loadingPopup.close();

				// Handle error response
				console.error('Download failed:', response.statusText);
			}
		} catch (error) {
			console.error('Download failed:', error);
		}
	};
	const handleDownloadSummaryFullClick = async () => {
		try {
			// Display a loading popup
			handleCloseDialogRangeDate();

			const loadingPopup = Swal.fire({
				title: 'กำลังดาวน์โหลดไฟล์',
				text: 'โปรดรอสักครู่...',
				icon: 'info',
				showCancelButton: false,
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				onBeforeOpen: () => {
					Swal.showLoading();
				},
			});
			const encodedParams = new URLSearchParams({
				startdate: formatDate(startDate),
				enddate: formatDate(endDate),
			}).toString();
			const response = await fetch(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/SDoCSummaryFull`,
				{
					method: 'POST',
					headers: {
						'Authorization': `Basic bmJ0YyNkYXRhY2VudGVyOmRhdGFjZW50ZXJzZG9jQDY1`,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: encodedParams,
				}
			);

			if (response.ok) {
				const blob = await response.blob();

				// Close the loading popup
				loadingPopup.close();

				// Create a download link
				const downloadLink = document.createElement('a');
				downloadLink.href = URL.createObjectURL(blob);
				downloadLink.download = 'SDoCAddress.xlsx';

				// Trigger a click event on the download link
				downloadLink.click();

				// Cleanup: Remove the download link
				URL.revokeObjectURL(downloadLink.href);
				downloadLink.remove();
			} else {
				// Close the loading popup
				loadingPopup.close();

				// Handle error response
				console.error('Download failed:', response.statusText);
			}
		} catch (error) {
			console.error('Download failed:', error);
		}
	};
	function exceljsPreProcess({ workbook, worksheet }) {
		workbook.created = new Date(); // Add metadata
		worksheet.name = 'Monthly Results'; // Modify worksheet name

		// Write on first line the date of creation
		worksheet.getCell('A1').value = `Values from the`;
		worksheet.getCell('A2').value = new Date();
	}

	function exceljsPostProcess({ worksheet }) {
		// Add a text after the data
		worksheet.addRow(); // Add empty row

		const newRow = worksheet.addRow();
		newRow.getCell(1).value = 'Those data are for internal use only';
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarExport
					className={`w-72`}
					color="primary"
					variant="contained"
					style={{ marginRight: '10px', height: '30px' }}
					label="ดาวน์โหลดรายการที่อนุมัติแล้ว"
					csvOptions={{
						fileName: 'รายการใบคำขอที่ได้รับอนุมัติ.csv',
						exportButtonText: 'ดาวน์โหลดรายการที่อนุมัติแล้ว',
						utf8WithBom: true,
						fields: ['signatureAdmin', 'approveDateExport', 'invoiceDate', 'sDocNoExport', 'operator', 'operatorInfo', 'brandName', 'productModel', 'approver', 'amount']
					}}
					excelOptions={{
						exceljsPreProcess,
						exceljsPostProcess,
					}}
				/>

				<Button
					className={`w-72`}
					color="primary"
					variant="contained"
					style={{ marginRight: '10px', height: '30px' }}
					onClick={() => handleOpenDialogRangeDate('SDoCSummaryExcel')}>
					<SaveAlt style={{ fontSize: 16 }} className="mr-1" />
					<Grid className={`text-xs`}>แบบรับรองตนเองทีได้รับการอนุมัติ</Grid>
				</Button>
				<Button
					className={`w-72`}
					color="primary"
					variant="contained"
					style={{ marginRight: '10px', height: '30px' }}
					onClick={() => handleOpenDialogRangeDate('SDoCSummaryFull')}>
					<SaveAlt style={{ fontSize: 16 }} className="mr-1" />
					<Grid className={`text-xs`}>ข้อมูลผู้ยื่นแบบรับรองตนเองที่ได้รับการอนุมัติ</Grid>
				</Button>
			</GridToolbarContainer>
		);
	}

	const formatDate = (date) => {
		if (!(date instanceof Date) || isNaN(date)) {
			return ''; // or handle invalid dates appropriately
		}
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};


	const [DialogRangeDateType , setDialogRangeDateType] = useState('SDoCSummaryExcel'); //  SDoCSummaryExcel or SDoCSummaryFull
	const [openDialogRangeDate, setOpenDialogRangeDate] = useState(false);
	const handleCloseDialogRangeDate = () => {
		setOpenDialogRangeDate(false);
	};
	const handleOpenDialogRangeDate = (sdoctype) => {
		setDialogRangeDateType(sdoctype);
		setOpenDialogRangeDate(true);
	};
	// initail value for startDate first day of month
	const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
	// initial value for endDate today
	const [endDate, setEndDate] = useState(new Date());
	const handleStartDateChange = (event) => {
		const date = new Date(event.target.value);
		setStartDate(date);
	};

	const handleEndDateChange = (event) => {
		const date = new Date(event.target.value);
		setEndDate(date);
	};

	return (
		<>
			<Grid className="flex flex-col">
				<Grid className="p-10">
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									แบบรับรองตนเองทีได้รับการอนุมัติแล้ว
									<Tooltip title="อัพเดทข้อมูล">
										<Button
											style={{ marginLeft: 10, width: 30, height: 30 }}
											color="primary"
											variant="contained"
											onClick={() => {
												forceSDOCWaitStatusList();
											}}>
											<Refresh style={{ fontSize: 22 }} className="mr-1" />
										</Button>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Button
									className={`w-36`}
									color="primary"
									variant="contained"
									onClick={toggleDialog}>
									<Search style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>ค้นหาขั้นสูง</Grid>
								</Button>
							</Grid>
							{/* <Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Button
									className={`w-72`}
									color="primary"
									variant="contained"
									onClick={handleDownloadClick}>
									<GetApp style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>แบบรับรองตนเองทีได้รับการอนุมัติ</Grid>
								</Button>
							</Grid> */}
							<Grid className="md:px-5" style={{ height: '80vh', width: '100%' }}>
								<DataGrid
									rows={rowsApproveStatus}
									columns={columnsApproveStatus}
									pageSize={10}
									rowsPerPageOptions={[10]}
									disableSelectionOnClick
									sortModel={sortModel}
									rowHeight={66}
									onSortModelChange={(model) => {
										handleSortChange(model)
									}}
									localeText={{
										toolbarExport: 'ดาวน์โหลดข้อมูลจากตาราง',
									}}
									components={{
										NoRowsOverlay: () => {
											if (!isDataLoading) {
												return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
											} else {
												return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
											}
										},
										Toolbar: CustomToolbar,
									}}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>

			<Dialog
				className="w-full"
				maxWidth="md"
				fullWidth
				scroll="paper"
				open={isDialogOpen}
				onClose={toggleDialog}>
				<DialogTitle style={{ backgroundColor: '#a74345' }}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Grid className="flex flex-row items-center" component="div">
							<Grid className="text-white">ค้นหาขั้นสูง</Grid>
						</Grid>
						<IconButton onClick={toggleDialog} style={{ color: '#fff' }}>
							<Close />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogContent dividers>
					<table
						className="w-full"
						style={{ textAlign: 'left', borderCollapse: 'collapse' }}
					>
						<tbody>
							<tr>
								<td style={{ width: "20%" }}>เลขที่ใบรับรอง:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'sDocNo')?.value || ''}
										placeholder="(ไม่ต้องใส่ SD นำหน้า)"
										id="sDocNo"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td style={{ width: "20%" }}>เลขที่คำขอ:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'runningNumber')?.value || ''}
										placeholder="(ไม่ต้องใส่ SR นำหน้า)"
										id="runningNumber"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ตราอักษร:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'brandName')?.value || ''}
										id="brandName"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>รุ่นของผลิตภัณฑ์:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'productModel')?.value || ''}
										id="productModel"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ผู้ยื่นเอกสาร:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'operator')?.value || ''}
										id="operator"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ยื่นในนาม:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'operatorInfo')?.value || ''}
										id="operatorInfo"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ผู้ได้รับมอบหมายงาน:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'approver')?.value || ''}
										id="approver"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>วันที่ได้รับอนุมัติ:</td>
								<td>
									<TextField
										style={{ maxWidth: '200px', width: '200px' }}
										type="date"
										value={searchCreate}
										onChange={handleCreateChange}
									/>
								</td>
							</tr>
							<tr>
								<td>บันทึกข้อมูลการค้นหา:</td>
								<td className="w-full ">
									<Switch
										checked={isSaveSearch}
										onChange={handleSaveSearch}
										color="primary"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</DialogContent>
				<DialogActions>
					<Grid
						className="w-8/12 mx-auto flex flex-row justify-between items-center"
						component="div">
						<Button
							className="w-5/12"
							onClick={onSearchResultClear}
							color="secondary"
							variant="outlined">
							ล้างผลการค้นหา
						</Button>
						<Button
							className="w-5/12"
							color="primary"
							variant="contained"
							onClick={onSearchResult}>
							ค้นหา
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>

			<Dialog open={openDialogRangeDate} onClose={handleCloseDialogRangeDate} aria-labelledby="form-dialog-title">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Grid className="flex flex-row items-center" component="div">
							<Grid className="text-white">ดาวน์โหลดข้อมูล{DialogRangeDateType == "SDoCSummaryExcel" ? "แบบรับรองตนเองทีได้รับการอนุมัติ" : "ผู้ยื่นแบบรับรองตนเองที่ได้รับการอนุมัติ"}</Grid>
						</Grid>
						<IconButton onClick={toggleDialog} style={{ color: '#fff' }}>
							<Close />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogTitle id="form-dialog-title">เลือกวันที่เริ่มต้นและสิ้นสุด</DialogTitle>
				<DialogContent>
					<table>
						<tbody>
							<tr>
								<td>วันที่เริ่มต้น:</td>
								<td>
									<TextField
										style={{ maxWidth: '200px', width: '200px' }}
										type="date"
										value={formatDate(startDate)}
										onChange={handleStartDateChange}
									/>
								</td>
							</tr>
							<tr>
								<td>วันที่สิ้นสุด:</td>
								<td>
									<TextField
										style={{ maxWidth: '200px', width: '200px' }}
										type="date"
										value={formatDate(endDate)}
										onChange={handleEndDateChange}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialogRangeDate} color="primary">
						ยกเลิก
					</Button>
					<Button onClick={DialogRangeDateType == "SDoCSummaryExcel" ? handleDownloadClick : handleDownloadSummaryFullClick} color="primary">
						ดาวน์โหลด
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
