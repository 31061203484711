export const convertNumber = (num, isToFixed) => {
	if (num) {
		if (isToFixed)
			return (
				Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
			).toFixed(2)

		return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
	} else return ''
}
