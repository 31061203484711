import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions, useStoreState } from 'store'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	CircularProgress,
} from '@material-ui/core'
import { now } from '../../../../../../node_modules/moment/moment'
const useStyles = makeStyles({
	table: {
		width: '100%',
	},
})

export default function TablePOA(props) {
	const classes = useStyles()
	const { data } = useStoreState(state => state.poa)
	const selectPOA = useStoreActions(action => action.poa.setSelected)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		// console.log('data',data)
		selectPOA(null)
		// eslint-disable-next-line
	}, [])

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>เลขประจำตัวผู้เสียภาษี</TableCell>

						<TableCell>ระยะเวลามอบอำนาจ</TableCell>
						<TableCell>ชื่อผู้ประกอบการ</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow key="0">
						<TableCell component="td" scope="row">
							-
						</TableCell>
						<TableCell component="td" scope="row">
							-
						</TableCell>
						<TableCell component="td" scope="row">
							บุคคลธรรมดา
						</TableCell>
						<TableCell align="right">
							<Button
								className="w-5/12"
								onClick={e => {
									selectPOA(0)
									setLoading(true)
								}}
								disabled={loading}
								color="primary"
								variant="contained">
								เลือก
							</Button>
						</TableCell>
					</TableRow>
					{data.map(row => {
						var exp = new Date(new String(row.expireDate).split('/')[2] + '-' + new String(row.expireDate).split('/')[1] + '-' + new String(row.expireDate).split('/')[0])
						// if (row.expireDate == null){
						// 	// if expireDate is null, set expireDate to today + 1 day
						// 	exp = new Date()
						// 	exp.setDate(exp.getDate() + 7)
						// }
						var now = new Date()
						exp.setDate(exp.getDate() + 1)
						console.log('exp', exp.getTime())
						console.log('now', now.getTime())
						if (exp.getTime() >= now.getTime()) {
							return (
								<TableRow key={row.juristicNo}>
									<TableCell component="td" scope="row">
										{row.juristicNo}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.startDate} ถึง {row.expireDate ? row.expireDate : 'ไม่มีกำหนด'}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.operator ? row.operator.operatorName : 'ไม่พบในระบบ'}
									</TableCell>
									<TableCell align="right">
										<Button
											className="w-5/12"
											onClick={e => {
												selectPOA(row)
												setLoading(true)
											}}
											disabled={loading}
											color="primary"
											variant="contained">
											{loading && <CircularProgress size={25} />}
											{!loading && 'เลือก'}
										</Button>
									</TableCell>
								</TableRow>
							)
						}
					})}




				</TableBody>
			</Table>
		</TableContainer>
	)
}
