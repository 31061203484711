import { useState, useEffect } from 'react'

/**
 * Sub Label Types:
 * 1) No sub label exist.
 * 2) One sub label.
 * 3) One main sub label with child of sub label.
 */

export const useReduceForm = formParam => {
	const [form, setForm] = useState([])

	useEffect(() => {
		const temp = formParam.reduce((results, item) => {
			let result

			const findedIndex = results.findIndex(
				find_item => find_item.no === item.no
			)

			if (findedIndex !== -1) {
				let isnew_targeted_text = false
				let tempResult = results[findedIndex]

				// Targeted Text
				if (
					tempResult.targeted_text[tempResult.targeted_text.length - 1] !==
					item.targeted_text
				)
					isnew_targeted_text = true

				// Sub Labels
				let labels = tempResult.sub_labels
				let isnew_labels = false

				if (labels.sub_label_type === 3) {
					const findedLabelNameIndex = labels.labels.findIndex(
						label => label.label_name === item.subLabel_1
					)

					if (findedLabelNameIndex !== -1) {
						labels.labels[findedLabelNameIndex].childs.push({
							id: item.id,
							label: item.subLabel_2,
						})
					} else {
						labels.labels.push({
							label_name: item.subLabel_1,
							childs: [{ id: item.id, label: item.subLabel_2 }],
						})
					}

					isnew_labels = true
				} else if (labels.sub_label_type === 2) {
					labels.labels.push({ id: item.id, label: item.subLabel_1 })
					isnew_labels = true
				}

				tempResult = {
					...tempResult,
					targeted_text: isnew_targeted_text
						? [...tempResult.targeted_text, item.targeted_text]
						: tempResult.targeted_text,
					sub_labels: isnew_labels ? labels : tempResult.sub_labels,
					records: [...tempResult.records, { id: item.id, ...item.records }],
				}
				result = tempResult

				results[findedIndex] = result
			} else {
				// Sub Labels
				const sub_label_type =
					item.subLabel_2 && item.subLabel_1 ? 3 : item.subLabel_1 ? 2 : 1

				const labels = []

				if (sub_label_type === 3)
					labels.push({
						label_name: item.subLabel_1,
						childs: [{ id: item.id, label: item.subLabel_2 }],
					})
				else if (sub_label_type === 2)
					labels.push({ id: item.id, label: item.subLabel_1 })

				result = {
					no: item.no,
					label: item.label,
					targeted_text: [item.targeted_text],
					filling_condition: item.filling_condition,
					sub_labels: {
						sub_label_type,
						labels: labels,
					},
					isNeedAnswer: item.isNeedAnswer,
					records: [{ id: item.id, ...item.records }],
					data_type: item.data_type,
				}

				return [...results, result]
			}

			return results
		}, [])

		setForm(temp)
	}, [formParam])

	return { form }
}
