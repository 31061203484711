import { Grid } from '@material-ui/core'

import ReportCard from '../form'

export default function StandardReportStep2({
	list,
	currentFilledFormIndex,
	// Standard Report Answers
	fixedGeneralAnswers,
	fixedInfoAnswers,
	fixedSoundAnswers,
	mobileGeneralAnswers,
	mobileInfoAnswers,
	mobileSoundAnswers,
	// Validation
	currentSelectedError,
	openValidationModal,
	onOpenValidationModal,
	onCloseValidationModal,
}) {
	return (
		<>
			<Grid className="w-full flex flex-col gap-y-6">
				<ReportCard
					type={list[currentFilledFormIndex]}
					// Standard Report Answers
					fixedGeneralAnswers={fixedGeneralAnswers}
					fixedInfoAnswers={fixedInfoAnswers}
					fixedSoundAnswers={fixedSoundAnswers}
					mobileGeneralAnswers={mobileGeneralAnswers}
					mobileInfoAnswers={mobileInfoAnswers}
					mobileSoundAnswers={mobileSoundAnswers}
					// Validation
					currentSelectedError={currentSelectedError}
					openValidationModal={openValidationModal}
					onOpenValidationModal={onOpenValidationModal}
					onCloseValidationModal={onCloseValidationModal}
				/>
			</Grid>
		</>
	)
}
