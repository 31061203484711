import API, { adminHeader } from '../config/axios'

const datasetReportsAPI = {
	getDatasetReports: () => {
		return API.get('/DataSetReports')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	approveDatasetReports: reportID => {
		return API.post(`/DataSetReports/${reportID}/approve`)
			.then(res => {
				return true
			})
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getUnReportDatasetReportsOperator: () => {
		return API.get('/DataSetReports/unReportedOperators', {
			headers: adminHeader,
		})
			.then(res => res)
			.catch(err => err)
	},
	updateAdminProcess: (currentDatSetReportID, process) => {
		return API.put(
			`/DataSetReports/${currentDatSetReportID}/updateAdminProcess`,
			process,
			{
				headers: adminHeader,
			}
		)
			.then(res => res)
			.catch(err => {
				console.error(err)
			})
	},
	deleteReport: (reportID) => {
		return API.delete(
			`/DataSetReports/${reportID}`,
		)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	
}

export default datasetReportsAPI
