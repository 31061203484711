import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { Typography, Card, Button, Grid } from '@material-ui/core/'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { CalendarToday, Menu } from '@material-ui/icons'

const StandardReportHistoryDetails = () => {
	const history = useHistory()
	const columns = [
		{
			field: 'serviceQualityIndicator',
			headerName: 'ค่าชี้วัดคุณภาพบริการ',
			width: 435,
		},
		{
			field: 'operator',
			headerName: 'ผู้ดำเนินการ',
			width: 180,
		},
		{
			field: 'duty',
			headerName: 'หน้าที่',
			width: 160,
		},
		{
			field: 'date',
			headerName: 'วันที่ดำเนินการ',
			width: 140,
		},
		{
			field: 'workStatus',
			headerName: 'สถานะ',
			width: 140,
		},
		{
			field: '',
			headerName: 'กิจกรรม',
			sortable: false,
			width: 110,
			disableClickEventBubbling: true,
			renderCell: params => {
				return (
					<Button
						variant="contained"
						className="w-28"
						onClick={() => {
							history.push('/standard-report-history/')
						}}>
						<Menu style={{ fontSize: 20 }} className="mr-1" />
						ประวัติ
					</Button>
				)
			},
		},
	]

	const rows = [
		{
			id: 1,
			serviceQualityIndicator: '1.1.1 ระยะเวลาสำหรับการขอเริ่มเปิดใช้บริการ',
			operator: 'นาย กนก สุวรรณศรี',
			duty: 'ผู้เตรียมข้อมูล',
			date: '05/07/2564',
			workStatus: 'เตรียมข้อมูล',
		},
		{
			id: 2,
			serviceQualityIndicator:
				'1.1.2 ร้อยละของการติดตั้งบริการสำเร็จภายในวันที่',
			operator: 'นาย ป้องไท ศีรีกุล',
			duty: 'ผู้ตรวจสอบข้อมูล',
			date: '06/07/2564',
			workStatus: 'ตรวจสอบข้อมูล',
		},
		{
			id: 3,
			serviceQualityIndicator:
				'1.3.1 ร้อยละของเวลารวมที่อุปกรณ์กระจายสัญญาณปลายทางไม่สามารถให้บริการได้ใน 1 เดือน',
			operator: 'นาย กนก สุวรรณศรี',
			duty: 'ผู้เตรียมข้อมูล',
			date: '06/07/2564',
			workStatus: 'แก้ไขข้อมูล',
		},
		{
			id: 4,
			serviceQualityIndicator:
				'1.3.3 ความเร็วเฉลี่ยในการส่งข้อมูลแบบ FTP (Average speed of FTP transfers)',
			operator: 'นาย นราทิพย์ จรัญชัย',
			duty: 'ผู้อนุมัติการส่งข้อมูล',
			date: '07/07/2564',
			workStatus: 'ส่งข้อมูล',
		},
	]
	const [isDataLoading, setIsDataLoading] = useState(false)
	return (
		<>
			<Card className="flex flex-col items-center justify-center lg:mt-20 mt-4 lg:mx-16 mx-2">
				<Grid className="flex flex-row my-4">
					<CalendarToday style={{ fontSize: 22 }} className="mr-1" />
					<Typography>สถานะการส่งค่าตัวชี้วัด</Typography>
				</Grid>
				<Grid style={{ height: 400 }} className="lg:px-10 mb-10 w-full">
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={5}
						disableSelectionOnClick
						components={{
							NoRowsOverlay: () => {
								if (!isDataLoading) {
									return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
								}else{
									return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
								}
							}
						}}
					/>
				</Grid>
			</Card>
		</>
	)
}

export default StandardReportHistoryDetails
