import { Card, Grid, Divider, TextField, Button } from '@material-ui/core'
import { Assignment, Archive } from '@material-ui/icons'

import File from './file'

export default function DocumentAdditionCard({
	sDOCID,
	mountType,
	additionalFiles = [],
	tempFile = {
		file: null,
		name: '',
		filedate: '',
		isfilelocked: false,
	},
	onAddTemp,
	onAddDocument,
	onDeleteDocument,
	onChangeAttachedFileName,
	onDownloadDocument,
	onDeleteUploadedDocument,
	onChangeIsFileLocked,
	ApprovalControl,
	sDOCStatus,
}) {
	return (
		<Card className={`border-t-4 border-card_top_border mb-5`}>
			<Grid className="flex flex-col justify-center items-start px-5 mb-5">
				{/* Header */}
				<Grid className="flex flex-row mt-4 mb-2">
					<Assignment style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						เอกสารหลักฐานประกอบการรับรองตนเอง
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>

				{/* Files Section */}
				<Grid className="flex flex-col items-start my-2 w-8/12 gap-y-3">
					{/* Heading */}
					<Grid>เอกสารแนบประกอบการรับรองตนเอง</Grid>
					{/* <Grid className="font-bold text-xs my-4">
						* อัพโหลดไฟล์ข้อมูลใน format .xlsx .xls .docx .doc .pdf .jpeg .png
						.jpg .zip เท่านั้น *
					</Grid> */}
					<Grid className="font-bold text-xs my-4">
						* อัพโหลดไฟล์ข้อมูลใน format .pdf เท่านั้น *
					</Grid>
					<Grid className="flex flex-row gap-x-3 items-end w-full">
						<Grid className="flex flex-col">
							{additionalFiles.map((item, index) => {
								console.log('item', item)
								const fileKey = `sdoc-file-additional-${index}-${item.fileID}`;
								return (
									<File
										key={fileKey}
										sDOCID={sDOCID}
										mountType={mountType}
										fileID={item.fileID}
										index={index}
										originalFileName={
											mountType && !item.file?.name
												? item.file
												: item.file?.name
										}
										fileName={item.name}
										isFileLocked={item.isfilelocked}
										// fileDate={item.filedate}
										onDelete={() => onDeleteDocument(index)}
										onDownloadDocument={onDownloadDocument}
										onChangeAttachedFileName={onChangeAttachedFileName}
										onDeleteUploadedDocument={() => onDeleteUploadedDocument(item.fileID, index)}
										onChangeIsFileLocked={() => onChangeIsFileLocked(item.fileID, index)}
										ApprovalControl={ApprovalControl}
										sDOCStatus={sDOCStatus}
									/>
								)
							})}
						</Grid>
					</Grid>
					{mountType && (
						<Grid className="flex flex-row gap-x-3 items-end w-full">
							<a
								href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${sDOCID}/attach/ALL/download`}
								target="_blank"
								rel="noreferrer">
								<Button
									color="primary"
									variant="contained"
									component="label"
									size="small"
									className="flex w-full"
								>
									<Archive />
									ดาวน์โหลดทั้งหมด
								</Button>
							</a>
						</Grid>
					)}

					{(!mountType || mountType === 'attachFile' || mountType === "ModifyInfo") && (
						<Grid className="flex flex-row gap-x-3 items-end w-full">
							<Grid className={`flex flex-col items-start gap-y-3 w-full`}>
								{/* <Grid className="flex flex-row">
									<Grid>ชื่อเอกสาร</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid> */}
								<Grid className="flex flex-row">
									<Grid>ข้อมูลเอกสารแนบ</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid className="flex flex-row w-full">
									<TextField
										fullWidth
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 70 }}
										value={tempFile.name}
										onChange={e => onAddTemp(e, 'name')}
										label="โปรดตั้งชื่อเอกสาร*"
									/>
									{/* <Grid className="flex flex-row">
									<Grid>วันที่เอกสาร</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid> */}
									{/* <TextField
										fullWidth
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 40 }}
										value={tempFile.filedate}
										onChange={e => onAddTemp(e, 'filedate')}
										label="วันที่เอกสาร*"
									/> */}
								</Grid>
							</Grid>
							<Button
								color="primary"
								variant="contained"
								component="label"
								size="small"
								className="flex w-2/12">
								เลือกไฟล์
								<input
									id="sdocFileInputButton"
									type="file"
									hidden
									// accept=".xlsx, .xls, .docx, .doc, .pdf, .jpeg, .png, .jpg, .zip"
									accept=".pdf"
									onChange={e => {
										onAddTemp(e, 'file')
										e.target.value = ''
									}}
								/>
							</Button>
							<Grid
								className={`${tempFile.file ? 'text-black' : 'text-gray-400'
									} flex w-2/12`}>
								{tempFile.file ? tempFile.file?.name : 'ยังไม่ได้เลือกไฟล์'}
							</Grid>

							<Grid className="ml-10 flex w-2/12">
								<Button
									fullWidth
									variant="contained"
									size="small"
									color="primary"
									// disabled={!tempFile.file || tempFile.name === '' || tempFile.filedate === ''}
									disabled={!tempFile.file || tempFile.name === ''}
									onClick={onAddDocument}>
									แนบไฟล์
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
					{/* display text please upload file least 1 file */}
					<Grid className="mt-3">
						{!mountType && (
							<Grid className="text-red-500 text-sm">
								* กรุณาแนบเอกสารประกอบการรับรองตนเองอย่างน้อย 2 ไฟล์<br />
								* ขนาดไฟล์รวมกันต้องไม่เกิน 300 MB<br />
								** หากเอกสารเป็นไฟล์ผลการทดสอบเครื่องโทรคมนาคม (Test Report) ขอให้ท่านตั้งชื่อเอกสารเป็นหมายเลขผลการทดสอบ
								เช่น Test Report No. : XXXXXXX
							</Grid>
						)}
					</Grid>
				</Grid>
				{/* Uploaded File */}
			</Grid>
		</Card>
	)
}
