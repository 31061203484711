import { forwardRef, useRef, useState } from 'react'
import {
	Grid,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Slide,
	CircularProgress,
	useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import { datasetRecord } from '../api'

import Cancel from '../assets/images/cancel.png'

const makeid = (length) => {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
	}
	return result;
}


export function AuthenticatedLink({ url, filename = null, children }) {
	const [isDownload, setIsDownload] = useState(false)
	const [showSomethingWentWrongModal, setShowSomethingWentWrongModal] =
		useState(false)
	// const link = createRef()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)
	if(!filename){
		filename = makeid(5);
	}
	const handleAction = async () => {
		const result = await datasetRecord.downloadFile(url)

		const blob = await result.data

		if (blob) {
			const href = window.URL.createObjectURL(blob)

			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', filename)

			document.body.appendChild(link)

			link.click()

			setIsDownload(false)

			link.parentNode.removeChild(link)
		} else {
			setShowSomethingWentWrongModal(true)
			setIsDownload(false)
		}
	}

	return (
		<>
			{/* eslint-disable-next-line */}
			<a
				onClick={() => {
					setIsDownload(true)
					handleAction()
				}}
				target="_blank"
				rel="noreferrer">
				{children}
			</a>

			{/* Spinner Loading */}
			<Dialog
				className="w-full"
				open={isDownload}
				maxWidth="xs"
				fullWidth
				fullScreen={fullScreen}
				TransitionComponent={Transition}
				scroll="paper">
				<DialogContent>
					<DialogContentText
						className="h-72 gap-y-10 flex flex-col justify-center items-center"
						tabIndex={-1}
						ref={descriptionElementRef}
						component="div">
						<CircularProgress color="primary" />
						<Grid className="text-lg lg:text-xl text-center">
							กำลังดาวน​์โหลดไฟล์
						</Grid>
					</DialogContentText>
				</DialogContent>
			</Dialog>

			{/* Failed Modal */}
			<Dialog
				className="w-full"
				open={showSomethingWentWrongModal}
				maxWidth="xs"
				fullWidth
				fullScreen={fullScreen}
				TransitionComponent={Transition}
				scroll="paper">
				<DialogContent>
					<DialogContentText
						className="h-72 gap-y-10 flex flex-col"
						tabIndex={-1}
						ref={descriptionElementRef}
						component="div">
						<img
							src={Cancel}
							alt="Correct Checking"
							width={200}
							className="mx-auto"
						/>
						<Grid className="text-lg lg:text-xl text-center">
							เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						component="div">
						<Grid item className="p-3 w-5/12" component="div">
							<Button
								fullWidth
								onClick={() => setShowSomethingWentWrongModal(false)}
								color="secondary"
								size="large"
								variant="contained">
								ตกลง
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
