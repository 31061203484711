import React, { useState } from 'react'
import { Button, Grid, Card, TextField, Divider } from '@material-ui/core'

import SuccessModal from '../../../components/users/client-api/success-modal'
import EmptyFileModal from '../../../components/users/standard-report/empty-file-modal'

import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { form } from '../../../api'

import logo from '../../../assets/images/NBTC-logo.png'

const ClientAPI = () => {
	const history = useHistory()
	const [excelFile, setExcelFile] = useState(null)
	const [excelFileShow, setExcelFileShow] = useState('')
	const [openEmptyFileModal, setOpenEmptyFileModal] = useState(false)
	const [openUploadSuccessModal, setOpenUploadSuccessModal] = useState(false)
	let excelFileTemp = []
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const onOpenEmptyFileModal = () => setOpenEmptyFileModal(!openEmptyFileModal)
	const onOpenUploadSuccessModal = () =>
		setOpenUploadSuccessModal(!openUploadSuccessModal)

	const uploadExcel = async ({ token }) => {
		const resExcelFile = await form.APIExcelUploading(excelFile, token, true)
		if (resExcelFile === false) onOpenEmptyFileModal()
		else {
			onOpenUploadSuccessModal()
		}
	}

	return (
		<>
			<Grid className="h-screen flex flex-col items-center justify-center sign-in-container p-3">
				<Card className="flex flex-col items-center justify-center px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5">
					<Grid className="">
						<img src={logo} alt="NBTC" width="140" />
					</Grid>
					<Grid className="mt-3 text-sm md:text-base text-center">
						<h1>ทดสอบการส่งข้อมูลผ่าน API</h1>
					</Grid>

					<Grid className="mt-6 w-full">
						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextField
									fullWidth
									type="text"
									label="Token"
									autoFocus={true}
									variant="filled"
									size="small"
									onChange={onChange}
									value={value}
								/>
							)}
							name="token"
							defaultValue=""
						/>
						{/* National Card ID No. Error Message */}
						<p className="text-red-500 h-8">
							{errors.token?.type === 'required' && 'กรุณาใส่ API Token'}
						</p>
						<Grid className="flex items-center justify-center ">
							<Card className="flex flex-col h-auto p-4 w-full gap-y-2 border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0">
								<Grid className="flex flex-row font-bold text-sm items-center">
									<Grid className="text-card_header_color">อัพโหลดไฟล์</Grid>
								</Grid>
								<Grid>
									<Divider />
								</Grid>
								<Grid className="mt-4 font-bold">
									* อัพโหลดไฟล์ข้อมูลใน format .xls, .xlsx เท่านั้น *
								</Grid>
								<Grid className="flex flex-row items-center ml-10 my-1">
									<Button variant="contained" component="label" size="small">
										Choose file
										<input
											id="excelFile"
											type="file"
											value={excelFileTemp}
											hidden
											accept=".xlsx, .xls"
											onChange={e => {
												setExcelFile(e.target.files[0])
												setExcelFileShow(e.target.files[0].name)
											}}
										/>
									</Button>
									<Grid className="ml-2">{excelFileShow}</Grid>
								</Grid>
							</Card>
						</Grid>
						<Divider />

						<Grid className="flex flex-row gap-x-3 w-full justify-center mx-auto py-5">
							<Button
								className="text-white w-full"
								variant="contained"
								color="secondary"
								size="large"
								onClick={handleSubmit(uploadExcel)}>
								ส่งข้อมูล
							</Button>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<EmptyFileModal
				open={openEmptyFileModal}
				handleButton={onOpenEmptyFileModal}
			/>
			<SuccessModal
				open={openUploadSuccessModal}
				handleButton={() => history.go(0)}
			/>
		</>
	)
}

export default ClientAPI
