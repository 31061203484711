// Page Descrition: Admin's user management page
import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core/'

import OrgSearchResult from '@pages/main-admin/user-management/components/search-result/index'
import OrganizationUserModal from 'pages/main-admin/user-management/components/user-modal/index'
import { DeleteUserModal } from '@pages/main-admin/user-management/components/delete-user-modal'
import NotFoundModal from '@pages/main-admin/user-management/components/not-found-modal'
import AddingSuccessModal from '@pages/main-admin/user-management/components/adding-success-modal'
import AddingFailedModal from '@pages/main-admin/user-management/components/adding-failed-modal'
import EditingSuccessModal from '@pages/main-admin/user-management/components/editing-success-modal'
import EditingFailedModal from '@pages/main-admin/user-management/components/editing-failed-modal'
import DeletedSuccessModal from '@pages/main-admin/user-management/components/deleted-success-modal'
import DeletedFailedModal from '@pages/main-admin/user-management/components/deleted-failed-modal'

import { useStoreRehydrated } from '../../../store'

import { useLoaded } from '../../../utils/useLoaded'
import { admin, adminQoS } from '../../../api/index'

import { org_status } from '../../../data/organization-user/organization-user-status'

const initialUserInfoState = {
	adminRoleID: 'no-permission',
	adminRoleIDSDoc: 'no-permission',
	adminRoleIDCentralAdmin: 'no-permission',
	email: '',
	firstname: '',
	lastname: '',
	tel: '',
	position: '',
	adminStatusID: 1,
	signature: null,
	mobile: '',
	prefixname: 'ไม่ระบุ',
}

/**
 * * Developer Notes - Fetched Admin Permission Role ID Field Name
 * `dataportalServiceID: 1`: QoS
 * `dataportalServiceID: 2`: SDoc
 * `dataportalServiceID: 3`: Central Admin
 */

export default function MainAdminUserManagement() {
	const loaded = useLoaded()
	const isRehydrated = useStoreRehydrated()
	// Main Users Data
	const [user, setUser] = useState([])
	const [userData, setUserData] = useState([])
	// Modal State
	const [currentEditUserID, setCurrentEditUserID] = useState(null)
	const [openUserModal, setOpenUserModal] = useState(false)
	const [userModalType, setUserModalType] = useState('add')
	const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
	const [openAddingSuccessModal, setOpenAddingSuccessModal] = useState(false)
	const [openAddingFailedModal, setOpenAddingFailedModal] = useState(false)
	const [openNotFoundModal, setOpenNotFoundModal] = useState(false)
	const [openEditingSuccessModal, setOpenEditingSuccessModal] = useState(false)
	const [openEditingFailedModal, setOpenEditingFailedModal] = useState(false)
	const [openDeletedSuccessModal, setOpenDeletedSuccessModal] = useState(false)
	const [openDeletedFailedModal, setOpenDeletedFailedModal] = useState(false)
	const [openUserNotFoundModal, setOpenUserNotFoundModal] = useState(false)
	// Validation
	const [isSearchingEmail, setIsSearchingEmail] = useState(false)
	const [emailValidation, setEmailValidation] = useState(false)
	const [showPositionAndSignatureAdd, setShowPositionAndSignatureAdd] =
		useState(false)
	const [showPositionAndSignatureEdit, setShowPositionAndSignatureEdit] =
		useState(false)

	// Adding User State
	const [nationalIDCardNo, setNationalIDCardNo] = useState('')
	const [userInfo, setUserInfo] = useState(initialUserInfoState)
	const [activeDate, setActiveDate] = useState(null)
	const [expireDate, setExpireDate] = useState(null)
	const [fixedTypePermission, setFixedTypePermission] = useState(false)
	const [mobileTypePermission, setMobileTypePermission] = useState(false)
	// const [organizationData, setOrganizationData] = useState(null)
	// Editing User State
	const [editNationalIDCardNo, setEditNationalIDCardNo] = useState('')
	const [editUserInfo, setEditUserInfo] = useState(initialUserInfoState)
	const [editActiveDate, setEditActiveDate] = useState(null)
	const [editExpireDate, setEditExpireDate] = useState(null)
	const [editFixedTypePermission, setEditFixedTypePermission] = useState(false)
	const [editMobileTypePermission, setEditMobileTypePermission] =
		useState(false)
	// Save Button Logic
	const [saveButtonAdd, setSaveButtonAdd] = useState(true)
	const [saveButtonEdit, setSaveButtonEdit] = useState(true)

	// Admin Roles
	const [adminRoles, setAdminRoles] = useState([])

	/** -----------------------------------------------------------> */

	// Main Function

	const onOpenUserNotFoundModal = () => {
		setOpenUserNotFoundModal(!openUserNotFoundModal)
	}

	const onOpenUserModal = () => {
		setOpenUserModal(!openUserModal)
		clearAllUserModalState()
	}

	const onOpenDeleteUserModal = () => {
		setOpenDeleteUserModal(!openDeleteUserModal)
	}

	const onOpenNotFoundModal = () => {
		setOpenNotFoundModal(!openNotFoundModal)
	}

	const onOpenAddingSuccessModal = () => {
		setOpenAddingSuccessModal(!openAddingSuccessModal)
	}

	const onOpenAddingFailedModal = () => {
		setOpenAddingFailedModal(!openAddingFailedModal)
	}

	const onOpenEditingSuccessModal = () => {
		setOpenEditingSuccessModal(!openEditingSuccessModal)
	}

	const onOpenEditingFailedModal = () => {
		setOpenEditingFailedModal(!openEditingFailedModal)
	}

	const onOpenDeletedSuccessModal = () => {
		setOpenDeletedSuccessModal(!openDeletedSuccessModal)
	}

	const onOpenDeletedFailedModal = () => {
		setOpenDeletedFailedModal(!openDeletedFailedModal)
	}

	const onSearchEmail = async () => {
		setIsSearchingEmail(true)
		const resSearch = await adminQoS.ldapAuth(userInfo.username)
		if (resSearch) {
			if (resSearch.status === 200) {
				setEmailValidation(true)
				setUserInfo({
					...userInfo,
					adminRoleID: userInfo.adminRoleID,
					adminRoleIDSDoc: userInfo.adminRoleIDSDoc,
					adminRoleIDCentralAdmin: userInfo.adminRoleIDCentralAdmin,
					firstname: resSearch.data.firstname,
					lastname: resSearch.data.lastname,
					email: resSearch.data.email,
					prefixname: resSearch.data.prefixname,
					adminStatusID: userInfo.adminStatusID,
				})
			} else {
				setEmailValidation(false)
				onOpenUserNotFoundModal()
			}
			setIsSearchingEmail(false)
		}
	}

	const onUploadSignature = (e, type) => {
		const { files } = e.target
		if (type === 'add' && files.length !== 0)
			setUserInfo({ ...userInfo, signature: files[0] })
		else if (type === 'edit' && files.length !== 0){
			setEditUserInfo({ ...editUserInfo, signature: files[0] })
		}
			
	}

	const getAdminRoles = async () => {
		const resRoles = await admin.getAdminRoles()
		if (resRoles) {
			if (resRoles.status === 200) {
				const fetchedAdminRoles = resRoles.data
				let tempAdminRoles = []
				fetchedAdminRoles.forEach((item, index) => {
					if (item.isActive === '1') {
						tempAdminRoles.push({
							name: item.adminRoleName,
							dataportalServiceID: item.dataportalServiceID,
							value: item.id,
						})
					}
				})
				setAdminRoles(tempAdminRoles)
				return tempAdminRoles
			}
		}
	}

	const onAddAdmin = async () => {
		const res = await adminQoS.addAdmin(userInfo)
		if (res.status === 201) {
			onOpenAddingSuccessModal()
			setUserInfo(initialUserInfoState)
			setEditUserInfo(initialUserInfoState)
		} else {
			onOpenAddingFailedModal()
		}
	}

	const onLanding = async () => {
		getAdminRoles()
		const res = await admin.getAdmins()
		if (res) {
			if (res.status === 200) {
				const fetchedAdmin = res.data

				const preprocessedArr = fetchedAdmin.map((item, index) => {
					return {
						id: index + 1,
						adminId: item.id ?? '',
						name: item.firstname + ' ' + item.lastname,
						// email: item.email ?? '',
						// nationalIDCardNo: item.nationalIDCardNo ?? '',
						// mobile: item.mobile ?? '',
						adminRoleID: item.adminRoleID ?? '',
						// periodActivate: `${
						// 	item.activeDate
						// 		? format(new Date(item.activeDate), 'dd/MM/yyyy')
						// 		: ''
						// }  ${
						// 	item.expireDate
						// 		? '- ' + format(new Date(item.expireDate), 'dd/MM/yyyy')
						// 		: ''
						// } `,
						status: parseInt(item.adminStatusID) ?? '',
						adminAccess: item.adminAccess,
					}
				})

				setUserData(fetchedAdmin)
				setUser(preprocessedArr)
			}
		}
	}

	/** <----------------------------------------------------------- */

	// Adding User

	const onFillNationalCardIDNo = e => {
		var pattern = /^[0-9\b]+$/
		const { value } = e.target
		if (pattern.test(value) || value === '') {
			setNationalIDCardNo(value)
			setSaveButtonAdd(true)
		}
	}

	const onCheckFormTypePermission = (type, checked) => {
		if (type === 1) setFixedTypePermission(checked)
		else if (type === 2) setMobileTypePermission(checked)
	}

	const onChangeUserRoleID = e => {
		const { value } = e.target
		setUserInfo({ ...userInfo, adminRoleID: value })
	}

	const onChangeUserRoleIDSDoc = e => {
		const { value } = e.target
		setUserInfo({ ...userInfo, adminRoleIDSDoc: value })
	}

	const onChangeUserRoleIDCentralAdmin = e => {
		const { value } = e.target
		setUserInfo({ ...userInfo, adminRoleIDCentralAdmin: value })
	}

	const onSelectActiveDate = val => setActiveDate(val)

	const onSelectExpireDate = val => setExpireDate(val)

	//new
	const onChangeUserprefixname = e => {
		const { value } = e.target
		setUserInfo({ ...userInfo, prefixname: value })
	}

	// Editing User

	const onFindUserToEdit = () => {
		const finded = userData.find(item => item.id === currentEditUserID)

		if (finded) {
			const findedAdminRoleId = finded?.adminAccess
				? finded.adminAccess.find(
						item => parseInt(item.dataportalServiceID) === 1
				  )
				: null
			const findedAdminRoleIdSdoc = finded?.adminAccess
				? finded.adminAccess.find(
						item => parseInt(item.dataportalServiceID) === 2
				  )
				: null
			const findedAdminRoleIdCentralAdmin = finded?.adminAccess
				? finded.adminAccess.find(
						item => parseInt(item.dataportalServiceID) === 3
				  )
				: null

			setEditUserInfo({
				adminId: finded.id,
				adminRoleID: findedAdminRoleId?.adminRoleID ?? 'no-permission',
				adminRoleIDSDoc: findedAdminRoleIdSdoc?.adminRoleID ?? 'no-permission',
				adminRoleIDCentralAdmin:
					findedAdminRoleIdCentralAdmin?.adminRoleID ?? 'no-permission',
				firstname: finded.firstname,
				lastname: finded.lastname,
				email: finded.email,
				prefixname: finded.prefixname,
				username: finded.username,
				adminStatusID: finded.adminStatusID,
				adminAccess: finded.adminAccess,
				mobile: finded.mobile,
				position: finded.position,
				signature:finded.signaturePicturePath
			})
		}
	}

	const onFillEditNationalCardIDNo = e => {
		var pattern = /^[0-9\b]+$/
		const { value } = e.target
		if (pattern.test(value) || value === '') {
			setEditNationalIDCardNo(value)
		}
	}

	const onFillAddUserInfo = e => {
		const { name, value } = e.target
		setUserInfo({ ...userInfo, [name]: value })
	}

	const onFillEditUserInfo = e => {
		const { name, value } = e.target
		setEditUserInfo({ ...editUserInfo, [name]: value })
	}

	const onChangeEditUserRoleID = e => {
		const { value } = e.target
		setEditUserInfo({ ...editUserInfo, adminRoleID: value })
	}

	const onChangeEditUserRoleIDSDoc = e => {
		const { value } = e.target
		setEditUserInfo({ ...editUserInfo, adminRoleIDSDoc: value })
	}

	const onChangeEditUserRoleIDCentralAdmin = e => {
		const { value } = e.target
		setEditUserInfo({ ...editUserInfo, adminRoleIDCentralAdmin: value })
	}

	const onChangeEditUserStatus = e => {
		const { value } = e.target
		const findedStatus = Object.keys(org_status).find(
			(item, index) => item === value
		)

		if (findedStatus)
			setEditUserInfo({
				...editUserInfo,
				userStatus: {
					id: findedStatus,
					name: org_status[findedStatus],
				},
			})
	}

	const onCheckEditFormTypePermission = (type, checked) => {
		if (type === 1) setEditMobileTypePermission(checked)
		else if (type === 2) setEditFixedTypePermission(checked)
	}

	const onSelectEditActiveDate = val => setEditActiveDate(val)

	const onSelectEditExpireDate = val => setEditExpireDate(val)

	const onEditUser = async () => {
		const res = await adminQoS.editAdmin(editUserInfo)
		if (res) {
			if (res.status === (200 || 201)) onOpenEditingSuccessModal()
			else onOpenEditingFailedModal()
		}
	}
	// new code
	const onChangeEditUserprefixname = e => {
		const { value } = e.target
		setEditUserInfo({ ...editUserInfo, prefixname: value })
	}

	// Delete User
	const deleteUserID = async () => {
		onOpenDeleteUserModal()
		const finded = userData.find(item => item.id === currentEditUserID)
		if (finded) {
			const res = await admin.deleteAdmin(currentEditUserID)
			const status = res.status
			if (status === 200) {
				onOpenDeletedSuccessModal()
			} else {
				onOpenDeletedFailedModal()
			}
		} else {
			onOpenDeletedFailedModal()
		}
	}

	// Get Admin Role from ID
	const getAdminRoleFromID = id => {
		for (let i = 0; i < adminRoles.length; i++) {
			if (adminRoles[i].value === id) return adminRoles[i].name
		}
	}

	const clearAllUserModalState = () => {
		setUserInfo(initialUserInfoState)
		setEditUserInfo(initialUserInfoState)
	}

	// Reset Role of Admin

	const resetAdminRole = () => {
		if (userModalType === 'add') {
			setUserInfo({
				adminRoleID: 'no-permission',
				adminRoleIDSDoc: 'no-permission',
				adminRoleIDCentralAdmin: 'no-permission',
				email: userInfo.email,
				prefixname: userInfo.prefixname,
				firstname: userInfo.firstname,
				lastname: userInfo.lastname,
				adminStatusID: userInfo.adminStatusID,
			})
		} else if (userModalType === 'edit') {
			onFindUserToEdit()
		}
	}

	/** -----------------------------------------------------------> */

	// Main Rendering Function

	useEffect(() => {
		if (userModalType === 'edit') onFindUserToEdit()
		//eslint-disable-next-line
	}, [openUserModal])

	useEffect(() => {
		if (isRehydrated) onLanding()
		// eslint-disable-next-line
	}, [isRehydrated])

	useEffect(() => {
		setEmailValidation(false)
	}, [userInfo.username])

	useEffect(() => {
		if (
			emailValidation &&
			(userInfo.adminRoleID !== 'no-permission' ||
				userInfo.adminRoleIDSDoc !== 'no-permission' ||
				userInfo.adminRoleIDCentralAdmin !== 'no-permission')
		) {
			if (
				(userInfo.adminRoleID === 8 || userInfo.adminRoleIDSDoc === 12) &&
				(!userInfo.position || userInfo.signature === null)
			) {
				setSaveButtonAdd(false)
			} else if (
				userInfo.email === '' ||
				userInfo.email === null ||
				userInfo.email === ' '
			) {
				setSaveButtonAdd(false)
			} else {
				setSaveButtonAdd(true)
			}
		} else {
			setSaveButtonAdd(false)
		}
	}, [
		openUserModal,
		emailValidation,
		userInfo.email,
		userInfo.adminRoleID,
		userInfo.adminRoleIDSDoc,
		userInfo.adminRoleIDCentralAdmin,
		userInfo.position,
		userInfo.signature,
	])

	useEffect(() => console.log('userInfo', userInfo), [userInfo])

	useEffect(() => {
		if (
			editUserInfo.adminRoleID ||
			editUserInfo.adminRoleIDSDoc ||
			editUserInfo.adminRoleIDCentralAdmin
		) {
			if (
				(editUserInfo.adminRoleID === 8 || editUserInfo.adminRoleID === 9 ||editUserInfo.adminRoleID === 12 ) &&
				(!editUserInfo.position || editUserInfo.signature === null)
			) {
				setSaveButtonEdit(false)
			} else if (
				editUserInfo.email === '' ||
				editUserInfo.email === null ||
				editUserInfo.email === ' '
			) {
				setSaveButtonAdd(false)
			} else {
				setSaveButtonEdit(true)
			}
		} else {
			setSaveButtonEdit(false)
		}
	}, [
		openUserModal,
		editUserInfo.email,
		editUserInfo.adminRoleID,
		editUserInfo.adminRoleIDSDoc,
		editUserInfo.adminRoleIDCentralAdmin,
		editUserInfo.position,
		editUserInfo.signature,
	])

	useEffect(() => {
		if (userInfo.adminRoleIDSDoc === 8 ||userInfo.adminRoleIDSDoc === 9 || userInfo.adminRoleIDSDoc === 12 || userInfo.adminRoleIDSDoc === 15)
			setShowPositionAndSignatureAdd(true)
		else setShowPositionAndSignatureAdd(false)
	}, [userInfo.adminRoleID, userInfo.adminRoleIDSDoc])

	useEffect(() => {
		if (editUserInfo.adminRoleIDSDoc === 8 ||editUserInfo.adminRoleIDSDoc === 9 || editUserInfo.adminRoleIDSDoc === 12 || editUserInfo.adminRoleIDSDoc === 15)
			setShowPositionAndSignatureEdit(true)
		else setShowPositionAndSignatureEdit(false)
	}, [editUserInfo.adminRoleID, editUserInfo.adminRoleIDSDoc])

	/** <----------------------------------------------------------- */

	return (
		loaded && (
			<>
				<Grid className="min-h-screen flex flex-col px-3 lg:px-10 pb-10">
					{/* <SearchBox /> */}
					<OrgSearchResult
						user={user}
						onOpenUserModal={onOpenUserModal}
						setUserModalType={setUserModalType}
						onOpenDeleteUserModal={onOpenDeleteUserModal}
						setCurrentEditUserID={setCurrentEditUserID}
						getAdminRoleFromID={getAdminRoleFromID}
					/>

					<OrganizationUserModal
						open={openUserModal}
						onClose={onOpenUserModal}
						userModalType={userModalType}
						// Adding States
						nationalCardIDNo={nationalIDCardNo}
						userInfo={userInfo}
						activeDate={activeDate}
						expireDate={expireDate}
						fixedTypePermission={fixedTypePermission}
						mobileTypePermission={mobileTypePermission}
						// Adding Handler
						onFillNationalCardIDNo={onFillNationalCardIDNo}
						onCheckFormTypePermission={onCheckFormTypePermission}
						onChangeUserRoleID={onChangeUserRoleID}
						onChangeUserRoleIDSDoc={onChangeUserRoleIDSDoc}
						onChangeUserRoleIDCentralAdmin={onChangeUserRoleIDCentralAdmin}
						onSelectActiveDate={onSelectActiveDate}
						onSelectExpireDate={onSelectExpireDate}
						// organizationData={organizationData}
						//new
						onChangeUserprefixname={onChangeUserprefixname}
						// Edit States
						editNationalCardIDNo={editNationalIDCardNo}
						editUserInfo={editUserInfo}
						editActiveDate={editActiveDate}
						editExpireDate={editExpireDate}
						editFixedTypePermission={editFixedTypePermission}
						editMobileTypePermission={editMobileTypePermission}
						// Editing Handler
						onFillEditNationalCardIDNo={onFillEditNationalCardIDNo}
						onCheckEditFormTypePermission={onCheckEditFormTypePermission}
						onSelectEditActiveDate={onSelectEditActiveDate}
						onSelectEditExpireDate={onSelectEditExpireDate}
						onFillAddUserInfo={onFillAddUserInfo}
						onFillEditUserInfo={onFillEditUserInfo}
						onChangeEditUserRoleID={onChangeEditUserRoleID}
						onChangeEditUserRoleIDSDoc={onChangeEditUserRoleIDSDoc}
						onChangeEditUserRoleIDCentralAdmin={
							onChangeEditUserRoleIDCentralAdmin
						}
						onChangeEditUserStatus={onChangeEditUserStatus}
						//new
						onChangeEditUserprefixname={onChangeEditUserprefixname}
						// Main Handler
						handleButton={userModalType === 'add' ? onAddAdmin : onEditUser}
						// Save Button Logic
						saveButtonAdd={saveButtonAdd}
						saveButtonEdit={saveButtonEdit}
						// Admin Roles
						adminRoles={adminRoles}
						// Search Email
						onSearchEmail={onSearchEmail}
						emailValidation={emailValidation}
						// Validation
						showPositionAndSignatureAdd={showPositionAndSignatureAdd}
						showPositionAndSignatureEdit={showPositionAndSignatureEdit}
						isSearchingEmail={isSearchingEmail}
						// Reset Admin Role
						resetAdminRole={resetAdminRole}
						// Uploading Signature Handler
						onUploadSignature={onUploadSignature}
						// Clear All User Modal State Handler
						clearAllUserModalState={clearAllUserModalState}
					/>
					<DeleteUserModal
						open={openDeleteUserModal}
						onClose={onOpenDeleteUserModal}
						onDelete={deleteUserID}
					/>
					<NotFoundModal
						open={openNotFoundModal}
						handleButton={onOpenNotFoundModal}
					/>
					<AddingSuccessModal
						open={openAddingSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenAddingSuccessModal()
							onOpenUserModal()
						}}
					/>
					<AddingFailedModal
						open={openAddingFailedModal}
						handleButton={onOpenAddingFailedModal}
						message="เพิ่มผู้ใช้งานไม่สำเร็จ โปรดลองอีกครั้ง"
					/>
					<AddingFailedModal
						open={openUserNotFoundModal}
						handleButton={onOpenUserNotFoundModal}
						message="ไม่พบรายชื่อที่ค้นหา โปรดลองอีกครั้ง"
					/>
					<EditingSuccessModal
						open={openEditingSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenEditingSuccessModal()
							onOpenUserModal()
						}}
					/>
					<EditingFailedModal
						open={openEditingFailedModal}
						handleButton={onOpenEditingFailedModal}
						message="แก้ไขข้อมูลผู้ใช้ไม่สำเร็จ โปรดลองอีกครั้ง"
					/>
					<DeletedSuccessModal
						open={openDeletedSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenDeletedSuccessModal()
							// onOpenUserModal()
						}}
					/>
					<DeletedFailedModal
						open={openDeletedFailedModal}
						handleButton={onOpenDeletedFailedModal}
						message="ลบข้อมูลผู้ใช้ไม่สำเร็จ โปรดลองอีกครั้ง"
					/>
				</Grid>
			</>
		)
	)
}
