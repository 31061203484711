import React, { useState, useEffect } from 'react'

import { Grid, Card, Button, useMediaQuery, TextField } from '@material-ui/core'
import { ListAlt, Menu, SendOutlined, SearchOutlined } from '@material-ui/icons'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'

import { ChangeStatusModal } from '../../../components/admin/standard-report/change-status-modal'

import SendingStandardReportToAdminModalSuccess from '../../../components/admin/standard-report/send-to-admin-modal'
import SendingStandardReportToAdminModalFailed from '../../../components/admin/standard-report/send-to-admin-modal-failed'
import SureModal from '../../../components/SureModal'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useStoreRehydrated } from '../../../store'
import { format } from 'date-fns'

import { datasetRecord } from '../../../api/'

const useStyles = makeStyles(theme => {
	return {
		card_styles: {
			height: '80vh',
		},
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		type_of_report: {
			backgroundColor: '#c8e8ff',
			color: '#028BFF',
			borderColor: '#84BFF0',
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
	}
})

export default function StandardReport() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isMobile2 = useMediaQuery('(min-width:425px)')
	const dataTypeOfReport = { mobile: 'เคลื่อนที่', fixed: 'ประจำที่' }

	// Work Status 0 = รอตรวจสอบ, 1 = ตรวจสอบแล้ว, 2 = ไม่อนุมัติ
	// eslint-disable-next-line
	const status = ['รอตรวจสอบ', 'ตรวจสอบแล้ว', 'ไม่อนุมัติ']
	const [statusIDList, setStatusIDList] = useState([])
	const [tempID, setTempID] = useState(0)
	const [tempName, setTempName] = useState('')
	const [statusDialogOpen, setStatusDialogOpen] = useState(false)
	const [statusLabelTemp, setStatusLabelTemp] = useState('')
	const [reportID, setReportID] = useState(null)
	const [pageSize, setPageSize] = useState(8)

	// Modal ​State
	const [openSendToAdminModal, setOpenSendToAdminModal] = useState(false)
	const [openSendToAdminModalFailed, setOpenSendToAdminModalFailed] =
		useState(false)
	const [
		openSubmitSendingToAdminSureModal,
		setOpenSubmitSendingToAdminSureModal,
	] = useState(false)

	// eslint-disable-next-line
	const handleOpenDialog = id => {
		setStatusDialogOpen(true)
		setTempID(id)
		for (let i = 0; i < rows.length; i++) {
			if (rows[i].id === id) {
				setTempName(rows[i].name)
				break
			}
		}
		if (statusIDList[id - 1] === 0) {
			setStatusLabelTemp('รอตรวจสอบ')
		} else if (statusIDList[id - 1] === 1) {
			setStatusLabelTemp('ตรวจสอบแล้ว')
		} else if (statusIDList[id - 1] === 2) {
			setStatusLabelTemp('ไม่อนุมัติ')
		}
	}

	const handleCloseDialog = () => {
		setStatusDialogOpen(false)
	}

	const [columnsId, setColumnsId] = useState([130, 0])
	const [columnsName, setColumnsName] = useState([360, 0])
	const [columnsDataTypeList, setColumnsDataTypeList] = useState([200, 0])
	const [columnsSubmitDate, setColumnsSubmitDate] = useState([200, 0])
	const [columnsReportDetails, setColumnsReportDetails] = useState([200, 0])
	const [columnsWorkStatusId, setColumnsWorkStatusId] = useState([200, 0])

	var columns = [
		{
			field: 'id',
			headerName: 'ลำดับ',
			width: columnsId[0],
			flex: columnsId[1],
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				return <Grid className="pl-4">{params.value}</Grid>
			},
		},
		{
			field: 'name',
			headerName: 'ชื่อ-นามสกุล',
			width: columnsName[0],
			flex: columnsName[1],
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'organization',
			headerName: 'ชื่อผู้ประกอบการ',
			width: columnsName[0],
			flex: columnsName[1],
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'dataTypeList',
			headerName: 'ประเภทรายงาน',
			sortable: true,
			width: columnsDataTypeList[0],
			flex: columnsDataTypeList[1],
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-center items-center">
						{params.value.map((e, index) => (
							<Grid
								key={e + index}
								className={`rounded-full py-1 px-4 m-0.5 font-bold text-xs border ${classes.type_of_report}`}>
								{dataTypeOfReport[e]}
							</Grid>
						))}
					</Grid>
				)
			},
		},
		{
			field: 'yearNQuarter',
			headerName: 'ปีและไตรมาส',
			width: columnsSubmitDate[0],
			flex: columnsSubmitDate[1],
			align: 'left',
			editable: false,
		},
		{
			field: 'submit_date',
			headerName: 'วันที่ส่งข้อมูล',
			width: columnsSubmitDate[0],
			flex: columnsSubmitDate[1],
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return <Grid className="">{params.value}</Grid>
			},
		},
		{
			field: 'submitType',
			headerName: 'วิธีการส่งรายงาน',
			width: columnsSubmitDate[0],
			flex: columnsSubmitDate[1],
			align: 'left',
			editable: false,
		},
		{
			field: 'report_detials',
			headerName: 'ดูข้อมูลรายงาน',
			sortable: false,
			width: columnsReportDetails[0],
			flex: columnsReportDetails[1],
			headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return (
					<Grid className="mx-auto">
						<Button
							onClick={() => {
								let tempIndex = 0
								for (tempIndex; tempIndex < rows.length; tempIndex++) {
									if (params.value === rows[tempIndex].id) {
										break
									}
								}
								history.push({
									pathname: `/admin/standard-report/${params.value}`,
									state: {
										rowData: rows[tempIndex],
										statusIDList: statusIDList,
									},
								})
							}}
							className={`w-28 `}
							color="secondary"
							variant="contained">
							<Menu style={{ fontSize: 20 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>
					</Grid>
				)
			},
		},
		{
			field: 'workStatus',
			headerName: 'สถานะ',
			sortable: false,
			width: columnsWorkStatusId[0],
			flex: columnsWorkStatusId[1],
			headerAlign: 'center',
			align: 'center',
			renderCell: params => {
				return (
					<>
						<Grid className="">
							<Button
								// onClick={() => {
								// 	handleOpenDialog(params.value.workStatus)
								// }}
								className={`w-36 ${
									params.value.workStatus === 4
										? classes.wait_button
										: params.value.workStatus === 5
										? classes.approve_button
										: classes.reject_button
								}`}>
								<Grid className={`text-xs `}>
									{params.value.workStatusName}
								</Grid>
							</Button>
						</Grid>
					</>
				)
			},
		},
		// {
		// 	field: 'actions',
		// 	headerName: ' ',
		// 	width: columnsWorkStatusId[0],
		// 	flex: columnsWorkStatusId[1],
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	renderCell: params => (
		// 		<Grid className="flex flex-row justify-end items-center w-full md:pr-5 md:py-2 p-2">
		// 			<Button
		// 				variant="contained"
		// 				size="small"
		// 				color="primary"
		// 				onClick={() => {
		// 					setReportID(params.value)
		// 					onOpenSendingToAdminSureModal()
		// 				}}>
		// 				ส่งรายงาน
		// 			</Button>
		// 		</Grid>
		// 	),
		// },
	]

	const [rows, setRows] = useState([
		// Work Status 0 = รอตรวจสอบ, 1 = ตรวจสอบแล้ว, 2 = ไม่อนุมัติ
		// Data Type List 0 = ประจำที่, 1 = เคลื่อนที่
	])
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [rowsData, setRowsData] = useState([])

	function handleStatusTempChange() {
		var statusIDListTemp = statusIDList
		statusIDListTemp[tempID - 1] = statusLabelTemp
		if (statusLabelTemp === 'รอตรวจสอบ') {
			statusIDListTemp[tempID - 1] = 0
		} else if (statusLabelTemp === 'ตรวจสอบแล้ว') {
			statusIDListTemp[tempID - 1] = 1
		} else if (statusLabelTemp === 'ไม่อนุมัติ') {
			statusIDListTemp[tempID - 1] = 2
		}
		setStatusIDList(statusIDListTemp)
		handleCloseDialog()
	}

	const onChangeStatusTemp = e => {
		setStatusLabelTemp(e.target.value)
	}

	//  -------------------------------
	const getDatasetRecordList = async () => {
		setIsDataLoading(true)
		const res = await datasetRecord.getListAdmin()

		if (res) {
			if (res.status === 200) {
				setRowsData(res.data)

				const rowsTemp = []

				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].dataSetRecordHeadStatus.id !== 5) {
						const qosType = []

						if (res.data[i].isFixed === 'y') qosType.push('fixed')
						if (res.data[i].isMobile === 'y') qosType.push('mobile')

						const tempObject = {
							id: res.data[i].id,
							name:
								(res.data[i].operatorApproveUser?.firstname ?? '') +
								' ' +
								(res.data[i].operatorApproveUser?.lastname ?? ''),
							organization: res.data[i].dataSetReport.operator.operatorName,
							submit_date: res.data[i].updatedAt
								? format(new Date(res.data[i].updatedAt), 'dd/MM/yyyy')
								: '',
							submitType: res.data[i].sendMethod,
							workStatus: {
								report_id: res.data[i].id,
								workStatus: res.data[i].dataSetRecordHeadStatus.id,
								workStatusName: res.data[i].dataSetRecordHeadStatus.statusName,
							},
							report_detials: res.data[i].id,
							dataTypeList: qosType,
							actions: res.data[i].id,
							yearNQuarter: res.data[i].dataSetReport.year + '/' + res.data[i].dataSetReport.quarter,
						}

						let tempStatusIDList = statusIDList
						tempStatusIDList[i] = {
							report_id: res.data[i].id,
							workStatus: res.data[i].dataSetRecordHeadStatus.id,
							workStatusName: res.data[i].dataSetRecordHeadStatus.statusName,
						}
						setStatusIDList(tempStatusIDList)
						setReportID(res.data[i].id)
						rowsTemp.push(tempObject)
					}
				}
				setRows(rowsTemp)
			}
		}
		setIsDataLoading(false)
	}

	const onSearhResult = e => {
		const { value } = e.target
		const searchedName = rowsData.filter(row => {
			const name =
				row.operatorApproveUser?.firstname +
				' ' +
				row.operatorApproveUser?.lastname
			return name.includes(value)
		})
		const searchedOperatorName = rowsData.filter(row =>
			row.dataSetReport?.operator?.operatorName.includes(value)
		)

		const mergedSearched = [...searchedName, ...searchedOperatorName]
		const searchedResults = []

		mergedSearched.forEach(i => {
			const find = searchedResults.find(item => item.id === i.id)
			if (!find && i.dataSetRecordHeadStatus.id !== 5) {
				const qosType = []

				if (i.operatorUser.operatorUserQOSTypes)
					i.operatorUser.operatorUserQOSTypes.forEach(item => {
						if (item.qosTypeID === 1) qosType.push('mobile')
						if (item.qosTypeID === 2) qosType.push('fixed')
					})

				searchedResults.push({
					id: i.id,
					name:
						i.operatorApproveUser?.firstname +
						' ' +
						i.operatorApproveUser?.lastname,
					organization: i.dataSetReport?.operator?.operatorName,
					submit_date: i.updatedAt
						? format(new Date(i.updatedAt), 'dd/MM/yyyy')
						: '',
					submitType: i.sendMethod,
					workStatus: {
						report_id: i.id,
						workStatus: i.dataSetRecordHeadStatus.id,
						workStatusName: i.dataSetRecordHeadStatus.statusName,
					},
					report_detials: i.id,
					dataTypeList: qosType,
					actions: i.id,
					yearNQuarter: i.dataSetReport.year + '/' + i.dataSetReport.quarter,
				})
			}
		})

		setRows(searchedResults)
	}

	const onSubmitToAdmin = async () => {
		const res = await datasetRecord.sendToAdmin(reportID)
		if (res.status !== 400) {
			onOpenSendingToAdminModal()
			onOpenSendingToAdminSureModal()
		} else {
			onOpenSendingToAdminSureModal()
			onOpenSendingToAdminFailedModal()
		}
	}

	const onOpenSendingToAdminSureModal = () => {
		setOpenSubmitSendingToAdminSureModal(!openSubmitSendingToAdminSureModal)
	}

	const onOpenSendingToAdminModal = () => {
		setOpenSendToAdminModal(!openSendToAdminModal)
	}

	const onOpenSendingToAdminFailedModal = () => {
		setOpenSendToAdminModalFailed(!openSendToAdminModalFailed)
	}

	useEffect(() => {
		if (isMobile) {
			setColumnsId([130, 0])
			setColumnsName([100, 0])
			setColumnsDataTypeList([200, 0])
			setColumnsSubmitDate([200, 0])
			setColumnsReportDetails([200, 0])
			setColumnsWorkStatusId([200, 0])
		} else {
			setColumnsId([0, 0.7])
			setColumnsName([0, 1.2])
			setColumnsDataTypeList([0, 1.2])
			setColumnsSubmitDate([0, 1])
			setColumnsReportDetails([0, 0.9])
			setColumnsWorkStatusId([0, 0.8])
		}
	}, [isMobile])

	useEffect(() => {
		if (isRehydrated) getDatasetRecordList()
		// eslint-disable-next-line
	}, [isRehydrated])

	return (
		<>
			<Grid className="lg:p-10">
				<Card
					className={`${classes.card_styles} lg:border-t-4 border-card_top_border`}>
					<Grid className="flex flex-col justify-center items-start overflow-auto">
						<Grid className="flex flex-row items-center justify-between mt-4 mb-3 px-5 w-full">
							<Grid className="flex flex-row items-center">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									รายชื่อรายงาน
								</Grid>
							</Grid>
							<TextField
								className="w-full lg:w-4/12"
								size="small"
								placeholder="ค้นหาโดย ชื่อ-นามสกุล, ชื่อผู้ประกอบการ"
								onChange={onSearhResult}
								label=""
								variant="outlined"
								InputProps={{
									startAdornment: (
										<SearchOutlined style={{ color: 'rgba(0,0,0,0.54)' }} />
									),
								}}
							/>
						</Grid>
						<Grid
							className="md:px-5"
							style={{ height: isMobile2 ? "65vh"  : 350, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								disableSelectionOnClick
								rowsPerPageOptions={[5, 8, 10, 20]}
								pageSize={pageSize}
								onPageSizeChange={size => setPageSize(size)}
								sortModel={[
									{
										field: 'id',
										sort: 'desc',
									},
								]}
								components={{
									NoRowsOverlay: () => {
										if (!isDataLoading) {
											return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
										}else{
											return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Card>
			</Grid>

			<ChangeStatusModal
				handleCloseDialog={handleCloseDialog}
				statusDialogOpen={statusDialogOpen}
				name={tempName}
				statusLabelTemp={statusLabelTemp}
				onChangeStatusTemp={onChangeStatusTemp}
				handleStatusTempChange={handleStatusTempChange}
			/>
			<SendingStandardReportToAdminModalSuccess
				open={openSendToAdminModal}
				handleButton={() => history.go(0)}
			/>
			<SendingStandardReportToAdminModalFailed
				open={openSendToAdminModalFailed}
				handleButton={onOpenSendingToAdminFailedModal}
			/>
			<SureModal
				title="ส่งรายงานมาตรฐานคุณภาพไปยังสำนักงานกสทช."
				content="คุณแน่ใจที่จะส่งรายงานมาตรฐานคุณภาพไปยัง สำนักงานกสทช. หรือไม่"
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				open={openSubmitSendingToAdminSureModal}
				onClose={onOpenSendingToAdminSureModal}
				handleCancel={onOpenSendingToAdminSureModal}
				handleButton={onSubmitToAdmin}
			/>
		</>
	)
}
