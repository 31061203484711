import React, { useMemo } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState, useStoreRehydrated } from '../../../store'

export default function ProtectedRoute({ children, ...rest }) {
	const { adminMESDOC, adminSDOC } = useStoreState(s => s.sdocAdminModel)
	const isRehydrated = useStoreRehydrated()

	const sdocAdminRoleId = useMemo(() => adminMESDOC?.roleID, [adminMESDOC])

	return (
		isRehydrated && (
			<Route
				{...rest}
				render={({ location }) => {
				

					return adminSDOC ? (
						children
					) : (
						<Redirect
							to={{
								pathname: '/admin/sdoc/sign-in',
								state: { from: location },
							}}
						/>
					)
				}}
			/>
		)
	)
}
