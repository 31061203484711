import { forwardRef, useRef } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	IconButton,
	TextField,
	Button,
	Divider,
	useMediaQuery,
} from '@material-ui/core'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'
import { Close, ReportProblem } from '@material-ui/icons'

import File from './file'

import { useTheme } from '@material-ui/core/styles'

export default function FillingUnqualifiedModal({
	currentSelectedItem,
	id,
	question_number,
	question_label,
	currentAnswer,
	open,
	errorMessage,
	onClose,
	handleButton,
	onInputValidationReason,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	return (
		<Dialog
			className="w-full px-3"
			open={open}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<ReportProblem
							htmlColor="#EC8424"
							style={{ fontSize: 35 }}
							className="mr-1 h-full"
						/>
						<Grid className="font-bold text-lg">สาเหตุของการไม่ผ่านเกณฑ์</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#000' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full w-10/12 mx-auto"
					tabIndex={-1}
					ref={descriptionElementRef}>
					<Grid className="flex flex-row gap-x-3 mb-4 text-black font-bold">
						<Grid>{question_number}</Grid>
						<Grid>{question_label}</Grid>
					</Grid>
					<Grid className="flex flex-row gap-x-3 mb-4 text-red-500">
						สาเหตุของการไม่ผ่านเกณฑ์​: {errorMessage}
					</Grid>
					{currentSelectedItem.inputMethod === 'message' && (
						<Grid className="grid grid-cols-12 gap-x-3 gap-y-10 w-full">
							{/* Reason */}
							<Grid className="col-span-2  text-black">เหตุผล*</Grid>
							<Grid className="col-span-10">
								<TextField
									fullWidth
									maxRows={3}
									minRows={3}
									multiline
									size="small"
									variant="outlined"
									placeholder="ระบุเหตุผลที่ไม่ผ่านเกณฑ์"
									style={{ backgroundColor: '#F8FAFC' }}
									name="reason"
									value={currentAnswer.reason}
									onChange={e => onInputValidationReason(e, id, false)}
								/>
							</Grid>
							{/* How to solve */}
							<Grid className="col-span-2  text-black">วิธีการแก้ไข*</Grid>
							<Grid className="col-span-10">
								<TextField
									fullWidth
									maxRows={3}
									minRows={3}
									multiline
									size="small"
									variant="outlined"
									placeholder="ระบุวิธีการแก้ไข"
									style={{ backgroundColor: '#F8FAFC' }}
									name="solution"
									value={currentAnswer.solution}
									onChange={e => onInputValidationReason(e, id, false)}
								/>
							</Grid>
							{/* Duration */}
							<Grid className="col-span-2 text-black">ระยะเวลาการแก้ไข*</Grid>
							<Grid className="col-span-5">
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										variant="dialog"
										color="primary"
										margin="normal"
										views={['date', 'month', 'year']}
										format="dd/MM/yyyy"
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										name="periodSolution"
										value={currentAnswer.periodSolution}
										onChange={e => {
											onInputValidationReason(e, id, true)
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						</Grid>
					)}
					<Grid className="my-5">
						<Divider />
					</Grid>
					{currentSelectedItem.inputMethod === 'attach' && (
						<Grid className="flex flex-col gap-y-10">
							<Grid className="font-bold text-black">แนบไฟล์เพิ่มเติม</Grid>
							<Grid className="flex flex-row gap-x-5 w-full">
								<Button variant="contained" component="label" size="small">
									เลือกไฟล์
									<input
										id="invalidAttachFile"
										type="file"
										hidden
										accept=".xlsx, .xls, .docx, .doc, .pdf, .jpeg, .png, .jpg"
										onChange={e => onInputValidationReason(e, id, false)}
										name="file"
									/>
								</Button>
								<File originalFileName={currentAnswer.file?.name} />
							</Grid>
						</Grid>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-2/12" component="div">
						<Button
							fullWidth
							size="large"
							onClick={handleButton}
							color="secondary"
							variant="contained"
							disabled={
								currentSelectedItem.inputMethod === 'message'
									? currentAnswer.periodSolution === null ||
									currentAnswer.reason === '' ||
									currentAnswer.solution === ''
									: currentAnswer.file === null
							}
						>
							ตกลง
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
