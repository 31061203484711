import { forwardRef } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Slide,
	IconButton,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	useMediaQuery,
} from '@material-ui/core'
import { Close, ReportProblem } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export default function AskingValidationModal({
	open,
	onClose,
	handleButton,
	invalidInputMethod,
	onChangeInvalidaInputMethod,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Dialog
			className="w-full px-3"
			open={open}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<ReportProblem
							htmlColor="#EC8424"
							style={{ fontSize: 35 }}
							className="mr-1 h-full"
						/>
						<Grid className="font-bold text-lg">
							เลือกวิธีการแจ้งเหตุผลที่ไม่ผ่านเกณฑ์
						</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#000' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<Grid className="w-full">
					<RadioGroup
						aria-label="select-status"
						defaultValue={'message'}
						value={invalidInputMethod}
						onChange={e => onChangeInvalidaInputMethod(e.target.value)}
						name="radio-buttons-group">
						<FormControlLabel
							value="message"
							control={<Radio />}
							label="กรอกเป็นข้อความ"
							className="bg-gray-100 w-full py-2 my-1"
						/>
						<FormControlLabel
							value="attach"
							control={<Radio />}
							label="แนบไฟล์แสดงเหตุผล"
							className="bg-gray-100 w-full py-2 my-1"
						/>
					</RadioGroup>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-3/12" component="div">
						<Button
							fullWidth
							onClick={handleButton}
							color="secondary"
							variant="contained">
							เลือก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
