import API from '../config/axios'

const userAPI = {
	getUsers: () => {
		return API.get('/OperatorUsers')
			.then(res => res)
			.catch(err => {
				return err
			})
	},
	addUser: (
		payload
	) => {
		return API.post('/OperatorUsers',payload)
			.then(res => res)
	},
	editUser: (
		userID,
		firstname,
		lastname,
		mobile,
		nationalIDCardNo,
		email,
		activeDate,
		expireDate,
		operatorUserQOSTypes,
		userStatusId,
		userRoleID
	) => {
		return API.put(`/OperatorUsers/${userID}`, {
			firstname,
			lastname,
			mobile,
			nationalIDCardNo,
			email,
			activeDate,
			expireDate,
			operatorUserQOSTypes,
			statusID:userStatusId,
			userRoleID
		})
			.then(res => res)
			.catch(err => console.log('error'))
	},
	getMyProfile: () => {
		return API.get('/OperatorUsers/me')
			.then(res => res)
			.catch(err => err)
	},
	getMyPOA: (token) => {
		return API.post('/OperatorUsers/listPOA',{"_token":token})
			.then(res => res.data)
			.catch(err => {console.error(err)})
	},
	getMyPOASDoC: (token) => {
		return API.post('/OperatorUsers/listPOASDoC',{"_token":token})
			.then(res => res.data)
			.catch(err => {console.error(err)})
	},
	deleteUser: userID => {
        return API.delete(`/OperatorUsers/access/${userID}`)
            .then(res => res)
            .catch(err => err)
    },
}

export default userAPI
