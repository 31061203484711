import { forwardRef, useRef, useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Grid,
	Typography,
	IconButton,
	Slide,
	useMediaQuery,
	Button,
} from '@material-ui/core'
import { Delete, Close } from '@material-ui/icons'

import { useTheme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	delete_button: {
		backgroundColor: 'red',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#b43e2f',
		},
	},
}))

export function DeleteUserModal({ open, onClose, onDelete }) {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [open])

	return (
		<Dialog
			className={`w-full ${classes.dialogPaper}`}
			open={open}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}>
			<DialogTitle style={{ backgroundColor: 'red' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<Delete htmlColor="#fff" />
						<Typography variant="h5" className="text-white">
							ลบผู้ใช้งาน
						</Typography>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="overflow-scroll h-full text-center p-5"
					tabIndex={-1}
					ref={descriptionElementRef}>
					<Typography variant="h6">
						คุณแน่ใจที่จะลบผู้ใช้งานคนนี้ใช่หรือไม่?
					</Typography>
					<Grid className="flex flex-row justify-between mt-10">
						<Button variant="contained" onClick={onClose}>
							ยกเลิก
						</Button>
						<Button
							variant="contained"
							className={classes.delete_button}
							onClick={onDelete}>
							ลบผู้ใช้งาน
						</Button>
					</Grid>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
