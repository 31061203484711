import React, { useState, useEffect } from 'react'

import { Grid, Card, Button, useMediaQuery } from '@material-ui/core'
import { ListAlt, SendOutlined } from '@material-ui/icons'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'

import UpdateProcessModalSuccess from '../../../components/admin/standard-report-organization/update-process-modal-success'
import UpdateProcessModalFailed from '../../../components/admin/standard-report-organization/update-process-modal-failed'
import ConfirmSendNotifyModal from './components/confirmSendNotifyModal'

// import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useStoreState, useStoreRehydrated } from '../../../store'

import { dataSetReports, adminQoS } from 'api/index'
import { set } from 'date-fns'

const useStyles = makeStyles(theme => {
	return {
		card_styles: {
			height: '80vh',
		},
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		type_of_report: {
			backgroundColor: '#c8e8ff',
			color: '#028BFF',
			borderColor: '#84BFF0',
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
	}
})

// Page Description: Lists of Organization who don't send their report either of in time and on time
export default function StandardReportOrganization() {
	// const history = useHistory()
	const [pageSize, setPageSize] = useState(8)
	const isRehydrated = useStoreRehydrated()
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isMobile2 = useMediaQuery('(min-width:425px)')
	const [rows, setRows] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	const admin = useStoreState(s => s.QoSAdminModel.admin)
	const [signature, setSignature] = useState([]);
	const [selected, setSelected] = useState('');
	// Current dataSetReportID
	const [currentDataSetReportID, setCurrentDataSetReportID] = useState(null)
	// Sending Modal States
	const [sureModalText, setSureModalText] = useState({
		title: '',
		content: '',
	})
	const [openSureModal, setOpenSureModal] = useState(false)
	const [openUpdateProcessModalSuccess, setOpenUpdateProcessModalSuccess] =
		useState(false)
	const [openUpdateProcessModalFailed, setOpenUpdateProcessModalFailed] =
		useState(false)
	const [sendingSuccessLabel, setSendingSuccessLabel] = useState('')
	const [sendingFailedLabel, setSendingFailedLabel] = useState('')
	const [isSetToProcess, setIsSetToProcess] = useState(true)
	// Table Width
	const [columnsId, setColumnsId] = useState([80, 0])
	const [columnsName, setColumnsName] = useState([360, 0])
	const [columnsWorkStatusId, setColumnsWorkStatusId] = useState([200, 0])

	/** <-----------------------------------------------------------------------> */

	// Setting Process Function Handler

	// roleID: 1 and roleID: 2
	const onUpdateSetToProcess = async () => {
		const res = await dataSetReports.updateAdminProcess(
			currentDataSetReportID,
			{
				isSetToProcess: 'y',
			}
		)

		if (res && res.status === 200) {
			setSendingSuccessLabel('ส่งให้ผู้ออกหนังสือเร่งรัดสำเร็จ')
			onOpenUpdateProcessModalSuccess()
		} else if (res && res.response.status === 400) {
			setSendingFailedLabel(
				'ส่งให้ผู้ออกหนังสือเร่งรัดไม่สำเร็จ โปรดลองอีกครั้ง'
			)
			onOpenUpdateProcessModalFailed()
		}
	}

	// roleID: 1 and roleID: 7
	const onUpdateSentNotify = async () => {

		const res = await dataSetReports.updateAdminProcess(
			currentDataSetReportID,
			{
				isSentNotify: 'y',
				signatureID: selected
			}
		)

		if (res && res.status === 200) {
			setSendingSuccessLabel('ออกหนังสือเร่งรัดสำเร็จ')
			onOpenUpdateProcessModalSuccess()
		} else {
			setSendingFailedLabel('ออกหนังสือเร่งรัดไม่สำเร็จ โปรดลองอีกครั้ง')
			onOpenUpdateProcessModalFailed()
		}
	}

	const onAskUpdateProcessSetToProcess = () => {
		setIsSetToProcess(true)
		setSureModalText({
			title: 'ส่งให้ผู้ออกหนังสือเร่งรัด',
			content: 'คุณแน่ใจที่จะส่งให้ผู้ออกหนังสือเร่งรัดหรือไม่?',
		})
		setOpenSureModal(true)
	}

	const onAskUpdateProcessSentNotify = () => {
		setIsSetToProcess(false)
		setSureModalText({
			title: 'ออกหนังสือเร่งรัด',
			content: 'คุณแน่ใจที่จะออกหนังสือเร่งรัดหรือไม่?',
		})
		setOpenSureModal(true)
	}

	const onOpenUpdateProcessModalSuccess = () =>
		setOpenUpdateProcessModalSuccess(!openUpdateProcessModalSuccess)

	const onOpenUpdateProcessModalFailed = () =>
		setOpenUpdateProcessModalFailed(!openUpdateProcessModalFailed)

	const selectSignature = ID => {
		setSelected(ID)
	}

	/** <-----------------------------------------------------------------------> */

	// Main Rendering

	var columns = [
		{
			field: 'id',
			headerName: 'ลำดับ',
			width: columnsId[0],
			flex: columnsId[1],
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				return <Grid className="pl-4">{params.value}</Grid>
			},
		},
		{
			field: 'operatorName',
			headerName: 'ผู้ประกอบการ',
			width: columnsName[0],
			flex: columnsName[1],
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'yearNQuarter',
			headerName: 'ปีและไตรมาส',
			width: columnsWorkStatusId[0],
			flex: columnsWorkStatusId[1],
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'actions',
			headerName: ' ',
			width: columnsWorkStatusId[0],
			flex: columnsWorkStatusId[1],
			headerAlign: 'center',
			align: 'center',
			renderCell: params => {
				const roleID = admin?.roleID
				const isSetToProcess = params.row.isSetToProcess
				const isSentNotify = params.row.isSentNotify
				const dataSetReportID = params.row.dataSetReportID

				return (
					<Grid className="flex flex-row justify-center gap-x-2 items-center w-full md:pr-5 md:py-2 p-2">
						{(roleID === 1 || roleID === 2) && (isSetToProcess === 'n' || isSetToProcess === null) && (
							<Button
								variant="contained"
								size="small"
								color="primary"
								onClick={() => {
									setCurrentDataSetReportID(dataSetReportID)
									onAskUpdateProcessSetToProcess()
								}}>
								เตรียมส่งข้อมูลให้ผู้ออกหนังสือเร่งรัด
							</Button>
						)}
						{/* case: sended to roleID: 7 (ผู้ออกหนังสือเร่งรัด) and admin is roleID: 2 (ผู้เตรียมข้อมูล) */}
						{(roleID === 1 || roleID === 2) && isSetToProcess === 'y' && (
							<Button
								variant="contained"
								size="small"
								className={`${classes.approve_button} pointer-events-none`}>
								เตรียมข้อมูลแล้ว
							</Button>
						)}
						{(roleID === 1 || roleID === 7) &&
							isSetToProcess === 'y' &&
							isSentNotify !== 'y' && (
								<Button
									variant="contained"
									size="small"
									color="primary"
									onClick={() => {
										setCurrentDataSetReportID(dataSetReportID)
										onAskUpdateProcessSentNotify()
									}}>
									ออกหนังสือเร่งรัด
								</Button>
							)}
					</Grid>
				)
			},
		},
	]

	const onLanding = async () => {
		setIsDataLoading(true)
		const res = await dataSetReports.getUnReportDatasetReportsOperator()
		if (res && res.status === 200) {
			const roleID = admin?.roleID
			const response = res.data
			const temp = []

			for (let i = 0; i < response.length; i++) {
				// ผู้ออกหนังสือเร่งรัด
				if (roleID === 7 && response[i].isSetToProcess === 'y') {
					temp.push({
						id: i + 1,
						unReportedID: response[i].id,
						operatorName: response[i].operatorName,
						dataSetReportID: response[i].dataSetReportID,
						isSentNotify: response[i].isSentNotify,
						isSetToProcess: response[i].isSetToProcess,
						yearNQuarter: response[i].year + '/' + response[i].quarter,
					})
				}
				// superadmin and ผู้เตรียมข้อมูล
				else {
					temp.push({
						id: i + 1,
						unReportedID: response[i].id,
						operatorName: response[i].operatorName,
						dataSetReportID: response[i].dataSetReportID,
						isSentNotify: response[i].isSentNotify,
						isSetToProcess: response[i].isSetToProcess,
						yearNQuarter: response[i].year + '/' + response[i].quarter,
					})
				}
			}

			setRows(temp)
		}
		setIsDataLoading(false)
	}

	/** <-----------------------------------------------------------------------> */

	useEffect(() => {
		if (isMobile) {
			setColumnsId([80, 0])
			setColumnsName([360, 0])
			setColumnsWorkStatusId([200, 0])
		} else {
			setColumnsId([0, 0.2])
			setColumnsName([0, 1.2])
			setColumnsWorkStatusId([0, 0.8])
		}
	}, [isMobile])

	useEffect(() => {
		if (isRehydrated) {
			onLanding();
			adminQoS.getSignature().then(e => {
				setSignature(e);
			})
		}
		// eslint-disable-next-line
	}, [isRehydrated])



	return (
		<>
			<Grid className="lg:p-10">
				<Card
					className={`${classes.card_styles} lg:border-t-4 border-card_top_border`}>
					<Grid className="flex flex-col justify-center items-start overflow-auto">
						<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
							<Grid>
								<ListAlt style={{ fontSize: 22 }} className="mr-1" />
							</Grid>
							<Grid className="font-bold text-sm text-card_header_color">
								รายชื่อผู้ประกอบการที่ยังไม่ส่งรายงานมาตรฐาน
							</Grid>
						</Grid>
						<Grid
							className="md:px-5"
							style={{ height: isMobile2 ? "65vh" : 550, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								pageSize={pageSize}
								rowsPerPageOptions={[5, 8, 10, 20]}
								onPageSizeChange={size => setPageSize(size)}
								disableSelectionOnClick
								components={{
									NoRowsOverlay: () => {
										if (!isDataLoading) {
											return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
										}else{
											return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<UpdateProcessModalSuccess
				open={openUpdateProcessModalSuccess}
				label={sendingSuccessLabel}
				handleButton={() => {
					onLanding()
					onOpenUpdateProcessModalSuccess()
					setOpenSureModal(false)
				}}
			/>
			<UpdateProcessModalFailed
				open={openUpdateProcessModalFailed}
				message={sendingFailedLabel}
				handleButton={() => {
					onLanding()
					onOpenUpdateProcessModalFailed()
					setOpenSureModal(false)
				}}
			/>
			<ConfirmSendNotifyModal
				title={sureModalText.title}
				content={sureModalText.content}
				listSignature={signature}
				isSetToProcess={isSetToProcess}
				selectSignature={selectSignature}
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				open={openSureModal}
				handleButton={
					isSetToProcess ? onUpdateSetToProcess : onUpdateSentNotify
				}
				handleCancel={() => setOpenSureModal(false)}
			/>
		</>
	)
}
