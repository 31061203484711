import React, { useState } from 'react'
import {
	Grid,
	TextField,
	Card,
	Button,
	Divider,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import IncorrectIdModal from '../../../components/users/registration/modal/incorrect-id'
import RegisterFailModal from '../../../components/users/registration/modal/register-fail'
import RegisterSuccessModal from '../../../components/users/registration/modal/register-success'

import logo from '../../../assets/images/NBTC-logo.png'

import { useHistory } from 'react-router-dom'
import { useStoreActions } from '../../../store'

import { organization as Orgs } from '../../../data/registration/organization'
import { users as Users } from '../../../data/registration/users'

export default function Registration() {
	const history = useHistory()
	const registration = useStoreActions(a => a.userModel.registration)

	// ------------------------------------->
	// Step which tell Personal Information Part or Company Part
	const [regisStep, setRegisStep] = useState(0) // 0 = Personal Information Part, 1 =  Company Part
	const increaseRegisStep = () => {
		// Go to next step or Corfirm the Registeration
		if (regisStep === 0) {
			// Go from first to next step
			setRegisStep(1)
		} else {
			// Open Success Modal

			registration({
				citizenID: citizenID,
				phoneNumber: phoneNumber,
				email: email,
				firstname: firstname,
				lastname: lastname,
				companyID: company.id,
			}).then(e => {
				if (e) onOpenStandardReportSuccessModal()
				else onOpenRegisterFailModal()
			})
		}
	}
	const decreaseRegisStep = () => {
		// Back to previous step or Back to Sign In page
		if (regisStep === 1) {
			//  Go back from last step to first step
			setRegisStep(0)
		} else {
			// Go back to Sign In page
			history.push('/sign-in')
		}
	}
	// <-------------------------------------

	// ------------------------------------->
	// Create State and OnChange of CitizenID and TaxID
	const [citizenID, setCitizenID] = useState('')
	const [taxID, setTaxID] = useState('')
	const onChangeCitizenID = e => {
		setCitizenID(e.target.value)
	}
	const onChangeaxID = e => {
		setTaxID(e.target.value)
	}
	// <-------------------------------------

	// ------------------------------------->

	const cleanDataFromIncorrectId = () => {
		if (regisStep === 0) {
			// If CitizenID on First step is empty, then show Text Alert and Can't go next step
			setStepValidStep1(false)
			// Set default data
			setFirstname('')
			setLastname('')
			setEmail('')
			setPhoneNumber('')
		} else if (regisStep === 1) {
			// If TaxID on Last step is empty, then show Text Alert and Can't confirm registeration
			setStepValidStep2(false)
			// Set default data
			setCompanyName('')
			setCompanyAddress('')
		}
		onOpenInCorrectIdModal()
	}

	const validGoNextStep = () => {
		if (regisStep === 0) {
			// If CitizenID is not empty, then hide Alert and Can go next step
			setStepValidStep1(true)
		} else {
			// If TaxID is not empty, then hide Alert and Can confirm registeration
			setStepValidStep2(true)
		}
	}
	// <-------------------------------------

	// ------------------------------------->
	const [stepValidStep1, setStepValidStep1] = useState(false)
	const [stepValidStep2, setStepValidStep2] = useState(false)
	// <-------------------------------------

	// ------------------------------------->
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [company, setCompany] = useState({})
	const [companyName, setCompanyName] = useState('')
	const [companyAddress, setCompanyAddress] = useState('')
	// <-------------------------------------

	// ------------------------------------->
	// Modal Success Manage
	const [openStandardReportSuccessModal, setOpenStandardReportSuccessModal] =
		useState(false)

	const onOpenStandardReportSuccessModal = () =>
		setOpenStandardReportSuccessModal(!openStandardReportSuccessModal)

	const onAgreeStandardReportSuccessModalCTA = () => {
		onOpenStandardReportSuccessModal()
		// Confirm Registeration
		history.push({
			pathname: '/sign-in',
		})
	}
	// <-------------------------------------

	// Modal Register Fail
	const [openRegisterFailModal, setOpenRegisterFailModal] = useState(false)

	const onOpenRegisterFailModal = () =>
		setOpenRegisterFailModal(!openRegisterFailModal)
	// <-------------------------------------

	// Modal Incorrect ID
	const [openInCorrectIdModal, setOpenInCorrectIdModal] = useState(false)

	const onOpenInCorrectIdModal = () =>
		setOpenInCorrectIdModal(!openInCorrectIdModal)
	// <-------------------------------------

	// ------------------------------------->
	// Searching Users
	const onSearchUser = () => {
		if (regisStep === 0) {
			const user = Users.find(item => item.nationalCardIDNo === citizenID)
			if (user && citizenID !== '') {
				setFirstname(user.firstname)
				setLastname(user.lastname)
				setEmail(user.email)
				setPhoneNumber(user.phoneNumber)
				validGoNextStep()
			} else {
				cleanDataFromIncorrectId()
			}
		} else if (regisStep === 1 && taxID !== '') {
			const org = Orgs.find(item => item.taxno === taxID)
			if (org) {
				setCompany(org)
				setCompanyName(org.title)
				setCompanyAddress(org.addr)
				validGoNextStep()
			} else {
				cleanDataFromIncorrectId()
			}
		} else {
			cleanDataFromIncorrectId()
		}

		// checkSearchError()
	}

	return (
		<>
			<Grid className="h-screen flex flex-col items-center justify-center sign-in-container p-3">
				<Card
					className="flex flex-col items-center py-6 px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5"
					style={{ minHeight: 700 }}>
					<Grid className="">
						<img src={logo} alt="NBTC" width="140" />
					</Grid>
					<Grid className="secondary text-2xl" style={{ color: '#a74345' }}>
						ลงทะเบียนระบบ Data Portal
					</Grid>
					<Grid className="w-full mt-6">
						{regisStep === 0 ? (
							<TextField
								fullWidth
								type="number"
								label="หมายเลขบัตรประจำตัวประชาชน"
								autoFocus={true}
								variant="filled"
								size="small"
								onChange={onChangeCitizenID}
								value={citizenID}
							/>
						) : (
							<TextField
								fullWidth
								type="number"
								label="หมายเลขประจำตัวผู้เสียภาษี"
								autoFocus={true}
								variant="filled"
								size="small"
								onChange={onChangeaxID}
								value={taxID}
							/>
						)}
					</Grid>
					<Grid className="w-full mt-2">
						<Button
							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="medium"
							onClick={onSearchUser}>
							ค้นหา
						</Button>
					</Grid>
					<Grid className="w-full my-4">
						<Divider />
					</Grid>
					<Grid className="w-full h-72">
						{regisStep === 0 ? (
							<>
								<TextField
									fullWidth
									type="text"
									label="ชื่อ*"
									variant="outlined"
									size="small"
									disabled={true}
									value={firstname}
									className="w-full h-14 mt-2"
								/>
								<TextField
									fullWidth
									type="text"
									label="นามสกุล*"
									variant="outlined"
									size="small"
									disabled={true}
									value={lastname}
									className="w-full h-14 mt-2"
								/>
								<TextField
									fullWidth
									type="text"
									label="อีเมล*"
									variant="outlined"
									size="small"
									disabled={true}
									value={email}
									className="w-full h-14 mt-2"
								/>
								<TextField
									fullWidth
									type="text"
									label="เบอร์โทรศัพท์*"
									variant="outlined"
									size="small"
									disabled={true}
									value={phoneNumber}
									className="w-full h-14 mt-2"
								/>
							</>
						) : (
							<>
								<TextField
									fullWidth
									type="text"
									label="ชื่อบริษัท*"
									variant="outlined"
									size="small"
									disabled={true}
									value={companyName}
									className="w-full h-16 mt-2"
								/>
								<TextField
									fullWidth
									type="text"
									label="ที่อยู่บริษัท*"
									variant="outlined"
									size="small"
									disabled={true}
									multiline
									rows={5}
									value={companyAddress}
									className="w-full h-36 mt-2"
								/>

								{stepValidStep2 && (
									<>
										<Grid>ชุดข้อมูล</Grid>
										<Grid className="w-full">
											{company.dataType && company.dataType.includes(2) && (
												<FormControlLabel
													control={<Checkbox checked={true} />}
													label={'ชุดข้อมูลแบบประจำที่'}
												/>
											)}
											{company.dataType && company.dataType.includes(1) && (
												<FormControlLabel
													control={<Checkbox checked={true} />}
													label={'ชุดข้อมูลแบบเคลื่อนที่'}
												/>
											)}
										</Grid>
									</>
								)}
							</>
						)}
					</Grid>

					<Grid className="flex sm:flex-row flex-col items-center justify-between w-full mt-4 gap-y-4">
						<Grid className="sm:w-5/12 w-full">
							<Button
								className="text-white w-full"
								variant="outlined"
								color="secondary"
								size="medium"
								onClick={decreaseRegisStep}>
								ย้อนกลับ
							</Button>
						</Grid>
						<Grid className="sm:w-5/12 w-full">
							<Button
								className="text-white w-full"
								variant="contained"
								color="secondary"
								size="medium"
								disabled={regisStep === 0 ? !stepValidStep1 : !stepValidStep2}
								onClick={increaseRegisStep}>
								{regisStep === 0 ? 'ถัดไป' : 'ยืนยันการสมัคร'}
							</Button>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<RegisterSuccessModal
				open={openStandardReportSuccessModal}
				handleButton={onAgreeStandardReportSuccessModalCTA}
			/>
			<IncorrectIdModal
				open={openInCorrectIdModal}
				handleButton={onOpenInCorrectIdModal}
				regisStep={regisStep}
			/>
			<RegisterFailModal
				open={openRegisterFailModal}
				handleButton={onOpenRegisterFailModal}
			/>
		</>
	)
}
