import React, { useState } from 'react';
import { Grid, IconButton, Input, Button, TextField } from '@material-ui/core'
import { Delete, Archive, Pageview, LockOpen, Lock } from '@material-ui/icons'

export default function File({
	sDOCID,
	fileID,
	mountType,
	originalFileName,
	fileName,
	// fileDate,
	onDownloadDocument,
	onDelete,
	index = 0,
	isFileLocked,
	onDeleteUploadedDocument,
	onChangeAttachedFileName,
	onChangeIsFileLocked,
	ApprovalControl,
	sDOCStatus,
	isEditing,
}) {
	const [FileName, setFileName] = useState(fileName);
	const handleFileNameChange = (e) => {
		setFileName(e.target.value);
	};
	return (
		<Grid className="flex flex-row gap-x-3 w-full items-center">
			<Grid>{index + 1}.) </Grid>
			{/* <Grid>ชื่อเอกสาร: {fileName} {fileDate}</Grid> */}
			<Grid> {isEditing ?
				<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
					<TextField
						fullWidth
						variant="outlined"
						size="small"
						inputProps={{ maxLength: 200 }}
						value={FileName}
						onChange={handleFileNameChange}
						label="โปรดตั้งชื่อเอกสาร*"
					/>
					<Button variant="contained" color="primary">
						บันทึก
					</Button>
				</div> :
				<>ชื่อเอกสาร : {FileName}</>
			}
			</Grid>
			<Grid>({originalFileName})</Grid>
			{(mountType && fileID) &&
				<Grid>
					<a
						href={`
				${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${sDOCID}/attach/${fileID}/download`}
						target="_blank"
						rel="noreferrer">
						<IconButton color="primary" onClick={onDownloadDocument}>
							<Archive />
						</IconButton>
					</a>
					<a
						href={`
			${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${sDOCID}/attach/${fileID}/View`}
						target="_blank"
						rel="noreferrer">
						<IconButton color="primary" onClick={onDownloadDocument}>
							<Pageview />
						</IconButton>
					</a>
					{((mountType == "ModifyInfo" || mountType == "attachFile" || ((sDOCStatus && sDOCStatus == 2) && (ApprovalControl && ApprovalControl == "true"))) && isFileLocked === false) && (
						<IconButton color="primary" onClick={onDeleteUploadedDocument}>
							<Delete />
						</IconButton>
					)}
				</Grid>
			}
			{(!mountType || (mountType && (mountType == "ModifyInfo" || mountType == "attachFile")) && !fileID) && (
				<>
					<IconButton color="primary" onClick={onDelete}>
						<Delete />
					</IconButton>
					(เอกสารที่แนบเพิ่มเติม)
				</>
			)}
			{(ApprovalControl && ApprovalControl == "true") && (
				isFileLocked === true ? (
					<IconButton color="primary" onClick={onChangeIsFileLocked}>
						<Lock />
					</IconButton>) : (
					<IconButton color="primary" onClick={onChangeIsFileLocked}>
						<LockOpen />
					</IconButton>
				)
			)}
		</Grid>
	)
}
