import React, { useEffect, useState } from 'react'
import {
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	useMediaQuery,
	Card,
	Tab,
	Tabs,
} from '@material-ui/core/'
import {
	ExpandMore,
	DescriptionOutlined,
	CalendarToday,
	InsertDriveFileOutlined,
	Cancel,
} from '@material-ui/icons'
import { styled } from '@material-ui/styles'
import { dataSetReports, operators, datasetRecord } from '../../../api/'
import { useLoaded } from '../../../utils/useLoaded'
import { useStoreRehydrated } from '../../../store'
import SuccessModal from '../../../components/modal/SuccessModal'
import {
	BLUE_ACCORDION,
	GRAY_ACCORDION,
} from '../../../config/constants/accordion-color'

const StyledTabs = styled(props => (
	<Tabs
		style={{ backgroundColor: '#e5e7eb' }}
		{...props}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'hidden',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
})

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(1),
		color: 'black',
		'&.Mui-selected': {
			color: '#fff',
			backgroundColor: '#a74345', // Pig Blood Red
		},
	})
)

const StandardReportHistory = () => {
	const isRehydrated = useStoreRehydrated()
	const loaded = useLoaded()
	const quarters = ['ไตรมาสที่ 1', 'ไตรมาสที่ 2', 'ไตรมาสที่ 3', 'ไตรมาสที่ 4']
	const [quarterNow, setQuarterNow] = useState('')
	const [yearPanel, setYearPanel] = useState('')
	const [quarterPanel, setQuarterPanel] = useState('')
	const onChangeYearPanel = panel => (event, newExpanded) => {
		setYearPanel(newExpanded ? panel : false)
	}
	const onChangeQuarterPanel = panel => (event, newExpanded) => {
		setQuarterPanel(newExpanded ? panel : false)
	}
	const [organizationData, setOrganizationData] = useState(null)
	const isNotTablet = useMediaQuery('(min-width:768px)')

	const [report, setReport] = useState({})
	const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)

	const [tabController, setTabController] = useState(0)
	const onTabChange = (event, newValue) => {
		setTabController(newValue)
	}

	const getDataSetReports = async () => {
		const res = await dataSetReports.getDatasetReports()
		const quarterData = res.data
		let yearListsTemp = {}
		for (const data of quarterData) {
			let year = data.year
			if (!yearListsTemp[year]) {
				yearListsTemp = { ...yearListsTemp, [year]: [] }
			}
			yearListsTemp[year].push({
				quarter: data.quarter,
				dataSetRecordHeads: data.dataSetRecordHeads,
			})
		}

		setReport(yearListsTemp)
	}

	const handleDownloadExcel = async id => {
		const res = await datasetRecord.downloadExcel(id)
		if (res && res.status === 200 && res.data) {
			let blob = new Blob([res.data], { type: '' })
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.setAttribute('download', 'exportData.xlsx')
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} else {
			alert('ไม่สามาถดาวน์โหลดไฟล์ได้')
		}
	}

	const onHeadDelete = async reportID => {
		const res = await dataSetReports.deleteReport(reportID)
		if (res && res?.status === 200) {
			setIsOpenSuccessModal(true)
			getDataSetReports()
		}
	}

	const getOrganizationData = async () => {
		const res = await operators.getOrganizationData()
		const status = res.status
		const data = res.data
		if (status === 200) {
			setOrganizationData(data)
		}
	}

	useEffect(() => {
		if (organizationData) {
			getDataSetReports()
		}
	}, [organizationData])
	useEffect(() => {
		if (isRehydrated) getOrganizationData()
	}, [isRehydrated])

	useEffect(() => {
		let dateNow = new Date()
		let yearNow = dateNow.getFullYear()
		let quarterNow = ((dateNow.getMonth() + 1) / 3).toString()
		setQuarterNow('y' + yearNow + 'm' + quarterNow)
		setYearPanel(yearNow)
		setQuarterPanel('y' + yearNow + 'm' + quarterNow)

		// let panelMonthNow = 'panelMonth' + quarterNow
	}, [])

	return (
		<Grid className="p-10">
			<Grid>
				<StyledTabs
					value={tabController}
					onChange={onTabChange}
					variant="scrollable">
					<StyledTab label="รายงานมาตรฐานคุณภาพการให้บริการ" />
					{/* <StyledTab label="เมนู 2" />
					<StyledTab label="เมนู 3" /> */}
				</StyledTabs>
			</Grid>
			<Grid className="bg-white shadow">
				{loaded && tabController === 0 && (
					<>
						{!isNotTablet ? (
							<Grid className="w-full pt-6">
								<Grid></Grid>
								{Object.entries(report).map(([year, yearData]) => (
									<Accordion
										expanded={yearPanel === year}
										onChange={onChangeYearPanel(year)}
										key={year}
										className={`w-full mb-6`}>
										<AccordionSummary
											expandIcon={<ExpandMore />}
											aria-controls={`${year}`}
											id={`${year}`}>
											<DescriptionOutlined
												style={{ fontSize: 22 }}
												className="mr-1"
											/>
											<Grid className="text-sm font-bold text-card_header_color">
												{`ปี พ.ศ. ${year + 543}`}
											</Grid>
										</AccordionSummary>
										<AccordionDetails className="flex flex-col items-center justify-center mb-4 mt-2">
											{yearData.length > 0 &&
												yearData.map(quarterData => (
													<Accordion
														expanded={
															quarterPanel === `y${year}m${quarterData.quarter}`
														}
														onChange={onChangeQuarterPanel(
															`y${year}m${quarterData.quarter}`
														)}
														key={`y${year}m${quarterData.quarter}`}
														className="my-2 w-full">
														<AccordionSummary
															expandIcon={<ExpandMore />}
															aria-controls={`y${year}m${quarterData.quarter}`}
															id={`y${year}m${quarterData.quarter}`}
															style={{
																backgroundColor:
																	quarterPanel ===
																		`y${year}m${quarterData.quarter}`
																		? BLUE_ACCORDION
																		: GRAY_ACCORDION,
															}}>
															<Grid className="flex justify-between items-center w-full flex-row">
																<Grid className="flex flex-row items-center">
																	<CalendarToday
																		style={{ fontSize: 20 }}
																		className="mr-1"
																	/>
																	<Grid className="text-base">
																		{quarters[quarterData.quarter - 1]}&#160;
																	</Grid>
																	<Grid className="text-sm">
																		{quarterNow ===
																			`y${year}m${quarterData.quarter}`
																			? '(ปัจจุบัน)'
																			: ''}
																	</Grid>
																</Grid>
															</Grid>
														</AccordionSummary>
														<AccordionDetails className="mb-4 flex flex-col justify-center items-center">
															<Grid className="flex flex-col items-center w-full mt-4">
																<Grid className="my-1 w-full flex flex-col items-center">
																	{/* <Button
																	variant="contained"
																	color="secondary"
																	style={{
																		paddingLeft: 4,
																		paddingRight: 4,
																		paddingTop: 6,
																		paddingBottom: 6,
																	}}
																	className={`w-full h-full`}
																	onClick={() => onHeadDelete(reportID)}>
																	<Cancel
																		style={{ fontSize: 18 }}
																		className="mr-1"
																	/>
																	<Grid className="text-sm">ยกเลิกรายงาน</Grid>
																</Button> */}
																</Grid>
															</Grid>
															{quarterData &&
																quarterData.dataSetRecordHeads &&
																quarterData.dataSetRecordHeads.map(e => (
																	<Card
																		className="flex flex-col items-center w-full mt-4 py-2 gap-y-2"
																		style={{ backgroundColor: GRAY_ACCORDION }}>
																		<Grid className="flex flex-row">
																			<Grid className="text-sm">
																				{e.isFixed === 'y' && e.isMobile === 'y'
																					? 'ชุดข้อมูลแบบเคลื่อนที่และประจำที่'
																					: ''}
																				{e.isFixed === 'y' && e.isMobile === 'n'
																					? 'ชุดข้อมูลแบบประจำที่'
																					: ''}
																				{e.isFixed === 'n' && e.isMobile === 'y'
																					? 'ชุดข้อมูลแบบเคลื่อนที่'
																					: ''}
																			</Grid>
																		</Grid>

																		<Grid className="w-full">
																			<Button
																				variant="contained"
																				color="secondary"
																				onClick={() => {
																					handleDownloadExcel(e.id)
																				}}
																				className={`w-full`}>
																				<InsertDriveFileOutlined
																					style={{ fontSize: 20 }}
																					className="mr-1"
																				/>
																				<Grid className="text-xs ">รายงาน</Grid>
																			</Button>
																		</Grid>

																		<Grid className="w-full">
																			<a href="/doc/Document1.pdf">
																				<Button
																					variant="contained"
																					color="secondary"
																					className={`w-full`}>
																					<DescriptionOutlined
																						style={{ fontSize: 18 }}
																						className="mr-1"
																					/>
																					<Grid className="text-xs">
																						ใบรับรายงาน
																					</Grid>
																				</Button>
																			</a>
																		</Grid>
																	</Card>
																))}
														</AccordionDetails>
													</Accordion>
												))}
										</AccordionDetails>
									</Accordion>
								))}
							</Grid>
						) : (
							<Grid className="flex flex-col items-center justify-center p-4 pb-10">
								<Grid></Grid>
								{Object.entries(report).map(([year, yearData]) => (
									<Accordion
										expanded={yearPanel === year}
										onChange={onChangeYearPanel(year)}
										key={year}
										className="w-full border-t-4 mb-6 border-card_top_border">
										<AccordionSummary
											expandIcon={<ExpandMore />}
											aria-controls={`${year}`}
											id={`${year}`}>
											<DescriptionOutlined
												style={{ fontSize: 24 }}
												className="mr-1"
											/>
											<Grid className="text-base font-bold text-card_header_color">
												{`ปี พ.ศ. ${parseInt(year) + 543}`}
											</Grid>
										</AccordionSummary>
										<AccordionDetails className="flex flex-col items-center justify-center mb-4 mt-2">
											<Grid></Grid>
											{yearData.length > 0 &&
												yearData.map(quarterData => (
													<Accordion
														expanded={
															quarterPanel === `y${year}m${quarterData.quarter}`
														}
														onChange={onChangeQuarterPanel(
															`y${year}m${quarterData.quarter}`
														)}
														key={`y${year}m${quarterData.quarter}`}
														className="my-2 w-full">
														<AccordionSummary
															expandIcon={<ExpandMore />}
															aria-controls={`y${year}m${quarterData.quarter}`}
															id={`y${year}m${quarterData.quarter}`}
															style={{
																backgroundColor:
																	quarterPanel ===
																		`y${year}m${quarterData.quarter}`
																		? BLUE_ACCORDION
																		: GRAY_ACCORDION,
															}}>
															<Grid className="flex justify-between items-center w-full flex-row">
																<Grid className="flex flex-row items-center">
																	<CalendarToday
																		style={{ fontSize: 22 }}
																		className="mr-1"
																	/>
																	<Grid className="text-base">
																		{quarters[quarterData.quarter - 1]}&#160;
																	</Grid>
																	<Grid className="text-sm">
																		{quarterNow ===
																			`y${year}m${quarterData.quarter}`
																			? '(ปัจจุบัน)'
																			: ''}
																	</Grid>
																</Grid>
																<Grid className="flex flex-row items-center"></Grid>
															</Grid>
														</AccordionSummary>
														<AccordionDetails className="mb-4 flex flex-col justify-center items-center">
															{quarterData &&
																quarterData.dataSetRecordHeads &&
																quarterData.dataSetRecordHeads.map(e => {
																	if (e.isFixed == null && e.isMobile == null)
																		return;
																	return (
																		<Card
																			className="flex justify-between items-center w-full h-16 flex-row pl-6 pr-2 my-1 mt-6"
																			style={{
																				backgroundColor: GRAY_ACCORDION,
																			}}>
																			<Grid className="flex flex-row">
																				<Grid className="text-base">
																					{e.isFixed === 'y' &&
																						e.isMobile === 'y'
																						? 'ชุดข้อมูลแบบเคลื่อนที่และประจำที่'
																						: ''}
																					{e.isFixed === 'y' &&
																						e.isMobile === 'n'
																						? 'ชุดข้อมูลแบบประจำที่'
																						: ''}
																					{e.isFixed === 'n' &&
																						e.isMobile === 'y'
																						? 'ชุดข้อมูลแบบเคลื่อนที่'
																						: ''}
																				</Grid>
																			</Grid>
																			<Grid className="flex flex-row items-center w-auto mx-1 gap-x-2">
																				<Grid className="my-1 mx-1 w-auto flex flex-col items-center">
																					<a
																						href="/doc/response1.json"
																						download
																						target="_blank"
																						rel="noreferrer">
																						<Button
																							variant="contained"
																							color="secondary"
																							className={`w-full`}>
																							<DescriptionOutlined
																								style={{ fontSize: 18 }}
																								className="mr-1"
																							/>
																							<Grid className="text-base">
																								Json Report
																							</Grid>
																						</Button>
																					</a>
																				</Grid>
																				<Grid className="my-1 mx-1 w-auto flex flex-col items-center">
																					<Button
																						variant="contained"
																						color="secondary"
																						onClick={() => {
																							handleDownloadExcel(e.id)
																						}}
																						className={`w-full`}>
																						<InsertDriveFileOutlined
																							style={{ fontSize: 20 }}
																							className="mr-1"
																						/>
																						<Grid className="text-base ">
																							รายงาน
																						</Grid>
																					</Button>
																				</Grid>
																				<Grid className="my-1 mx-1 w-auto flex flex-col items-center">
																					<Button
																						variant="contained"
																						color="secondary"
																						className={`w-full`}
																						onClick={() => onHeadDelete(e.id)}>
																						<Cancel
																							style={{ fontSize: 20 }}
																							className="mr-1"
																						/>
																						<Grid className="text-base">
																							ยกเลิกรายงาน
																						</Grid>
																					</Button>
																				</Grid>
																				<Grid>
																					<a
																						href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/acceptReport?headID=${e.id}`}
																						target="_blank"
																						rel="noreferrer">
																						<Button
																							variant="contained"
																							color="secondary"
																							className={`w-full`}>
																							<DescriptionOutlined
																								style={{ fontSize: 20 }}
																								className="mr-1"
																							/>
																							<Grid className="text-base">
																								ใบรับรายงาน
																							</Grid>
																						</Button>
																					</a>
																				</Grid>
																			</Grid>
																		</Card>
																	)
																})}
														</AccordionDetails>
													</Accordion>
												))}
										</AccordionDetails>
									</Accordion>
								))}
							</Grid>
						)}
					</>
				)}
				{tabController === 1 && <Grid className="p-10">เมนู 2</Grid>}
				{tabController === 2 && <Grid className="p-10">เมนู 3</Grid>}
			</Grid>
			<SuccessModal
				open={isOpenSuccessModal}
				title={`ยกเลิกรายงานสำเร็จแล้ว`}
				handleButton={() => setIsOpenSuccessModal(false)}
			/>
		</Grid>
	)
}

export default StandardReportHistory
