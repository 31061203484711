export const fixed_general_details = [
	{
		id: 1,
		no: '1.1.1',
		label:
			'ระยะเวลาสำหรับการขอเริ่มเปิดใช้บริการ (Supply time for initial connection)',
		targeted_text: 'ภายใน 10 วันทำการ(ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 10,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 0',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'int',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 7,
		priority: 1,
	},
	{
		id: 2,
		no: '1.1.2',
		label:
			'ร้อยละของการติดตั้งบริการสำเร็จภายในวันที่กำหนด (Percentage of service installation completed on or before the date confirmed)',
		targeted_text: 'ไม่น้อยกว่าร้อยละ 90 (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 12,
		priority: 2,
	},
	{
		id: 3,
		no: '1.1.3',
		label:
			'จำนวนของความผิดปกติที่ถูกรายงาน ต่อจำนวนผู้ใช้บริการ 100 ราย (Number of reported faults per 100 subscribers)',
		targeted_text:
			'ไม่เกิน 5 ครั้ง ต่อผู้ใช้บริการ 100 ราย (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 5,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 0',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'int',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 17,
		priority: 3,
	},
	{
		id: 4,
		no: '1.1.4',
		label: 'ระยะเวลาในการซ่อมแซม (Fault repair time)',
		targeted_text: 'ไม่เกิน 24 ชั่วโมง (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 24,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 0',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'int',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 22,
		priority: 4,
	},
	{
		id: 5,
		no: '1.1.5',
		label:
			'อัตราข้อร้องเรียนที่เกี่ยวกับข้อผิดพลาดในการเรียกเก็บค่าบริการ (Billing inaccuracy)',
		targeted_text: 'ไม่เกินร้อยละ 0.3 (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0.3,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 27,
		priority: 5,
	},
	{
		id: 6,
		no: '1.1.6',
		label:
			'ระยะเวลาที่ต้องรอในการขอใช้บริการดูแลลูกค้าจากศูนย์ตอบรับโทรศัพท์ (Response time for accessing customer-service call center)',
		targeted_text: 'ไม่เกิน 60 วินาที (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 60,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าเท่ากับ 1-60 วินาที',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'int',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 32,
		priority: 6,
	},
	{
		id: 7,
		no: '1.1.7',
		label:
			'ความพร้อมในการใช้งานของเครื่องโทรศัพท์สาธารณะ (Payphone Service Availability)',
		targeted_text:
			'ไม่ต่ำกว่าร้อยละ 90 ในเขตกรุงเทพฯ และปริมณฑล (ให้วัดเป็นรายเดือน)',
		subLabel_1:
			'ไม่ต่ำกว่าร้อยละ 90 ในเขตกรุงเทพฯ และปริมณฑล (ให้วัดเป็นรายเดือน)',
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 37,
		priority: 7,
	},
	{
		id: 8,
		no: '1.1.7',
		label:
			'ความพร้อมในการใช้งานของเครื่องโทรศัพท์สาธารณะ (Payphone Service Availability)',
		targeted_text:
			'ไม่ต่ำกว่าร้อยละ 85 ในเขตต่างจังหวัด (ให้วัดเป็นรายเดือน)',
		subLabel_1: 'ไม่ต่ำกว่าร้อยละ 85 ในเขตต่างจังหวัด (ให้วัดเป็นรายเดือน)',
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 85,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 38,
		isPayphone: true,
		priority: 8,
	},
]
