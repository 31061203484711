import { Grid, Input, Button } from '@material-ui/core'
export default function SubLabelType2({
	type,
	flags,
	item,
	onFillForm,
	answers,
	onOpenValidationModal,
}) {
	const answer = answers.find(ans => ans.dataSetItemID === item.id)
	const is2G = item.label.includes('2G') ? true : false

	return (
		<>
			{!is2G ? (
				<Grid className="col-span-12 text-center border flex flex-row">
					<Grid className="border-r w-3/12 p-1 font-bold text-left">
						{item.label}
					</Grid>
					{/* Months */}
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonth1}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth1', e.target.value)
						}
						type="text"
					/>
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonth2}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth2', e.target.value)
						}
						type="text"
					/>
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonth3}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth3', e.target.value)
						}
						type="text"
					/>
					{/* Average */}
					<Input
						className="border-r border-gray-500 w-1/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonthAvg}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonthAvg', e.target.value)
						}
						type="text"
					/>
					{/* Validation */}
					<Grid className="border-r border-b border-gray-500 w-2/12 p-1 font-bold text-center flex justify-center items-center self-center h-full">
					{((!answer?.dataValueMonth1 || !answer?.dataValueMonth2 || !answer?.dataValueMonth3) && !answer?.isError) ? null : (answer?.isError ==='y' ? (
							<Button
								variant="contained"
								color="secondary"
								onClick={() =>
									onOpenValidationModal(
										item.id,
										answer.reason,
										answer.solution,
										answer.periodSolution,
										answer?.validateFileUrl
									)
								}>
								ไม่ผ่านเกณฑ์
							</Button>
						) : (
							<Grid className="text-green-500 font-bold text-center ">
								ผ่านเกณฑ์
							</Grid>
						))}
					</Grid>
				</Grid>
			) : (
				<></>
			)}
		</>
	)
}
