import { forwardRef, useRef } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Slide,
	IconButton,
	useMediaQuery,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export function RejectReasonModal({ open, onClose, header_icon, content }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{header_icon}
						<Grid className="text-white">เหตุผลที่ไม่อนุมัติ</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					ref={descriptionElementRef}>
					<Grid className="w-full py-10 text-center">{content}</Grid>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
