import React from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'

import NavBar from '../components/NavBar'
import UsersRoute from './users'
import AdminsRoute from './admin'
import SDOCUsersRoute from './sdoc/users'
import SDOCAdminsRoute from './sdoc/admin/'
import MainAdminRoutes from './main-admin/'

const Main = () => {
	return (
		<>
			<NavBar>
				<Switch>
					{/* Admin Qos Routes */}
					<Route path="/main-admin">
						<MainAdminRoutes />
					</Route>

					{/* SDOC Admin's Routes */}
					<Route path="/admin/sdoc">
						<SDOCAdminsRoute />
					</Route>

					{/* SDOC User's Routes */}
					<Route path="/sdoc">
						<SDOCUsersRoute />
					</Route>

					{/* Admin Routes */}
					<Route path="/admin">
						<AdminsRoute />
					</Route>

					{/* User's Routes */}
					<Route path="/">
						<UsersRoute />
					</Route>

					<Redirect to="/" from="*" />
				</Switch>
			</NavBar>
		</>
	)
}

export default withRouter(Main)
