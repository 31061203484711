import React from 'react'
import {
	Button,
	Grid,
	Card,
	Divider,
} from '@material-ui/core'

import logo from 'assets/images/NBTC-logo.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
	return {
		AppDescription: {
			fontSize: '24px',
		},
		AppDescriptionSpan: {
			color: theme.palette.primary.main,
		},
	}
})
const FormSelectService = ({onSelectService}) => {
	const classes = useStyles()
	return (
	
			<Card className="flex flex-col items-center justify-center px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5">
				<Grid className="">
					<img src={logo} alt="NBTC" width="140" />
				</Grid>
				<Grid
					className={`mt-3 text-sm md:text-base text-center ${classes.AppDescription}`}>
					<span className={classes.AppDescriptionSpan}>NBTC</span> DATA PORTAL
				</Grid>

				<Grid className={`mt-4 ${classes.AppDescriptionSpan}`}>
				เลือกระบบที่ต้องการใช้บริการ
				
				</Grid>
				
				<Grid className="mt-6 w-full">
				
				<Divider />
					

					<Grid className="flex flex-row gap-x-3 w-full justify-center mx-auto py-5">
					<Button
						
							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="large"
							id="signin-button"
                            onClick={e=>onSelectService('qos')}
							>
							เลือกผู้ประกอบการ
						</Button>
						{/* <Button
						
							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="large"
							id="signin-button2"
							onClick={e=>onSelectService('sdoc')}
							>
							SDOC
						</Button> */}
					</Grid>
				</Grid>
			</Card>
	
	)
}

export default FormSelectService
