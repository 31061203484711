import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState, useStoreRehydrated } from '../../store'

export default function ProtectedRoute({ children, ...rest }) {
	const user = useStoreState(s => s.userModel.user)
	const isRehydrated = useStoreRehydrated()

	return (
		isRehydrated && (
			<Route
				{...rest}
				render={({ location }) =>
					user ? (
						children
					) : (
						<Redirect
							to={{
								pathname: '/sign-in',
								state: { from: location },
							}}
						/>
					)
				}
			/>
		)
	)
}
