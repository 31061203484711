import API from '../config/axios'
import store from '../store'

const authAPI = {
	signin: async (payloads) => {
		const res = await API.post('/OperatorUsers/authenticate', payloads)

		if (res) {
			if (res.status === 200) {
				return res
			} else {
				console.error(res)
				return false
			}
		}
	},
	signinQosWithRegisterCompany: async (payloads) => {
		const res = await API.post('/OperatorUsers/authenticateWithRegisterCompany', payloads)

		if (res) {
			if (res.status === 200) {
				return res
			} else {
				console.error(res)
				return false
			}
		}
	},

	
	identify: async (_token) => {
		const res = await API.post('/OperatorUsers/identify', {_token})

		if (res) {
			if (res.status === 200) {
				return res
			} else {
				console.error(res)
				return false
			}
		}
	},
	signout: () => {
		const setUser = store.getActions().userModel.set
		const setFormPermissionType =
			store.getActions().userModel.setFormPermissionType
		const setUserOperatorProfile =
			store.getActions().userModel.setUserOperatorProfile

		setUser(null)
		setFormPermissionType(null)
		setUserOperatorProfile(null)
	},
	register: (
		nationalIDCardNo,
		mobile,
		email,
		firstname,
		lastname,
		operatorID
	) => {
		return API.post('/Operators/register', {
			nationalIDCardNo,
			mobile,
			email,
			firstname,
			lastname,
			operatorID,
		})
			.then(() => {
				return true
			})
			.catch(err => {
				console.error(err)
				return false
			})
	},
}

export default authAPI
