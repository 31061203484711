export const mobile_sound_details = [
	{
		id: 41,
		no: "2.2.1",
		label: "อัตราส่วนการเรียกสำเร็จ (Successful call ratio) กรณีที่เป็นการโทรศัพท์ภายในโครงข่ายของผู้ประกอบการเดียวกัน",
		targeted_text: "1. ช่วงเวลา 10.00 - 13.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_1: "1. ช่วงเวลา 10.00 - 13.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 7,
		priority: 41
	},
	{
		id: 42,
		no: "2.2.1",
		label: "อัตราส่วนการเรียกสำเร็จ (Successful call ratio) กรณีที่เป็นการโทรศัพท์ภายในโครงข่ายของผู้ประกอบการเดียวกัน",
		targeted_text: "2. ช่วงเวลา 16.00 - 19.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_1: "2. ช่วงเวลา 16.00 - 19.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 11,
		priority: 42
	},
	{
		id: 43,
		no: "2.2.2",
		label: "อัตราส่วนการเรียกสำเร็จ (Successful call ratio) กรณีที่เป็นการโทรข้ามโครงข่ายต่างผู้ประกอบการ",
		targeted_text: "1. ช่วงเวลา 10.00 - 13.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_1: "1. ช่วงเวลา 10.00 - 13.00 น.เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 16,
		priority: 43
	},
	{
		id: 44,
		no: "2.2.2",
		label: "อัตราส่วนการเรียกสำเร็จ (Successful call ratio) กรณีที่เป็นการโทรข้ามโครงข่ายต่างผู้ประกอบการ",
		targeted_text: "2. ช่วงเวลา 16.00 - 19.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_1: "2. ช่วงเวลา 16.00 - 19.00 น. เฉลี่ยทุก 3 เดือน ไม่น้อยกว่าร้อยละ 90",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 20,
		priority: 44
	},
	{
		id: 45,
		no: "2.2.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate)",
		targeted_text: "1. ช่วงเวลา 10.00 - 13.00 น. เฉลี่ยทุก 3 เดือน ไม่เกินร้อยละ 2",
		subLabel_1: "1. ช่วงเวลา 10.00 - 13.00 น. เฉลี่ยทุก 3 เดือน ไม่เกินร้อยละ 2",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 25,
		priority: 45
	},
	{
		id: 46,
		no: "2.2.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate)",
		targeted_text: "2. ช่วงเวลา 16.00 - 19.00 น. เฉลี่ยทุก 3 เดือน ไม่เกินร้อยละ 2",
		subLabel_1: "2. ช่วงเวลา 16.00 - 19.00 น. เฉลี่ยทุก 3 เดือน ไม่เกินร้อยละ 2",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 29,
		priority: 46
	},
	{
		id: 47,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "ร้อยละ 90 ของข้อมูลที่วัดได้ ต้องไม่ต่ำกว่าค่า 2.5 (MoS Score)",
		subLabel_1: "1-ภาคเหนือ",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I",
			"E"
		],
		excelValueRow: 36,
		priority: 47
	},
	{
		id: 322,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "",
		subLabel_1: "MOS เฉลี่ย ไม่ต่ำกว่า 2.5",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2.5,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 37,
		priority: 47.1
	},
	{
		id: 48,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "ร้อยละ 90 ของข้อมูลที่วัดได้ ต้องไม่ต่ำกว่าค่า 2.5 (MoS Score)",
		subLabel_1: "2-ภาคใต้",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I",
			"E"
		],
		excelValueRow: 38,
		priority: 48
	},
	{
		id: 323,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "",
		subLabel_1: "MOS เฉลี่ย ไม่ต่ำกว่า 2.5",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2.5,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 39,
		priority: 48.1
	},
	{
		id: 49,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "ร้อยละ 90 ของข้อมูลที่วัดได้ ต้องไม่ต่ำกว่าค่า 2.5 (MoS Score)",
		subLabel_1: "3-ภาคกลาง",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I",
			"E"
		],
		excelValueRow: 40,
		priority: 49
	},
	{
		id: 324,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "",
		subLabel_1: "MOS เฉลี่ย ไม่ต่ำกว่า 2.5",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2.5,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 41,
		priority: 49.1
	},
	{
		id: 50,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "ร้อยละ 90 ของข้อมูลที่วัดได้ ต้องไม่ต่ำกว่าค่า 2.5 (MoS Score)",
		subLabel_1: "4-ภาคตะวันออกเฉียงเหนือ",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: "ge",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I",
			"E"
		],
		excelValueRow: 42,
		priority: 50
	},
	{
		id: 325,
		no: "2.2.4",
		label: "คุณภาพของเสียง (Mean Opinion Score (MOS))",
		targeted_text: "",
		subLabel_1: "MOS เฉลี่ย ไม่ต่ำกว่า 2.5",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2.5,
		targetCondition: "ge",
		filling_condition: "",
		isNeedAnswer: true,
		records: {
			rural_1: null,
			rural_2: null,
			urban_1: null,
			urban_2: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 43,
		priority: 50.1
	},
	{
		id: 51,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 241,
		priority: 51
	},
	{
		id: 52,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 242,
		priority: 52
	},
	{
		id: 53,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 243,
		priority: 53
	},
	{
		id: 54,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 244,
		priority: 54
	},
	{
		id: 55,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 245,
		priority: 55
	},
	{
		id: 200,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 246,
		priority: 55.1
	},
	{
		id: 301,
		no: "2.2.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "7-สถานีกลางกรุงเทพอภิวัฒน์ (บางซื่อ)",
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 247,
		priority: 55.2
	},
	{
		id: 56,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "00.00 - 01.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 248,
		priority: 56
	},
	{
		id: 57,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "01.00 - 02.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 249,
		priority: 57
	},
	{
		id: 58,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "02.00 - 03.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 250,
		priority: 58
	},
	{
		id: 59,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "03.00 - 04.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 251,
		priority: 59
	},
	{
		id: 60,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "04.00 - 05.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 252,
		priority: 60
	},
	{
		id: 61,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "05.00 - 06.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 253,
		priority: 61
	},
	{
		id: 62,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "06.00 - 07.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 254,
		priority: 62
	},
	{
		id: 63,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "07.00 - 08.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 255,
		priority: 63
	},
	{
		id: 64,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "08.00 - 09.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 256,
		priority: 64
	},
	{
		id: 65,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "09.00 - 10.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 257,
		priority: 65
	},
	{
		id: 66,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "10.00 - 11.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 258,
		priority: 66
	},
	{
		id: 67,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "11.00 - 12.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 259,
		priority: 67
	},
	{
		id: 68,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "12.00 - 13.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 260,
		priority: 68
	},
	{
		id: 69,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "13.00 - 14.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 261,
		priority: 69
	},
	{
		id: 70,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "14.00 - 15.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 262,
		priority: 70
	},
	{
		id: 71,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "15.00 - 16.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 263,
		priority: 71
	},
	{
		id: 72,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "16.00 - 17.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 264,
		priority: 72
	},
	{
		id: 73,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "17.00 - 18.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 265,
		priority: 73
	},
	{
		id: 74,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "18.00 - 19.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 266,
		priority: 74
	},
	{
		id: 75,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "19.00 - 20.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 267,
		priority: 75
	},
	{
		id: 76,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "20.00 - 21.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 268,
		priority: 76
	},
	{
		id: 77,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "21.00 - 22.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 269,
		priority: 77
	},
	{
		id: 78,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "22.00 - 23.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 270,
		priority: 78
	},
	{
		id: 79,
		no: "2.2.5.1",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "1-ท่าอากาศยานสุวรรณภูมิ",
		subLabel_2: "23.00 - 24.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 271,
		priority: 79
	},
	{
		id: 80,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "00.00 - 01.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 273,
		priority: 80
	},
	{
		id: 81,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "01.00 - 02.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 274,
		priority: 81
	},
	{
		id: 82,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "02.00 - 03.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 275,
		priority: 82
	},
	{
		id: 83,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "03.00 - 04.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 276,
		priority: 83
	},
	{
		id: 84,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "04.00 - 05.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 277,
		priority: 84
	},
	{
		id: 85,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "05.00 - 06.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 278,
		priority: 85
	},
	{
		id: 86,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "06.00 - 07.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 279,
		priority: 86
	},
	{
		id: 87,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "07.00 - 08.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 280,
		priority: 87
	},
	{
		id: 88,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "08.00 - 09.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 281,
		priority: 88
	},
	{
		id: 89,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "09.00 - 10.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 282,
		priority: 89
	},
	{
		id: 90,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "10.00 - 11.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 283,
		priority: 90
	},
	{
		id: 91,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "11.00 - 12.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 284,
		priority: 91
	},
	{
		id: 92,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "12.00 - 13.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 285,
		priority: 92
	},
	{
		id: 93,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "13.00 - 14.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 286,
		priority: 93
	},
	{
		id: 94,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "14.00 - 15.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 287,
		priority: 94
	},
	{
		id: 95,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "15.00 - 16.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 288,
		priority: 95
	},
	{
		id: 96,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "16.00 - 17.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 289,
		priority: 96
	},
	{
		id: 97,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "17.00 - 18.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 290,
		priority: 97
	},
	{
		id: 98,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "18.00 - 19.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 291,
		priority: 98
	},
	{
		id: 99,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "19.00 - 20.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 292,
		priority: 99
	},
	{
		id: 100,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "20.00 - 21.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 293,
		priority: 100
	},
	{
		id: 101,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "21.00 - 22.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 294,
		priority: 101
	},
	{
		id: 102,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "22.00 - 23.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 295,
		priority: 102
	},
	{
		id: 103,
		no: "2.2.5.2",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "2-ท่าอากาศยานดอนเมือง",
		subLabel_2: "23.00 - 24.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 296,
		priority: 103
	},
	{
		id: 104,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "00.00 - 01.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 298,
		priority: 104
	},
	{
		id: 105,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "01.00 - 02.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 299,
		priority: 105
	},
	{
		id: 106,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "02.00 - 03.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 300,
		priority: 106
	},
	{
		id: 107,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "03.00 - 04.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 301,
		priority: 107
	},
	{
		id: 108,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "04.00 - 05.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 302,
		priority: 108
	},
	{
		id: 109,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "05.00 - 06.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 303,
		priority: 109
	},
	{
		id: 110,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "06.00 - 07.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 304,
		priority: 110
	},
	{
		id: 111,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "07.00 - 08.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 305,
		priority: 111
	},
	{
		id: 112,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "08.00 - 09.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 306,
		priority: 112
	},
	{
		id: 113,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "09.00 - 10.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 307,
		priority: 113
	},
	{
		id: 114,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "10.00 - 11.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 308,
		priority: 114
	},
	{
		id: 115,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "11.00 - 12.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 309,
		priority: 115
	},
	{
		id: 116,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "12.00 - 13.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 310,
		priority: 116
	},
	{
		id: 117,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "13.00 - 14.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 311,
		priority: 117
	},
	{
		id: 118,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "14.00 - 15.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 312,
		priority: 118
	},
	{
		id: 119,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "15.00 - 16.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 313,
		priority: 119
	},
	{
		id: 120,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "16.00 - 17.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 314,
		priority: 120
	},
	{
		id: 121,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "17.00 - 18.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 315,
		priority: 121
	},
	{
		id: 122,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "18.00 - 19.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 316,
		priority: 122
	},
	{
		id: 123,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "19.00 - 20.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 317,
		priority: 123
	},
	{
		id: 124,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "20.00 - 21.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 318,
		priority: 124
	},
	{
		id: 125,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "21.00 - 22.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 319,
		priority: 125
	},
	{
		id: 126,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "22.00 - 23.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 320,
		priority: 126
	},
	{
		id: 127,
		no: "2.2.5.3",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "3-สถานีขนส่งผู้โดยสารกรุงเทพ (จตุจักร)",
		subLabel_2: "23.00 - 24.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 321,
		priority: 127
	},
	{
		id: 128,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "00.00 - 01.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 323,
		priority: 128
	},
	{
		id: 129,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "01.00 - 02.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 324,
		priority: 129
	},
	{
		id: 130,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "02.00 - 03.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 325,
		priority: 130
	},
	{
		id: 131,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "03.00 - 04.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 326,
		priority: 131
	},
	{
		id: 132,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "04.00 - 05.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 327,
		priority: 132
	},
	{
		id: 133,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "05.00 - 06.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 328,
		priority: 133
	},
	{
		id: 134,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "06.00 - 07.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 329,
		priority: 134
	},
	{
		id: 135,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "07.00 - 08.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 330,
		priority: 135
	},
	{
		id: 136,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "08.00 - 09.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 331,
		priority: 136
	},
	{
		id: 137,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "09.00 - 10.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 332,
		priority: 137
	},
	{
		id: 138,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "10.00 - 11.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 333,
		priority: 138
	},
	{
		id: 139,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "11.00 - 12.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 334,
		priority: 139
	},
	{
		id: 140,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "12.00 - 13.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 335,
		priority: 140
	},
	{
		id: 141,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "13.00 - 14.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 336,
		priority: 141
	},
	{
		id: 142,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "14.00 - 15.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 337,
		priority: 142
	},
	{
		id: 143,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "15.00 - 16.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 338,
		priority: 143
	},
	{
		id: 144,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "16.00 - 17.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 339,
		priority: 144
	},
	{
		id: 145,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "17.00 - 18.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 340,
		priority: 145
	},
	{
		id: 146,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "18.00 - 19.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 341,
		priority: 146
	},
	{
		id: 147,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "19.00 - 20.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 342,
		priority: 147
	},
	{
		id: 148,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "20.00 - 21.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 343,
		priority: 148
	},
	{
		id: 149,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "21.00 - 22.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 344,
		priority: 149
	},
	{
		id: 150,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "22.00 - 23.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 345,
		priority: 150
	},
	{
		id: 151,
		no: "2.2.5.4",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "4-สถานีขนส่งผู้โดยสารกรุงเทพ (ถนนบรมราชชนนี)",
		subLabel_2: "23.00 - 24.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 346,
		priority: 151
	},
	{
		id: 152,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "00.00 - 01.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 348,
		priority: 152
	},
	{
		id: 153,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "01.00 - 02.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 349,
		priority: 153
	},
	{
		id: 154,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "02.00 - 03.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 350,
		priority: 154
	},
	{
		id: 155,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "03.00 - 04.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 351,
		priority: 155
	},
	{
		id: 156,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "04.00 - 05.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 352,
		priority: 156
	},
	{
		id: 157,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "05.00 - 06.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 353,
		priority: 157
	},
	{
		id: 158,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "06.00 - 07.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 354,
		priority: 158
	},
	{
		id: 159,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "07.00 - 08.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 355,
		priority: 159
	},
	{
		id: 160,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "08.00 - 09.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 356,
		priority: 160
	},
	{
		id: 161,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "09.00 - 10.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 357,
		priority: 161
	},
	{
		id: 162,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "10.00 - 11.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 358,
		priority: 162
	},
	{
		id: 163,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "11.00 - 12.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 359,
		priority: 163
	},
	{
		id: 164,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "12.00 - 13.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 360,
		priority: 164
	},
	{
		id: 165,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "13.00 - 14.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 361,
		priority: 165
	},
	{
		id: 166,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "14.00 - 15.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 362,
		priority: 166
	},
	{
		id: 167,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "15.00 - 16.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 363,
		priority: 167
	},
	{
		id: 168,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "16.00 - 17.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 364,
		priority: 168
	},
	{
		id: 169,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "17.00 - 18.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 365,
		priority: 169
	},
	{
		id: 170,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "18.00 - 19.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 366,
		priority: 170
	},
	{
		id: 171,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "19.00 - 20.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 367,
		priority: 171
	},
	{
		id: 172,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "20.00 - 21.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 368,
		priority: 172
	},
	{
		id: 173,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "21.00 - 22.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 369,
		priority: 173
	},
	{
		id: 174,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "22.00 - 23.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 370,
		priority: 174
	},
	{
		id: 175,
		no: "2.2.5.5",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "5-สถานีขนส่งผู้โดยสารกรุงเทพ (เอกมัย)",
		subLabel_2: "23.00 - 24.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 371,
		priority: 175
	},
	{
		id: 176,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "00.00 - 01.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 373,
		priority: 176
	},
	{
		id: 177,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "01.00 - 02.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 374,
		priority: 177
	},
	{
		id: 178,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "02.00 - 03.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 375,
		priority: 178
	},
	{
		id: 179,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "03.00 - 04.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 376,
		priority: 179
	},
	{
		id: 180,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "04.00 - 05.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 377,
		priority: 180
	},
	{
		id: 181,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "05.00 - 06.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 378,
		priority: 181
	},
	{
		id: 182,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "06.00 - 07.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 379,
		priority: 182
	},
	{
		id: 183,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "07.00 - 08.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 380,
		priority: 183
	},
	{
		id: 184,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "08.00 - 09.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 381,
		priority: 184
	},
	{
		id: 185,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "09.00 - 10.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 382,
		priority: 185
	},
	{
		id: 186,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "10.00 - 11.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 383,
		priority: 186
	},
	{
		id: 187,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "11.00 - 12.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 384,
		priority: 187
	},
	{
		id: 188,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "12.00 - 13.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 385,
		priority: 188
	},
	{
		id: 189,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "13.00 - 14.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 386,
		priority: 189
	},
	{
		id: 190,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "14.00 - 15.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 387,
		priority: 190
	},
	{
		id: 191,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "15.00 - 16.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 388,
		priority: 191
	},
	{
		id: 192,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "16.00 - 17.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 389,
		priority: 192
	},
	{
		id: 193,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "17.00 - 18.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 390,
		priority: 193
	},
	{
		id: 194,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "18.00 - 19.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 391,
		priority: 194
	},
	{
		id: 195,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "19.00 - 20.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 392,
		priority: 195
	},
	{
		id: 196,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "20.00 - 21.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 393,
		priority: 196
	},
	{
		id: 197,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "21.00 - 22.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 394,
		priority: 197
	},
	{
		id: 198,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "22.00 - 23.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 395,
		priority: 198
	},
	{
		id: 199,
		no: "2.2.5.6",
		label: "อัตราส่วนของกรณีที่สายหลุด (Drop Call Rate) ในพื้นที่เฝ้าระวัง",
		targeted_text: "ไม่เกินร้อยละ 2",
		subLabel_1: "6-สถานีรถไฟกรุงเทพ (หัวลำโพง)",
		subLabel_2: "23.00 - 24.00 น.",
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 2,
		targetCondition: "le",
		filling_condition: "ช่องสีเขียว ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)",
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null
		},
		data_type: "float",
		excelValueCol: [
			"F",
			"G",
			"H",
			"I"
		],
		excelValueRow: 396,
		priority: 199
	}
]
