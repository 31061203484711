import { action, thunk } from 'easy-peasy'
import { admin } from '../../../api/index'

export const QoSAdminModel = {
	// States
	admin: null,
	adminProfile: null,
	// Setters
	setAdmin: action((state, admin) => {
		state.admin = admin
	}),
	setAdminProfile: action((state, adminProfile) => {
		state.adminProfile = adminProfile
	}),
	// Custom Function
	QoSAdminSignIn: thunk(async (actions, { username, password }) => {
		const res = await admin.signin(username, password, 1)

		console.log('res admin sign in', res)

		if (res && res?.status === 200) {
			actions.setAdmin(res.data)
			actions.getAdminProfile()
		}
	}),
	adminSignout: thunk(actions => {
		actions.setAdmin(null)
	}),
	getAdminProfile: thunk(async actions => {
		const res = await admin.me()
		if (res && res?.status === 200) {
			actions.setAdminProfile(res.data)
		}
	}),
}
