import { Card, Grid, Divider, Button } from '@material-ui/core'
import { Settings, CheckCircle, NoteAdd, Cancel } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
	return {
		disabled_text_field: {
			color: '#000',

		},
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		common_button: {
			backgroundColor: '#eee',
			color: '#000',
			'&:hover': {
				backgroundColor: '#eee',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		addedDoc_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
	}
})

export default function ApprovalCard({
	sDOCID,
	onRequestAddition,
	setCurrentActionSDoc,
	setSubmitType,
	setOpenSubmitSDocSureModal,
	onRejectJob

}) {
	const classes = useStyles()

	return (

		<Card className={`border-t-4 border-card_top_border mb-5 p-5`}>

			<Grid className="flex flex-row justify-between items-center w-full">
				<Grid className="flex flex-row mt-4 mb-2">
					<Settings style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						ผลการตรวจสอบข้อมูลเอกสารหลักฐานประกอบการรับรองตนเอง
					</Grid>
				</Grid>
			</Grid>
			<Grid className="mt-3 mb-1 w-full">
				<Divider />
			</Grid>
			<Grid className="flex flex-row mt-4 mb-2 gap-x-2">
				<Grid className="flex flex-row justify-start items-center gap-x-2">
					<Button
						className={`w-40`}
						color="primary"
						variant="contained"
						onClick={() => {
							// onRequestAddition(sDOCID)
							setCurrentActionSDoc(sDOCID)
							setSubmitType('requestAdditionDoc')
							setOpenSubmitSDocSureModal(true)
						}}>
						<NoteAdd style={{ fontSize: 16 }} className="mr-1" />
						<Grid className={`text-xs`}>ขอเอกสารเพิ่ม</Grid>
					</Button>

					<Button
						className={`w-40`}
						color="primary"
						variant="contained"
						onClick={() => {
							setCurrentActionSDoc(sDOCID)
							setSubmitType('requestModifyInfo')
							setOpenSubmitSDocSureModal(true)
						}}>
						<NoteAdd style={{ fontSize: 16 }} className="mr-1" />
						<Grid className={`text-xs`}>ขอปรับปรุงข้อมูล</Grid>
					</Button>

					<Button
						className={`w-40 ${classes.approve_button}`}
						variant="contained"
						onClick={() => {
							setCurrentActionSDoc(sDOCID)
							setSubmitType('approve')
							setOpenSubmitSDocSureModal(true)
						}}>
						<CheckCircle style={{ fontSize: 16 }} className="mr-1" />
						<Grid className={`text-xs`}>อนุมัติ</Grid>
					</Button>
					<Button
						className={`w-40`}
						variant="contained"
						color="primary"
						onClick={() => {
							setCurrentActionSDoc(sDOCID)
							setSubmitType('reject')
							setOpenSubmitSDocSureModal(true)
						}}>
						<Cancel style={{ fontSize: 16 }} className="mr-1" />
						<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
					</Button>

					<Button
						className={`w-40`}
						variant="contained"
						color="primary"
						onClick={() => {
							onRejectJob(sDOCID)
						}}>
						<Cancel style={{ fontSize: 16 }} className="mr-1" />
						<Grid className={`text-xs`}>ปฏิเสธงาน</Grid>
					</Button>
				</Grid>
			</Grid>
		</Card>
	)
}
