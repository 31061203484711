import React, { useState, useEffect, useCallback } from 'react'

import { Grid, Card, Button } from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import {
	ListAlt,
	Description,
	AttachFile,
	Menu,
	Close,
} from '@material-ui/icons'

import { RejectReasonModal } from './components/RejectReasonModal'
import { CancelSDoCModal } from './components/CancelSDoCModal'

import { useStoreRehydrated, useStoreState } from '@store'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import { sdoc } from '@api'
import { now } from '../../../../../node_modules/moment/moment'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => {
	return {
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		common_button: {
			backgroundColor: '#eee',
			color: '#000',
			'&:hover': {
				backgroundColor: '#eee',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
	}
})

// Page Description: สถานะการยื่นแบบหนังสือรับรองตนเอง
export default function SDOCStatus() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const classes = useStyles()
	// const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [rowsWaitStatus, setRowsWaitStatus] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [rowsApproveStatus, setRowsApproveStatus] = useState([])
	// const [columnsWidthApproveStatus, setColumnsWidthApproveStatus] = useState({
	// 	brandNameWidth: 0,
	// 	brandNameFlex: 0.9,
	// 	approveDateWidth: 0,
	// 	approveDateFlex: 0.8,
	// 	activityWidth: 0,
	// 	activityFlex: 1.2,
	// })
	// eslint-disable-next-line
	// GET POA DATA
	const userSDOC = useStoreState(s => s.sdocUserModel.userSDOC)
	const [statusList, setStatusList] = useState([])
	const [openRejectReasonModal, setOpenRejectReasonModal] = useState(false)
	const [rejectReason, setRejectReason] = useState('')
	const [openCancelSdoCModal, setOpenCancelSDoCModal] = useState(false)
	const [cancelSDoCID, setCancelSDoCID] = useState('')
	const [cancelSDoCRunningNo, setCancelSDoCRunningNo] = useState('')
	const [sortModelInprogress, setSortModelInprogress] = useState([
		{
			field: 'runningNumber',
			sort: 'desc',
		},
	]);
	const [sortModel, setSortModel] = useState([
		{
			field: 'sDocNo',
			sort: 'desc',
		},
	]);
	const onClickBillInquiry = useCallback(async (id) => {
		const res = await sdoc.billInquiry(id)
		Swal.fire({
			title: 'กรุณารอสักครู่',
			text: 'ระบบกำลังทำรายการ',
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})
		// wait for 1.5 seconds
		setTimeout(() => {
			if (res && res.status === 200) {
				Swal.close()
				if (res.data.billInquiryStatus === "Match") {
					Swal.fire({
						title: 'ทำการตรวจสอบการชำระเงินสำเร็จ',
						text: 'ตรวจพบการชำระเงินของท่านแล้ว กรุณารอระบบดำเนินการต่อ',
						icon: 'success',
						confirmButtonText: 'OK',
					}).then((result) => {
						if (result.isConfirmed) {
							getSDOCWaitStatusList();
						}
					})
				} else {
					Swal.fire({
						title: 'ไม่พบข้อมูลการชำระเงินดังกล่าว',
						text: 'ไม่พบการชำระเงินรายการนี้ หรือ ข้อมูลอาจจะล่าช้า กรุณารอสักครู่ และทำรายการใหม่ภายหลัง',
						icon: 'error',
						confirmButtonText: 'OK',
					}).then((result) => {
						if (result.isConfirmed) {
							getSDOCWaitStatusList();
						}
					}
					)
				}
			}
		}, 1500)
	})
	const onClickBillRenew = useCallback(async (id) => {
		const res = await sdoc.billRenew(id)
		// show waiting modal
		Swal.fire({
			title: 'กรุณารอสักครู่',
			text: 'ระบบกำลังทำรายการ',
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})
		if (res && res.status === 200) {
			// hide waiting modal
			Swal.close()
			Swal.fire({
				title: 'Success',
				text: 'ทำรายการใบนำฝากใหม่สำเร็จ',
				icon: 'success',
				confirmButtonText: 'OK',
			}).then((result) => {
				if (result.isConfirmed) {
					getSDOCWaitStatusList();
				}
			})
		}
	})
	const onCancelSDoC = useCallback(async (id) => {
		// show waiting modal
		Swal.fire({
			title: 'กรุณารอสักครู่',
			text: 'ระบบกำลังทำรายการ',
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading()
			}

		})
		const res = await sdoc.cancelSDoC(id)
		if (res && res.status === 200) {
			// hide waiting modal
			Swal.close()
			Swal.fire({
				title: 'ยกเลิกรายการคำขอสำเร็จ',
				text: 'ระบบได้ทำการยกเลิกคำขอยื่นแบบรับรองตนเองของท่านแล้ว',
				icon: 'success',
				confirmButtonText: 'OK',
			}).then((result) => {
				if (result.isConfirmed) {
					getSDOCWaitStatusList();
				}
			})
		} else {
			Swal.close()
			Swal.fire({
				title: 'ไม่สามารถยกเลิกรายการคำขอได้',
				text: 'ระบบไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่',
				icon: 'error',
				confirmButtonText: 'OK',
			}).then((result) => {
				if (result.isConfirmed) {
					getSDOCWaitStatusList();
				}
			})
		}

	})



	var columnsWaitingStatus = [
		{
			field: 'runningNumber',
			headerName: 'เลขที่คำขอ',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'brandName',
			headerName: 'ตราอักษร (Brand Name)',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'productModel',
			headerName: 'รุ่นของผลิตภัณฑ์',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'submitDate',
			headerName: 'วันที่และเวลายื่นแบบ',
			width: 200,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : ''}
					{/* {params.value ? format(new Date(params.value), 'MM/dd/yyyy HH:mm') : ''}  */}
				</Grid>
			),
		},
		{
			field: 'statusID',
			headerName: 'สถานะ',
			width: 130,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				var today = new Date();
				today.setDate(today.getDate() + 1);
				var dueDate = null;
				if (params.row.transactions != null && params.row.transactions.dueDate != null) {
					dueDate = new Date(params.row.transactions.dueDate);
				} else {
					dueDate = new Date();
				}
				dueDate.setHours(23, 59, 59, 999);
				return (
					<Grid
						className={`flex flex-row justify-center items-center w-32 h-7 rounded text-xs
					${(params.row.isUploadedAttachment == false) ?
						classes.common_button
						: params.value === 1 || (params.value === 2 && params.row.requestAddtionDocStatusID === 0) || params.value === 4
								? classes.wait_button
								: params.value === 5 || (params.value === 2 && params.row.requestAddtionDocStatusID === 1) || (params.value === 2 && params.row.requestAddtionDocStatusID === 2)
									? classes.approve_button
									: params.value === 6
										? classes.reject_button
										: (params.value === 8 && (dueDate < today)) ?
											classes.reject_button
											: classes.common_button
							}
					`}>
						{
							(params.row.isUploadedAttachment == false) ?
								'คำขอไม่สมบูรณ์'
								: (params.value === 2 && params.row.requestAddtionDocStatusID === 1) ?
									'เพิ่มเอกสารแล้ว'
									: (params.value === 2 && params.row.requestAddtionDocStatusID === 2) ?
										'ปรับปรุงข้อมูลแล้ว'
										: (params.value === 3 && params.row.requestAddtionDocStatusID === 2) ?
											'ขอปรับปรุงเอกสาร'
											: (params.value === 8 && (dueDate < today)) ?
												'เลยกำหนดชำระ'
												: params.row.statusName

						}
					</Grid>
				)
			},
		},
		{
			field: 'activity',
			headerName: 'กิจกรรม',
			width: 800,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
			editable: false,
			renderCell: params => {
				var today = new Date();
				today.setDate(today.getDate() + 1);
				var dueDate = null;
				if (params.row.transactions != null && params.row.transactions.dueDate != null) {
					dueDate = new Date(params.row.transactions.dueDate);
				} else {
					dueDate = new Date();
				}
				dueDate.setHours(23, 59, 59, 999);
				return (
					<Grid className="flex flex-row justify-start items-center w-full gap-x-2">
						<Button
							className={`w-24`}
							color="primary"
							variant="contained"
							onClick={() => {
								history.push(`/sdoc/form/${params.row.id}?mountType=readOnly`)
							}}>
							<Menu style={{ fontSize: 16 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>
						{params.row.statusID === 6 && (
							<Button
								className={`w-32`}
								color="primary"
								variant="contained"
								onClick={() => {
									setRejectReason(params.row.remark)
									setOpenRejectReasonModal(true)
								}}>
								<Close style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ดูเหตุผลที่ไม่อนุมัติ</Grid>
							</Button>
						)}

						{(params.row.statusID === 3 && params.row.requestAddtionDocStatusID === 1) || (params.row.statusID === 1 && params.row.isUploadedAttachment == false) ? (
							<Button
								className={`w-24`}
								color="primary"
								variant="contained"
								onClick={() => {
									history.push(
										`/sdoc/form/${params.row.id}?mountType=attachFile`
									)
								}}>
								<AttachFile style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>แนบไฟล์เพิ่ม</Grid>
							</Button>
						) : params.row.statusID === 3 && params.row.requestAddtionDocStatusID === 2 ? (
							<Button
								className={`w-24`}
								color="primary"
								variant="contained"
								onClick={() => {
									history.push(
										`/sdoc/form/${params.row.id}?mountType=ModifyInfo`
									)
								}}>
								<AttachFile style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ปรับปรุงข้อมูล</Grid>
							</Button>
						) : params.row.statusID === 5 ? (
							<a
								href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocPaper/${params.row.id}`}
								rel="noreferrer"
								target="_blank">
								<Button className={`w-40`} color="primary" variant="contained">
									<Description style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>ดาวน์โหลดหนังสือรับรอง</Grid>
								</Button>
							</a>
						) : params.row.statusID === 8 ? (
							// console.log(new Date(params.row.transactions?.dueDate) > new Date()),
							(dueDate > today) ? (
								<Grid>
									<a
										onClick={() => { onClickBillInquiry(params.row.id) }}
										rel="noreferrer"
										target="_blank">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>อัพเดทข้อมูลการชำระเงิน</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocBillPayment/${params.row.id}`}
										rel="noreferrer"
										target="_blank"
										style={{ marginLeft: 10 }}
									>
										<Button className={`w-42`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบนำฝากชำระเงิน</Grid>
										</Button>
									</a>
								</Grid>
							) : (
								<Grid>
									<a
										onClick={() => { onClickBillRenew(params.row.id) }}
										rel="noreferrer"
										target="_blank">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ทำรายการใบนำฝากใหม่</Grid>
										</Button>
									</a>
								</Grid>
							)
						) : (
							<Grid></Grid>
						)}

						{((params.row.statusID === 1 || params.row.statusID === 2 || params.row.statusID === 3 || params.row.statusID === 8 || params.row.StausID === 11) && params.row.transactions?.invoiceNo == null) && (
							<Button
								className={`w-32`}
								color="primary"
								variant="contained"
								onClick={() => {
									setCancelSDoCID(params.row.id)
									setCancelSDoCRunningNo(params.row.runningNumber)
									setOpenCancelSDoCModal(true)
								}}>
								<Close style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ยกเลิกคำขอ</Grid>
							</Button>
						)}
					</Grid>
				)
			},
		},
	]

	var columnsApproveStatus = [
		{
			field: 'sDocNo',
			headerName: 'sDoc No.',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				return params.row.sDocNo;
			},
			valueGetter: params => {
				if (params.row.sDocNo) {
					return params.row.sDocNo.slice(8, 10) + '-' + params.row.sDocNo.slice(2, 7)
				}
			}
		},
		{
			field: 'brandName',
			headerName: 'ตราอักษร (Brand Name)',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'productModel',
			headerName: 'รุ่นของผลิตภัณฑ์',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		// {
		// 	field: 'approver',
		// 	headerName: 'ผู้อนุมัติ',
		// 	width: 170,
		// 	headerAlign: 'left',
		// 	align: 'left',
		// 	sortable: true,
		// 	editable: false,
		// },
		{
			field: 'approveDate',
			headerName: 'วันที่และเวลาได้รับอนุมัติ',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy  HH:mm') : ''}
					{/* {params.value ? format(new Date(params.value), 'MM/dd/yyyy  HH:mm') : ''} */}
				</Grid>
			),
		},
		{
			field: 'activity',
			headerName: 'กิจกรรม',
			width: 500,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-start items-center w-full gap-x-2">
						<Button
							className={`w-24`}
							color="primary"
							variant="contained"
							onClick={() => {
								history.push(`/sdoc/form/${params.row.id}?mountType=readOnly`)
							}}>
							<Menu style={{ fontSize: 16 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>
						<a
							href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocPaper/${params.row.id}`}
							target="_blank"
							rel="noreferrer">
							<Button className={`w-40`} color="primary" variant="contained">
								<Description style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ดาวน์โหลดหนังสือรับรอง</Grid>
							</Button>
						</a>
						<a
							href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/GetInvoice`}
							target="_blank"
							rel="noreferrer">
							<Button className={`w-40`} color="primary" variant="contained">
								<Description style={{ fontSize: 16 }} className="mr-1" />
								<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
							</Button>
						</a>
					</Grid>
				)
			},
		},
	]

	const getSDocStatusList = async () => {
		// Get Status List
		const resStatusList = await sdoc.getListsStatus()
		const dataStatusList = resStatusList.data
		const statusListTemp = []
		if (resStatusList) {
			if (resStatusList.status === 200) {
				for (let i = 0; i < dataStatusList.length; i++) {
					statusListTemp.push({
						id: dataStatusList[i].id,
						statusName: dataStatusList[i].statusName,
					})
				}
				setStatusList(statusListTemp)
			}
		}
		return statusListTemp
	}

	const getSDOCWaitStatusList = async () => {
		// Get Status List
		setIsDataLoading(true)
		getSDocStatusList()

		// Get SDoc List
		const resList = await sdoc.getListsV2(userSDOC.poa)
		const dataSDocList = resList.data
		if (resList) {
			if (resList.status === 200) {
				const rowsTemp = []
				const rowsApproveTemp = []
				for (let i = 0; i < dataSDocList.length; i++) {
					if (dataSDocList[i].sDocStatusID === 5) {
						rowsApproveTemp.push({
							id: dataSDocList[i].id,
							brandName: dataSDocList[i].brandName,
							// brandName: dataSDocList[i].brandName + dataSDocList[i].id,
							// operator: 'Operator No.' + dataSDocList[i].sDocUserID,
							approver:
								(dataSDocList[i].approver?.firstname ?? '') +
								' ' +
								dataSDocList[i].approver?.lastname,
							operator: dataSDocList[i].operatorName,
							approveDate: dataSDocList[i].approveDate,
							statusID: dataSDocList[i].sDocStatusID,
							productModel: dataSDocList[i].productModel,
							sDocNo: dataSDocList[i].sDocNo,
							remark: dataSDocList[i].remark,
							requestAddtionDocStatusID: dataSDocList[i].requestAddtionDocStatusID,
							transactions: dataSDocList[i].sDocTransactions,
							// statusID: (i % 6) + 1,
						})
					} else {
						rowsTemp.push({
							id: dataSDocList[i].id,
							brandName: dataSDocList[i].brandName,
							// brandName: dataSDocList[i].brandName + dataSDocList[i].id,
							// operator: 'Operator No.' + dataSDocList[i].sDocUserID,
							operator: dataSDocList[i].operatorName,
							submitDate: dataSDocList[i].createDate,
							statusID: dataSDocList[i].sDocStatusID,
							statusName: dataSDocList[i].sDocStatus.publicStatusName,
							productModel: dataSDocList[i].productModel,
							runningNumber: dataSDocList[i].runningNo,
							remark: dataSDocList[i].remark,
							requestAddtionDocStatusID: dataSDocList[i].requestAddtionDocStatusID,
							transactions: dataSDocList[i].sDocTransactions,
							isUploadedAttachment: dataSDocList[i].isUploadedAttachment,
							// statusID: (i % 6) + 1,
						})
					}
				}
				setRowsWaitStatus(rowsTemp)
				setRowsApproveStatus(rowsApproveTemp)
			}
		}
		setIsDataLoading(false)
	}

	// useEffect(() => {
	// 	if (isMobile) {
	// 		setColumnsWidthWaitStatus({
	// 			brandNameWidth: 220,
	// 			brandNameFlex: 0,
	// 			operatorWidth: 170,
	// 			operatorFlex: 0,
	// 			submitDateWidth: 150,
	// 			submitDateFlex: 0,
	// 			statusWidth: 130,
	// 			statusFlex: 0,
	// 			activityWidth: 360,
	// 			activityFlex: 0,
	// 		})
	// 		setColumnsWidthApproveStatus({
	// 			brandNameWidth: 300,
	// 			brandNameFlex: 0,
	// 			approveDateWidth: 220,
	// 			approveDateFlex: 0,
	// 			activityWidth: 240,
	// 			activityFlex: 0,
	// 		})
	// 	} else {
	// 		setColumnsWidthWaitStatus({
	// 			brandNameWidth: 0,
	// 			brandNameFlex: 1.2,
	// 			operatorWidth: 0,
	// 			operatorFlex: 1,
	// 			submitDateWidth: 0,
	// 			submitDateFlex: 0.8,
	// 			statusWidth: 0,
	// 			statusFlex: 1,
	// 			activityWidth: 0,
	// 			activityFlex: 2.6,
	// 		})
	// 		setColumnsWidthApproveStatus({
	// 			brandNameWidth: 0,
	// 			brandNameFlex: 0.9,
	// 			approveDateWidth: 0,
	// 			approveDateFlex: 0.8,
	// 			activityWidth: 0,
	// 			activityFlex: 1.2,
	// 		})
	// 	}
	// }, [isMobile])

	const handleSortChange = (model) => {
		if (isRehydrated && model[0] !== sortModel[0])
			setSortModel(model);
	};

	const handleSortInprogressChange = (model) => {
		if (isRehydrated && model[0] !== sortModelInprogress[0])
			setSortModelInprogress(model);
	};


	useEffect(() => {
		if (isRehydrated) {
			getSDOCWaitStatusList()
		}
		// eslint-disable-next-line
	}, [isRehydrated])

	return (
		<>
			<Grid className="flex flex-col">
				<Grid className="py-10 px-3 sm:px-10">
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									สถานะการยื่นแบบรับรองตนเอง
								</Grid>
							</Grid>
							<Grid className="md:px-5" style={{ height: 380, width: '100%' }}>
								<DataGrid
									rows={rowsWaitStatus}
									columns={columnsWaitingStatus}
									pageSize={5}
									rowsPerPageOptions={[5]}
									disableSelectionOnClick
									sortModel={sortModelInprogress}
									onSortModelChange={(model) => {
										handleSortInprogressChange(model)
									}}
									components={{
										NoRowsOverlay: () => {
											if (!isDataLoading) {
												return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
											} else {
												return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
											}
										}
									}}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid className="pb-10 px-3 sm:px-10">
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									แบบรับรองตนเองทีได้รับการอนุมัติแล้ว
								</Grid>
							</Grid>
							<Grid className="md:px-5" style={{ height: 380, width: '100%' }}>
								<DataGrid
									rows={rowsApproveStatus}
									columns={columnsApproveStatus}
									pageSize={5}
									rowsPerPageOptions={[5]}
									disableSelectionOnClick
									sortModel={sortModel}
									onSortModelChange={(model) => {
										handleSortChange(model)
									}}
									components={{
										NoRowsOverlay: () => {
											if (!isDataLoading) {
												return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
											} else {
												return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
											}
										}
									}}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>

			<RejectReasonModal
				open={openRejectReasonModal}
				content={<Grid className="text-center">{rejectReason}</Grid>}
				onClose={() => {
					setOpenRejectReasonModal(false)
					setRejectReason('')
				}}
			/>

			<CancelSDoCModal
				open={openCancelSdoCModal}
				content={cancelSDoCRunningNo}
				SDoCID={cancelSDoCID}
				handleCancel={() => {
					setOpenCancelSDoCModal(false)
					setCancelSDoCID('')
					setCancelSDoCRunningNo('')
				}}
				handleButton={() => {
					onCancelSDoC(cancelSDoCID)
					setOpenCancelSDoCModal(false)
					setCancelSDoCID('')
					setCancelSDoCRunningNo('')
				}}
				onClose={() => {
					setOpenCancelSDoCModal(false)
					setCancelSDoCID('')
					setCancelSDoCRunningNo('')
				}}
			/>
		</>
	)
}
