import React, { useState } from 'react'
import {
	Card,
	Grid,
	Divider,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputBase,
	Button,
	Select,
	MenuItem,
} from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { operators } from 'api/index'
import { useStoreState } from '../../../store/index'
import { MailOutlineSharp } from '../../../../node_modules/@material-ui/icons/index'
import { Alert, AlertTitle } from '../../../../node_modules/@material-ui/lab/index'
import Swal from 'sweetalert2'
export default function OrganizationDetailCardV3({
	mountType,
	senderState,
	confirmationInfo,
	onChangeSenderType,
	onChangeCompanyInfo,
}) {
	// eslint-disable-next-line
	const [selectPOAError, setSelectPOAError] = useState(false)
	// eslint-disable-next-line
	const [dbdData, setDbdData] = useState(null)
	const [isProfileError, setIsProfileError] = useState(false)
	const userSDOC = useStoreState(s => s.sdocUserModel.userSDOC)

	const handleInput = async e => {
		const { name, value } = e.target

		let newData = confirmationInfo.organization
		onChangeCompanyInfo({ ...newData, [name]: value })

		if (name === 'branchNo') {
			if (value.length === 5) {
				const res = await operators.getOperatorInfo({ taxno: userSDOC?.poa, branchno: value });

				if (res && res?.status === 200 && res?.data.status !== 'error') {
					const data = res.data
					if (data.operatorInfo.zipcode?.length !== 5) {
						setIsProfileError(true)
						Swal.fire({
							icon: 'error',
							title: 'ข้อมูลที่อยู่ไม่ถูกต้อง หรือรูปแบบผิด',
							text: 'กรุณาตรวจสอบและปรับปรุงข้อมูลในเมนู "ข้อมูลผู้ใช้งาน" และกรอกข้อมูลลงฟอร์มให้ถูกต้อง',
							confirmButtonText: 'ตกลง',
						})

					}
					onChangeCompanyInfo({
						...newData,
						type: 'organization',
						name: data.operators.operatorName ?? '',
						// cardNo: data.operators.taxNo ?? '',
						prefix: data.operators.prefix ?? '',
						branchNo: value,
						address: data.operatorInfo.addr ?? '',
						// address: data.operatorInfo.address + ' ' + data.operatorInfo.district + ' ' + data.operatorInfo.amphoe + ' ' + data.operatorInfo.province + ' ' + data.operatorInfo.zipcode ?? '',
						addr: data.operatorInfo.addr ?? '',
						email: data.operatorInfo.email ?? '',
						mobile: data.operatorInfo.mobile ?? '',
						district: data.operatorInfo.district ?? '',
						amphoe: data.operatorInfo.amphoe ?? '',
						province: data.operatorInfo.province ?? '',
						zipcode: data.operatorInfo.zipcode ?? '',
					})
				} else {
					onChangeCompanyInfo({
						...newData,
						type: 'organization',
						// name: '',
						// cardNo: '',
						// prefix: '',
						branchNo: value,
						address: '',
						email: '',
						mobile: '',
					})
				}
			} else {
				onChangeCompanyInfo({
					...newData,
					type: 'organization',
					// name: '',
					// cardNo: '',
					// prefix: '',
					branchNo: value,
					address: '',
					addr: '',
					district: '',
					amphoe: '',
					province: '',
					zipcode: '',
					email: '',
					mobile: '',
				})
			}
		}
	}

	return (
		<Card className={`border-t-4 border-card_top_border mb-5`}>
			<Grid className="flex flex-col justify-center items-start px-5 mb-5">
				{/* Header */}
				<Grid className="flex flex-row mt-4 mb-2">
					<Business style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						1. รายละเอียดผู้ยื่นแบบรับรองตนเอง
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>
				{/* Normal Person or Organization */}
				{!mountType && <Alert severity="warning" className="w-full"><AlertTitle>การแก้ไขข้อมูลผู้ยื่นแบบรับรองตนเอง</AlertTitle>หากข้อมูลในรายละเอียดด้านล่างไม่ตรงกับข้อมูลปัจจุบัน ผู้ใช้งานสามารถแก้ไขข้อมูลได้ที่เมนู "ข้อมูลผู้ใช้งาน" ก่อนยื่นแบบรับรองตนเอง</Alert>}

				<Grid className="flex flex-row items-center justify-between w-full">
					<Grid className="flex flex-col gap-y-2 mt-4">
						<Grid>ประเภทของผู้ยื่นหนังสือยืนยันตนเอง</Grid>
						<RadioGroup
							row
							name="type"
							value={senderState}
							onChange={onChangeSenderType}>
							<FormControlLabel
								value={'normal_person'}
								control={<Radio />}
								label="บุคคลธรรมดา"
								disabled={mountType}
							/>
							{(mountType || userSDOC?.poa) && (
								<FormControlLabel
									value={'organization'}
									control={<Radio />}
									label="นิติบุคคล"
									disabled={mountType}
								/>
							)}
						</RadioGroup>
					</Grid>
				</Grid>
				{/* Normal Person or Organization's Name */}
				{senderState === 'normal_person' && (
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>ชื่อ-นามสกุล</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
							<InputBase
								className="w-full"
								name="confirmationInfoName pointer-events-none"
								readOnly
								disabled
								value={confirmationInfo.normal_person.name}
							/>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>เลขประจำตัวบัตรประชาชน</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="cardNo"
									readOnly
									disabled
									value={confirmationInfo.normal_person.cardNo}
								/>
							</Grid>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>ที่อยู่</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="address"
									readOnly
									disabled
									value={confirmationInfo.normal_person.address}
								// onChange={handleInput}
								/>
							</Grid>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>Email</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="email"
									readOnly
									disabled
									type="email"
									value={confirmationInfo.normal_person.email}
								// onChange={handleInput}
								/>
							</Grid>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>เบอร์โทรศัพท์</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="mobile"
									readOnly
									disabled
									value={confirmationInfo.normal_person.mobile}
								// onChange={handleInput}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}

				{senderState === 'organization' && (
					<>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							{/* {!mountType && (
								<Grid className="flex flex-row">
									<Grid>
										<Button
											className="text-white w-full"
											onClick={handleSearchCompany}
											variant="contained"
											color="secondary"
											size="large"
											id="signin-button">
											ตรวจสอบข้อมูลนิติบุคคล
										</Button>
									</Grid>
								</Grid>
							)} */}
							<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>เลขประจำตัวผู้เสียภาษีอากร</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="cardNo"
										readOnly
										disabled
										value={confirmationInfo.organization.cardNo}
									// value={userSDOC.poa}
									/>
								</Grid>
							</Grid>

							<Grid style={{ display: 'none' }} className={`flex flex-col items-start my-2 w-full gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>ประเภทสถานประกอบการ</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="nameType"
										value={confirmationInfo.organization.nameType}
										// onChange={handleInput}
										readOnly
										disabled
									/>
								</Grid>
							</Grid>
							<Grid className="flex flex-row">
								<Grid>คำนำหน้าชื่อบริษัท</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="prefix"
									value={confirmationInfo.organization.prefix}
									// placeholder="คำนำหน้าชื่อบริษัท"
									readOnly
									disabled
								// onChange={handleInput}
								/>
								{/* <Select
									className="w-full"
									name="prefix"
									// onChange={handleInput}
									readOnly={mountType && mountType === 'readOnly'}
									value={confirmationInfo.organization.prefix}
								>
									<MenuItem value={"บริษัท"}>บริษัท</MenuItem>
									<MenuItem value={"ห้างหุ้นส่วน"}>ห้างหุ้นส่วน</MenuItem>
									<MenuItem value={"ห้างหุ้นส่วนจำกัด"}>ห้างหุ้นส่วนจำกัด</MenuItem>
									<MenuItem value={"สหกรณ์"}>สหกรณ์</MenuItem>
									<MenuItem value={"อื่นๆ"}>อื่น ๆ</MenuItem>

								</Select> */}
								{/* {confirmationInfo.organization.prefix === "อื่นๆ" && (
									<InputBase
										className="w-full"
										name="prefixother"
										value={confirmationInfo.organization.prefixother}
										placeholder="ระบุ"
										onChange={handleInput}
									/>
								)} */}

							</Grid>
							<Grid className="flex flex-row">
								<Grid>ชื่อหน่วยงาน / ชื่อผู้ประกอบการ</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="name"
									// onChange={handleInput}
									readOnly
									disabled
									value={confirmationInfo.organization.name}

								/>
							</Grid>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>รหัสสาขา</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="branchNo"
									inputProps={{ maxLength: 5 }}
									readOnly={(mountType && mountType === 'readOnly')}
									placeholder="กรุณากรอกรหัสสาขา เช่น สำนักงานใหญ่ ให้กรอก 00000 หรือ สาขาอื่น ๆ เช่น 43423"
									onChange={handleInput}
									value={confirmationInfo.organization.branchNo}
								/>
							</Grid>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>ที่อยู่</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="address"
									value={confirmationInfo.organization.address}
									// onChange={handleInput}
									readOnly
									disabled
								/>
							</Grid>
						</Grid>
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>Email</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="email"
									// onChange={handleInput}
									readOnly
									disabled
									value={confirmationInfo.organization.email}
								/>
							</Grid>
						</Grid>

						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>เบอร์โทรศัพท์</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="mobile"
									// onChange={handleInput}
									readOnly
									disabled
									value={confirmationInfo.organization.mobile}
								/>
							</Grid>
						</Grid>

					</>
				)}

				{/* Card Number */}

				{/* Address */}
			</Grid>
		</Card>
	)
}
