import Step1 from './step-1'
import Step2 from './step-2'
// import Step3 from './step-3'

export default function getStepContent(
	step,
	rowData,
	// Standard Report State
	reportTypes,
	currentFilledFormIndex,
	// Standard Report Answers
	fixedGeneralAnswers,
	fixedInfoAnswers,
	fixedSoundAnswers,
	mobileGeneralAnswers,
	mobileInfoAnswers,
	mobileSoundAnswers,
	dataSetFilesUpload,
	// Validation
	currentSelectedError,
	openValidationModal,
	onOpenValidationModal,
	onCloseValidationModal,
	// Document Status ID
	documentStatusID
) {
	switch (step) {
		case 0:
			return <Step1 rowData={rowData} dataSetFilesUpload={dataSetFilesUpload} />
		case 1:
			return (
				<Step2
					list={reportTypes}
					currentFilledFormIndex={currentFilledFormIndex}
					fixedGeneralAnswers={fixedGeneralAnswers}
					fixedInfoAnswers={fixedInfoAnswers}
					fixedSoundAnswers={fixedSoundAnswers}
					mobileGeneralAnswers={mobileGeneralAnswers}
					mobileInfoAnswers={mobileInfoAnswers}
					mobileSoundAnswers={mobileSoundAnswers}
					// Validation
					currentSelectedError={currentSelectedError}
					openValidationModal={openValidationModal}
					onOpenValidationModal={onOpenValidationModal}
					onCloseValidationModal={onCloseValidationModal}
					// Document Status ID
					documentStatusID={documentStatusID}
				/>
			)
		default:
			return 'Unknown step'
	}
}
