import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'
import Step4 from './step-4'

export default function StepContent({
	// Main State
	activeStep: step,
	hasMobile,
	// Standard Report Form Handler State
	flags,
	needToFillFormType,
	filledFormAmount,
	currentFilledFormIndex,
	onFillForm,
	// Standard Report Answers
	fixedGeneralAnswers,
	fixedInfoAnswers,
	fixedSoundAnswers,
	mobileGeneralAnswers,
	mobileInfoAnswers,
	mobileSoundAnswers,
	// Step 1
	userOperatorProfile,
	reportMethod,
	onChangeRadio,
	fixedReportTypeRadioValue,
	mobileReportTypeRadioValue,
	onChangeReportType,
	hasEverSubmitedForm,
	isNewForm,
	onChangeIsContinueRadio,
	continueFormSubmittedType,
	// Step 2 Upload File
	excelFile,
	setExcelFile,
	excelFileShow,
	setExcelFileShow,
	// Step 3 Additional Files
	mobileFiles,
	onInputAdditionalFile,
	onDeleteAdditionalFile,
	// Step 4 Validation
	invalidDataSetRecords,
	invalidAnswers,
	onInputValidationReason,
	invalidInputMethod,
	onChangeInvalidaInputMethod,
	openAskingValidationModal,
	onOpenAskingValidationModal,
	onCloseAskingValidationModal,
	onAskInvalidInputMethod,
	currentValiationQuestionNumber,
	openFillingModal,
	currentSelectedItem,
	onCloseFillingModal,
}) {
	return (
		<>
			{step === 0 && (
				<Step1
					fixedReportTypeRadioValue={fixedReportTypeRadioValue}
					mobileReportTypeRadioValue={mobileReportTypeRadioValue}
					onChangeReportType={onChangeReportType}
					reportMethod={reportMethod}
					onChangeRadio={onChangeRadio}
					userOperatorProfile={userOperatorProfile}
					hasEverSubmitedForm={hasEverSubmitedForm}
					isNewForm={isNewForm}
					onChangeIsContinueRadio={onChangeIsContinueRadio}
					continueFormSubmittedType={continueFormSubmittedType}
				/>
			)}

			{step === 1 && (
				<Step2
					flags={flags}
					needToFillFormType={needToFillFormType}
					filledFormAmount={filledFormAmount}
					currentFilledFormIndex={currentFilledFormIndex}
					onFillForm={onFillForm}
					fixedGeneralAnswers={fixedGeneralAnswers}
					fixedInfoAnswers={fixedInfoAnswers}
					fixedSoundAnswers={fixedSoundAnswers}
					mobileGeneralAnswers={mobileGeneralAnswers}
					mobileInfoAnswers={mobileInfoAnswers}
					mobileSoundAnswers={mobileSoundAnswers}
					reportMethod={reportMethod}
					// Upload File
					excelFile={excelFile}
					setExcelFile={setExcelFile}
					excelFileShow={excelFileShow}
					setExcelFileShow={setExcelFileShow}
				/>
			)}

			{hasMobile && step === 2 && (
				<Step3
					mobileFiles={mobileFiles}
					onInputAdditionalFile={onInputAdditionalFile}
					onDeleteAdditionalFile={onDeleteAdditionalFile}
				/>
			)}

			{((step === 3 && hasMobile) || (step === 2 && !hasMobile)) && (
				<Step4
					formType={needToFillFormType}
					invalidDataSetRecords={invalidDataSetRecords}
					invalidAnswers={invalidAnswers}
					onInputValidationReason={onInputValidationReason}
					invalidInputMethod={invalidInputMethod}
					onChangeInvalidaInputMethod={onChangeInvalidaInputMethod}
					openAskingValidationModal={openAskingValidationModal}
					onOpenAskingValidationModal={onOpenAskingValidationModal}
					onAskInvalidInputMethod={onAskInvalidInputMethod}
					currentValiationQuestionNumber={currentValiationQuestionNumber}
					openFillingModal={openFillingModal}
					onCloseAskingValidationModal={onCloseAskingValidationModal}
					currentSelectedItem={currentSelectedItem}
					onCloseFillingModal={onCloseFillingModal}
				/>
			)}
		</>
	)
}
