import { useState, useEffect, useMemo, useCallback } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';

import OrganizationDetailCard from '@components/sdoc/organization-detail-card'
import OrganizationDetailCardV2 from '@components/sdoc/organization-detail-cardV2'
import OrganizationDetailCardV3 from '@components/sdoc/organization-detail-cardV3'
import ProfileCardV2 from 'components/sdoc/profile-cardV2/index';
import BrandNameCard from '@components/sdoc/brand-name-card'
import EquipmentCard from 'components/sdoc/equipment-card/index'
import DocumentAdditionCard from 'components/sdoc/document-addition-card/index'
import ApprovalCard from 'components/sdoc/approval-card/index'
import SDoCLogsCard from 'components/sdoc/sdoclogs-card/index'
import SDoCDetailCard from 'components/sdoc/sdoc-detail-card/index'
import SureModal from '@components/SureModal'
import SuccessModal from '@components/modal/SuccessModal'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import { sdoc, sdocAdmin, admin, operators, sdocUser } from '@api'
import { useQuery } from 'utils/useQuery'
import { useStoreRehydrated } from '@store'

import { frequency, powerUnit, type_equipment } from 'data/sdoc'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'

const initEquipmentState = {
	ProductTypeText: '',
	FrequencyText: '',
	PowerText: '',
	PolicyText: '',
	SelectedPowerUnit: '',
	PolicyText_2: '',
	PolicyText_3: '',
	Flag: '',
	isError: false,
}


const EQUIPMENT_INITIAL_STATE = {
	ProductTypeText: '',
	FrequencyText: '',
	PowerText: '',
	PolicyText: '',
	SelectedPowerUnit: '',
	PolicyText_2: '',
	PolicyText_3: '',
	Flag: '',
	isError: false,
}

const CONFIRMATION_INFO_STORAGE_INITIAL_STATE = {
	normal_person: {
		type: 'normal-person',
		name: '',
		cardNo: '',
		address: '',
	},
	organization: {
		type: 'organization',
		name: '',
		cardNo: '',
		address: '',
	},
}

// Page Description: ยื่นแบบหนังสือรับรองตนเอง
export default function SDOC() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const location = useLocation()
	const query = useQuery()

	const { id: sDOCID } = useParams()
	const [mountType, setmountType] = useState('')

	const ApprovalControl = query.get('ApprovalControl')
	const [currentSenderState, setCurrentSenderState] = useState('normal_person')
	// SDOC Id
	// eslint-disable-next-line
	const [sDOCId, setSDOCId] = useState(null)
	// Confirmation Info. Status
	// eslint-disable-next-line
	const [confirmationInfoHasFetched, setConfirmationInfoHasFetched] = useState({
		normal_person: false,
		organization: false,
	})
	// eslint-disable-next-line
	const [confirmationInfoStorage, setConfirmationInfoStorage] = useState(CONFIRMATION_INFO_STORAGE_INITIAL_STATE)
	// Confirmation Info.
	const [confirmationInfo, setConfirmationInfo] = useState({
		type: 'normal-person',
		name: '',
		cardNo: '',
		address: '',
	})

	const [equipmentMasterData, setEquipmentMasterData] = useState({
		productType: [],
		typeEquipment: [],
		frequency: [],
		powerUnit: [],
	})
	const [inputModel, setInputModel] = useState({
		brandName: '',
		productModel: '',
	})
	// Input Equipment State
	const [inputEquipmentState, setInputEquipmentState] = useState([
		EQUIPMENT_INITIAL_STATE,
	])
	// Additional Files
	const [tempFile, setTempFile] = useState({
		file: null,
		name: '',
		// filedate: '',
	})
	const [additionalFiles, setAdditionalFiles] = useState([])
	// Modal States
	const [submitEquipmentSureModal, setSubmitEquipmentSureModal] =
		useState(false)
	const [openSubmitEquipmentSuccessModal, setOpenSubmitEquipmentSuccessModal] =
		useState(false)
	const [submitTextModal, setSubmitTextModal] = useState({
		title: '',
		content: '',
		handleButton: null,
	})
	const [isSubmitting, setIsSubmitting] = useState(false)
	// SDOC Status
	const [sDOCStatus, setSDOCStatus] = useState('')
	// Running NO / SDoC NO
	const [runningNo, setRunningNo] = useState('')
	const [sDoCNo, setSDoCNo] = useState('')
	// AdminRole
	const [myRole, setMyRole] = useState('')
	// Sure Modal For Update SDOC Status
	const [openSubmitSDocSureModal, setOpenSubmitSDocSureModal] = useState(false)
	const [submitType, setSubmitType] = useState('approve')
	const [remark, setRemark] = useState('')
	const [currentActionSDoc, setCurrentActionSDoc] = useState('')

	const [openModalAssignAdmin, setOpenModalAssignAdmin] = useState(false)
	const [openModalAssignSignature, setOpenModalAssignSignature] =
		useState(false)
	const [isRejectable, setIsRejectable] = useState(true)
	// for editing profile
	const [isEditingSDoC, setIsEditingSDoC] = useState(false)
	const [AddressList, setAddressList] = useState('')

	const getAddressList = async () => {
		const res = await sdocUser.getAddressList()
		if (res && res?.status === 200) {
			const data = res.data
			setAddressList(data)
		}
	}

	const sureModalTitle = useMemo(
		() =>
			submitType === 'approve'
				? 'อนุมัติแบบหนังสือรับรองตนเอง'
				: 'ไม่อนุมัติแบบหนังสือรับรองตนเอง',
		[submitType]
	)
	const contentSureModal = useMemo(
		() => {

			if (submitType === 'approve') {
				return <div className="text-center">ยืนยันอนุมัติแบบหนังสือรับรองตนเอง</div>
			} else if (submitType === 'reject') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="text"
					label="เหตุผลที่ไม่อนุมัติ"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'requestAdditionDoc') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="textarea"
					label="เอกสารที่ขอเพิ่มเติม"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'requestModifyInfo') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="textarea"
					label="ขอปรับปรุงข้อมูล"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'rejectchecker') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="text"
					label="เหตุผลที่ไม่อนุมัติ และส่งกลับไปยังผู้ตรวจสอบ"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			}
		},
		[submitType, remark]
	)

	// const onDeny = useCallback(
	// 	async sDocID => {
	// 		const res = await sdocAdmin.denySDoc(sDocID, remark)
	// 		if (res && res.status === 200) {
	// 			// getListAndPrefield()
	// 			setOpenSubmitSDocSureModal(false)
	// 			Swal.fire({
	// 				title: 'ปฏิเสธคำขอสำเร็จ',
	// 				text: '',
	// 				icon: 'success',
	// 				confirmButtonText: 'ปิด'
	// 			})
	// 		}
	// 	},
	// 	// [getListAndPrefield, remark]
	// )

	// const sendToIssue = useCallback(
	// 	async sDocID => {
	// 		const res = await sdocAdmin.sendToIssue(sDocID)
	// 		if (res && res.status === 200) {
	// 			// getListAndPrefield()
	// 			setOpenSubmitSDocSureModal(false)
	// 			Swal.fire({
	// 				title: 'อนุมัติคำขอสำเร็จ',
	// 				text: '',
	// 				icon: 'success',
	// 				confirmButtonText: 'ปิด'
	// 			})
	// 		}
	// 	},
	// 	// [getListAndPrefield]
	// )

	// const onSubmitApproval = useCallback(() => {
	// 	if (submitType === 'approve') {
	// 		sendToIssue(currentActionSDoc)
	// 	} else if (submitType === 'reject') {
	// 		onDeny(currentActionSDoc)
	// 	}
	// }, [onDeny, sendToIssue, currentActionSDoc, submitType])

	const sendToIssue = async sDocID => {
		const res = await sdocAdmin.sendToIssue(sDocID)
		if (res && res.status === 200) {
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'อนุมัติคำขอสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			getListAndPrefield()
		}
	}

	const onDeny = async sDocID => {
		const res = await sdocAdmin.denySDoc(sDocID, remark)
		if (res && res.status === 200) {
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'ปฏิเสธคำขอสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			getListAndPrefield()
		}
	}

	const onSubmitApproval = useCallback(() => {
		// Prevent multiple submit
		if (isSubmitting) return
		setIsSubmitting(true)

		if (submitType === 'approve') {
			sendToIssue(currentActionSDoc)
		} else if (submitType === 'reject') {
			onDeny(currentActionSDoc)
		} else if (submitType === 'requestAdditionDoc') {
			onRequestAddition(currentActionSDoc);
		} else if (submitType === 'requestModifyInfo') {
			onRequestModifyInfo(currentActionSDoc);
		} else if (submitType === 'rejectchecker') {
			onDenyChecker(currentActionSDoc)
		}
		setIsSubmitting(false)
	}, [onDeny, sendToIssue, currentActionSDoc, submitType])

	const onRequestAddition = async sDocID => {
		const res = await sdocAdmin.requestAddition(sDocID, remark)
		if (res && res.status === 200) {
			// getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false);
			Swal.fire({
				title: 'ร้องขอเอกสารเพิ่มเติมสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			getListAndPrefield()
		}
	}

	const onRequestModifyInfo = async sDocID => {
		const res = await sdocAdmin.requestModifyInfo(sDocID, remark)
		if (res && res.status === 200) {
			// getSDOCWaitStatusList()
			// setOpenSubmitSDocSureModal(false);
			// setRemark('');
			setOpenSubmitSDocSureModal(false);
			Swal.fire({
				title: 'ร้องขอเอกสารให้แก้ไขคำขอสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			getListAndPrefield()
		}
	}

	const onDenyChecker = useCallback(
		async sDocID => {
			const res = await sdocAdmin.denyCheckerSDoc(sDocID, remark)
			if (res && res.status === 200) {
				// getSDOCWaitStatusList()
				// setOpenSubmitSDocSureModal(false)
				// setRemark('');
				setOpenSubmitSDocSureModal(false);
				Swal.fire({
					title: 'ปฏิเสธการลงนาม',
					text: '',
					icon: 'success',
					confirmButtonText: 'ปิด'
				})
				getListAndPrefield()
			}
		},
	)

	const onRejectJob = async sDocID => {
		const res = await sdocAdmin.rejectJob(sDocID)
		if (res && res.status === 200) {
			// getSDOCWaitStatusList()
			Swal.fire({
				title: 'ปฏิเสธงานสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			getListAndPrefield()
		}
	}

	// Validation States

	// eslint-disable-next-line
	const [canSubmit, setCanSubmit] = useState(false)
	const [isEquipmentCardValid, setIsEquipmentCardValid] = useState(false)
	const [attachFileLength, setAttachFileLength] = useState(0)
	const [EjectCredential, setEjectCredential] = useState('')
	const [requestAddiotionFile, setRequestAddiotionFile] = useState('')
	const [requestModifyInfo, setRequestModifyInfo] = useState('')
	const [ejectRemark, setEjectRemark] = useState('')
	const [SDoCLogs, setSDoCLogs] = useState([])

	/** <---------------------------------------------------------------------> */

	const onOpenSubmitEquipmentSureModal = () => {
		console.log('onOpenSubmitEquipmentSureModal')
		if (mountType === 'attachFile')
			setSubmitTextModal({
				title: 'แนบเอกสารเพิ่มเติม',
				content:
					'คุณแน่ใจที่จะแนบเอกสารเพิิ่มเติมสำหรับยื่นแบบหนังสือรับรองตนเองหรือไม่?​',
				handleButton: () => onSubmitAddtionalFiles(sDOCID),
			})
		else
			setSubmitTextModal({
				title: 'บันทึกข้อมูลหนังสือรับรองตนเอง',
				// content: 'คุณแน่ใจที่จะยื่นแบบหนังสือรับรองตนเอง​หรือไม่?',
				content: 'คุณต้องการบันทึกข้อมูลหนังสือรับรองตนเองที่คุณแก้ไขแล้วหรือไม่?',
				handleButton: onSubmitEquipment,
			})
		setSubmitEquipmentSureModal(!submitEquipmentSureModal)
	}

	const onOpenSubmitEquipmentSuccessModal = () =>
		setOpenSubmitEquipmentSuccessModal(!openSubmitEquipmentSuccessModal)

	const disabledRuleSubmitButton = () => {
		const isCardError = inputEquipmentState.some(s => s.isError)
		if (isCardError) {
			setCanSubmit(true)
		} else if (mountType === 'attachFile') {
			setCanSubmit(false)
		} else if (isEquipmentCardValid && (mountType && mountType == "ModifyInfo") && (confirmationInfoStorage[currentSenderState].address)) {
			setCanSubmit(false)
		} else {
			setCanSubmit(true)
		}
		console.log(canSubmit)
	}

	const onEquipmentCardError = (errIdx, isError) => {
		setInputEquipmentState(prevState => {
			const newList = prevState.map((item, prevIndex) => {
				if (prevIndex === errIdx) {
					item.isError = isError
				}
				return item
			})
			return newList
		})
	}

	// case: mountType is 'readOnly'
	const getListAndPrefield = async () => {
		const res = await sdoc.getListDetail(sDOCID)
		console.log(res.data)
		if (res && res.status === 200) {
			const findedList = res.data
			// console.log(findedList);
			// หากขอเอกสารเพิ่มเติมและมีการแนบหมายเหตุ จะแสดงในหน้าเจ้าหน้าที่ด้วย
			if (findedList.sDocStatusID === 2) {
				if (findedList.requestAddtionDocStatusID === 1 && findedList.requestAdditionFileRemark !== null) {
					setRequestAddiotionFile(findedList.requestAdditionFileRemark);
				}
				if (findedList.requestAddtionDocStatusID === 2 && findedList.requestModifyInfoRemark !== null) {
					setRequestModifyInfo(findedList.requestModifyInfoRemark);
				}
				if (findedList.requestAddtionDocStatusID === 3 && findedList.ejectCredentialRemark !== null) {
					setEjectCredential(findedList.ejectCredentialRemark);

				}
				if (findedList.sDocTransactions && findedList.sDocTransactions?.invoiceNo !== null) {
					setIsRejectable(false);
				}

			}

			if (findedList.sDocStatusID === 6) {
				setEjectRemark(findedList.remark);
			}

			// console.log(data);
			if (findedList.senderType === 'organization') {
				const res2 = await operators.getOperatorInfo({ taxno: findedList.operatorTaxNo, branchno: findedList.operatorBranchNo });
				if (res2 && res2.status === 200 && res2?.data.status !== 'error') {
					var data = res2.data
				}
				console.log(res2)
				setCurrentSenderState('organization')
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						organization: {
							type: 'organization',
							name: data.operators.operatorName ?? '',
							cardNo: data.operators.taxNo ?? '',
							prefix: data.operators.prefix ?? '',
							branchNo: data.operatorInfo.branchNo,
							address: data.operatorInfo.addr ?? '',
							email: data.operatorInfo.email ?? '',
							mobile: data.operatorInfo.mobile ?? '',

						},
					}
				})
			} else if (findedList.senderType === 'normal-person') {
				const res3 = await operators.getOperatorUserInfo({ cardNo: findedList.operatorTaxNo })
				if (res3 && res3.status === 200 && res3?.data.status !== 'error') {
					var data2 = res3.data
				}
				setCurrentSenderState('normal_person')
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						normal_person: {
							type: 'normal-person',
							// name: findedList.operatorTitle ?? '',
							name: (data2?.operatorUser?.prefix ?? '') + data2?.operatorUser?.firstname + ' ' + data2?.operatorUser?.lastname,
							cardNo: findedList.operatorTaxNo ?? '',
							branchNo: findedList.operatorBranchNo ?? '00000',
							address: data2?.operatorUser?.address ?? '',
							email: data2?.operatorUser?.email ?? '',
							mobile: data2?.operatorUser?.mobile ?? '',
						},
					}
				})
			}

			if (findedList) {
				const productModel = findedList.productModel
				const productDescription = findedList.productDescription
				const brandName = findedList.brandName
				const sDocItems = findedList.sDocItems
				const sDocAttachs = findedList.sDocAttachs
				const sDocStatusID = findedList.sDocStatusID

				setInputModel({
					brandName: brandName ?? '',
					productModel: productModel ?? '',
					productDescription: productDescription ?? '',
				})

				if (findedList.sDocLogs) {
					setSDoCLogs(findedList.sDocLogs)
				}

				const newList = sDocItems.map(sdocItem => {
					const regexpSize = /^(\S+)\s(.+)/
					const match = sdocItem.powerText.match(regexpSize)

					const obj = {
						ProductTypeText: sdocItem.productTypeText,
						FrequencyText: sdocItem.frequencyText,
						PolicyText: sdocItem.policyText,
						PowerText: match ? match[1] : '',
						SelectedPowerUnit: match ? match[2] : '',
						isError: false,
						PolicyText_2: sdocItem.policyText_2,
						PolicyText_3: sdocItem.policyText_3,
						Flag: sdocItem.flag,
					}

					return obj
				})

				setInputEquipmentState(newList)

				setAttachFileLength(sDocAttachs.length)
				setAdditionalFiles(() => {
					const newList = sDocAttachs.map(sdocAttach => {
						return {
							fileID: sdocAttach.id,
							file: sdocAttach.fileName,
							name: sdocAttach.title,
							isfilelocked: sdocAttach.isFileLocked ?? false,
							// filedate: sdocAttach.fileDate,
						}
					})
					return newList
				})
				setSDOCStatus(sDocStatusID)

				setRunningNo(findedList.runningNo)
				setSDoCNo(findedList.sDocNo?.length == 0 ? 'ยังไม่มีเลขที่ SDoC NO.' : findedList.sDocNo)
			}
		}
	}

	/** <---------------------------------------------------------------------> */

	// Get Admin Profile
	const getAdminProfile = async () => {
		await admin.me().then(async event => {
			if (event) {
				const dataMe = event.data
				setMyRole(dataMe.roleID)
			}
		})
	}

	/** <---------------------------------------------------------------------> */
	// Organization Detail Function

	const onChangeConfirmationInfo = e => {
		const { name, value } = e.target
		setConfirmationInfo({ ...confirmationInfo, [name]: value })
	}

	/** <---------------------------------------------------------------------> */

	// Equipment Card

	const onChangeInputModel = e => {
		const { name, value } = e.target
		setInputModel({ ...inputModel, [name]: value })
	}

	const onChangeInputEquipmentState = (name, value, key) => {
		setInputEquipmentState(prevState => {
			const newState = [...prevState]

			if (name === 'ProductTypeText')
				newState[key] = {
					...prevState[key],
					ProductTypeText: value,
					FrequencyText: '',
					PolicyText: '',
					PolicyText_2: '',
					PolicyText_3: '',
					Flag: '',
				}
			else if (name === 'FrequencyText')
				newState[key] = {
					...prevState[key],
					FrequencyText: value,
					PolicyText: '',
					PolicyText_2: '',
					PolicyText_3: '',
					Flag: '',
				}
			else newState[key] = { ...prevState[key], [name]: value }

			return newState
		})
	}

	const onFetchMasterdata = async () => {
		setEquipmentMasterData({
			productType: [],
			typeEquipment: type_equipment,
			frequency: frequency,
			powerUnit: powerUnit,
		})
		// const res = await something
	}
	// eslint-disable-next-line
	const addMoreItem = () => {
		setInputEquipmentState(prevState => [...prevState, EQUIPMENT_INITIAL_STATE])
	}

	const onDeleteItem = index => {
		if (inputEquipmentState.length === 1) return
		setInputEquipmentState(prevState => {
			const newList = prevState.filter((item, prevIndex) => prevIndex !== index)
			return newList
		})
	}

	const onSubmitEquipment = async () => {
		const items = inputEquipmentState.map(item => {
			return {
				PolicyText: item.PolicyText,
				FrequencyText: item.FrequencyText,
				PowerText: item.PowerText + ' ' + item.SelectedPowerUnit,
				PolicyText_2: item.PolicyText_2,
				PolicyText_3: item.PolicyText_3,
				Flag: item.Flag,
			}
		})
		console.log(items);

		// const res = await sdoc.addList(
		// 	inputModel.brandName,
		// 	inputModel.productModel,
		// 	null,
		// 	items
		// )
		// if (res && res.status === 200) {
		// 	setSDOCId(res.data.id)
		// 	if (additionalFiles.length !== 0) {
		// 		onSubmitAddtionalFiles(res.data.id)
		// 	} else onOpenSubmitEquipmentSuccessModal()
		// }
	}

	/** <---------------------------------------------------------------------> */

	// Document Additional Card

	const onAddTemp = (e, type) => {
		const { files, value } = e.target
		if (type === 'file' && files.length !== 0)
			setTempFile({ ...tempFile, file: files[0] })
		if (type === 'name') setTempFile({ ...tempFile, name: value })
		// if (type === 'filedate') setTempFile({ ...tempFile, filedate: value })
	}

	const onAddDocument = () => {
		if (tempFile.file.type !== 'application/pdf') {
			Swal.fire({
				icon: 'error',
				title: 'ชนิดไฟล์ไม่ถูกต้อง',
				text: 'ชนิดไฟล์ต้องเป็น PDF เท่านั้น',
			})
			return
		}
		// if (tempFile.file && tempFile.name !== '' && tempFile.filedate !== '') {
		if (tempFile.file && tempFile.name !== '') {
			setAdditionalFiles(prevState => [
				...prevState,
				// { file: tempFile.file, name: tempFile.name, filedate: tempFile.filedate },
				{ file: tempFile.file, name: tempFile.name },
			])
			// setTempFile({ file: null, name: '', filedate: '' })
			setTempFile({ file: null, name: '' })
		}
	}

	const onChangeAttachedFileName = (e, index) => {
		const { value } = e.target
		setAdditionalFiles(prevState => {
			const newList = prevState.map((item, itemIndex) => {
				if (itemIndex === index)
					return {
						file: item?.file,
						name: value,
						// filedate: item?.filedate,

					}

				return item
			})
			return newList
		})
	}

	const onDeleteDocument = index => {
		setAdditionalFiles(prevState => {
			const temp_list = prevState.filter((_, listInd) => {
				return listInd !== index
			})
			return temp_list
		})
	}

	const onSubmitAddtionalFiles = async id => {
		console.log("submit")
		// const currentAdditionalFileLength = additionalFiles.length
		// let sendedFiles = []

		// if (mountType && mountType === 'attachFile') {
		// 	const amountOfNewFiles = currentAdditionalFileLength - attachFileLength

		// 	additionalFiles.forEach((file, index) => {
		// 		if (index > currentAdditionalFileLength - amountOfNewFiles - 1)
		// 			sendedFiles.push(file)
		// 	})
		// } else sendedFiles = additionalFiles

		// const res = await sdoc.attachAdditionalFiles(id, sendedFiles)
		// if (res && res.status === 200) {
		// 	onOpenSubmitEquipmentSuccessModal()
		// }
	}

	const onDownloadDocument = async () => {
		const res = await sdoc.downloadAdditionalFile(21, '11')
		if (res && res.status === 200) {
		}
	}

	const onClickEditingSDoC = () => {

		setIsEditingSDoC(true)
		setmountType('ModifyInfo')
	}

	const onChangeIsFileLocked = async (fileID, index) => {
		console.log(additionalFiles[index].isfilelocked)
		const res = await sdocAdmin.changeFileLockStatus(fileID)
		if (res && res.status === 200) {
			setAdditionalFiles(prevState => {
				const newList = prevState.map((item, itemIndex) => {
					if (itemIndex === index)
						return {
							...item,
							isfilelocked: !item.isfilelocked,
						}
					return item
				})
				return newList
			})
		}
	}

	const onDeletedUploadedDocument = index => {
		setAdditionalFiles(prevState => {
			const temp_list = prevState.filter((_, listInd) => {
				return listInd !== index
			})
			setAttachFileLength(attachFileLength - 1)
			return temp_list
		})
	}
	
	const onDeleteUploadedDocument = async (id, index) => {
		Swal.fire({
			title: 'คุณต้องการลบไฟล์นี้ใช่หรือไม่?',
			text: 'คุณจะไม่สามารถกู้คืนไฟล์นี้ได้หากลบไปแล้ว',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'ใช่, ลบไฟล์นี้',
			cancelButtonText: 'ยกเลิก',
		}).then(async result => {
			if (result.value) {
				const res = await sdoc.deleteAdditionalFile(sDOCID, id)
				if (res && res.status === 200) {
					onDeletedUploadedDocument(index)
					Swal.fire('ลบไฟล์สำเร็จ!', 'ไฟล์ของคุณถูกลบแล้ว', 'success')
				}
			}
		})
	}

	/** <---------------------------------------------------------------------> */

	// Trigger when change the confirmation type
	// useEffect(() => {
	// 	if (confirmationInfo.type === 'normal-person') {
	// 		if (confirmationInfoHasFetched.normal_person) {
	// 			setConfirmationInfo(prevState => {
	// 				return {
	// 					...prevState,
	// 					type: confirmationInfoStorage.normal_person.type,
	// 					name: confirmationInfoStorage.normal_person.name,
	// 					cardNo: confirmationInfoStorage.normal_person.nationalIDCardNo,
	// 					address: confirmationInfoStorage.normal_person.address,
	// 				}
	// 			})
	// 		} else getUserProfile()
	// 	} else if (confirmationInfo.type === 'organization') {
	// 		if (confirmationInfoHasFetched.organization) {
	// 			setConfirmationInfo(prevState => {
	// 				return {
	// 					...prevState,
	// 					type: confirmationInfoStorage.organization.type,
	// 					name: confirmationInfoStorage.organization.name,
	// 					cardNo: confirmationInfoStorage.organization.nationalIDCardNo,
	// 					address: confirmationInfoStorage.organization.address,
	// 				}
	// 			})
	// 		} else getOperatorProfile()
	// 	}
	// 	// eslint-disable-next-line
	// }, [confirmationInfo.type])

	// // Equipment Validation
	// useEffect(() => {
	// 	const isValid = inputEquipmentState.every(
	// 		item =>
	// 			item.FrequencyText !== '' &&
	// 			// (item.PolicyText !== '' || item.PolicyText_2 !== '' || item.PolicyText_3 !== '') &&
	// 			item.PowerText !== '' &&
	// 			item.SelectedPowerUnit !== ''
	// 	)
	// 	setIsEquipmentCardValid(isValid)
	// }, [inputEquipmentState])

	const eqipmentValidation = useCallback(() => {

		inputEquipmentState.forEach(item => {

			const premilinaryCondition =
				item.FrequencyText !== '' &&
				// (item.PolicyText !== '' || item.PolicyText_2 !== '' || item.PolicyText_3 !== '') &&
				item.PowerText !== '' &&
				item.SelectedPowerUnit !== '' &&
				item.ProductTypeText !== ''

			const isFilledText =
				inputModel.brandName !== '' &&
				inputModel.productModel !== '' &&
				inputModel.productDescription !== ''
			setIsEquipmentCardValid(premilinaryCondition && isFilledText)
		})
	}, [
		inputEquipmentState,
		inputModel.productDescription,
		inputModel.productModel,
		inputModel.brandName,
	])

	// Equipment Validation
	useEffect(() => {
		eqipmentValidation()
	}, [eqipmentValidation])

	// Submit Button Validation
	useEffect(
		() => disabledRuleSubmitButton(),
		// eslint-disable-next-line
		[isEquipmentCardValid, inputEquipmentState, additionalFiles, confirmationInfoStorage[currentSenderState]]
	)

	// Initial Fetch
	useEffect(() => {
		onFetchMasterdata()
		setmountType(query.get('mountType'))
		// eslint-disable-next-line
	}, [])

	// Initial Fetch from /manage
	useEffect(() => {
		if (sDOCID && mountType) {
			setSDOCId(sDOCID)
			getListAndPrefield()
		}
		// eslint-disable-next-line
	}, [sDOCID, mountType])

	// On Unmounting
	useEffect(() => {
		return () => (location.state = null)
		// eslint-disable-next-line
	}, [])

	// Load Admin Profile 
	useEffect(() => {
		if (isRehydrated) {
			getAdminProfile()
		}
		//eslint-disable-next-line
	}, [isRehydrated])

	useEffect(() => {
		if (isEditingSDoC) {
			getAddressList()
		}
	}, [isEditingSDoC])

	function makeLinksClickable(text) {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.split(urlRegex).map((part, index) => {
			if (part.match(urlRegex)) {
				// This part is a URL, convert it to a clickable link
				return (
					<a key={index} href={part} target="_blank" rel="noopener noreferrer">
						{part}
					</a>
				);
			} else {
				// This part is not a URL, render it as plain text
				return <span key={index}>{part}</span>;
			}
		});
	}

	return (
		<>
			<Grid className="p-10">
				{(EjectCredential && EjectCredential !== '') && (<Alert severity="warning" className="mb-5" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดจากผู้ออกใบรับรอง</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(EjectCredential)}</div></Alert>)}
				{(requestAddiotionFile && requestAddiotionFile !== '') && (<Alert severity="warning" className="mb-5" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดการขอเอกสารเพิ่มเติมจากเจ้าหน้าที่ ที่แจ้งไป</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(requestAddiotionFile)}</div></Alert>)}
				{(requestModifyInfo && requestModifyInfo !== '') && (<Alert severity="warning" className="mb-5" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดการขอให้ปรับปรุงข้อมูลจากเจ้าหน้าที่ ที่แจ้งไป</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(requestModifyInfo)}</div></Alert>)}
				{(ejectRemark && ejectRemark !== '') && (<Alert severity="warning" className="mb-5 w-full" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดการไม่อนุมัติคำขอจากเจ้าหน้าที่</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(ejectRemark)}</div></Alert>)}

				{/* <Grid className="flex flex-row justify-end items-end w-full gap-x-2 my-10">
					<Button
						className={`w-2/12`}
						color="primary"
						variant="contained"
						onClick={onClickEditingSDoC}
					>
						เปิดการแก้ไข
					</Button>
				</Grid> */}
				<SDoCDetailCard
					runningNo={runningNo}
					sDoCNo={sDoCNo}
				/>
				{isEditingSDoC ? (
					// <ProfileCardV2
					// 	confirmationInfo={confirmationInfoStorage}
					// 	addressList={AddressList}
					// 	onChangeUserInfo={onChangeUserInfo}
					// 	onChangeCompanyInfo={onChangeCompanyInfo}
					// 	onChangeSenderType={onChangeSenderType}
					// 	onSubmitNormalPerson={onSubmitNormalPerson}
					// 	onSubmitOrganizationInfo={onSubmitOrganizationInfo}
					// 	onSubmitOrganizationBranch={onSubmitOrganizationBranch}
					// 	senderState={currentSenderState}
					// />
					<></>

				) : (
					<OrganizationDetailCardV3
						mountType={mountType}
						confirmationInfo={confirmationInfoStorage}
						onChangeConfirmationInfo={onChangeConfirmationInfo}
						senderState={currentSenderState}
					/>
				)}
				<BrandNameCard
					mountType={mountType}
					inputModel={inputModel}
					onChangeInputModel={onChangeInputModel}
				/>

				{inputEquipmentState.map((item, index) => {
					return (
						<EquipmentCard
							mountType={mountType}
							inputEquipmentState={item}
							seq={index + 1}
							onChangeInputEquipmentState={onChangeInputEquipmentState}
							equipmentMasterData={equipmentMasterData}
							onDelete={() => onDeleteItem(index)}
							index={index}
							key={'EquipmentCard' + index}
							onError={(errIdx, isError) =>
								onEquipmentCardError(errIdx, isError)
							}
						/>
					)
				})}
				{(!mountType || (mountType && mountType === "ModifyInfo")) && (
					<Grid className="flex flex-row justify-center items-center w-full gap-x-2 mb-5">
						<Button
							onClick={addMoreItem}
							className={`w-72`}
							color="primary"
							variant="contained">
							<Grid className={`text-xs`}>เพิ่มอุปกรณ์ย่านความถี่</Grid>
						</Button>
					</Grid>
				)}
				<DocumentAdditionCard
					sDOCID={sDOCID}
					mountType={mountType}
					additionalFiles={additionalFiles}
					tempFile={tempFile}
					onAddTemp={onAddTemp}
					onAddDocument={onAddDocument}
					onDeleteDocument={onDeleteDocument}
					onChangeAttachedFileName={onChangeAttachedFileName}
					onDownloadDocument={onDownloadDocument}
					onChangeIsFileLocked={onChangeIsFileLocked}
					onDeleteUploadedDocument={onDeleteUploadedDocument}
					ApprovalControl={ApprovalControl}
					sDOCStatus={sDOCStatus}
				/>
				{mountType !== 'readOnly' && (
					<Grid className="flex flex-row justify-end items-end w-full gap-x-2 my-10">
						<Button
							className={`w-2/12`}
							color="primary"
							variant="contained"
							onClick={onOpenSubmitEquipmentSureModal}
							disabled={canSubmit || (requestAddiotionFile && additionalFiles.length === 0) || (!requestAddiotionFile && additionalFiles.length < 2)}>
							บันทึกข้อมูล
						</Button>
					</Grid>
				)}
				{(myRole === 11 || myRole === 15) &&
					(sDOCStatus === 2 && (ApprovalControl && ApprovalControl === "true")) ?
					<ApprovalCard
						sDOCID={sDOCID}
						onRequestAddition={onRequestAddition}
						setCurrentActionSDoc={setCurrentActionSDoc}
						setSubmitType={setSubmitType}
						setOpenSubmitSDocSureModal={setOpenSubmitSDocSureModal}
						onRejectJob={onRejectJob}
						isRejectable={isRejectable}
					/>
					: null}
				<SDoCLogsCard
					SDoCLogs={SDoCLogs}
				/>

			</Grid>
			<SureModal
				open={openSubmitSDocSureModal}
				title={sureModalTitle}
				content={contentSureModal}
				onClose={() => {
					setOpenSubmitSDocSureModal(false)
				}}
				handleButton={onSubmitApproval}
				handleCancel={() => {
					setOpenSubmitSDocSureModal(false)
				}}
			/>
			<SureModal
				open={submitEquipmentSureModal}
				title={submitTextModal.title}
				content={submitTextModal.content}
				handleButton={submitTextModal.handleButton}
				handleCancel={onOpenSubmitEquipmentSureModal}
				onClose={onOpenSubmitEquipmentSureModal}
			/>
			<SuccessModal
				open={openSubmitEquipmentSuccessModal}
				title="บันทึกข้อมูล"
				handleButton={() => history.go(0)}
			/>
		</>
	)
}
