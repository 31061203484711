import { forwardRef, useRef, useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	IconButton,
	Slide,
	useMediaQuery,
	Button,
	Divider,
} from '@material-ui/core'
import { Close, SendOutlined } from '@material-ui/icons'

import File from './file'
import { useTheme } from '@material-ui/core/styles'

export default function SubmitModal({
	submitDialogOpen,
	handleCloseSubmitDialog,
	handleSubmitButton,
	additionalFile,
	additionalFiles = [],
	tempFile = {
		file: null,
		name: '',
	},
	onAddTemp,
	onAddDocument,
	onDeleteDocument,
	onChangeAttachedFileName,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	useEffect(() => {
		if (submitDialogOpen) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [submitDialogOpen])

	return (
		<Dialog
			maxWidth="md"
			fullWidth
			fullScreen={fullScreen}
			onClose={handleCloseSubmitDialog}
			open={submitDialogOpen}
			TransitionComponent={Transition}>
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<SendOutlined
							style={{ fontSize: 20 }}
							className="mr-1 text-white"
						/>
						<Grid className="text-white text-lg">
							ยืนยันการส่งรายงานมาตรฐานคุณภาพไปยังสำนักงานกสทช
						</Grid>
					</Grid>
					<IconButton
						onClick={handleCloseSubmitDialog}
						style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<Grid className="flex flex-col justify-center items-start px-5">
					{/* Files Section */}
					<Grid className="flex flex-col items-start my-2 w-full gap-y-3">
						{/* Heading */}
						<Grid>เอกสารแนบใบนำส่ง</Grid>
						<Grid className="font-bold text-xs my-4">
							* อัพโหลดไฟล์ข้อมูลใน format .pdf เท่านั้น *
						</Grid>
						<Grid className="flex flex-row gap-x-3 items-end w-full">
							<Button
								variant="contained"
								component="label"
								size="small"
								className="flex w-2/12">
								เลือกไฟล์
								<input
									id="sdocFileInputButton"
									type="file"
									hidden
									accept=".pdf"
									onChange={e => {
										onAddTemp(e, 'file')
										e.currentTarget.value = ''
									}}
								/>
							</Button>
							<Grid
								className={`${
									tempFile.file ? 'text-black' : 'text-gray-400'
								} flex w-5/12`}>
								{tempFile.file ? tempFile.file?.name : 'ยังไม่ได้เลือกไฟล์'}
							</Grid>

							<Grid className="ml-10 flex w-2/12">
								<Button
									fullWidth
									variant="contained"
									size="small"
									color="primary"
									disabled={!tempFile.file}
									onClick={onAddDocument}>
									แนบไฟล์
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid className="mt-3 mb-1 w-full">
						<Divider />
					</Grid>
					{/* Uploaded File */}
					<Grid className="flex flex-col w-full mb-5">
						{additionalFile && (
							<File
								originalFileName={additionalFile.name}
								onDelete={onDeleteDocument}
							/>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-3/12" component="div">
						<Button
							fullWidth
							onClick={handleSubmitButton}
							color="secondary"
							variant="contained"
							// disabled={!additionalFile}
							>
							ส่งรายงาน
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
