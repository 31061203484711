import { forwardRef, useRef, useEffect } from 'react'
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Slide,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	TextField,
	Divider,
	FormControl,
	Select,
	MenuItem,
	CircularProgress,
} from '@material-ui/core'
import 'date-fns'

import { Close, PersonAdd, Edit } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'

export default function OrganizationUserModal({
	open,
	handleButton,
	onClose,
	userModalType,
	// Adding States
	nationalCardIDNo,
	userInfo,
	activeDate,
	expireDate,
	fixedTypePermission,
	mobileTypePermission,
	// Editing States
	editNationalCardIDNo,
	editUserInfo,
	editActiveDate,
	editExpireDate,
	editFixedTypePermission,
	editMobileTypePermission,
	// Adding Handler
	onFillNationalCardIDNo,
	onCheckFormTypePermission,
	onChangeUserRoleID,
	onChangeUserRoleIDSDoc,
	onChangeUserRoleIDCentralAdmin,
	onChangeUserprefixname,
	onSelectActiveDate,
	onSelectExpireDate,
	organizationData,
	// Editing Handler
	onChangeEditUserRoleID,
	onChangeEditUserRoleIDSDoc,
	onChangeEditUserRoleIDCentralAdmin,
	onChangeEditUserprefixname,
	onFillEditNationalCardIDNo,
	onCheckEditFormTypePermission,
	onSelectEditActiveDate,
	onSelectEditExpireDate,
	onFillAddUserInfo,
	onFillEditUserInfo,
	onChangeEditUserStatus,
	// Save Button Logic
	saveButtonAdd,
	saveButtonEdit,
	// Admin Role
	adminRoles,
	// Search Email
	onSearchEmail,
	emailValidation,
	isSearchingEmail,
	// Validation
	showPositionAndSignatureAdd,
	showPositionAndSignatureEdit,
	// Reset Admin Role
	resetAdminRole,
	// Uploading Signature Handler
	onUploadSignature,
	// Clear All User Modal State Handler
	clearAllUserModalState,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [open])

	const typeGenerator = _userModalType => {
		if (_userModalType === 'add')
			return {
				label: 'เพิ่มผู้ใช้งาน',
				icon: <PersonAdd htmlColor="#fff" className="mr-1" />,
			}
		if (_userModalType === 'edit')
			return {
				label: 'แก้ไขข้อมูลผู้ใช้งาน',
				icon: <Edit htmlColor="#fff" className="mr-1" />,
			}

		return { label: '', icon: '' }
	}

	useEffect(() => {
		return () => clearAllUserModalState()
		// eslint-disable-next-line
	}, [])

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle className="bg-card_top_border">
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center">
						{typeGenerator(userModalType).icon}
						<Typography variant="h6" className="text-white">
							{typeGenerator(userModalType).label}
						</Typography>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent className="h-full" dividers>
				<Grid className="flex flex-row justify-between items-center my-3">
					<Typography>ข้อมูลเจ้าหน้าที่</Typography>
					<Button
						size="small"
						onClick={resetAdminRole}
						color="primary"
						variant="contained">
						รีเซ็ต
					</Button>
				</Grid>
				<Grid>
					<Divider />
				</Grid>
				<Grid className="flex flex-col mt-5">
					{userModalType === 'add' && (
						<Grid className="flex flex-col lg:flex-row lg:w-2/3 items-end gap-x-8 gap-y-5 lg:gap-y-0 justify-between">
							<Grid className="w-full lg:w-9/12">
								<Grid className="flex flex-row">
									<Typography>ชื่อผู้ใช้งาน</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="username"
									value={userInfo.username}
									onChange={onFillAddUserInfo}
									onKeyPress={e => {
										if (e.key === 'Enter') onSearchEmail()
									}}
								/>
							</Grid>
							<Grid className=" lg:w-3/12 h-full ">
								<Button
									size="medium"
									onClick={onSearchEmail}
									color="primary"
									variant="contained">
									{isSearchingEmail ? (
										<CircularProgress size={25} color="#fff" />
									) : (
										'ค้นหา'
									)}
								</Button>
							</Grid>
						</Grid>
					)}
					{userModalType === 'edit' && (
						<Grid className="flex flex-col lg:flex-row items-end gap-x-8 gap-y-5 lg:gap-y-0">
							<Grid className="w-full lg:w-8/12">
								<Grid className="flex flex-row">
									<Typography>ชื่อผู้ใช้งาน</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								<TextField
									disabled
									className="w-full"
									variant="outlined"
									size="small"
									name="email"
									value={editUserInfo.username}
									onChange={onFillEditUserInfo}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
				<hr className="mt-5 mb-5" />
				{userModalType === 'add' && !emailValidation ? (
					<></>
				) : (
					<>
						<Grid container spacing={2}>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>ชื่อ</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								{userModalType === 'add' && (
									<TextField
										disabled
										className="w-full"
										variant="outlined"
										size="small"
										name="firstname"
										value={userInfo.firstname}
									/>
								)}
								{userModalType === 'edit' && (
									<TextField
										disabled
										className="w-full"
										variant="outlined"
										size="small"
										name="firstname"
										value={editUserInfo.firstname}
									/>
								)}
							</Grid>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>นามสกุล</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								{userModalType === 'add' && (
									<TextField
										disabled
										className="w-full"
										variant="outlined"
										size="small"
										name="lastname"
										value={userInfo.lastname}
									/>
								)}
								{userModalType === 'edit' && (
									<TextField
										disabled
										className="w-full"
										variant="outlined"
										size="small"
										name="lastname"
										value={editUserInfo.lastname}
									/>
								)}
							</Grid>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>เบอร์โทรศัพท์</Typography>
								</Grid>
								{userModalType === 'add' && (
									<TextField
										onChange={onFillAddUserInfo}
										className="w-full"
										variant="outlined"
										size="small"
										name="mobile"
										value={userInfo.mobile}
									/>
								)}
								{userModalType === 'edit' && (
									<TextField
										onChange={onFillEditUserInfo}
										className="w-full"
										variant="outlined"
										size="small"
										name="mobile"
										value={editUserInfo.mobile}
									/>
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>E-Mail</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								{userModalType === 'add' && (
									<TextField
										onChange={onFillAddUserInfo}
										className="w-full"
										variant="outlined"
										size="small"
										name="email"
										value={userInfo.email}
									/>
								)}
								{userModalType === 'edit' && (
									<TextField
										onChange={onFillAddUserInfo}
										className="w-full"
										variant="outlined"
										size="small"
										name="email"
										value={editUserInfo.email}
									/>
								)}
							</Grid>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>คำนำหน้าชื่อ</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										value={
											userModalType === 'add'
												? userInfo.prefixname
												: editUserInfo.prefixname
										}
										onChange={
											userModalType === 'add'
												? onChangeUserprefixname
												: onChangeEditUserprefixname
										}>
										<MenuItem value={'ไม่ระบุ'}>ไม่ระบุ</MenuItem>
										<MenuItem value={'นาย'}>นาย</MenuItem>
										<MenuItem value={'นาง'}>นาง</MenuItem>
										<MenuItem value={'นางสาว'}>นางสาว</MenuItem>
										<MenuItem value={'คุณ'}>คุณ</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<hr className="mt-5 mb-5" />
						<Grid container spacing={2}>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>สิทธิ์การใช้งานส่วนแอดมินกลาง</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								<Grid className="w-full py-2 mx-auto">
									<FormControl variant="outlined" fullWidth size="small">
										<Select
											value={
												userModalType === 'add'
													? userInfo.adminRoleIDCentralAdmin
													: editUserInfo.adminRoleIDCentralAdmin
											}
											onChange={
												userModalType === 'add'
													? onChangeUserRoleIDCentralAdmin
													: onChangeEditUserRoleIDCentralAdmin
											}>
											<MenuItem value={'no-permission'}>ไม่มีสิทธิ์</MenuItem>
											{Object.values(adminRoles).map((item, index) => {
												if (item.dataportalServiceID === 3)
													return (
														<MenuItem key={index} value={item.value}>
															{item.name}
														</MenuItem>
													)

												return null
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>

							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>สิทธิ์การใช้งานระบบ QoS</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								<Grid className="w-full py-2 mx-auto">
									<FormControl variant="outlined" fullWidth size="small">
										<Select
											value={
												userModalType === 'add'
													? userInfo.adminRoleID
													: editUserInfo.adminRoleID
											}
											onChange={
												userModalType === 'add'
													? onChangeUserRoleID
													: onChangeEditUserRoleID
											}>
											<MenuItem value={'no-permission'}>ไม่มีสิทธิ์</MenuItem>
											{Object.values(adminRoles).map((item, index) => {
												if (item.dataportalServiceID === 1)
													return (
														<MenuItem key={index} value={item.value}>
															{item.name}
														</MenuItem>
													)

												return null
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item sm={4}>
								<Grid className="flex flex-row">
									<Typography>สิทธิ์การใช้งานระบบ SDoc</Typography>
									<Typography className="text-red-500">*</Typography>
								</Grid>
								<Grid className="w-full py-2 mx-auto">
									<FormControl variant="outlined" fullWidth size="small">
										<Select
											value={
												userModalType === 'add'
													? userInfo.adminRoleIDSDoc
													: editUserInfo.adminRoleIDSDoc
											}
											onChange={
												userModalType === 'add'
													? onChangeUserRoleIDSDoc
													: onChangeEditUserRoleIDSDoc
											}>
											<MenuItem value={'no-permission'}>ไม่มีสิทธิ์</MenuItem>
											{Object.values(adminRoles).map((item, index) => {
												if (item.dataportalServiceID === 2)
													return (
														<MenuItem key={index} value={item.value}>
															{item.name}
														</MenuItem>
													)

												return null
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>

						{userModalType === 'add' && showPositionAndSignatureAdd && (
							<>
								<Grid className="flex flex-col lg:flex-row items-center justify-between  gap-x-8 gap-y-5 lg:gap-y-0 mt-5">
									<Grid className="w-full">
										<Grid className="flex flex-row">
											<Typography>ตำแหน่ง</Typography>
											<Typography className="text-red-500">*</Typography>
										</Grid>
										<TextField
											className="w-full"
											variant="outlined"
											size="small"
											name="position"
											value={userInfo.position}
											onChange={onFillAddUserInfo}
										/>
									</Grid>



									<Grid className="w-full h-full flex flex-col gap-y-3">
										<Grid className="flex flex-row">
											<Typography>ลายเซ็น (.PNG เท่านั้น)</Typography>
											<Typography className="text-red-500">*</Typography>
										</Grid>
										<Grid className="flex flex-row gap-x-3 items-center">
											<Button
												size="medium"
												color="primary"
												variant="contained"
												onClick={() =>
													document
														.getElementById('add-signature-button')
														.click()
												}>
												อัพโหลดลายเซ็น
												<input
													id="add-signature-button"
													type="file"
													hidden
													accept=".png"
													onChange={e => onUploadSignature(e, 'add')}
												/>
											</Button>
											<Typography
												className={`${userInfo.signature ? 'text-black' : 'text-gray-400'
													}`}>
												{userInfo.signature
													? userInfo.signature?.name
													: 'ยังไม่เลือกไฟล์'}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}

						{userModalType === 'edit' && showPositionAndSignatureEdit && (
							<>
								<Grid className="flex flex-col lg:flex-row lg:w-2/3 items-center justify-between  gap-x-8 gap-y-5 lg:gap-y-0 mt-5">
									<Grid className="w-full lg:w-3/5">
										<Grid className="flex flex-row">
											<Typography>ตำแหน่ง</Typography>
											<Typography className="text-red-500">*</Typography>
										</Grid>
										<TextField
											className="w-full"
											variant="outlined"
											size="small"
											name="position"
											value={editUserInfo.position}
											onChange={onFillEditUserInfo}
										/>
									</Grid>

									<Grid className="w-full lg:w-3/5"></Grid>
								</Grid>
								<Grid className="flex flex-col lg:flex-row items-center gap-x-8 gap-y-5 lg:gap-y-0 mt-5">
									<Grid className="w-full lg:w-3/12 h-full ">
										<Grid className="flex flex-row">
											<Typography>ลายเซ็น (.PNG เท่านั้น)</Typography>
											<Typography className="text-red-500">*</Typography>
											{editUserInfo.signature && (<a
												href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/admins/signature/${editUserInfo.adminId}`}
												target="_blank"
												style={{ marginLeft: '15px' }}
												rel="noreferrer">
												ดูลายเซนต์ที่ใช้อยู่

											</a>)}
										</Grid>
										<Grid className="flex flex-row gap-x-3 items-center">
											<Button
												size="medium"
												color="primary"
												variant="contained"
												onClick={() =>
													document
														.getElementById('edit-signature-button')
														.click()
												}>
												อัพโหลดลายเซ็น
												<input
													id="edit-signature-button"
													type="file"
													hidden
													accept=".png"
													onChange={e => onUploadSignature(e, 'edit')}
												/>
											</Button>
											<Typography
												className={`${editUserInfo.signature
													? 'text-black'
													: 'text-gray-400'
													}`}>
												{editUserInfo.signature
													? editUserInfo.signature?.name
													: 'ยังไม่เลือกไฟล์'}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="flex-end" alignItems="center">
					<Grid item className="p-3 w-max"></Grid>
					<Grid item className="p-3 w-3/12">
						<Button
							fullWidth
							disabled={
								userModalType === 'add' ? !saveButtonAdd : !saveButtonEdit
							}
							onClick={handleButton}
							color="primary"
							variant="contained">
							บันทึก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
