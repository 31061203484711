import { forwardRef, useRef } from 'react'
import {
	Grid,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	Button,
	useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import Cancel from '../../../../../assets/images/cancel.png'

export default function IncorrectIdModal({ open, handleButton }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogContent>
				<DialogContentText
					className="h-72 gap-y-10 flex flex-col"
					tabIndex={-1}
					ref={descriptionElementRef}
					component="div">
					<img
						src={Cancel}
						alt="Cancel Checking"
						width={200}
						className="mx-auto"
					/>
					<Grid className="text-lg lg:text-xl text-center">
						ไม่มีหมายเลขรหัสบัตรประชาชนนี้
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-3/12" component="div">
						<Button
							fullWidth
							onClick={handleButton}
							color="secondary"
							variant="contained">
							ตกลง
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
