import { action } from 'easy-peasy'

export const appModel = {
	// States
	sidebar: false,
	app_type: null,
	// Function
	setSideBar: action((state, isClose) => {
		state.sidebar = isClose
	}),
	setAppType: action((state, appType) => {
		state.app_type = appType
	}),
}
