import React, { useState } from 'react'
import {
	Card,
	Grid,
	Divider,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputBase,
	Button,
	Select,
	MenuItem,
	TextField,
	FormControl,
} from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { operators } from 'api/index'
import { useStoreState } from '../../../store/index'
import { useEffect } from 'react'

export default function ProfileCardV3({
	senderState,
	confirmationInfo,
	onChangeUserInfo,
	onChangeSenderType,
	onChangeCompanyInfo,
	onSubmitNormalPerson,
	onSubmitOrganizationInfo,
	onSubmitOrganizationBranch,
	addressList,
}) {
	// eslint-disable-next-line
	const [selectPOAError, setSelectPOAError] = useState(false)
	// eslint-disable-next-line
	const [dbdData, setDbdData] = useState(null)
	const userSDOC = useStoreState(s => s.sdocUserModel.userSDOC)
	const [ProvinceList, setProvinceList] = useState([]);
	const [DistrictList, setDistrictList] = useState([]);
	const [AmphoeList, setAmphoeList] = useState([]);
	const [ProvinceListNP, setProvinceListNP] = useState([]);
	const [DistrictListNP, setDistrictListNP] = useState([]);
	const [AmphoeListNP, setAmphoeListNP] = useState([]);

	const [currentProvince, setCurrentProvince] = useState('');
	const [currentAmphoe, setCurrentAmphoe] = useState('');
	const [currentDistrict, setCurrentDistrict] = useState('');

	const isEmailValid = (email) => {
		// Simple email validation check
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};


	const initProvinceList = async () => {
		const availableSelection = { province: [], district: [], amphoe: [] }
		addressList.map((item) => {
			if (!availableSelection.province.includes(item.province)) {
				availableSelection.province.push(item.province)
			}
		})
		setProvinceList(availableSelection.province)
		setProvinceListNP(availableSelection.province)
	}

	const updateAvailableAddress = async (name, value) => {
		var availableAddress = []
		if (name == 'province') {
			setCurrentProvince(value)
			availableAddress = addressList.filter((item) => item.province === value)
		} else if (name == 'amphoe') {
			setCurrentAmphoe(value)
			availableAddress = addressList.filter((item) =>
				item.province === currentProvince &&
				item.amphoe === value)
		} else if (name == 'district') {
			setCurrentDistrict(value)
			availableAddress = addressList.filter((item) =>
				item.province === currentProvince &&
				item.amphoe === currentAmphoe &&
				item.district === value)
		}

		if (availableAddress.length > 0) {
			const availableSelection = { province: [], district: [], amphoe: [], zipcode: [] }
			availableAddress.map((item) => {
				if (!availableSelection.district.includes(item.district)) {
					availableSelection.district.push(item.district)
				}
				if (!availableSelection.amphoe.includes(item.amphoe)) {
					availableSelection.amphoe.push(item.amphoe)
				}
				if (!availableSelection.zipcode.includes(item.zipcode)) {
					availableSelection.zipcode.push(item.zipcode)
				}
			})
			// setProvinceListNP(availableSelection.province)
			if (name == 'province') {
				setAmphoeList(availableSelection.amphoe)
				setDistrictList([]);
			} else if (name == 'amphoe') {
				setDistrictList(availableSelection.district)
			} else if (name == 'district') {
				let newData = confirmationInfo.organization
				onChangeCompanyInfo({
					...newData,
					district: value,
					zipcode: availableSelection.zipcode[0],
				})
			}
		} else {
			setDistrictList([]);
			setAmphoeList([]);
			let newData = confirmationInfo.organization
			onChangeUserInfo({
				...newData,
				district: '',
				amphoe: '',
				province: '',
				zipcode: '',
			})
		}
	}

	const updateAvailableAddressNP = async (name, value) => {
		var availableAddress = []
		if (name == 'province') {
			availableAddress = addressList.filter((item) => item.province === value)
		} else if (name == 'amphoe') {
			availableAddress = addressList.filter((item) =>
				item.province === confirmationInfo.normal_person.province &&
				item.amphoe === value)
		} else if (name == 'district') {
			availableAddress = addressList.filter((item) =>
				item.province === confirmationInfo.normal_person.province &&
				item.amphoe === confirmationInfo.normal_person.amphoe &&
				item.district === value)
		}

		if (availableAddress.length > 0) {
			const availableSelection = { province: [], district: [], amphoe: [], zipcode: [] }
			availableAddress.map((item) => {
				if (!availableSelection.district.includes(item.district)) {
					availableSelection.district.push(item.district)
				}
				if (!availableSelection.amphoe.includes(item.amphoe)) {
					availableSelection.amphoe.push(item.amphoe)
				}
				if (!availableSelection.zipcode.includes(item.zipcode)) {
					availableSelection.zipcode.push(item.zipcode)
				}
			})
			// setProvinceListNP(availableSelection.province)
			if (name == 'province') {
				setAmphoeListNP(availableSelection.amphoe)
				setDistrictListNP([]);
			} else if (name == 'amphoe') {
				setDistrictListNP(availableSelection.district)
			} else if (name == 'district') {
				let newData = confirmationInfo.normal_person
				onChangeUserInfo({
					...newData,
					district: value,
					zipcode: availableSelection.zipcode[0],
				})
			}
		} else {
			setDistrictListNP([]);
			setAmphoeListNP([]);
			let newData = confirmationInfo.normal_person
			onChangeUserInfo({
				...newData,
				district: '',
				amphoe: '',
				province: '',
				zipcode: '',
			})
		}
	}

	const handleInput = async e => {
		const { name, value } = e.target
		const numericValue = name === 'mobile' || name === 'zipcode' || name === 'branchNo' ? value.replace(/[^0-9]/g, '') : value;
		let newData = confirmationInfo.organization
		onChangeCompanyInfo({ ...newData, [name]: numericValue })

		if (name === 'branchNo') {
			const newValue = value.replace(/[^0-9]/g, '');
			if (value.length === 5) {
				const res = await operators.getOperatorInfo({ taxno: userSDOC.poa, branchno: newValue });
				if (res && res?.status === 200 && res?.data.status !== 'error') {
					// setCurrentDistrict(res?.data?.operatorInfo?.district)
					const data = res.data
					await onChangeCompanyInfo({
						...newData,
						type: 'organization',
						branchNo: newValue,
						address: data.operatorInfo.address ?? '',
						email: data.operatorInfo.email ?? '',
						mobile: data.operatorInfo.mobile ?? '',
						district: data.operatorInfo.district ?? '',
						amphoe: data.operatorInfo.amphoe ?? '',
						province: data.operatorInfo.province ?? '',
						zipcode: data.operatorInfo.zipcode ?? '',
					})
					// console.log(confirmationInfo.organization)
					setCurrentProvince(res?.data?.operatorInfo?.province)
					setCurrentAmphoe(res?.data?.operatorInfo?.amphoe)
					setCurrentDistrict(res?.data?.operatorInfo?.district)


				} else {
					onChangeCompanyInfo({
						...newData,
						type: 'organization',
						branchNo: newValue,
						address: '',
						email: '',
						mobile: '',
						district: '',
						amphoe: '',
						province: '',
						zipcode: '',
					})
				}
			} else {
				onChangeCompanyInfo({
					...newData,
					type: 'organization',
					branchNo: newValue,
					address: '',
					email: '',
					mobile: '',
					district: '',
					amphoe: '',
					province: '',
					zipcode: '',
				})
			}
		} else if (name === 'province' || name === 'district' || name === 'amphoe') {
			updateAvailableAddress(name, value)
			if (name == 'province') {
				onChangeCompanyInfo({ ...newData, [name]: value, amphoe: '', district: '', zipcode: '' })
			} else if (name == 'amphoe') {
				onChangeCompanyInfo({ ...newData, [name]: value, district: '', zipcode: '' })
			}
		}
	}
	const handleUserInput = e => {
		const { name, value } = e.target
		// console.log('name', name, 'value', value)
		const numericValue = name === 'mobile' || name === 'zipcode' ? value.replace(/[^0-9]/g, '') : value;
		let newData = confirmationInfo.normal_person;
		onChangeUserInfo({ ...newData, [name]: numericValue });

		if (name === 'province' || name === 'district' || name === 'amphoe') {
			updateAvailableAddressNP(name, value)
			if (name == 'province') {
				onChangeUserInfo({ ...newData, [name]: value, amphoe: '', district: '', zipcode: '' })
			} else if (name == 'amphoe') {
				onChangeUserInfo({ ...newData, [name]: value, district: '', zipcode: '' })
			}
		}
	}



	useEffect(() => {
		if (addressList.length > 0) {
			console.log(confirmationInfo)
			initProvinceList()
			updateAvailableAddressNP('province', confirmationInfo.normal_person.province)
			updateAvailableAddressNP('amphoe', confirmationInfo.normal_person.amphoe)
			// updateAvailableAddressNP('district', confirmationInfo.normal_person.district)
			if (userSDOC.poa && confirmationInfo.organization.branchNo.length === 5) {
				setCurrentProvince(confirmationInfo.organization.province)
				setCurrentAmphoe(confirmationInfo.organization.amphoe)
				// setCurrentDistrict(confirmationInfo.organization.district)
			}
		}
		// eslint-disable-next-line
	}, [addressList])

	useEffect(() => {
		if (addressList.length > 0) {
			updateAvailableAddress('province', currentProvince)
		}
	}, [currentProvince])
	useEffect(() => {
		if (addressList.length > 0) {
			updateAvailableAddress('amphoe', currentAmphoe)
		}
	}, [currentAmphoe])
	useEffect(() => {
		if (addressList.length > 0) {
			// updateAvailableAddress('district', currentDistrict)
		}
	}, [currentDistrict])

	return (
		<Card className={`border-t-4 border-card_top_border mb-5`}>
			<Grid className="flex flex-col justify-center items-start px-5 mb-5">
				{/* Header */}
				<Grid className="flex flex-row mt-4 mb-2">
					<Business style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						รายละเอียดผู้ยื่นแบบรับรองตนเอง
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>
				{/* Normal Person or Organization */}
				<Grid className="flex flex-row items-center justify-between w-full">
					<Grid className="flex flex-col gap-y-2 mt-4">
						<Grid>ประเภทของข้อมูลผู้ใช้งาน</Grid>
						<RadioGroup
							row
							name="type"
							value={senderState}
							onChange={onChangeSenderType}>
							<FormControlLabel
								value={'normal_person'}
								control={<Radio />}
								label="บุคคลธรรมดา"

							/>
							{(userSDOC.poa) && (
								<FormControlLabel
									value={'organization'}
									control={<Radio />}
									label="นิติบุคคล"
								/>
							)}
						</RadioGroup>
					</Grid>
				</Grid>
				{/* Normal Person or Organization's Name */}
				{senderState === 'normal_person' && (
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>เลขประจำตัวบัตรประชาชน</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
							<InputBase
								className="w-full"
								name="cardNo"
								readOnly
								disabled
								value={confirmationInfo.normal_person.cardNo}
							/>
						</Grid>
						{/* <Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>เลขที่สาขา</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
								<InputBase
									className="w-full"
									name="branchNo"
									value={confirmationInfo.normal_person.branchNo}
									onChange={handleUserInput}
								/>
							</Grid>
						</Grid> */}
						<Grid className="flex flex-row">
							<Grid>คำนำหน้าชื่อ / ประเภทร้านค้า</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<FormControl variant="outlined" fullWidth size="small">
							{/* <InputBase
								className="w-full"
								name="prefix"
								value={confirmationInfo.normal_person.prefix}
								onChange={handleUserInput}
							/> */}
							<Select
								className="w-full"
								name="prefix"
								value={confirmationInfo.normal_person.prefix}
								onChange={handleUserInput}
							>
								<MenuItem value="นาย">นาย</MenuItem>
								<MenuItem value="นาง">นาง</MenuItem>
								<MenuItem value="นางสาว">นางสาว</MenuItem>
								<MenuItem value="ท่านผู้หญิง">ท่านผู้หญิง</MenuItem>
								{/* <MenuItem value="คุณ">คุณ</MenuItem> */}
							</Select>
						</FormControl>
						{
							// if branchNo is 00000 or null then show this
							(confirmationInfo.normal_person.branchNo === '00000' || confirmationInfo.normal_person.branchNo === null) ? (
								<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>ชื่อ-นามสกุล</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<Grid
										className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
										<InputBase
											className="w-full"
											name="name"
											readOnly
											disabled
											value={confirmationInfo.normal_person.name}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>ชื่อร้านค้า</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<Grid
										className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
										<InputBase
											className="w-full"
											name="firstname"
											readOnly
											value={confirmationInfo.normal_person.firstName}
											onChange={handleUserInput}
										/>
									</Grid>
								</Grid>
							)}
						{/* <Grid className="flex flex-row">
							<Grid>ที่อยู่</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
							<InputBase
								className="w-full"
								name="address"
								value={confirmationInfo.normal_person.address}
								onChange={handleUserInput}
							/>
						</Grid> */}
						<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
							<Grid className="flex flex-row">
								<Grid>ที่อยู่</Grid>
								<Grid className="text-red-500">*</Grid>
							</Grid>
							{/* <Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="address"
										value={confirmationInfo.organization.address}
										onChange={handleInput}
									/>
								</Grid> */}
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full`}>
								<TextField
									fullWidth
									variant="outlined"
									size="small"
									className="w-full"
									name="addr"
									value={confirmationInfo.normal_person.addr}
									onChange={handleUserInput}
									placeholder="บ้านเลขที่ / ตึก อาคาร / หมู่บ้าน / ซอย / ถนน"
									label="บ้านเลขที่ / ตึก อาคาร / หมู่บ้าน / ซอย / ถนน"
								/>
							</Grid>
							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>จังหวัด</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										name="province"
										onChange={handleUserInput}
										value={confirmationInfo.normal_person.province}
										label="จังหวัด"
										disabled={ProvinceListNP.length === 0}
										placeholder="จังหวัด">
										{/* <MenuItem value={""}>จังหวัด</MenuItem> */}
										{ProvinceListNP.map((item, index) => (
											<MenuItem key={index} value={item}> {item} </MenuItem>
										))}

									</Select>
								</FormControl>
							</Grid>
							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>อำเภอ / เขต</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										name="amphoe"
										onChange={handleUserInput}
										value={confirmationInfo.normal_person.amphoe}
										disabled={AmphoeListNP.length === 0}
										placeholder="อำเภอ / เขต">
										{/* <MenuItem value={""}>เลือกอำเภอ</MenuItem> */}
										{AmphoeListNP.map((item, index) => (
											<MenuItem key={index} value={item}> {item} </MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>ตำบล / แขวง</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										name="district"
										onChange={handleUserInput}
										value={confirmationInfo.normal_person.district}
										label="district"
										disabled={DistrictListNP.length === 0}
										placeholder="ตำบล / แขวง">
										{/* <MenuItem value={""}>เลือกตำบล</MenuItem> */}
										{DistrictListNP.map((item, index) => (
											<MenuItem key={index} value={item}> {item} </MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>รหัสไปรษณีย์</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<InputBase
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}
									name="zipcode"
									onChange={handleUserInput}
									inputProps={{ maxLength: 5 }}
									// readOnly
									value={confirmationInfo.normal_person.zipcode}
									placeholder="รหัสไปรษณีย์"
								/>
							</Grid>






						</Grid>

						<Grid className="flex flex-row">
							<Grid>Email</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
							<InputBase
								className="w-full"
								name="email"

								onChange={handleUserInput}
								value={confirmationInfo.normal_person.email}
							/>
						</Grid>


						<Grid className="flex flex-row">
							<Grid>เบอร์โทรศัพท์</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
							<InputBase
								className="w-full"
								name="mobile"
								inputProps={{ maxLength: 10 }}
								type="tel"
								onChange={handleUserInput}
								value={confirmationInfo.normal_person.mobile}
							/>
						</Grid>
						<Grid className="flex flex-row justify-end items-end w-full gap-x-2 my-10">
							<Button
								className={`w-2/12`}
								color="primary"
								variant="contained"
								onClick={onSubmitNormalPerson}
								disabled={confirmationInfo.normal_person.prefix === '' || confirmationInfo.normal_person.addr.length < 2 || confirmationInfo.normal_person.district === '' || confirmationInfo.normal_person.amphoe === '' || confirmationInfo.normal_person.province === '' || confirmationInfo.normal_person.email === '' || confirmationInfo.normal_person.mobile === '' || confirmationInfo.normal_person.zipcode.length < 5 || !isEmailValid(confirmationInfo.normal_person.email)}
							>
								บันทึกข้อมูลส่วนตัว
							</Button>
						</Grid>
					</Grid>
				)}

				{senderState === 'organization' && (
					<>
						<Grid className="flex flex-row w-full">
							<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
								<Grid style={{ fontSize: 20 }}>ข้อมูลบริษัท</Grid>
								<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>เลขประจำตัวผู้เสียภาษีอากร</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<Grid
										className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
										<InputBase
											className="w-full"
											name="cardNo"
											readOnly
											disabled
											value={confirmationInfo.organization.cardNo = userSDOC.poa}
										// value={userSDOC.poa}
										/>
									</Grid>
								</Grid>

								<Grid style={{ display: 'none' }}>
									<Grid className="flex flex-row">
										<Grid>ประเภทสถานประกอบการ</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<Grid
										className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
										<InputBase
											className="w-full"
											name="typeName"
											value={confirmationInfo.organization.typeName}
										// onChange={handleInput}
										/>
									</Grid>
								</Grid>

								<Grid className="flex flex-row">
									<Grid>คำนำหน้าชื่อบริษัท</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										className="w-full"
										name="prefix"
										onChange={handleInput}
										value={confirmationInfo.organization.prefix}
									>
										<MenuItem value={"บริษัท"}>บริษัท</MenuItem>
										<MenuItem value={"ห้างหุ้นส่วน"}>ห้างหุ้นส่วน</MenuItem>
										<MenuItem value={"ห้างหุ้นส่วนจำกัด"}>ห้างหุ้นส่วนจำกัด</MenuItem>
										<MenuItem value={"อื่นๆ"}>อื่น ๆ</MenuItem>

									</Select>
									{confirmationInfo.organization.prefix === "อื่นๆ" && (
										<Grid
											className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>

											<InputBase
												className="w-full"
												name="prefixother"
												value={confirmationInfo.organization.prefixother}
												placeholder="ระบุ"
												onChange={handleInput}
											/>
										</Grid>
									)}

								</FormControl>

								<Grid className="flex flex-row">
									<Grid>ชื่อหน่วยงาน / ชื่อผู้ประกอบการ</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="name"
										onChange={handleInput}
										value={confirmationInfo.organization.name}
									/>
								</Grid>
								<Grid className="flex flex-row justify-end items-end w-full gap-x-2 my-10">
									<Button
										className={`w-2/12`}
										color="primary"
										variant="contained"
										onClick={onSubmitOrganizationInfo}
										disabled={confirmationInfo.organization.prefix === "" || confirmationInfo.organization.name.length < 2}
									>
										บันทึกข้อมูลบริษัท
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid className="mb-1 w-full">
							<Divider />
						</Grid>
						<Grid style={{ fontSize: 20 }}>ข้อมูลสาขา</Grid>
						<Grid className={`flex flex-col w-full`}>

							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>รหัสสาขา</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="branchNo"
										inputProps={{ maxLength: 5 }}
										onChange={handleInput}
										value={confirmationInfo.organization.branchNo}
									/>
								</Grid>
							</Grid>

							<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>ที่อยู่</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								{/* <Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="address"
										value={confirmationInfo.organization.address}
										onChange={handleInput}
									/>
								</Grid> */}
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full`}>
									<TextField
										fullWidth
										variant="outlined"
										size="small"
										className="w-full"
										name="address"
										value={confirmationInfo.organization.address}
										onChange={handleInput}
										placeholder="บ้านเลขที่ / ตึก อาคาร / หมู่บ้าน / ซอย / ถนน"
										label="บ้านเลขที่ / ตึก อาคาร / หมู่บ้าน / ซอย / ถนน"
									/>
								</Grid>
								<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>จังหวัด</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<FormControl variant="outlined" fullWidth size="small">
										<Select
											name="province"
											onChange={handleInput}
											value={confirmationInfo.organization.province}
											label="จังหวัด"
											disabled={ProvinceList.length === 0}
											placeholder="จังหวัด">
											{/* <MenuItem value={""}>จังหวัด</MenuItem> */}
											{ProvinceList.map((item, index) => (
												<MenuItem key={index} value={item}> {item} </MenuItem>
											))}

										</Select>
									</FormControl>
								</Grid>
								<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>อำเภอ / เขต</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<FormControl variant="outlined" fullWidth size="small">
										<Select
											name="amphoe"
											onChange={handleInput}
											value={confirmationInfo.organization.amphoe}
											disabled={AmphoeList.length === 0}
											placeholder="อำเภอ / เขต">
											{/* <MenuItem value={""}>เลือกอำเภอ</MenuItem> */}
											{AmphoeList.map((item, index) => (
												<MenuItem key={index} value={item}> {item} </MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>ตำบล / แขวง</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<FormControl variant="outlined" fullWidth size="small">
										<Select
											name="district"
											onChange={handleInput}
											value={confirmationInfo.organization.district}
											label="district"
											disabled={DistrictList.length === 0}
											placeholder="ตำบล / แขวง">
											{/* <MenuItem value={""}>เลือกตำบล</MenuItem> */}
											{DistrictList.map((item, index) => (
												<MenuItem key={index} value={item}> {item} </MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
									<Grid className="flex flex-row">
										<Grid>รหัสไปรษณีย์</Grid>
										<Grid className="text-red-500">*</Grid>
									</Grid>
									<InputBase
										className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}
										name="zipcode"
										onChange={handleInput}
										inputProps={{ maxLength: 5 }}
										// readOnly
										value={confirmationInfo.organization.zipcode}
										placeholder="รหัสไปรษณีย์"
									/>
								</Grid>

							</Grid>

							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>Email</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="email"
										onChange={handleInput}
										value={confirmationInfo.organization.email}
									/>
								</Grid>
							</Grid>

							<Grid className={`flex flex-col items-start my-2 w-1/3 gap-y-3`}>
								<Grid className="flex flex-row">
									<Grid>เบอร์โทรศัพท์</Grid>
									<Grid className="text-red-500">*</Grid>
								</Grid>
								<Grid
									className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
									<InputBase
										className="w-full"
										name="mobile"
										onChange={handleInput}
										inputProps={{ maxLength: 10 }}
										value={confirmationInfo.organization.mobile}
									/>
								</Grid>
							</Grid>

							<Grid className="flex flex-row justify-end items-end w-full gap-x-2 my-10">
								<Button
									className={`w-2/12`}
									color="primary"
									variant="contained"
									onClick={onSubmitOrganizationBranch}
									disabled={confirmationInfo.organization.address.length < 1 || confirmationInfo.organization.district.length < 1 || confirmationInfo.organization.amphoe.length < 1 || confirmationInfo.organization.province.length < 1 || confirmationInfo.organization.email.length < 1 || confirmationInfo.organization.mobile.length < 1 || confirmationInfo.organization.zipcode.length < 5 || !isEmailValid(confirmationInfo.organization.email)}
								>
									บันทึกข้อมูลสาขา
								</Button>
							</Grid>


						</Grid>
					</>
				)}


			</Grid>
		</Card>
	)
}
