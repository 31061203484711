import { useState, useEffect } from 'react'

export const useTimeQuarter = () => {
	const [quarter, setQuarter] = useState({
		month1: '',
		month2: '',
		month3: '',
	})

	useEffect(() => {
		const now = new Date()
		const currentMonth = now.getMonth()

		// Jan. - Mar.
		if (currentMonth >= 0 && currentMonth <= 2)
			setQuarter({
				month1: 'ต.ค.',
				month2: 'พ.ย.',
				month3: 'ธ.ค.',
			})
		// April - June.
		else if (currentMonth >= 3 && currentMonth <= 5)
			setQuarter({
				month1: 'ม.ค.',
				month2: 'ก.พ.',
				month3: 'มี.ค.',
			})
		// July - Sep.
		else if (currentMonth >= 6 && currentMonth <= 8)
			setQuarter({
				month1: 'เม.ย.',
				month2: 'พ.ค.',
				month3: 'มิ.ย.',
			})
		// Oct. - Dec.
		else if (currentMonth >= 9 && currentMonth <= 11)
			setQuarter({
				month1: 'ก.ค.',
				month2: 'ส.ค.',
				month3: 'ก.ย.',
			})
	}, [])

	return { quarter }
}
