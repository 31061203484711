import { forwardRef, useRef } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	IconButton,
	Button,
	useMediaQuery,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import TablePOA from './TablePOA'
import { useTheme } from '@material-ui/core/styles'

export default function SelectOperatorModal({
	open,
	onClose,
	title,
	header_icon,
	isError
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)
	

	return (
		<Dialog
			className="w-full"
			open={open}
			onClose={onClose}
			maxWidth="md"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{header_icon}
						<Grid className="text-white" style={{marginLeft:20}}>{title}</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					ref={descriptionElementRef}>
				{!isError && (<TablePOA />)}
				{isError && (<div style={{textAlign:"center",margin:"50px 0"}}>ไม่สามารถเรียกข้อมูลได้ กรุณาลองใหม่อีกครั้ง</div>)}
					
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					className="w-full mx-auto flex flex-row justify-center items-center"
					component="div">
					<Button
						className="w-5/12"
						onClick={onClose}
						color="primary"
						variant="contained">
						ปิด
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
