import { useState, useEffect } from 'react'
import {
	Grid,
	Typography,
	Divider,
	Button,
	useMediaQuery,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import {
	ViewComfy,
	PersonAdd,
	// SearchOutlined
} from '@material-ui/icons'

import OrgActions from '../org-actions/index'

import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
	add_button_styles: {
		backgroundColor: '#3C8DBC',
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: '#2f6f94',
		},
	},
	normal_status_box: {
		backgroundColor: '#3C8DBC',
		color: '#fff',
	},
	ban_temp_box: {
		backgroundColor: '#F39C11',
		color: '#fff',
	},
	expired_box: {
		backgroundColor: '#DD4C39',
		color: '#fff',
	},
	normal_status_text: {
		color: '#3C8DBC',
		fontWeight: 600,
	},
	ban_temp_text: {
		color: '#F39C11',
		fontWeight: 600,
	},
	expired_text: {
		color: '#DD4C39',
		fontWeight: 600,
	},
}))

export default function OrgSearchResult({
	user,
	onOpenUserModal,
	setUserModalType,
	onOpenDeleteUserModal,
	onSearch,
	setCurrentEditUserID,
	isDataLoading = false,
}) {
	const classes = useStyles()
	const [pageSize, setPageSize] = useState(10)
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [columnsId, setColumnsId] = useState([100, 0])
	const [columnsName, setColumnsName] = useState([180, 0])
	const [columnsEmail, setColumnsEmail] = useState([180, 0])
	// eslint-disable-next-line
	const [columnsCitizenId, setColumnsCitizenId] = useState([250, 0])
	// eslint-disable-next-line
	const [columnsPhoneNumber, setColumnsPhoneNumber] = useState([250, 0])
	const [columnsDuty, setColumnsDuty] = useState([150, 0])
	const [columnsStatus, setColumnsStatus] = useState([150, 0])
	const [columnsActions, setColumnsActions] = useState([150, 0])

	const columns = [
		{
			field: 'id',
			headerName: 'ลำดับ',
			width: columnsId[0],
			flex: columnsId[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => (
				<Grid className="text-left w-full px-1">{params.value}</Grid>
			),
		},
		{
			field: 'name',
			headerName: 'ชื่อ-นามสกุล',
			width: columnsName[0],
			flex: columnsName[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => (
				<Grid className="text-left w-full px-1">{params.value}</Grid>
			),
		},
		{
			field: 'email',
			headerName: 'อีเมล',
			width: columnsEmail[0],
			flex: columnsEmail[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => (
				<Grid className="text-left w-full px-1">{params.value}</Grid>
			),
		},
		{
			field: 'periodActivate',
			headerName: 'ระยะเวลาการใช้งาน',
			width: columnsCitizenId[0],
			flex: columnsCitizenId[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => {
				return <Grid className="text-left w-full px-1">{params.value}</Grid>
			},
		},
		{
			field: 'mobile',
			headerName: 'หมายเลขโทรศัพท์',
			width: columnsPhoneNumber[0],
			flex: columnsPhoneNumber[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => {
				// eslint-disable-next-line
				const mobile =
					params.value.substring(0, 3) +
					'-' +
					params.value.substring(3, 6) +
					'-' +
					params.value.substring(6)
				return <Grid className="text-left w-full px-1">{params.value}</Grid>
			},
		},
		{
			field: 'userRole',
			headerName: 'หน้าที่',
			width: columnsDuty[0],
			flex: columnsDuty[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => {
			
				return (
					<Grid className="text-left w-full px-1">
						{params.value}
					</Grid>
				)
			},
		},
		{
			field: 'status',
			headerName: 'สถานะผู้ใช้งาน',
			width: columnsStatus[0],
			flex: columnsStatus[1],
			headerAlign: 'left',
			align: 'left',
			renderCell: params => {
				return (
					<Grid
						className={`text-left px-1 ${statusBoxGenerator(params.row.statusID)}`}>
						{params.value}
					</Grid>
				)
			},
		},
		{
			field: 'actions',
			headerName: 'การกระทำ',
			width: columnsActions[0],
			flex: columnsActions[1],
			headerAlign: 'center',
			align: 'center',
			renderCell: params => (
				<OrgActions
					id={params.row.accessId}
					setCurrentEditUserID={setCurrentEditUserID}
					onOpenUserModal={onOpenUserModal}
					setUserModalType={setUserModalType}
					onOpenDeleteUserModal={onOpenDeleteUserModal}
				/>
			),
		},
	]

	useEffect(() => {
		if (isMobile) {
			setColumnsId([100, 0])
			setColumnsName([180, 0])
			setColumnsEmail([180, 0])
			// setColumnsCitizenId([250, 0])
			// setColumnsPhoneNumber([250, 0])
			setColumnsDuty([150, 0])
			setColumnsStatus([150, 0])
			setColumnsActions([150, 0])
		} else {
			setColumnsId([0, 0.7])
			setColumnsName([0, 1])
			setColumnsEmail([0, 1])
			// setColumnsCitizenId([0, 1])
			// setColumnsPhoneNumber([0, 1])
			setColumnsDuty([0, 1])
			setColumnsStatus([0, 1])
			setColumnsActions([0, 0.8])
		}
	}, [isMobile])

	const statusBoxGenerator = status => {
		if (status === 1) return classes.normal_status_text
		else if (status === 2) return classes.ban_temp_text
		else if (status === 3) return classes.expired_text
	}

	return (
		<Grid className="border border-l-1 border-r-1 border-b-1 border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0 my-8 p-5 bg-white shadow-lg">
			<Grid className="h-full" style={{ height: '550px' }}>
				<Grid className="flex flex-row items-center gap-x-3">
					<ViewComfy style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="text-sm font-bold text-card_header_color">
						ผลการค้นหา
					</Grid>
				</Grid>
				<Grid className="my-3">
					<Divider />
				</Grid>
				<Grid className="flex flex-row justify-end gap-x-3 my-4">
					<Button
						variant="contained"
						color="secondary"
						size="large"
						onClick={() => {
							setUserModalType('add')
							onOpenUserModal()
						}}>
						<PersonAdd className="mr-1" />
						<Typography variant="button">เพิ่ม</Typography>
					</Button>
				</Grid>
				<Grid style={{ height: '420px' }}>
					<DataGrid
						disableSelectionOnClick
						disableColumnMenu
						rows={user}
						columns={columns}
						rowsPerPageOptions={[5, 10, 15, 20]}
						pageSize={pageSize}
						onPageSizeChange={size => setPageSize(size)}
						components={{
							NoRowsOverlay: () => {
								if (!isDataLoading) {
									return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
								}else{
									return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
								}
							}
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}
