import { action, thunk } from 'easy-peasy'
import { operators } from '../../../api/index'

export const operatorModel = {
	// States
	user: null,
	// Setters
	set: action((state, user) => {
		state.user = user
	}),

	// Custom Function
	searchOperatorDBD: thunk(async (actions, payloads) => {
		const res = await operators.searchDBD(payloads)
		if (res && res.status === 200) {
			return res.data
		}
	}),
}
