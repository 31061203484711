export const mobile_general_details = [
	{
		id: 31,
		no: '2.1.1',
		label: 'ระยะเวลาสำหรับการขอเริ่มเปิดใช้บริการ (Service activation time)',
		targeted_text:
			'สำหรับระบบ Pre-paid ไม่เกิน 3 ชั่วโมง สำหรับร้อยละ 90 ของการขอเริ่มเปิดใช้บริการ (ให้วัดเป็นรายเดือน)',
		subLabel_1:
			'สำหรับระบบ Pre-paid ไม่เกิน 3 ชั่วโมง สำหรับร้อยละ 90 ของการขอเริ่มเปิดใช้บริการ (ให้วัดเป็นรายเดือน)',
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 7,
		priority: 31,
	},
	{
		id: 32,
		no: '2.1.1',
		label: 'ระยะเวลาสำหรับการขอเริ่มเปิดใช้บริการ (Service activation time)',
		targeted_text:
			'สำหรับระบบ Post-paid ไม่เกิน 5 ชั่วโมงทำการ สำหรับร้อยละ 90 ของการขอเริ่มเปิดใช้บริการ (ให้วัดเป็นรายเดือน)',
		subLabel_1:
			'สำหรับระบบ Post-paid ไม่เกิน 5 ชั่วโมงทำการ สำหรับร้อยละ 90 ของการขอเริ่มเปิดใช้บริการ (ให้วัดเป็นรายเดือน)',
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 11,
		priority: 32,
	},
	{
		id: 33,
		no: '2.1.2',
		label:
			'อัตราข้อร้องเรียนที่เกี่ยวกับข้อผิดพลาดในการเรียกเก็บค่าบริการ (Billing inaccuracy)',
		targeted_text: 'ไม่เกินร้อยละ 0.3 (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 0.3,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 16,
		priority: 33,
	},
	{
		id: 34,
		no: '2.1.3',
		label:
			'ระยะเวลาที่ต้องรอในการขอใช้บริการดูแลลูกค้าจากศูนย์ตอบรับโทรศัพท์ (Response time for accessing customer-service call center)',
		targeted_text: 'ไม่เกิน 60 วินาที (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 60,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 1-60 วินาที',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'int',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 21,
		priority: 34,
	},
	{
		id: 35,
		no: '2.1.4',
		label:
			'จำนวนครั้งที่หน่วยรับ-ส่ง สัญญาณวิทยุย่อย (Cell) ภายในสถานีฐาน ไม่สามารถให้บริการได้ ติดต่อกันเกิน 4 ชั่วโมง ใน 1 เดือนต่อจำนวน Cell ทั้งหมดในทุกสถานี (network unavailability : number of cell outages continuously over 4 hours in a month)',
		targeted_text: 'ไม่เกิน 10 ครั้ง ต่อ 100 Cell ต่อเดือน',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 10,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องเป็นเลขจำนวนเต็ม และต้องมีค่ามากกว่าหรือเท่ากับ 0',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'int',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 26,
		priority: 35,
	},
	{
		id: 36,
		no: '2.1.5',
		label:
			'ร้อยละของจำนวนหน่วยรับ-ส่ง สัญญาณวิทยุย่อย (Cell) ภายในสถานีฐานที่หยุดทำงานสะสมเกินกว่า 24 ชั่วโมง ภายใน 1 เดือน (network unavailability : number of cell outages continuously over 24 hours in a month)',
		targeted_text: 'ไม่เกินร้อยละ 3 (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 3,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 31,
		priority: 36,
	},
	{
		id: 37,
		no: '2.1.6',
		label:
			'ร้อยละของเวลารวมที่ทุกหน่วยรับ-ส่ง สัญญาณวิทยุย่อย (Cell) ภายในสถานีฐานไม่สามารถให้บริการได้ใน 1 เดือน ของเวลาที่ต้องให้บริการทั้งหมด (network unavailability : cumulative cell outage time in a month)',
		targeted_text: 'ไม่เกินร้อยละ 1 (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 1,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 36,
		priority: 37,
	},
]
