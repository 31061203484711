import React, { useState, useEffect } from 'react'

import { Grid, Card, Button, useMediaQuery } from '@material-ui/core'
import { ListAlt, Menu, SendOutlined } from '@material-ui/icons'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'

import { ChangeStatusModal } from '@components/users/standard-report-validation/change-status-modal'

import SendingStandardReportToAdminModalSuccess from '@components/users/standard-report-validation/send-to-admin-modal'
import SendingStandardReportToAdminModalFailed from '@components/users/standard-report-validation/send-to-admin-modal-failed'
import SureModal from '@components/SureModal'
import SubmitModal from '@components/users/standard-report-validation/submit-modal'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useStoreRehydrated } from '@store'
import { format } from 'date-fns'

import { datasetRecord } from '@api'

const useStyles = makeStyles(theme => {
	return {
		card_styles: {
			height: '80vh',
		},
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		type_of_report: {
			backgroundColor: '#c8e8ff',
			color: '#028BFF',
			borderColor: '#84BFF0',
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
	}
})

export default function StandardReportValidation() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const classes = useStyles()
	// eslint-disable-next-line
	const theme = useTheme()
	const isMobile2 = useMediaQuery('(min-width:425px)')
	const dataTypeOfReport = ['เคลื่อนที่', 'ประจำที่']

	// Work Status 0 = รอการตรวจสอบจากผู้อนุมัติ, 1 = ตรวจสอบแล้ว, 2 = รับรายงาน
	// eslint-disable-next-line
	const [statusIDList, setStatusIDList] = useState([])
	// eslint-disable-next-line
	const [tempID, setTempID] = useState(0)
	// eslint-disable-next-line
	const [tempName, setTempName] = useState('')
	const [statusDialogOpen, setStatusDialogOpen] = useState(false)
	const [statusLabelTemp, setStatusLabelTemp] = useState('')
	const [statusIDTemp, setStatusIDTemp] = useState(null)
	// eslint-disable-next-line
	const [reportID, setReportID] = useState(null)
	const [activeReportHead, setActiveReportHead] = useState(null)
	const [pageSize, setPageSize] = useState(8)

	// Modal ​State
	const [openSendToAdminModal, setOpenSendToAdminModal] = useState(false)
	const [openSendToAdminModalFailed, setOpenSendToAdminModalFailed] =
		useState(false)
	const [
		openSubmitSendingToAdminSureModal,
		setOpenSubmitSendingToAdminSureModal,
	] = useState(false)

	// const handleOpenChangeStatusDialog = status_id => {
	// 	setStatusDialogOpen(true)
	// 	if (status_id === 2) {
	// 		setStatusLabelTemp('รอการตรวจสอบจากผู้อนุมัติ')
	// 		setStatusIDTemp(2)
	// 	} else if (status_id === 3) {
	// 		setStatusLabelTemp('ตรวจสอบแล้ว')
	// 		setStatusIDTemp(3)
	// 	} else if (status_id === 4) {
	// 		setStatusLabelTemp('รับรายงาน')
	// 		setStatusIDTemp(4)
	// 	}
	// }

	const handleCloseDialog = () => {
		setStatusDialogOpen(false)
	}

	// Additional Files
	const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
	const [tempFile, setTempFile] = useState({
		file: null,
		name: '',
	})
	const [additionalFile, setAdditionalFile] = useState(null)
	const [additionalFiles, setAdditionalFiles] = useState([])

	// Document Additional Card

	const onAddTemp = (e, type) => {
		const { files, value } = e.target
		if (type === 'file' && files.length !== 0)
			setTempFile({ ...tempFile, file: files[0] })
		if (type === 'name') setTempFile({ ...tempFile, name: value })
	}

	const onAddDocument = () => {
		if (tempFile.file) {
			setAdditionalFiles(prevState => [...prevState, tempFile.file])
			setAdditionalFile(tempFile.file)
			setTempFile({ file: null, name: '' })
		}
	}

	const onChangeAttachedFileName = (e, index) => {
		const { value } = e.target
		setAdditionalFiles(prevState => {
			const newList = prevState.map((item, itemIndex) => {
				if (itemIndex === index)
					return {
						file: item?.file,
						name: value,
					}

				return item
			})
			return newList
		})
	}

	const onDeleteDocument = () => {
		setAdditionalFile(null)
	}

	const handleCloseSubmitDialog = () => {
		setSubmitDialogOpen(!submitDialogOpen)
	}

	const onSubmitToAdmin = async () => {
		const res = await datasetRecord.sendToAdmin(
			activeReportHead,
			additionalFile
		)
		if (res?.response?.status === 400) {
			handleCloseSubmitDialog()
			onOpenSendingToAdminFailedModal()
		} else if (res.status === 200) {
			handleCloseSubmitDialog()
			onOpenSendingToAdminModal()
		}
	}

	/** <---------------------------------------------------------------------> */

	// On Click Change Status
	// const onClickChangeStatus = params => {
	// 	setReportID(params.row.id)
	// 	setStatusIDTemp(params.value.workStatus)
	// 	handleOpenChangeStatusDialog(params.value.workStatus)
	// }

	const columnsWidth = {
		columnsId: 110,
		columnsName: 300,
		columnsDataTypeList: 220,
		columnsSubmitDate: 160,
		columnsSubmitType: 180,
		columnsReportDetails: 140,
		columnsWorkStatusId: 200,
		columnsAction: 180,
	}
	var columns = [
		{
			field: 'id',
			headerName: 'ลำดับ',
			width: columnsWidth.columnsId,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				return <Grid className="pl-4">{params.value}</Grid>
			},
		},
		{
			field: 'name',
			headerName: 'ชื่อ-นามสกุล',
			width: columnsWidth.columnsName,
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'dataTypeList',
			headerName: 'ประเภทรายงาน',
			sortable: true,
			width: columnsWidth.columnsDataTypeList,
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-center items-center">
						{params.value.map((e, index) => (
							<Grid
								key={e + index}
								className={`rounded-full py-1 px-4 m-0.5 font-bold text-xs border ${classes.type_of_report}`}>
								{dataTypeOfReport[e]}
							</Grid>
						))}
					</Grid>
				)
			},
		},
		{
			field: 'submit_date',
			headerName: 'วันที่ส่งข้อมูล',
			width: columnsWidth.columnsSubmitDate,
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return <Grid className="">{params.value}</Grid>
			},
		},
		{
			field: 'submitType',
			headerName: 'วิธีการส่งรายงาน',
			width: columnsWidth.columnsSubmitType,
			align: 'left',
			editable: false,
		},
		{
			field: 'report_detials',
			headerName: 'ดูข้อมูลรายงาน',
			sortable: false,
			width: columnsWidth.columnsReportDetails,
			headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return (
					<Grid className="mx-auto">
						<Button
							onClick={() => {
								let tempIndex = 0
								for (tempIndex; tempIndex < rows.length; tempIndex++) {
									if (params.value === rows[tempIndex].id) {
										break
									}
								}
								history.push({
									pathname: `/standard-report-validation/${params.row.workStatus.report_id}`,
									state: {
										rowData: rows[tempIndex],
										statusIDList: statusIDList,
									},
								})
							}}
							className={`h-8`}
							color="secondary"
							size="small"
							variant="contained">
							<Menu style={{ fontSize: 18 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>
					</Grid>
				)
			},
		},
		{
			field: 'workStatus',
			headerName: 'สถานะ',
			sortable: false,
			width: columnsWidth.columnsWorkStatusId,
			headerAlign: 'center',
			align: 'center',
			renderCell: params => {
				let title = params.value.workStatusName;
				if (params.value.isRejected === 'y')
					title = "รอแก้ไขรายงาน"

				return (
					<>
						<Grid className="">
							<Button
								// onClick={() =>
								// 	params.row.isActive === 'y'
								// 		? onClickChangeStatus(params)
								// 		: null
								// }
								className={`h-8 pointer-events-none
								 ${params.value.workStatus === 2 || params.value.isRejected === 'y'
										? classes.wait_button
										: params.value.workStatus === 3
											? classes.approve_button
											: params.value.workStatus === 4
												? classes.approve_button
												: classes.approve_button
									}`}
								variant="contained"
								size="small">
								<Grid className={`text-xs `}>
									{title}
								</Grid>
							</Button>
						</Grid>
					</>
				)
			},
		},
		{
			field: 'actions',
			headerName: ' ',
			width: columnsWidth.columnsAction,
			headerAlign: 'center',
			align: 'center',
			renderCell: params => {
				if (params.row.workStatus.workStatus === 3) {
					return params.row.isActive === 'y' ? (
						<Grid>
							<Button
								variant="contained"
								size="small"
								color="primary"
								className="h-8"
								onClick={() => {
									setReportID(params.value)
									setActiveReportHead(params.id)
									handleCloseSubmitDialog()
								}}>
								ส่งรายงาน
							</Button>
						</Grid>
					) : (
						<Grid className="text-red-500 font-bold">เกินกำหนดส่งรายงาน</Grid>
					)
				} else {
					return <></>
				}
			},
		},
	]

	const [rows, setRows] = useState([
		// Work Status 0 = รอการตรวจสอบจากผู้อนุมัติ, 1 = ตรวจสอบแล้ว, 2 = รับรายงาน
		// Data Type List 0 = ประจำที่, 1 = เคลื่อนที่
	])
	const [isDataLoading, setIsDataLoading] = useState(false)

	function handleStatusTempChange() {
		changeStatusRecord()
		var statusIDListTemp = statusIDList
		statusIDListTemp[tempID - 1] = statusLabelTemp
		if (statusLabelTemp === 'รอการตรวจสอบจากผู้อนุมัติ') {
			statusIDListTemp[tempID - 1] = 0
		} else if (statusLabelTemp === 'ตรวจสอบแล้ว') {
			statusIDListTemp[tempID - 1] = 1
		} else if (statusLabelTemp === 'รับรายงาน') {
			statusIDListTemp[tempID - 1] = 2
		}
		setStatusIDList(statusIDListTemp)
		handleCloseDialog()
	}

	const onChangeStatusTemp = e => {
		setStatusLabelTemp(e.target.value)
		if (e.target.value === 'รอการตรวจสอบจากผู้อนุมัติ') {
			setStatusIDTemp(2)
		} else if (e.target.value === 'ตรวจสอบแล้ว') {
			setStatusIDTemp(3)
		} else if (e.target.value === 'รับรายงาน') {
			setStatusIDTemp(4)
		}
	}

	//  -------------------------------
	const getDatasetRecordList = async () => {
		setIsDataLoading(true)
		const res = await datasetRecord.getlist()

		if (res) {
			if (res.status === 200) {
				const rowsTemp = []

				for (let i = 0; i < res.data.length; i++) {
					const qosType = []
					// if (res.data[i].status > 1 && res.data[i].status < 4) {
					if (res.data[i].status > 1) {

						if (res.data[i].isFixed === 'y') qosType.push(1)
						if (res.data[i].isMobile === 'y') qosType.push(0)

						const tempObject = {
							id: res.data[i].id,
							name:
								res.data[i].operatorUser.firstname +
								' ' +
								res.data[i].operatorUser.lastname,
							submit_date: res.data[i].updatedAt
								? format(new Date(res.data[i].updatedAt), 'dd/MM/yyyy')
								: '',
							submitType: res.data[i].sendMethod,
							workStatus: {
								report_id: res.data[i].id,
								workStatus: res.data[i].dataSetRecordHeadStatus.id,
								workStatusName: res.data[i].dataSetRecordHeadStatus.statusName,
								isRejected: res.data[i].isRejected
							},
							report_detials: res.data[i].id,
							dataTypeList: qosType,
							actions: res.data[i].id,
							isActive: res.data[i].isActive,
						}

						let tempStatusIDList = statusIDList
						tempStatusIDList[i] = {
							report_id: res.data[i].id,
							workStatus: res.data[i].dataSetRecordHeadStatus.id,
							workStatusName: res.data[i].dataSetRecordHeadStatus.statusName,
						}
						setStatusIDList(tempStatusIDList)
						setReportID(res.data[i].id)
						rowsTemp.push(tempObject)
					}
				}
				setRows(rowsTemp)
			}
		}
		setIsDataLoading(false)
	}

	const changeStatusRecord = async () => {
		// eslint-disable-next-line
		const res = await datasetRecord.changeStatusReportHead(
			reportID,
			statusIDTemp
		)
		getDatasetRecordList()
	}

	const onOpenSendingToAdminSureModal = () => {
		setOpenSubmitSendingToAdminSureModal(!openSubmitSendingToAdminSureModal)
	}

	const onOpenSendingToAdminModal = () => {
		setOpenSendToAdminModal(!openSendToAdminModal)
	}

	const onOpenSendingToAdminFailedModal = () => {
		setOpenSendToAdminModalFailed(!openSendToAdminModalFailed)
	}

	const onLoading = async () => {
		getDatasetRecordList()
	}

	useEffect(() => {
		if (isRehydrated) onLoading()
		// eslint-disable-next-line
	}, [isRehydrated])

	return (
		<>
			<Grid className="lg:p-10">
				<Card
					className={`${classes.card_styles} lg:border-t-4 border-card_top_border`}>
					<Grid className="flex flex-col justify-center items-start overflow-auto">
						<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
							<Grid>
								<ListAlt style={{ fontSize: 22 }} className="mr-1" />
							</Grid>
							<Grid className="font-bold text-sm text-card_header_color">
								รายชื่อรายงาน
							</Grid>
						</Grid>
						<Grid
							className="md:px-5"
							style={{ height: isMobile2 ? "65vh"  : 350, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								disableSelectionOnClick
								rowsPerPageOptions={[5, 8, 10, 20]}
								pageSize={pageSize}
								onPageSizeChange={size => setPageSize(size)}
								components={{
									NoRowsOverlay: () => {
										if (!isDataLoading) {
											return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
										}else{
											return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Card>
			</Grid>

			<ChangeStatusModal
				handleCloseDialog={handleCloseDialog}
				statusDialogOpen={statusDialogOpen}
				name={tempName}
				statusLabelTemp={statusLabelTemp}
				onChangeStatusTemp={onChangeStatusTemp}
				handleStatusTempChange={handleStatusTempChange}
			/>
			<SendingStandardReportToAdminModalSuccess
				open={openSendToAdminModal}
				handleButton={() => history.go(0)}
			/>
			<SendingStandardReportToAdminModalFailed
				open={openSendToAdminModalFailed}
				handleButton={onOpenSendingToAdminFailedModal}
			/>
			<SureModal
				title="ส่งรายงานมาตรฐานคุณภาพไปยังสำนักงานกสทช."
				content="ยืนยันส่งรายงานมาตรฐานคุณภาพไปยัง สำนักงานกสทช. หรือไม่"
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				open={openSubmitSendingToAdminSureModal}
				onClose={onOpenSendingToAdminSureModal}
				handleCancel={onOpenSendingToAdminSureModal}
				handleButton={onSubmitToAdmin}
			/>
			<SubmitModal
				submitDialogOpen={submitDialogOpen}
				handleCloseSubmitDialog={handleCloseSubmitDialog}
				handleSubmitButton={onSubmitToAdmin}
				additionalFile={additionalFile}
				additionalFiles={additionalFiles}
				tempFile={tempFile}
				onAddTemp={onAddTemp}
				onAddDocument={onAddDocument}
				onDeleteDocument={onDeleteDocument}
				onChangeAttachedFileName={onChangeAttachedFileName}
			/>
		</>
	)
}
