import { useState, useEffect } from 'react'

export const useWidthExcelInputWithValidation = sub_label_type => {
	const [valueWidth, setValueWidth] = useState('')
	const [avgWidth, setAvgWidth] = useState('')
	const [validationWidth, setValidationWidth] = useState('')

	useEffect(() => {
		if (sub_label_type === 3 || sub_label_type === 2) {
			setValueWidth('w-2/12')
			setAvgWidth('w-1/12')
			setValidationWidth('w-2/12')
		} else if (sub_label_type === 1) {
			setValueWidth('w-2/12')
			setAvgWidth('w-3/12')
			setValidationWidth('w-3/12')
		}
	}, [sub_label_type])

	return { valueWidth, avgWidth, validationWidth }
}
