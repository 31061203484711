import { useState, useEffect } from 'react'
import {
	Card,
	CardContent,
	Grid,
	Divider,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
} from '@material-ui/core'
import { PlaylistAddCheck } from '@material-ui/icons'

import { adminQoS } from 'api/index'

export default function StandardReportStep3({
	changeValidationStep3,
	selectSignature,
	rejectReason,
	setRejectReason,
}) {
	// eslint-disable-next-line
	const [signature, setSignature] = useState([])
	// eslint-disable-next-line
	const [selected, setSelected] = useState('')
	const [reportMethod, setReportMethod] = useState('accept')

	const onChangeRadio = e => {
		setReportMethod(e.target.value)
	}

	const onChangeRejectReason = e => {
		setRejectReason(e.target.value)
	}

	useEffect(() => {
		if (reportMethod === 'reject' && rejectReason === '') {
			changeValidationStep3(true)
		} else {
			changeValidationStep3(false)
		}
	}, [reportMethod, rejectReason, changeValidationStep3])

	useEffect(() => {
		adminQoS.getSignature().then(e => {
			setSignature(e)
		})
	}, [])
	return (
		<Grid className="w-full flex flex-col gap-y-6">
			<Card className="border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0">
				<CardContent className="flex flex-col gap-y-3 w-full h-48">
					<Grid component="div" className="flex flex-row">
						<PlaylistAddCheck style={{ fontSize: 22 }} className="mr-1" />
						<Grid className="font-bold text-sm text-card_header_color">
							ตรวจสอบข้อมูล
						</Grid>
					</Grid>
					<Divider />
					<RadioGroup
						row
						value={reportMethod}
						onChange={onChangeRadio}
						defaultChecked={false}>
						<FormControlLabel value="accept" control={<Radio />} label="ผ่าน" />
						<FormControlLabel
							value="reject"
							control={<Radio />}
							label="ไม่ผ่าน"
						/>
					</RadioGroup>
					{reportMethod === 'reject' ? (
						<Grid className="mt-1">
							<TextField
								variant="outlined"
								label="ระบุเหตุผลที่ไม่อนุมัติ"
								value={rejectReason}
								onChange={onChangeRejectReason}
								className="w-full h-14 bg-text_field_bg"
							/>
						</Grid>
					) : (
						<Grid>
							{/* <FormControl  className="mt-1 w-1/3">
								<Select
									value={selected}
									displayEmpty
									onChange={handleChange}
									inputProps={{
										name: 'signature',
										id: 'select-signature',
									}}>
									<MenuItem value="">-- กรุณาเลือกผู้มีอำนาจลงนาม --</MenuItem>
									{signature.map(e=>(<MenuItem  value={e.id}>{e.firstname ?? ''} {e.lastname ?? ''}</MenuItem>))}
									
								</Select>
							</FormControl> */}
						</Grid>
					)}
				</CardContent>
			</Card>
		</Grid>
	)
}
