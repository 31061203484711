import React, { useState, useEffect } from 'react'
import {
	Button,
	Grid,
	Card,
	TextField,
	IconButton,
	Divider,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { useHistory, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useStoreActions, useStoreState } from '../../../../store'
import { makeStyles } from '@material-ui/core/styles'

import logo from '../../../../assets/images/NBTC-logo.png'

const useStyles = makeStyles(theme => {
	return {
		AppDescription: {
			fontSize: '24px',
		},
		AppDescriptionSpan: {
			color: theme.palette.primary.main,
		},
	}
})

export default function SDOCAdminSignIn() {
	const history = useHistory()
	const location = useLocation()
	const [showPassword, setShowPassword] = useState(false)
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const [signInMsg, setSignInMsg] = useState('')

	const classes = useStyles()

	const adminSDOC = useStoreState(s => s.sdocAdminModel.adminSDOC)
	const signInAdmin = useStoreActions(a => a.sdocAdminModel.signInAdmin)
	const [isLoading, setIsLoading] = useState(false)

	const onSignInAdmin = async ({ username, password }) => {
		setIsLoading(true)
		const { from } = location.state || { from: { pathname: '/admin/sdoc' } }

		signInAdmin({
			username,
			password,
		})
			.then(res => {
				setIsLoading(false)
				history.replace(from)
			})
			.catch(e => {
				console.error(e)
				setIsLoading(false)
				setSignInMsg('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง')
			})
	}

	const onShowPassword = () => {
		setShowPassword(!showPassword)
	}

	useEffect(() => {
		if (adminSDOC) history.replace('/admin/sdoc')
		// eslint-disable-next-line
	}, [adminSDOC])

	return (
		<Grid className="h-screen flex flex-col items-center justify-center admin-sign-in-container p-3">
			<Card className="flex flex-col items-center justify-center px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5">
				<Grid className="">
					<img src={logo} alt="NBTC" width="140" />
				</Grid>
				<Grid
					className={`mt-3 text-sm md:text-base text-center ${classes.AppDescription}`}>
					<span className={classes.AppDescriptionSpan}>NBTC</span> SDOC
				</Grid>
				<Grid className={`mt-4 text-sm ${classes.AppDescriptionSpan}`}>
					สำหรับเจ้าหน้าที่
				</Grid>
				<Grid className="mt-6 w-full">
					<Controller
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { onChange, onBlur, value } }) => (
							<TextField
								fullWidth
								type="text"
								label="ชื่อผู้ใช้งาน"
								autoFocus={true}
								variant="filled"
								size="small"
								onChange={onChange}
								value={value}
								name="username"
								onKeyPress={e => {
									if (e.key === 'Enter') handleSubmit(onSignInAdmin)()
								}}
							/>
						)}
						name="username"
						defaultValue={process.env.REACT_APP_DEMO_ADMIN_USERNAME ?? ''}
					/>
					<p className="text-red-500 h-8">
						{errors.username?.type === 'required' && 'กรุณากรอกอีเมล'}
					</p>
					<Controller
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { onChange, onBlur, value } }) => (
							<TextField
								fullWidth
								type={showPassword ? 'text' : 'password'}
								label="รหัสผ่าน"
								variant="filled"
								size="small"
								onChange={onChange}
								value={value}
								name="password"
								InputProps={{
									endAdornment: (
										<IconButton
											onClick={onShowPassword}
											edge="end"
											color="primary">
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									),
								}}
								onKeyPress={e => {
									if (e.key === 'Enter') handleSubmit(onSignInAdmin)()
								}}
							/>
						)}
						name="password"
						defaultValue={process.env.REACT_APP_DEMO_ADMIN_PASSWORD ?? ''}
					/>
					<p className="text-red-500 h-8 my-2">
						{errors.password?.type === 'required' && 'กรุณากรอกรหัสผ่าน'}
						{signInMsg}
					</p>
					<Grid className="flex flex-row w-full justify-between items-center mb-3"></Grid>
					<Divider />
					<Grid className="flex flex-row gap-x-3 w-full justify-center mx-auto py-5">
						<Button
							disabled={isLoading}
							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="large"
							id="admin-signin-button"
							onClick={handleSubmit(onSignInAdmin)}>
							{isLoading ? 'กำลังเข้าสู่ระบบ...' : 'เข้าสู่ระบบ'}
						</Button>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	)
}
