import API from 'config/axios'

const DashboardAPI = {
	getDashboardStat: () => {
		return API.get('/stat/dashboard-admin')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return false
			})
	},
}

export default DashboardAPI
