import API, { adminHeader } from '../config/axios'

const adminAPI = {
	signin: async (username, password, serviceID) => {
		const res = await API.post('/Admins/authenticate', {
			username,
			password,
			serviceID,
		})

		if (res) {
			if (res.status === 200) {
				return res
			} else {
				console.error(res)
				return false
			}
		}
	},
	me: () => {
		return API.get('/Admins/me', {
			headers: adminHeader,
		}).then(res => res)
	},
	getAdmins: () => {
		return API.get('/Admins', {
			headers: adminHeader,
		})
			.then(res => res)
			.catch(err => err)
	},
	getAdminRoles: () => {
		return API.get('/Admins/getRole', {
			headers: adminHeader,
		})
			.then(res => res)
			.catch(err => err)
	},
	addAdmin: (adminRoleID, email, firstname, lastname, adminStatusID) => {
		return API.post(
			'/Admins',
			{ adminRoleID, email, firstname, lastname, adminStatusID },
			{
				headers: adminHeader,
			}
		)
	},
	editAdmin: (
		adminID,
		adminRoleID,
		email,
		firstname,
		lastname,
		adminStatusID
	) => {
		return API.put(
			`/Admins/${adminID}`,
			{ adminRoleID, email, firstname, lastname, adminStatusID },
			{
				headers: adminHeader,
			}
		)
	},
	deleteAdmin: adminID => {
		return API.delete(`/Admins/${adminID}`, {
			headers: adminHeader,
		})
			.then(res => res)
			.catch(err => err)
	},
}

export default adminAPI
