export const fixed_info_details = [
	{
		id: 21,
		no: '1.3.1',
		label:
			'ร้อยละของเวลารวมที่อุปกรณ์กระจายสัญญาณปลายทางไม่สามารถให้บริการได้ใน 1 เดือน (network unavailability : cumulative Last Mile Node outage time in a month ratio)',
		targeted_text: 'ไม่เกินร้อยละ 1 (ให้วัดเป็นรายเดือน)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 1,
		targetCondition: 'le',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าเป็นทศนิยมได้ และต้องมากกว่าหรือเท่ากับ 0)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 7,
		priority: 21,
	},
	{
		id: 22,
		no: '1.3.2',
		label: 'ค่า Round Trip Time (RTT)',
		targeted_text:
			'ไม่เกิน 50 ms (ให้พิจารณาช่วงเวลาจากเกณฑ์ร้อยละ 95 ของช่วงเวลาที่มีการใช้งานหนาแน่น)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 50,
		targetCondition: 'le',
		filling_condition:
			'1) ใช้เป็นค่าชี้วัดอ้างอิงในการตรวจสอบคุณภาพการให้บริการเป็นรายกรณี หรือกรณีแก้ไขปัญหาเรื่องร้องเรียน \n\t\t\t 2) ไม่ต้องจัดส่งรายงานผลการตรวจวัด (รายไตรมาส) ให้สำนักงาน กสทช.',
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		priority: 22,
	},
	{
		id: 23,
		no: '1.3.3',
		label:
			'ความเร็วเฉลี่ยในการส่งข้อมูลแบบ FTP (Average speed of FTP transfers)',
		targeted_text:
			'ไม่ต่ำกว่าร้อยละ 70 ของความเร็วการให้บริการที่ผู้รับใบอนุญาตได้โฆษณาหรือแจ้งให้ผู้ใช้บริการทราบ (ให้พิจารณาช่วงเวลาจากเกณฑ์ร้อยละ 95 ของช่วงเวลาที่มีการใช้งานหนาแน่น)',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 70,
		targetCondition: 'ge',
		filling_condition:
			'1) ใช้เป็นค่าชี้วัดอ้างอิงในการตรวจสอบคุณภาพการให้บริการเป็นรายกรณี หรือกรณีแก้ไขปัญหาเรื่องร้องเรียน \n\t\t\t 2) ไม่ต้องจัดส่งรายงานผลการตรวจวัด (รายไตรมาส) ให้สำนักงาน กสทช.',
		isNeedAnswer: false,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		priority: 23,
	},
]
