import React, { useEffect, useState, useCallback } from 'react'
import { Card, Grid, Divider, Button } from '@material-ui/core/'
import {
	AccountCircle,
	Home,
	AccountBox,
	// Storage,
	Business,
	Work,
	Mail,
	Phone,
} from '@material-ui/icons'

import { operators } from '../../../../api/'

import { useParams } from 'react-router-dom'
import { useStoreRehydrated } from '../../../../store'

import EditInfoModal from '../../../../components/users/organization/edit-info-modal'
import SuccessModal from 'components/modal/SuccessModal'
import FailModal from 'components/modal/FailModal'

export default function OrganizationDetails() {
	const { id } = useParams()
	const isRehydrated = useStoreRehydrated()
	const [organizationData, setOrganizationData] = useState({
		companyName: '',
		citizenId: '',
		companyAddress: '',
		reportTypesFixed: '',
		reportTypesMobile: '',
		companyStatus: '',
		email: '',
		telephoneNumber: '',
		mobileNumber: '',
		apiToken: '',
		isPayphone: null,
		operatorQOS: [],
	})
	const [tempAPIToken, setTempAPIToken] = useState('')
	const [openEditInfoModal, setOpenEditInfoModal] = useState(false)
	const [openFailModal, setOpenFailModal] = useState(false)
	const [openSuccessModal, setOpenSuccessModal] = useState(false)

	const [validSubmitButton, setValidSubmitButton] = useState(true)
	const [email, setEmail] = useState('')
	const [telephone, setTelephone] = useState('')

	const onOpenSuccessModal = () => {
		setOpenSuccessModal(!openSuccessModal)
	}

	const onOpenFailModal = () => {
		setOpenFailModal(!openFailModal)
	}

	const onChangeEditInfoModal = () => {
		setOpenEditInfoModal(!openEditInfoModal)
	}

	// eslint-disable-next-line
	const onOpenEditInfoModal = () => {
		setEmail(organizationData.email)
		setTelephone(organizationData.mobileNumber)
		onChangeEditInfoModal()
	}

	const onSubmitChangeTeleEmail = async () => {
		const res = await operators.editOperatorContactInfo(telephone, email)
		onChangeEditInfoModal()
		if (res.status === 200) {
			onOpenSuccessModal()
		} else {
			onOpenFailModal()
		}
		getOrganizationData()
	}

	const onTeleEmailChange = (value, type) => {
		if (type === 'email') {
			setEmail(value)
		} else if (type === 'telephone') {
			setTelephone(value)
		}
	}

	const getOrganizationData = useCallback(async () => {
		const res = await operators.getOrganizationDataID(id)
		const status = res.status
		const data = res.data
		let reportTypesFixedTemp = ''
		let reportTypesMobileTemp = ''
		if (status === 200) {
			let organizationDataTemp = {
				companyName: data.operatorName,
				citizenId: '1100100010110',
				companyAddress: `${data.operatorInfos.addr} ${data.operatorInfos.postCode ?? ''}`,
				reportTypesFixed: reportTypesFixedTemp,
				reportTypesMobile: reportTypesMobileTemp,
				companyStatus: 'ดำเนินกิจการอยู่',
				email: data.operatorInfos.email,
				telephoneNumber: data.operatorInfos.telephone,
				mobileNumber: data.operatorInfos.mobile,
				apiToken: data.apiToken,
				isPayphone: data.isPayphone,
				operatorQOS: data.operatorQOS,
			}
			setTempAPIToken(data.apiToken)

			setOrganizationData(organizationDataTemp)
		}
	}, [id])

	// const changeAPIToken = async () => {
	// 	const res = await operators.resetAPIToken()
	// 	const status = res.status
	// 	const data = res.data
	// 	if (status === 200) {
	// 		setTempAPIToken(data.token)
	// 		let organizationDataTemp = {
	// 			companyName: organizationData.companyName,
	// 			citizenId: organizationData.citizenId,
	// 			companyAddress: organizationData.companyAddress,
	// 			reportTypesFixed: organizationData.reportTypesFixed,
	// 			reportTypesMobile: organizationData.reportTypesMobile,
	// 			companyStatus: organizationData.companyStatus,
	// 			email: organizationData.email,
	// 			telephoneNumber: organizationData.telephoneNumber,
	// 			mobileNumber: organizationData.mobilephoneNumber,
	// 			apiToken: data.token,
	// 			isPayphone: organizationData.isPayphone,
	// 			operatorQOS: organizationData.operatorQOS,
	// 		}
	// 		setOrganizationData(organizationDataTemp)
	// 	}
	// }

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	useEffect(() => {
		if (email === '' || telephone === '' || !validateEmail(email) || telephone.length < 9) {
			setValidSubmitButton(true)
		} else {
			setValidSubmitButton(false)
		}
	}, [email, telephone])

	useEffect(() => {
		if (isRehydrated) getOrganizationData()
		// eslint-disable-next-line
	}, [isRehydrated])

	return (
		<>
			<Grid className="p-10 ">
				<Card className={`border-t-4 border-card_top_border`}>
					<Grid className="flex flex-col justify-center items-start px-5">
						<Grid className="flex flex-row mt-4">
							<AccountCircle style={{ fontSize: 22 }} className="mr-1" />
							<Grid className="font-bold text-sm text-card_header_color">
								รายละเอียดผู้ประกอบการ
							</Grid>
						</Grid>
						<Grid className="mt-3 mb-1 w-full">
							<Divider />
						</Grid>
						{/* Company Name */}
						<Grid
							className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
							<Grid
								className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
								<AccountBox
									style={{ fontSize: 20 }}
									className="mr-1 h-full text-white"
								/>
								<Grid className="text-white text-sm">ชื่อหน่วยงาน</Grid>
							</Grid>
							<Grid className="pl-4 text-sm flex">
								{organizationData.companyName}
							</Grid>
						</Grid>
						{/* Address */}
						<Grid
							className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
							<Grid
								className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
								<Home
									style={{ fontSize: 20 }}
									className="mr-1 h-full text-white"
								/>
								<Grid className="text-white text-sm">ที่อยู่</Grid>
							</Grid>
							<Grid className="pl-4 text-sm flex">
								{organizationData.companyAddress}
							</Grid>
						</Grid>
						{/* Type of Fixed Report */}
						{/* {organizationData.operatorQOS.find(e => e.id === 2) && (
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Storage
										style={{ fontSize: 20 }}
										className="mr-1 h-full text-white"
									/>
									<Grid className="text-white text-sm">
										ชุดข้อมูลแบบประจำที่
									</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">
									ประเภททั่วไป, ประเภทข้อมูล, ประเภทเสียง
								</Grid>
							</Grid>
						)} */}

						{/* Type of Mobile Report */}
						{/* {organizationData.operatorQOS.find(e => e.id === 1) && (
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Storage
										style={{ fontSize: 20 }}
										className="mr-1 h-full text-white"
									/>
									<Grid className="text-white text-sm">
										ชุดข้อมูลแบบเคลื่อนที่
									</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">
									ประเภททั่วไป, ประเภทข้อมูล, ประเภทเสียง
								</Grid>
							</Grid>
						)} */}

						{/* Organization Status */}
						<Grid
							className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
							<Grid
								className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
								<Work
									style={{ fontSize: 20 }}
									className="mr-1 h-full text-white"
								/>
								<Grid className="text-white text-sm">สถานะการประกอบกิจการ</Grid>
							</Grid>
							<Grid className="pl-4 text-sm flex">
								{organizationData.companyStatus}
							</Grid>
						</Grid>
						{organizationData.isPayphone === 'y' && (
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Grid className="text-white text-sm">
										ให้บริการเครื่องโทรศัพท์สาธารณะ
									</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">ให้บริการ</Grid>
							</Grid>
						)}

						{/* API Token */}
						<Grid className="flex flex-row items-center justify-between h-full w-full gap-x-4">
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-10/12 bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Work
										style={{ fontSize: 20 }}
										className="mr-1 h-full text-white"
									/>
									<Grid className="text-white text-sm">API Token</Grid>
								</Grid>
								<Grid className="px-4 text-sm truncate w-96">
									{tempAPIToken}
								</Grid>
							</Grid>
							<Grid
								className={`flex flex-row items-center rounded gap-x-2 h-12 my-2 w-2/12 `}>
								<Button
									variant="outlined"
									color="inherit"
									className="items-center justify-center w-30 h-12 w-full "
									onClick={() => {
										navigator.clipboard.writeText(tempAPIToken)
									}}>
									Copy
								</Button>
								{/* <Button
									variant="outlined"
									color="inherit"
									className="items-center justify-center w-30 h-12"
									onClick={() => {
										changeAPIToken()
									}}>
									Reset
								</Button> */}
							</Grid>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			{/* Organization Status Card */}
			<Grid className="px-10 pb-10">
				<Card className={`border-t-4 border-card_top_border`}>
					<Grid className="flex flex-col justify-center items-start px-5">
						{/* Header */}
						<Grid className="flex flex-row mt-4 items-center justify-between w-full">
							<Grid className="flex flex-row mt-4 items-center justify-between w-full">
								<Grid className="flex flex-row">
									<Business style={{ fontSize: 22 }} className="mr-1" />
									<Grid className="font-bold text-sm text-card_header_color">
										ข้อมูลสำหรับการแจ้งเตือน
									</Grid>
								</Grid>
								{/* <Button
									variant="contained"
									color="primary"
									onClick={onOpenEditInfoModal}>
									แก้ไขข้อมูลผู้ประกอบการ
								</Button> */}
							</Grid>
						</Grid>
						<Grid className="mt-3 mb-1 w-full">
							<Divider />
						</Grid>

						{/* Notification Settings  */}
						<Grid
							className={`flex flex-row items-center rounded border border-grey-600 h-12 my-2 w-full bg-text_field_bg`}>
							<Grid
								className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
								<Mail
									style={{ fontSize: 20 }}
									className="mr-1 h-full text-white"
								/>
								<Grid className="text-white text-sm">อีเมล</Grid>
							</Grid>
							<Grid className="pl-4 text-sm flex">
								{organizationData.email === null ? '' : organizationData.email}
							</Grid>
						</Grid>
						{/* Phone Num */}
						<Grid
							className={`flex flex-row items-center rounded border border-grey-600 h-12 mt-2 mb-4 w-full bg-text_field_bg`}>
							<Grid
								className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
								<Phone
									style={{ fontSize: 20 }}
									className="mr-1 h-full text-white"
								/>
								<Grid className="text-white text-sm">เบอร์ติดต่อ</Grid>
							</Grid>
							<Grid className="pl-4 text-sm flex">
								{organizationData.mobileNumber}
							</Grid>
						</Grid>
					</Grid>
				</Card>
			</Grid>

			<EditInfoModal
				open={openEditInfoModal}
				onClose={onChangeEditInfoModal}
				handleButton={onSubmitChangeTeleEmail}
				onValueChange={onTeleEmailChange}
				email={email}
				telephone={telephone}
				validSubmitButton={validSubmitButton}
			/>
			<SuccessModal
				title={'การเปลี่ยนแปลงข้อมูลสำเร็จ'}
				open={openSuccessModal}
				handleButton={onOpenSuccessModal}
			/>
			<FailModal
				title={'การเปลี่ยนแปลงข้อมูลไม่สำเร็จ'}
				open={openFailModal}
				handleButton={onOpenFailModal}
			/>
		</>
	)
}
