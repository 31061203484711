const users = [
	
	{
		nationalCardIDNo: '3222222222220',
		email: 'supharerkt@gmail.com',
		firstname: 'นุกูล',
		lastname: 'โชตเศรษฐ์',
		phoneNumber: '0818262134',
	},
	{
		nationalCardIDNo: '3222222222221',
		email: 'supharerkt@gmail.com',
		firstname: 'สุรัชต์',
		lastname: 'องอาจ',
		phoneNumber: '0818262134',
	},
	{
		nationalCardIDNo: '3222222222222',
		email: 'supharerkt@gmail.com',
		firstname: 'ชุติมา',
		lastname: 'เกษมสมประดิษฐ์',
		phoneNumber: '0818262134',
	},
]

export { users }
