export const frequency = [
	{
		name: '≤135 kHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'RFID',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
				],
				power: [
					{
						label: '150 mW (eirp)',
						value: 150,
						unit: 'mW (eirp)',
					},
					{
						label: '0.15 W (eirp)',
						value: 0.15,
						unit: 'W (eirp)',
					},
					{
						label: '0.00015 kW (eirp)',
						value: 0.00015,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '≤315 kHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '150 mW (eirp)',
						value: 150,
						unit: 'mW (eirp)',
					},
					{
						label: '0.15 W (eirp)',
						value: 0.15,
						unit: 'W (eirp)',
					},
					{
						label: '0.00015 kW (eirp)',
						value: 0.00015,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '13.553-13.567 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'RFID',
				title: ['มาตรฐานทางเทคนิค กสทช.มท. 1010-2560'],
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '25-470 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'on-site paging (Pocket Unit)',
				title: ['มาตรฐานทางเทคนิค กสทช.มท. 1028-2558'],
				power: [
					{
						label: '50 mW (eirp)',
						value: 50,
						unit: 'mW (eirp)',
					},
					{
						label: '0.05 W (eirp)',
						value: 0.05,
						unit: 'W (eirp)',
					},
					{
						label: '0.00005 kW (eirp)',
						value: 0.00005,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '26.975-27.405 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '100 mW',
						value: 100,
						unit: 'mW',
					},
					{
						label: '0.1 W',
						value: 0.1,
						unit: 'W',
					},
					{
						label: '0.0001 kW',
						value: 0.0001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '30-50 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '54-74 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '88-108 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'wireless microphone',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1006-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒'],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'เครื่องส่งสัญญาณเสียง',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '165-210 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'wireless microphone',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1006-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒'
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '300-500 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '433.05 - 434.79 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'RFID',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'UAS',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์และเงื่อนไขการอนุญาตให้ใช้คลื่นความถี่ สำหรับอากาศยานซึ่งไม่มีนักบิน สำหรับใช้งานเป็นการทั่วไป',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b110010100001,
			},
		],
	},
	{
		name: '470 - 694 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'เครื่องส่งสัญญาณภาพ หรือเครื่องส่งสัญญาณภาพและเสียง',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W',
						value: 0.01,
						unit: 'W',
					},
					{
						label: '0.00001 kW',
						value: 0.00001,
						unit: 'kW',
					},
				],
				flag: 0b110010100001,
			},
		],
	},
	{
		name: '920-925 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'RFID',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การอนุญาตให้ใช้คลื่นความถี่ย่าน ๙๒๐-๙๒๕ เมกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
				],
				power: [
					{
						label: '50 mW (eirp)',
						value: 50,
						unit: 'mW (eirp)',
					},
					{
						label: '0.05 W (eirp)',
						value: 0.05,
						unit: 'W (eirp)',
					},
					{
						label: '0.00005 kW (eirp)',
						value: 0.00005,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111110100001,
			},
			{
				policy_id: 2,
				tech_type: 'Non-RFID',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การอนุญาตให้ใช้คลื่นความถี่ย่าน ๙๒๐-๙๒๕ เมกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
					'มาตรฐานทางเทคนิค กสทช.มท. 1033-2560',
				],
				power: [
					{
						label: '50 mW (eirp)',
						value: 50,
						unit: 'mW (eirp)',
					},
					{
						label: '0.05 W (eirp)',
						value: 0.05,
						unit: 'W (eirp)',
					},
					{
						label: '0.00005 kW (eirp)',
						value: 0.00005,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111110100001,
			},
		],
	},
	{
		name: '1900-1906 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'cordless telephone',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '10 mW',
						value: 10,
						unit: 'mW',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b110010100001,
			},
		],
	},
	{
		name: '2400-2500 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '100 mW (eirp)',
						value: 100,
						unit: 'mW (eirp)',
					},
					{
						label: '0.1 W (eirp)',
						value: 0.1,
						unit: 'W (eirp)',
					},
					{
						label: '0.0001 kW (eirp)',
						value: 0.0001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'RFID',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '100 mW (eirp)',
						value: 100,
						unit: 'mW (eirp)',
					},
					{
						label: '0.1 W (eirp)',
						value: 0.1,
						unit: 'W (eirp)',
					},
					{
						label: '0.0001 kW (eirp)',
						value: 0.0001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 3,
				tech_type: 'RLAN',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1035-2562',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '100 mW (eirp)',
						value: 100,
						unit: 'mW (eirp)',
					},
					{
						label: '0.1 W (eirp)',
						value: 0.1,
						unit: 'W (eirp)',
					},
					{
						label: '0.0001 kW (eirp)',
						value: 0.0001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 4,
				tech_type: 'UAS',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์และเงื่อนไขการอนุญาตให้ใช้คลื่นความถี่ สำหรับอากาศยานซึ่งไม่มีนักบิน สำหรับใช้งานเป็นการทั่วไป',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '100 mW (eirp)',
						value: 100,
						unit: 'mW (eirp)',
					},
					{
						label: '0.1 W (eirp)',
						value: 0.1,
						unit: 'W (eirp)',
					},
					{
						label: '0.0001 kW (eirp)',
						value: 0.0001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b110010100001,
			},
		],
	},
	{
		name: '5150-5350 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '200 mW (eirp)',
						value: 200,
						unit: 'mW (eirp)',
					},
					{
						label: '0.2 W (eirp)',
						value: 0.2,
						unit: 'W (eirp)',
					},
					{
						label: '0.0002 kW (eirp)',
						value: 0.0002,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'RFID',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '200 mW (eirp)',
						value: 200,
						unit: 'mW (eirp)',
					},
					{
						label: '0.2 W (eirp)',
						value: 0.2,
						unit: 'W (eirp)',
					},
					{
						label: '0.0002 kW (eirp)',
						value: 0.0002,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 3,
				tech_type: 'RLAN',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1035-2562',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '200 mW (eirp)',
						value: 200,
						unit: 'mW (eirp)',
					},
					{
						label: '0.2 W (eirp)',
						value: 0.2,
						unit: 'W (eirp)',
					},
					{
						label: '0.0002 kW (eirp)',
						value: 0.0002,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '5470-5725 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},

				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'RFID',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 3,
				tech_type: 'RLAN',
				title: [
					// 'ประกาศ กสทช. 1010-2562',
					'มาตรฐานทางเทคนิค กสทช.มท. 1035-2562',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '5725-5850 MHz',
		policy: [
			{
				policy_id: 1,
				tech_type: 'ทั่วไป',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 2,
				tech_type: 'RFID',
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 1010-2560',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 3,
				tech_type: 'RLAN',
				title: [
					// 'ประกาศ กสทช. 1010-2562',
					'มาตรฐานทางเทคนิค กสทช.มท. 1035-2562',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่ย่าน ๕ กิกะเฮิรตซ์',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
			{
				policy_id: 4,
				tech_type: 'UAS',
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์และเงื่อนไขการอนุญาตให้ใช้คลื่นความถี่ สำหรับอากาศยานซึ่งไม่มีนักบิน สำหรับใช้งานเป็นการทั่วไป',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				power: [
					{
						label: '1000 mW (eirp)',
						value: 1000,
						unit: 'mW (eirp)',
					},
					{
						label: '1 W (eirp)',
						value: 1,
						unit: 'W (eirp)',
					},
					{
						label: '0.001 kW (eirp)',
						value: 0.001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b110010100001,
			},
		],
	},
	{
		name: '5725-5875 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				tech_type: 'radar',
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '10-10.6 GHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				tech_type: 'radar',
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '24.05-24.25 GHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				tech_type: 'radar',
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '76-81 GHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป และที่แก้ไขเพิ่มเติม ฉบับที่ ๒',
				],
				tech_type: 'radar',
				power: [
					{
						label: '10 mW (eirp)',
						value: 10,
						unit: 'mW (eirp)',
					},
					{
						label: '0.01 W (eirp)',
						value: 0.01,
						unit: 'W (eirp)',
					},
					{
						label: '0.00001 kW (eirp)',
						value: 0.00001,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '2025.5-2053.5 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจําที่ ย่านความถี่ ๒ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '4400–5000 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๕ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '13 dbw',
						value: 13,
						unit: 'dbw',
					},
					{
						label: '20 w',
						value: 20,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '6425–7125 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๖.๗ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '13 dbw',
						value: 13,
						unit: 'dbw',
					},
					{
						label: '20 w',
						value: 20,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '7110 – 7425 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๗.๒ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '13 dbw',
						value: 13,
						unit: 'dbw',
					},
					{
						label: '20 w',
						value: 20,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '7425 – 7725 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๗.๕ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '13 dbw',
						value: 13,
						unit: 'dbw',
					},
					{
						label: '20 w',
						value: 20,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '7725 – 8285 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๘ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '13 dbw',
						value: 13,
						unit: 'dbw',
					},
					{
						label: '20 w',
						value: 20,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '10700 – 11700 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๑๑ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '13 dbw',
						value: 13,
						unit: 'dbw',
					},
					{
						label: '20 w',
						value: 20,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '14500 – 15350 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๑๕ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '10 dbw',
						value: 10,
						unit: 'dbw',
					},
					{
						label: '10 w',
						value: 10,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '17700 – 19700 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๑๘ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '10 dbw',
						value: 10,
						unit: 'dbw',
					},
					{
						label: '10 w',
						value: 10,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '21200 – 23600 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๒๓ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '10 dbw',
						value: 10,
						unit: 'dbw',
					},
					{
						label: '10 w',
						value: 10,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '12750 – 13250 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๑๓ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '55 dBw',
						value: 55,
						unit: 'dBw',
					},
					{
						label: '316 kW (eirp)',
						value: 316,
						unit: 'kW (eirp)',
					},
					{
						label: '10 dbw',
						value: 10,
						unit: 'dbw',
					},
					{
						label: '10 w',
						value: 10,
						unit: 'w',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '10150 – 10300 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๑๐ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '47 dBw',
						value: 47,
						unit: 'dBw',
					},
					{
						label: '50 kW (eirp)',
						value: 50,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '10500 – 10650 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจำที่ ย่านความถี่ ๑๐ กิกะเฮิรตซ์',
				],
				tech_type: 'Fix link',
				power: [
					{
						label: '47 dBw',
						value: 47,
						unit: 'dBw',
					},
					{
						label: '50 kW (eirp)',
						value: 50,
						unit: 'kW (eirp)',
					},
				],
				flag: 0b000000000000,
			},
		],
	},
	{
		name: '300 - 320.1 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุสำหรับการใช้งานเชื่อมโยงสัญญาณระหว่างห้องส่งกับเครื่องส่งวิทยุกระจายเสียง (Studio-Transmitter Link) ย่านความถี่ ๓๐๐-๓๒๐.๑ เมกะเฮิรตซ์',
				],
				tech_type: 'Studio-Transmitter Link',
				power: [
					{
						label: '20 W',
						value: 20,
						unit: 'W',
					},
					{
						label: '18 dBw',
						value: 18,
						unit: 'dBw',
					},
					{
						label: '0 ไม่มี',
						value: 0,
						unit: 'ไม่มี',
					},
				],
				flag: 0b000000000001,
			},
		],
	},
	{
		name: '=0',
		policy: [
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'เครืองโทรศัพท์ (telephone set)',
				power: [
					{
						label: '0 ไม่มี',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'เครืองโทรสาร (facsimile equipment)',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'ระบบโทรศัพท์กดปุ่ม (key telephone system)',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'โมเด็ม (modem)',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'บริภัณฑ์ข้อมูลปลายทาง (data terminal equipment)',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'ตู้สาขาโทรศัพท์อัตโนมัติ (PABX)',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กสทช.มท. 3001-2561',
					'มาตรฐานทางเทคนิค กสทช.มท. 4001-2561'
				],
				tech_type: 'เครืองโทรคมนาคมส่าหรับสายผู้เช่าดิจิทัลแบบต่าง ๆ (xDSL equipment)',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
			{
				title: [
					'มาตรฐานทางเทคนิค กทช.มท. 2001-2550'

				],
				tech_type: 'Optical System',
				power: [
					{
						label: '0',
						value: 0,
						unit: 'ไม่มี',
					},

				],
				flag: 0b111111100001,
			},
		],
	},
	{
		name: '5925-6425 MHz',
		policy: [
			{
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป (ฉบับที่ ๒)',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การอนุญาตให้ใช้คลื่นความถี่ ๕.๙๒๕ - ๖.๔๒๕ กิกะเฮิรตซ์ (Indoor)',
					'มาตรฐานทางเทคนิค กสทช.มท. 1039-2566 (Indoor)'
				],
				tech_type: 'RLAN Wifi 6e (ภายในอาคาร)',
				power: [
					{
						label: '250 mW (eirp)',
						value: 250,
						unit: 'mW (eirp)'
					},
				],
				flag: 0b111111100001,
			},
			{
				title: [
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การใช้คลื่นความถี่และเครื่องวิทยุคมนาคมที่อนุญาตให้มีการใช้งานเป็นการทั่วไป (ฉบับที่ ๒)',
					'ประกาศ กสทช. เรื่อง หลักเกณฑ์การอนุญาตให้ใช้คลื่นความถี่ ๕.๙๒๕ - ๖.๔๒๕ กิกะเฮิรตซ์ (Indoor/Outdoor)',
					'มาตรฐานทางเทคนิค กสทช.มท. 1039-2566 (Indoor/Outdoor)'
				],
				tech_type: 'RLAN Wifi 6e (ภายในและภายนอกอาคาร)',
				power: [
					{
						label: '25 mW (eirp)',
						value: 25,
						unit: 'mW (eirp)'
					},
				],
				flag: 0b111111100001,
			}
		]
	}
	// {
	// 	name: '71.000 - 71.125 GHz',
	// 	policy: [
	// 		{
	// 			title: [
	// 				'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจําที่ ย่านความถี่ ๗๑ - ๗๖ กิกะเฮิรตซ์ (GHz) และ ๘๑ - ๘๖ กิกะเฮิรตซ์ (GHz)',
	// 			],
	// 			tech_type: 'Fix link',
	// 			power: [
	// 				{
	// 					label: '55 dBw',
	// 					value: 55,
	// 					unit: 'dBw',
	// 				},
	// 				{
	// 					label: '316 kW (eirp)',
	// 					value: 316,
	// 					unit: 'kW (eirp)',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	name: '75.875 – 76.000 GHz',
	// 	policy: [
	// 		{
	// 			title: [
	// 				'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจําที่ ย่านความถี่ ๗๑ - ๗๖ กิกะเฮิรตซ์ (GHz) และ ๘๑ - ๘๖ กิกะเฮิรตซ์ (GHz)',
	// 			],
	// 			tech_type: 'Fix link',
	// 			power: [
	// 				{
	// 					label: '55 dBw',
	// 					value: 55,
	// 					unit: 'dBw',
	// 				},
	// 				{
	// 					label: '316 kW (eirp)',
	// 					value: 316,
	// 					unit: 'kW (eirp)',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	name: '81.000 - 81.125 GHz',
	// 	policy: [
	// 		{
	// 			title: [
	// 				'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจําที่ ย่านความถี่ ๗๑ - ๗๖ กิกะเฮิรตซ์ (GHz) และ ๘๑ - ๘๖ กิกะเฮิรตซ์ (GHz)',
	// 			],
	// 			tech_type: 'Fix link',
	// 			power: [
	// 				{
	// 					label: '55 dBw',
	// 					value: 55,
	// 					unit: 'dBw',
	// 				},
	// 				{
	// 					label: '316 kW (eirp)',
	// 					value: 316,
	// 					unit: 'kW (eirp)',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	name: '85.875 – 86.000 GHz',
	// 	policy: [
	// 		{
	// 			title: [
	// 				'ประกาศ กสทช. เรื่อง แผนความถี่วิทยุ กิจการประจําที่ ย่านความถี่ ๗๑ - ๗๖ กิกะเฮิรตซ์ (GHz) และ ๘๑ - ๘๖ กิกะเฮิรตซ์ (GHz)',
	// 			],
	// 			tech_type: 'Fix link',
	// 			power: [
	// 				{
	// 					label: '55 dBw',
	// 					value: 55,
	// 					unit: 'dBw',
	// 				},
	// 				{
	// 					label: '316 kW (eirp)',
	// 					value: 316,
	// 					unit: 'kW (eirp)',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
]
