import { useState, useEffect } from 'react'

export const useWidthExcelInput = sub_label_type => {
	const [valueWidth, setValueWidth] = useState('')
	const [noteWidth, setNoteWidth] = useState('')
	const [serviceValueWidth, setServiceValueWidth] = useState('')

	useEffect(() => {
		if (sub_label_type === 3 || sub_label_type === 2) {
			setValueWidth('w-2/12')
			setNoteWidth('w-2/12')
			setServiceValueWidth('w-2/12')
		} else if (sub_label_type === 1) {
			setValueWidth('w-3/12')
			setNoteWidth('w-3/12')
			setServiceValueWidth('w-3/12')
		}
	}, [sub_label_type])

	return { valueWidth, noteWidth, serviceValueWidth }
}
