import { action } from 'easy-peasy'
export const poaModel = {
	// States
	data: null,
	dataDBD:null,
	selected:null,
	// Setters
	setData: action((state, data) => {
		state.data = data
	}),
	setDataDBD: action((state, data) => {
		state.dataDBD = data
	}),
	
	setSelected: action((state, data) => {
		state.selected = data
	}),

	
}
