import { Card, Grid } from '@material-ui/core'

const InfoBox = ({
	icon,
	iconBgColor = '',
	label = '',
	number = 0,
	unit = '',
}) => {
	return (
		<Card className="w-full">
			<Grid className="flex flex-row h-24 w-full jusitfy-center gap-x-5">
				<Grid
					className="w-3/12 flex justify-center items-center"
					style={{ backgroundColor: iconBgColor }}>
					{icon}
				</Grid>
				<Grid className="w-9/12 flex flex-col justify-between p-2">
					<Grid className="text-lg">{label}</Grid>
					<Grid className="flex flex-row gap-x-3 items-center">
						<Grid className="font-bold text-lg">{number}</Grid>
						<Grid className="text-base">{unit}</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	)
}

export default InfoBox
