import { Grid, Card, Divider, Button } from '@material-ui/core'

import { PlaylistAddCheck } from '@material-ui/icons'
import ReportCard from '../form'

export default function StandardReportStep2({
	flags,
	// Standard Report Handler State
	needToFillFormType,
	currentFilledFormIndex,
	onFillForm,
	// Standard Report Answers
	fixedGeneralAnswers,
	fixedInfoAnswers,
	fixedSoundAnswers,
	mobileGeneralAnswers,
	mobileInfoAnswers,
	mobileSoundAnswers,
	reportMethod,
	// Upload File
	excelFile,
	setExcelFile,
	excelFileShow,
	setExcelFileShow,
}) {
	let excelFileTemp = []
	return (
		<>
			{reportMethod === 'web-form' ? (
				<Grid className="w-full flex flex-col gap-y-6">
					<ReportCard
						flags={flags}
						// Specify the type from current active index
						type={needToFillFormType[currentFilledFormIndex]}
						onFillForm={onFillForm}
						// Standard Report Answers
						fixedGeneralAnswers={fixedGeneralAnswers}
						fixedInfoAnswers={fixedInfoAnswers}
						fixedSoundAnswers={fixedSoundAnswers}
						mobileGeneralAnswers={mobileGeneralAnswers}
						mobileInfoAnswers={mobileInfoAnswers}
						mobileSoundAnswers={mobileSoundAnswers}
					/>
				</Grid>
			) : (
				<Grid className="flex items-center justify-center ">
					<Card className="flex flex-col h-auto p-4 w-full gap-y-2 border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0">
						<Grid className="flex flex-row font-bold text-sm items-center">
							<PlaylistAddCheck style={{ fontSize: 22 }} className="mr-1" />
							<Grid className="text-card_header_color">อัพโหลดไฟล์</Grid>
						</Grid>
						<Grid>
							<Divider />
						</Grid>
						<Grid className="mt-4 font-bold">
							* อัพโหลดไฟล์ข้อมูลใน format .xls, .xlsx เท่านั้น *
						</Grid>
						<Grid className="flex flex-row items-center ml-10 my-1">
							<Button variant="contained" component="label" size="small">
								Choose file
								<input
									id="excelFile"
									type="file"
									value={excelFileTemp}
									hidden
									accept=".xlsx, .xls"
									onChange={e => {
										setExcelFile(e.target.files[0])
										setExcelFileShow(e.target.files[0].name)
									}}
								/>
							</Button>
							<Grid className="ml-2">{excelFileShow}</Grid>
						</Grid>
					</Card>
				</Grid>
			)}
		</>
	)
}
