import { useState, useEffect } from 'react'
import { Grid, Divider, useMediaQuery } from '@material-ui/core'
import { Description } from '@material-ui/icons'

import { useTheme, makeStyles } from '@material-ui/styles'

import PassedQualifiedListItem from './passed-qualified-item'
import UnqualifiedListItem from './unqualified-list-item/index'
import FillingUnqualifiedModal from './filling-unqualified-modal/index'
import AskingValidationModal from './asking-validation-modal/index'

const useStyles = makeStyles(theme => ({
	container: {
		width: '500px',
		overflow: 'scroll',
	},
}))

export default function StandardReportStep4({
	invalidDataSetRecords,
	invalidAnswers,
	onInputValidationReason,
	invalidInputMethod,
	onChangeInvalidaInputMethod,
	openAskingValidationModal,
	onOpenAskingValidationModal,
	onAskInvalidInputMethod,
	currentValiationQuestionNumber,
	openFillingModal,
	onCloseAskingValidationModal,
	onCloseFillingModal,
	currentSelectedItem,
}) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [currentAnswer, setCurrentAnswer] = useState({
		reason: '',
		solution: '',
		periodSolution: null,
		file: null,
	})

	const [currentErrorMessage, setCurrentErrorMessage] = useState('')
	const [currentLabel, setCurrentLabel] = useState({ text: '', no: '' })

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [invalidDataSetRecords])

	useEffect(() => {
		const ans = invalidAnswers.find(
			item => item.id === currentValiationQuestionNumber
		)

		if (ans)
			setCurrentAnswer({
				reason: ans.reason,
				solution: ans.solution,
				periodSolution: ans.periodSolution,
				file: ans.file,
			})
	}, [invalidAnswers, currentValiationQuestionNumber])

	useEffect(() => {
		if (currentSelectedItem) {
			const findError = invalidDataSetRecords.find(
				x => x.id === currentSelectedItem.id
			)
			setCurrentErrorMessage(findError?.errors ?? '')
			if (findError)
				setCurrentLabel({
					text: findError.label,
					no: findError.question_number,
				})
		}
	}, [currentSelectedItem, invalidDataSetRecords])

	const isFillAnswer = id => {
		const ans = invalidAnswers.find(item => item.id === id)
		let fill = false
		if (ans) {
			if (ans.inputMethod === 'message') {
				if (
					ans.periodSolution !== null &&
					ans.reason !== '' &&
					ans.solution !== ''
				) {
					fill = true
				}
			} else if (ans.inputMethod === 'attach') {
				if (ans.file !== null) {
					fill = true
				}
			}
		}
		return fill
	}

	return (
		<>
			<Grid
				className={`border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0 my-8  bg-white shadow-lg overflow-scroll `}>
				{/* Header */}
				<Grid className={`flex flex-row items-center gap-x-2 p-3`}>
					<Description style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						ผลการตรวจสอบข้อมูล
					</Grid>
				</Grid>
				<Divider
					className={`w-full bg-black my-4 ${
						isMobile ? classes.container : ''
					}`}
				/>
				{/* Table Header */}
				<Grid
					className={`flex flex-row p-3 ${isMobile ? classes.container : ''}`}>
					<Grid className="border border-r-1 border-l-0 border-t-0 border-b-0 w-1/12 px-5">
						ข้อ
					</Grid>
					<Grid className="border border-r-1 border-l-0 border-t-0 border-b-0 w-6/12 px-5">
						หัวข้อ
					</Grid>
					<Grid className="border border-r-1 border-l-0 border-t-0 border-b-0 w-3/12 px-5">
						ข้อผิดพลาด
					</Grid>
					<Grid className="w-2/12 px-5 text-center">ให้เหตุผล</Grid>
				</Grid>
				{/* Table Content */}
				{invalidDataSetRecords.map((item, index) => (
					<UnqualifiedListItem
						key={item.question_number + item.label + index}
						question_number={item.question_number}
						label={item.label}
						errorMessage={item.errors}
						isFillAnswer={isFillAnswer(item.id)}
						handleButton={() => {
							onOpenAskingValidationModal(item.id)
						}}
					/>
				))}
				{invalidDataSetRecords.length === 0 && <PassedQualifiedListItem />}
			</Grid>
			<FillingUnqualifiedModal
				currentSelectedItem={currentSelectedItem}
				id={currentSelectedItem.id}
				question_number={currentLabel.no}
				question_label={currentLabel.text}
				errorMessage={currentErrorMessage}
				currentAnswer={currentAnswer}
				open={openFillingModal}
				onClose={onCloseFillingModal}
				handleButton={onCloseFillingModal}
				onInputValidationReason={onInputValidationReason}
			/>
			<AskingValidationModal
				open={openAskingValidationModal}
				onClose={onCloseAskingValidationModal}
				handleButton={onCloseAskingValidationModal}
				invalidInputMethod={currentSelectedItem.inputMethod}
				onChangeInvalidaInputMethod={onAskInvalidInputMethod}
			/>
		</>
	)
}
