const organization = [
	{
		id: 1,
		title: 'เอไอเอส',
		taxno: '123456',
		addr: 'เลขที่ 414 อาคารเอไอเอส 1 ชั้น 13 ส่วนหน้า ถนนพหลโยธิน แขวงสามเสนใน เขตพญาไท กรุงเทพฯ 10400',
	},
	// {
	// 	id: 13,
	// 	title: 'บมจ.โทรคมนาคมแห่งชาติ จำกัด',
	// 	taxno: '0107564000014',
	// 	addr: '99 ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพ  10210',
	// },
	{
		id: 3,
		title: 'ดีแทค',
		taxno: '178456',
		addr: 'บริษัท โทเทิ่ล แอ็คเซ็ส คอมมูนิเคชั่น จำกัด (มหาชน) เลขที่ 319 อาคารจัตุรัสจามจุรี ชั้น 38 ถนนพญาไท แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330. โทรศัพท์: 02-202-8000.',
	},
	{
		id: 14,
		title: 'บริษัท ทรู มูฟ เอช ยูนิเวอร์แซล คอมมิวนิเคชั่น จำกัด',
		taxno: '0105553045044',
		addr: 'เลขที่ 18 อาคารทรู ทาวเวอร์ ถนนรัชดาภิเษก แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310',
		dataType:[1],
	},
	{
		id: 15,
		title: 'บริษัท ทรู อินเทอร์เน็ต คอร์ปอเรชั่น จำกัด',
		taxno: '0105549025026',
		addr: 'เลขที่ 18 อาคารทรู ทาวเวอร์ ถนนรัชดาภิเษก แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310',
		dataType:[2],
	},
	{
		id: 13,
		title: 'บริษัท สื่อสาร จำกัด',
		taxno: '1234567890123',
		addr: 'กรุงเทพมหานคร',
		dataType:[1,2],
	},
]

export { organization }
