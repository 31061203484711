import { Grid, useMediaQuery } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
	container: {
		width: '500px',
	},
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundColor: '#a74345',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundColor: '#a74345',
	},
}))

export default function PassedQualifiedItem() {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Grid
			className={`w-full p-3 py-10 text-center ${
				isMobile ? classes.container : ''
			}`}>
			หัวข้อทั้งหมดผ่านเกณฑ์
		</Grid>
	)
}
