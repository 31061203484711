import { forwardRef ,useState,useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	IconButton,
	Slide,
	useMediaQuery,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export default function SignSignatureModal({
	open,
	onClose,
	handleButton,
	onChangeCheckerAdminID,
	checkerAdminList,
	checkerAdminID,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		setIsLoading(false)
	}, [open])


	return (
		<Dialog
			maxWidth="md"
			fullWidth
			fullScreen={fullScreen}
			onClose={onClose}
			open={open}
			TransitionComponent={Transition}>
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{/* <Grid className="text-white text-lg">เลือกผู้ตรวจสอบข้อมูล</Grid> */}
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<Grid className="flex flex-col justify-center items-center py-16 w-full" style={{fontSize:18}}>
					{/* <FormControl className="w-5/6">
						<InputLabel id="demo-simple-select-label">
							รายชื่อผู้ตรวจข้อมูล
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={checkerAdminID}
							label="รายชื่อผู้ตรวจข้อมูล"
							onChange={onChangeCheckerAdminID}>
							{checkerAdminList.map(admin => {
								return (
									<MenuItem key={admin.id} value={admin.id}>
										{admin.name ? admin.name : `${admin.firstname} ${admin.lastname}`}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl> */}
					ยืนยันการออกหนังรับรองด้วยลายเซนต์ของท่าน
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-3/12" component="div">
						<Button
							fullWidth
							onClick={e=>{handleButton(e); setIsLoading(true)}}
							color="secondary"
							variant="contained"
							disabled={isLoading}>
							{isLoading ? 'กำลังดำเนินการ...' : 'ยืนยัน'}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
