import { useState, useEffect, useCallback, useRef } from 'react'
import { Grid, Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';

import OrganizationDetailCard from '@components/sdoc/organization-detail-card'
import OrganizationDetailCardV2 from '@components/sdoc/organization-detail-cardV2'
import OrganizationDetailCardV3 from '@components/sdoc/organization-detail-cardV3'
import BrandNameCard from 'components/sdoc/brand-name-card/index'
import EquipmentCard from 'components/sdoc/equipment-card/index'
import DocumentAdditionCard from 'components/sdoc/document-addition-card/index'
import SureModal from '@components/SureModal'
import SuccessModal from '@components/modal/SuccessModal'

import { useLocation, useParams, useHistory } from 'react-router-dom'

import { useQuery } from 'utils/useQuery'
import {
	operators,
	sdocUser,
	sdoc,

} from 'api/index'

import { frequency, powerUnit, type_equipment } from 'data/sdoc'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'

import { useStoreState } from 'store/index'
import datasetRecordAPI from 'api/dataset-record';
import { maxWidth } from '../../../../../node_modules/tailwindcss/lib/plugins/index';

// Intitial State

const EQUIPMENT_INITIAL_STATE = {
	ProductTypeText: '',
	FrequencyText: '',
	PowerText: '',
	PolicyText: '',
	SelectedPowerUnit: '',
	PolicyText_2: '',
	PolicyText_3: '',
	Flag: '',
	isError: false,
}

const CONFIRMATION_INFO_STORAGE_INITIAL_STATE = {
	normal_person: {
		type: 'normal-person',
		name: '',
		cardNo: '',
		address: '',
	},
	organization: {
		type: 'organization',
		name: '',
		cardNo: '',
		address: '',
		prefix: '',
		branchNo: '',
	},
}

const EQUIPMENT_MASTER_DATA_INTITIAL_STATE = {
	productType: [],
	typeEquipment: [],
	frequency: [],
	powerUnit: [],
}

const INPUT_MODEL_INITIAL_STATE = {
	brandName: '',
	productModel: '',
	productDescription: '',
}

const TEMP_FILE_INTIAL_STATE = {
	file: null,
	name: '',
	// filedate: '',
}

const SUBMIT_TEXT_MODAL_INITIAL_STATE = {
	title: '',
	content: '',
	handleButton: null,
}

// Page Description: ยื่นแบบหนังสือรับรองตนเอง
export default function SDOC() {
	const history = useHistory()
	const location = useLocation()
	const query = useQuery()

	const { id: sDOCID } = useParams()
	const mountType = query.get('mountType')

	// eslint-disable-next-line
	const [sDOCId, setSDOCId] = useState(null)

	// Confirmation Info. Status
	const [currentSenderState, setCurrentSenderState] = useState('normal_person')
	const [confirmationInfoStorage, setConfirmationInfoStorage] = useState(
		CONFIRMATION_INFO_STORAGE_INITIAL_STATE
	)
	// Confirmation Info.

	const [equipmentMasterData, setEquipmentMasterData] = useState(
		EQUIPMENT_MASTER_DATA_INTITIAL_STATE
	)
	const [inputModel, setInputModel] = useState(INPUT_MODEL_INITIAL_STATE)
	// Input Equipment State
	const [inputEquipmentState, setInputEquipmentState] = useState([
		EQUIPMENT_INITIAL_STATE,
	])
	// Additional Files
	const [tempFile, setTempFile] = useState(TEMP_FILE_INTIAL_STATE)
	const [additionalFiles, setAdditionalFiles] = useState([])
	// Modal States
	const [submitEquipmentSureModal, setSubmitEquipmentSureModal] =
		useState(false)
	const [openSubmitEquipmentSuccessModal, setOpenSubmitEquipmentSuccessModal] =
		useState(false)
	const [submitTextModal, setSubmitTextModal] = useState(
		SUBMIT_TEXT_MODAL_INITIAL_STATE
	)
	const [isSubmit, setIsSubmit] = useState(false)
	// Validation States
	const [canSubmit, setCanSubmit] = useState(false)
	const [isEquipmentCardValid, setIsEquipmentCardValid] = useState(false)
	const [attachFileLength, setAttachFileLength] = useState(0)
	const [requestAddiotionFile, setRequestAddiotionFile] = useState('')
	const [requestModifyInfo, setRequestModifyInfo] = useState('')
	const [ejectRemark, setEjectRemark] = useState('')

	const [isProfileError, setIsProfileError] = useState(false)

	const [uploadProgress, setUploadProgress] = useState(0);


	// GET POA DATA
	const userSDOC = useStoreState(s => s.sdocUserModel.userSDOC)

	/** <---------------------------------------------------------------------> */

	const onOpenSubmitEquipmentSureModal = () => {

		if (mountType === 'attachFile')
			setSubmitTextModal({
				title: 'แนบเอกสารเพิ่มเติม',
				content:
					'คุณแน่ใจที่จะแนบเอกสารสำหรับยื่นแบบหนังสือรับรองตนเองหรือไม่?​',
				handleButton: () => onSubmitAddtionalFiles(sDOCID),
			})
		else
			if (mountType === 'ModifyInfo')
				setSubmitTextModal({
					title: 'ปรับปรุงข้อมูลเอกสาร',
					content:
						'คุณแน่ใจที่จะส่งข้อมูลที่ถูกปรับปรุงชุดนี้แล้วหรือไม่?​',
					handleButton: () => onSubmitModifyInfo(sDOCID),
				})
			else
				setSubmitTextModal({
					title: 'ยื่นแบบหนังสือรับรองตนเอง',
					// content: 'คุณแน่ใจที่จะยื่นแบบหนังสือรับรองตนเอง​หรือไม่?',
					content: (
						<div>

							คุณกำลังยื่นแบบหนังสือรับรองตนเอง​ในนามของ "<a style={{ color: 'red' }}>{confirmationInfoStorage[currentSenderState].type === 'organization' ? 'นิติบุคคล' : 'บุคคลธรรมดา'}</a>"<br />
							คุณสามารถดูตัวอย่างหนังสือรับรองตนเองก่อนยื่นได้ <a style={{ color: 'red', cursor: 'pointer', textDecoration: 'underline' }} onClick={handlePreviewButton}>ที่นี่</a> <br />
							คุณต้องการดำเนินการต่อหรือไม่? <br />
							<a style={{ color: 'red' }}>* หมายเหตุ เอกสารแนบที่แนบมาพร้อมคำขอนี้มีจำนวนทั้งสิ้น {additionalFiles.length} ไฟล์</a>
						</div>
					),
					handleButton: onSubmitEquipment,
				})
		setSubmitEquipmentSureModal(!submitEquipmentSureModal)
	}

	const onOpenSubmitEquipmentSuccessModal = () =>
		setOpenSubmitEquipmentSuccessModal(!openSubmitEquipmentSuccessModal)

	const disabledRuleSubmitButton = () => {
		const isCardError = inputEquipmentState.some(s => s.isError)
		if (isCardError) {
			setCanSubmit(true)
		} else if (mountType === 'attachFile') {
			setCanSubmit(false)
		} else if (isEquipmentCardValid && (!mountType || (mountType && mountType == "ModifyInfo")) && (confirmationInfoStorage[currentSenderState].address)) {
			setCanSubmit(false)
		} else {
			setCanSubmit(true)
		}
	}

	// case: mountType is 'readOnly'
	const getListAndPrefield = useCallback(async () => {
		const res = await sdoc.getListDetail(sDOCID)

		if (res && res.status === 200) {
			const findedList = res.data

			// หากขอเอกสารเพิ่มเติมและมีการแนบหมายเหตุ
			if (findedList.sDocStatusID === 3) {

				if (findedList.requestAddtionDocStatusID === 1 && findedList.requestAdditionFileRemark !== null) {
					setRequestAddiotionFile(findedList.requestAdditionFileRemark);
				}
				if (findedList.requestAddtionDocStatusID === 2 && findedList.requestModifyInfoRemark !== null) {
					setRequestModifyInfo(findedList.requestModifyInfoRemark);
				}

			}
			if (findedList.sDocStatusID === 2) {
				if (findedList.requestAddtionDocStatusID === 1 && findedList.requestAdditionFileRemark !== null) {
					setRequestAddiotionFile(findedList.requestAdditionFileRemark);
				}
				if (findedList.requestAddtionDocStatusID === 2 && findedList.requestModifyInfoRemark !== null) {
					setRequestModifyInfo(findedList.requestModifyInfoRemark);
				}

			}
			if (findedList.sDocStatusID === 6) {
				setEjectRemark(findedList.remark);
			}

			// console.log(data);
			if (findedList.senderType === 'organization') {
				const res2 = await operators.getOperatorInfo({ taxno: findedList.operatorTaxNo, branchno: findedList.operatorBranchNo });
				if (res2 && res2.status === 200 && res2?.data.status !== 'error') {
					var data = res2.data
				}
				setCurrentSenderState('organization')
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						organization: {
							type: 'organization',
							name: data.operators.operatorName ?? '',
							cardNo: data.operators.taxNo ?? '',
							prefix: data.operators.prefix ?? '',
							branchNo: data.operatorInfo.branchNo,
							address: data.operatorInfo.addr ?? '',
							email: data.operatorInfo.email ?? '',
							mobile: data.operatorInfo.mobile ?? '',

						},
					}
				})
			} else if (findedList.senderType === 'normal-person') {
				const res3 = await operators.getOperatorUserInfo({ cardNo: findedList.operatorTaxNo })
				if (res3 && res3.status === 200 && res3?.data.status !== 'error') {
					var data2 = res3.data
				}
				setCurrentSenderState('normal_person')
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						normal_person: {
							type: 'normal-person',
							// name: findedList.operatorTitle ?? '',
							name: (data2?.operatorUser?.prefix ?? '') + data2?.operatorUser?.firstname + ' ' + data2?.operatorUser?.lastname,
							cardNo: findedList.operatorTaxNo ?? '',
							branchNo: findedList.operatorBranchNo ?? '00000',
							address: data2?.operatorUser?.address ?? '',
							email: data2?.operatorUser?.email ?? '',
							mobile: data2?.operatorUser?.mobile ?? '',
						},
					}
				})
			}

			if (findedList) {
				const productModel = findedList.productModel
				const productDescription = findedList.productDescription
				const brandName = findedList.brandName
				const sDocItems = findedList.sDocItems
				const sDocAttachs = findedList.sDocAttachs

				setInputModel({
					brandName: brandName ?? '',
					productModel: productModel ?? '',
					productDescription: productDescription ?? '',
				})

				const newList = sDocItems.map(sdocItem => {
					const regexpSize = /^(\S+)\s(.+)/
					const match = sdocItem.powerText.match(regexpSize)

					const obj = {
						ProductTypeText: sdocItem.productTypeText,
						FrequencyText: sdocItem.frequencyText,
						PolicyText: sdocItem.policyText,
						PowerText: match ? match[1] : '',
						SelectedPowerUnit: match ? match[2] : '',
						PolicyText_2: sdocItem.policyText_2,
						PolicyText_3: sdocItem.policyText_3,
						Flag: sdocItem.flag,
						isError: false,
					}

					return obj
				})

				setInputEquipmentState(newList)

				setAttachFileLength(sDocAttachs.length)
				setAdditionalFiles(() => {
					const newList = sDocAttachs.map(sdocAttach => {
						return {
							fileID: sdocAttach.id,
							file: sdocAttach.fileName,
							name: sdocAttach.title,
							isfilelocked: sdocAttach.isFileLocked ?? false,
							// filedate: sdocAttach.fileDate,
						}
					})
					return newList
				})
			}
		}
		// eslint-disable-next-line
	}, [])

	/** <---------------------------------------------------------------------> */

	// Organization Detail Function

	const onChangeSenderType = e => {
		const { value } = e.target
		setCurrentSenderState(value)
	}

	const onChangeCompanyInfo = e => {
		setConfirmationInfoStorage(prev => ({ ...prev, organization: e }))
	}

	const getUserProfile = async () => {
		const res = await sdocUser.getMe()
		if (res && res?.status === 200) {
			const data = res.data
			const res2 = await sdocUser.getMyProfile(userSDOC.poa)
			if (res2 && res2?.status === 200) {
				// console.log(res2.data)
				var data2 = res2.data
			}
			if (data.prefix == null) {
				Swal.fire({
					icon: 'error',
					title: 'ข้อมูลส่วนตัวของผู้ยื่นแบบไม่ครบถ้วน',
					text: 'กรุณาตรวจสอบและปรับปรุงข้อมูลในเมนู "ข้อมูลผู้ใช้งาน" (ท่านยังไม่ได้ระบุคำนำหน้าชื่อ)',
					confirmButtonText: 'ตกลง',
				})
				setIsProfileError(true)
			}
			if (data2?.taxNo != "null" && (data2?.operators[0].prefix == null || data2?.operators[0].prefix.length < 2)) {
				Swal.fire({
					icon: 'error',
					title: 'ข้อมูลบริษัทไม่ครบถ้วน',
					text: 'กรุณาตรวจสอบและปรับปรุงข้อมูลในเมนู "ข้อมูลผู้ใช้งาน" (ท่านยังไม่ได้ระบุคำนำหน้าชื่อบริษัท)',
					confirmButtonText: 'ตกลง',
				})
				setIsProfileError(true)
			}
			if (data.addr == null || data.zipcode == null || data.province == null || data.amphoe == null || data.district == null) {
				Swal.fire({
					icon: 'error',
					title: 'ข้อมูลที่อยู่ไม่ถูกต้อง หรือรูปแบบผิด',
					text: 'กรุณาตรวจสอบและปรับปรุงข้อมูลในเมนู "ข้อมูลผู้ใช้งาน" และกรอกข้อมูลลงฟอร์มให้ถูกต้อง',
					confirmButtonText: 'ตกลง',
				})
				setIsProfileError(true)
			}
			setConfirmationInfoStorage(prevState => {
				return {
					...prevState,
					normal_person: {
						type: 'normal-person',
						name: (data.prefix ?? "") + data.firstname + ' ' + data.lastname,
						cardNo: data.nationalIDCardNo,
						branchNo: '00000',
						address: data.address ?? '',
						email: data.email ?? '',
						mobile: data.mobile ?? '',
					},
					organization: {
						type: 'organization',
						cardNo: userSDOC.poa ?? '',
						prefix: data2.operators[0]?.prefix ?? '',
						name: data2.operators[0]?.operatorName ?? '',
					}
				}
			})
		}
	}



	/** <---------------------------------------------------------------------> */

	// Equipment Card

	const onChangeInputModel = e => {
		const { name, value } = e.target
		setInputModel(prevState => ({ ...prevState, [name]: value }))
	}

	const onChangeInputEquipmentState = (name, value, key) => {
		setInputEquipmentState(prevState => {
			const newState = [...prevState]

			if (name === 'ProductTypeText')
				newState[key] = {
					...prevState[key],
					ProductTypeText: value,
					FrequencyText: '',
					PolicyText: '',
					PolicyText_2: '',
					PolicyText_3: '',
					Flag: '',
				}
			else if (name === 'FrequencyText')
				newState[key] = {
					...prevState[key],
					FrequencyText: value,
					PolicyText: '',
					PolicyText_2: '',
					PolicyText_3: '',
					Flag: '',
				}
			else newState[key] = { ...prevState[key], [name]: value }

			return newState
		})
	}

	const onFetchMasterdata = async () => {
		setEquipmentMasterData({
			productType: [],
			typeEquipment: type_equipment,
			frequency: frequency,
			powerUnit: powerUnit,
		})
	}

	const addMoreItem = () => {
		setInputEquipmentState(prevState => [...prevState, EQUIPMENT_INITIAL_STATE])
	}

	const onDeleteItem = index => {
		if (inputEquipmentState.length === 1) return
		setInputEquipmentState(prevState => {
			const newList = prevState.filter((item, prevIndex) => prevIndex !== index)
			return newList
		})
	}

	const onEquipmentCardError = (errIdx, isError) => {
		setInputEquipmentState(prevState => {
			const newList = prevState.map((item, prevIndex) => {
				if (prevIndex === errIdx) {
					item.isError = isError
				}
				return item
			})
			return newList
		})
	}

	const isExecuting = useRef(false);
	const handlePreviewButton = () => {
		onSubmitPreviewSDoC()
	}
	const onSubmitPreviewSDoC = async () => {
		// prevent multiple submit
		if (isExecuting.current) return

		try {
			isExecuting.current = true;
			Swal.fire({
				icon: 'info',
				title: 'กำลังดำเนินการ',
				text: 'กรุณารอสักครู่...',
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				footer: 'โปรดรอสักครู่....',
			})

			const items = inputEquipmentState.map(item => {
				return {
					ProductTypeText: item.ProductTypeText,
					PolicyText: item.PolicyText,
					FrequencyText: item.FrequencyText,
					PowerText: item.PowerText + ' ' + item.SelectedPowerUnit,
					PolicyText_2: item.PolicyText_2,
					PolicyText_3: item.PolicyText_3,
					Flag: item.Flag,
				}
			})
			let title = [];
			additionalFiles.forEach(file => {
				title.push(file.name);
			})

			const res = await sdoc.getPreviewSDoC(
				inputModel.brandName,
				inputModel.productModel,
				inputModel.productDescription,
				confirmationInfoStorage[currentSenderState],
				items,
				title
			)
			if (res && res.status === 200) {
				const base64Data = res.data.file; // Assuming the response data is already in base64 format

				// Convert base64 data to a Blob
				const byteCharacters = atob(base64Data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], { type: 'application/pdf' });

				// Create a URL for the Blob
				const blobUrl = URL.createObjectURL(blob);

				// Open the Blob URL in a new tab
				window.open(blobUrl, '_blank');
			}
		} finally {
			isExecuting.current = false;
			Swal.close();
		}

	}

	const onSubmitEquipment = async () => {
		// prevent multiple submit
		if (isSubmit) return
		setIsSubmit(true)

		const items = inputEquipmentState.map(item => {
			return {
				ProductTypeText: item.ProductTypeText,
				PolicyText: item.PolicyText,
				FrequencyText: item.FrequencyText,
				PowerText: item.PowerText + ' ' + item.SelectedPowerUnit,
				PolicyText_2: item.PolicyText_2,
				PolicyText_3: item.PolicyText_3,
				Flag: item.Flag,
			}
		})

		const res = await sdoc.addList(
			inputModel.brandName,
			inputModel.productModel,
			inputModel.productDescription,
			confirmationInfoStorage[currentSenderState],
			items
		)
		if (res && res.status === 200) {
			setSDOCId(res.data.id)
			if (additionalFiles.length !== 0) {
				setIsSubmit(false)
				onSubmitAddtionalFiles(res.data.id)
			} else {
				onOpenSubmitEquipmentSuccessModal()
			}
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 400) {
			Swal.fire({
				icon: 'error',
				title: 'ข้อมูลคำขอไม่ถูกต้อง',
				text: res.data.message,
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'เกิดข้อผิดพลาดจากระบบ',
				text: 'กรุณาลองใหม่ภายหลัง',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 408) {
			Swal.fire({
				icon: 'error',
				title: 'หมดเวลาในการทำรายการ',
				text: 'กรุณาตรวจสอบคำขอในเมนู สถานะการยื่นแบบรับรองตนเอง',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else {
			Swal.fire({
				icon: 'error',
				title: 'เกิดข้อผิดพลาดจากระบบ',
				text: 'กรุณาตรวจสอบคำขอในเมนู สถานะการยื่นแบบรับรองตนเอง (error code: ' + res.status + ')',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		}

	}

	const onSubmitModifyInfo = async id => {
		if (isSubmit) return
		setIsSubmit(true)

		const items = inputEquipmentState.map(item => {
			return {
				ProductTypeText: item.ProductTypeText,
				PolicyText: item.PolicyText,
				FrequencyText: item.FrequencyText,
				PowerText: item.PowerText + ' ' + item.SelectedPowerUnit,
				PolicyText_2: item.PolicyText_2,
				PolicyText_3: item.PolicyText_3,
				Flag: item.Flag,
			}
		})

		const res = await sdoc.editList(
			id,
			inputModel.brandName,
			inputModel.productModel,
			inputModel.productDescription,
			confirmationInfoStorage[currentSenderState],
			items,
		)
		console.log(res.status);
		if (res && res.status === 200) {
			setSDOCId(res.data.id)
			if (additionalFiles.length > attachFileLength) {
				setIsSubmit(false)
				onSubmitAddtionalFiles(res.data.id)
			} else onOpenSubmitEquipmentSuccessModal()
			setIsSubmit(false)
		} else if (res && res.status === 400) {
			Swal.fire({
				icon: 'error',
				title: 'ข้อมูลคำขอไม่ถูกต้อง',
				text: res.data.message,
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'เกิดข้อผิดพลาดจากระบบ',
				text: 'กรุณาลองใหม่ภายหลัง',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 408) {
			Swal.fire({
				icon: 'error',
				title: 'หมดเวลาในการทำรายการ',
				text: 'กรุณาตรวจสอบคำขอในเมนู สถานะการยื่นแบบรับรองตนเอง',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else {
			Swal.fire({
				icon: 'error',
				title: 'เกิดข้อผิดพลาดจากระบบ',
				text: 'กรุณาตรวจสอบคำขอในเมนู สถานะการยื่นแบบรับรองตนเอง (error code: ' + res.status + ')',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		}
	}

	/** <---------------------------------------------------------------------> */

	// Document Additional Card

	const onAddTemp = (e, type) => {
		const { files, value } = e.target
		if (type === 'file' && files.length !== 0)
			setTempFile({ ...tempFile, file: files[0] })
		if (type === 'name') setTempFile({ ...tempFile, name: value })
		// if (type === 'filedate') setTempFile({ ...tempFile, filedate: value })
	}

	const onAddDocument = () => {
		// check sum size all file
		let sumSize = 0
		additionalFiles.forEach(file => {
			sumSize += file.file.size
		})
		sumSize += tempFile.file.size
		if (sumSize > 314572800) {
			// if (sumSize > 100000000){
			Swal.fire({
				icon: 'error',
				title: 'ขนาดไฟล์เกินขีด จำกัด',
				text: 'ขนาดไฟล์รวมกันต้องไม่เกิน 300 MB',
			})
			return
		}
		if (tempFile.file.type !== 'application/pdf') {
			Swal.fire({
				icon: 'error',
				title: 'ชนิดไฟล์ไม่ถูกต้อง',
				text: 'ชนิดไฟล์ต้องเป็น PDF เท่านั้น',
			})
			return
		}
		// if (tempFile.file && tempFile.name !== '' && tempFile.filedate !== '') {
		if (tempFile.file && tempFile.name !== '') {
			setAdditionalFiles(prevState => [
				...prevState,
				// { file: tempFile.file, name: tempFile.name, filedate: tempFile.filedate },
				{ file: tempFile.file, name: tempFile.name },
			])
			// setTempFile({ file: null, name: '', filedate: '' })
			setTempFile({ file: null, name: '' })
		}
	}

	const onChangeAttachedFileName = (e, index) => {
		const { value } = e.target
		setAdditionalFiles(prevState => {
			const newList = prevState.map((item, itemIndex) => {
				if (itemIndex === index)
					return {
						file: item?.file,
						name: value,
						// filedate: item?.filedate,
					}

				return item
			})
			return newList
		})
	}

	const onDeleteDocument = index => {
		setAdditionalFiles(prevState => {
			const temp_list = prevState.filter((_, listInd) => {
				return listInd !== index
			})
			setAttachFileLength(temp_list.length)
			return temp_list
		})
	}

	const onDeletedUploadedDocument = index => {
		setAdditionalFiles(prevState => {
			const temp_list = prevState.filter((_, listInd) => {
				return listInd !== index
			})
			setAttachFileLength(attachFileLength - 1)
			return temp_list
		})
	}

	const onSubmitAddtionalFiles = async id => {
		if (isSubmit) return
		setIsSubmit(true)
		const currentAdditionalFileLength = additionalFiles.length
		let sendedFiles = []

		if (mountType && (mountType === 'attachFile' || mountType === 'ModifyInfo')) {
			const amountOfNewFiles = currentAdditionalFileLength - attachFileLength

			additionalFiles.forEach((file, index) => {
				if (index > currentAdditionalFileLength - amountOfNewFiles - 1)
					sendedFiles.push(file)
			})
		} else sendedFiles = additionalFiles

		const res = await sdoc.attachAdditionalFiles(id, sendedFiles, percentCompleted => {
			setUploadProgress(percentCompleted);
		});
		console.log("upload file complete");
		console.log(res);
		if (res && res.status === 200) {
			onOpenSubmitEquipmentSuccessModal()
			setIsSubmit(false)
		} else if (res && res.status === 400) {
			Swal.fire({
				icon: 'error',
				title: 'ข้อมูลคำขอไม่ถูกต้อง',
				text: res.data.message,
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'เกิดข้อผิดพลาดจากระบบ',
				text: 'กรุณาลองใหม่ภายหลัง',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else if (res && res.status === 408) {
			Swal.fire({
				icon: 'error',
				title: 'หมดเวลาในการทำรายการ',
				text: 'กรุณาตรวจสอบคำขอในเมนู สถานะการยื่นแบบรับรองตนเอง',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		} else {
			Swal.fire({
				icon: 'error',
				title: 'เกิดข้อผิดพลาดจากระบบ',
				text: 'กรุณาตรวจสอบคำขอในเมนู สถานะการยื่นแบบรับรองตนเอง (error code: ' + res.status + ')',
				confirmButtonText: 'ตกลง',
			})
			setSubmitEquipmentSureModal(false)
			setIsSubmit(false)
		}
	}

	const handleSubmitSuccess = () => {
		if (mountType && mountType === 'attachFile') {
			history.push('/sdoc/manage')
		}
		onOpenSubmitEquipmentSureModal()
		onOpenSubmitEquipmentSuccessModal()
		setSDOCId(null)
		setInputModel(INPUT_MODEL_INITIAL_STATE)
		setInputEquipmentState([EQUIPMENT_INITIAL_STATE])
		setTempFile(TEMP_FILE_INTIAL_STATE)
		setAdditionalFiles([])
		history.push('/sdoc/manage')
	}

	const onLandingFromEdit = () => {
		if (!mountType) {
			setSDOCId(null)
			setInputModel(INPUT_MODEL_INITIAL_STATE)
			setInputEquipmentState([EQUIPMENT_INITIAL_STATE])
			setTempFile(TEMP_FILE_INTIAL_STATE)
			setAdditionalFiles([])
		}
	}

	const onDeleteUploadedDocument = async (id, index) => {
		Swal.fire({
			title: 'คุณต้องการลบไฟล์นี้ใช่หรือไม่?',
			text: 'คุณจะไม่สามารถกู้คืนไฟล์นี้ได้หากลบไปแล้ว',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'ใช่, ลบไฟล์นี้',
			cancelButtonText: 'ยกเลิก',
		}).then(async result => {
			if (result.value) {
				const res = await sdoc.deleteAdditionalFile(sDOCID, id)
				if (res && res.status === 200) {
					onDeletedUploadedDocument(index)
					Swal.fire('ลบไฟล์สำเร็จ!', 'ไฟล์ของคุณถูกลบแล้ว', 'success')
				}
			}
		})
		// const res = await sdoc.deleteAdditionalFile(id)
		// if (res && res.status === 200) {
		// 	onDeleteDocument(index)

		// }

	}

	/** <---------------------------------------------------------------------> */

	// Trigger when change the confirmation type
	// useEffect(() => {
	// 	// if (confirmationInfo.type === 'normal-person'  ) {
	// 	// 	if (confirmationInfoHasFetched.normal_person && !sDOCId) {
	// 	// 		setConfirmationInfo(prevState => {
	// 	// 			return {
	// 	// 				...prevState,
	// 	// 				type: confirmationInfoStorage.normal_person.type,
	// 	// 				name: confirmationInfoStorage.normal_person.name,
	// 	// 				cardNo: confirmationInfoStorage.normal_person.cardNo,
	// 	// 				address: confirmationInfoStorage.normal_person.address,
	// 	// 			}
	// 	// 		})
	// 	// 	} else getUserProfile()
	// 	// } else if (confirmationInfo.type === 'organization' ) {

	// 	// 	if (confirmationInfoHasFetched.organization && !sDOCId) {

	// 	// 		setConfirmationInfo(prevState => {
	// 	// 			return {
	// 	// 				...prevState,
	// 	// 				type: confirmationInfoStorage.organization.type,
	// 	// 				name: confirmationInfoStorage.organization.name,
	// 	// 				cardNo: confirmationInfoStorage.organization.cardNo,
	// 	// 				address: confirmationInfoStorage.organization.address,
	// 	// 			}
	// 	// 		})
	// 	// 	}
	// 	// }
	// 	// eslint-disable-next-line
	// }, [confirmationInfo.type])

	// const eqipmentValidation = useCallback(() => {
	// 	inputEquipmentState.forEach(item => {
	// 		const premilinaryCondition =
	// 			item.FrequencyText !== '' &&
	// 			// (item.PolicyText !== '' || item.PolicyText_2 !== '' || item.PolicyText_3 !== '') &&
	// 			item.PowerText !== '' &&
	// 			item.SelectedPowerUnit !== '' &&
	// 			item.ProductTypeText !== ''

	// 		const isFilledText =
	// 			inputModel.brandName !== '' &&
	// 			inputModel.productModel !== '' &&
	// 			inputModel.productDescription !== ''

	// 		setIsEquipmentCardValid(premilinaryCondition && isFilledText)
	// 	})
	// }, [
	// 	inputEquipmentState,
	// 	inputModel.productDescription,
	// 	inputModel.productModel,
	// 	inputModel.brandName,
	// ])

	const eqipmentValidation = useCallback(() => {
		const isValid = inputEquipmentState.every(item => {
			const premilinaryCondition =
				item.FrequencyText !== '' &&
				// (item.PolicyText !== '' || item.PolicyText_2 !== '' || item.PolicyText_3 !== '') &&
				item.PowerText !== '' &&
				item.SelectedPowerUnit !== '' &&
				item.ProductTypeText !== ''

			const isFilledText =
				inputModel.brandName !== '' &&
				inputModel.productModel !== '' &&
				inputModel.productDescription !== ''

			return premilinaryCondition && isFilledText;
		});

		setIsEquipmentCardValid(isValid);
	}, [
		inputEquipmentState,
		inputModel.productDescription,
		inputModel.productModel,
		inputModel.brandName,
	]);


	// Equipment Validation
	useEffect(() => {
		eqipmentValidation()
	}, [eqipmentValidation])

	// Submit Button Validation
	useEffect(
		() => disabledRuleSubmitButton(),
		// eslint-disable-next-line
		[isEquipmentCardValid, inputEquipmentState, additionalFiles, confirmationInfoStorage[currentSenderState]]
	)

	// Initial Fetch
	useEffect(() => {
		onFetchMasterdata()
		getUserProfile()
		// eslint-disable-next-line
	}, [])

	// eslint-disable-next-line
	useEffect(() => onLandingFromEdit(), [mountType])

	// Initial Fetch from /manage
	useEffect(() => {
		if (sDOCID && mountType) {
			setSDOCId(sDOCID)
			getListAndPrefield()
		}
		// eslint-disable-next-line
	}, [sDOCID, mountType])

	// On Unmounting
	useEffect(() => {
		return () => (location.state = null)
		// eslint-disable-next-line
	}, [])

	function makeLinksClickable(text) {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.split(urlRegex).map((part, index) => {
			if (part.match(urlRegex)) {
				// This part is a URL, convert it to a clickable link
				return (
					<a key={index} href={part} target="_blank" rel="noopener noreferrer">
						{part}
					</a>
				);
			} else {
				// This part is not a URL, render it as plain text
				return <span key={index}>{part}</span>;
			}
		});
	}

	return (
		<>
			<Grid className="p-10">
				<Alert severity="info" className="mb-5 w-full" style={{ whiteSpace: "normal" }}><AlertTitle>ท่านสามารถศึกษาประกาศและกฎระเบียบที่เกี่ยวข้อง รวมถึงวิธีการยื่นอย่างละเอียดได้ที่</AlertTitle><a href="https://standard.nbtc.go.th/บริการออนไลน์/SDoC-Online.aspx" target="_blank">https://standard.nbtc.go.th/บริการออนไลน์/SDoC-Online.aspx</a></Alert>
				{!mountType && !userSDOC.poa && (<Alert severity="warning" className="mb-5 w-full" style={{ whiteSpace: "normal" }}><AlertTitle>ท่านยังไม่ได้รับการมอบอำนาจจากสถานประกอบการ</AlertTitle>ท่านยังไม่ได้รับการมอบอำนาจหรือสิทธิ์ ในการดำเนินการแทนสถานประกอบการ ทำให้ท่านไม่สามารถ ยื่นแบบหนังสือรับรองตนเอง แบบนิติบุคคล ได้ หากท่านต้องการดำเนินการแบบนิติบุคคล กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ เพื่อดำเนินการมอบอำนาจก่อนดำเนินการต่อไป</Alert>)}
				{requestAddiotionFile && requestAddiotionFile !== '' && (<Alert severity="warning" className="mb-5 w-full" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดการขอเอกสารเพิ่มเติมจากเจ้าหน้าที่</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(requestAddiotionFile)}</div></Alert>)}
				{requestModifyInfo && requestModifyInfo !== '' && (<Alert severity="warning" className="mb-5 w-full" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดการขอให้ปรับปรุงข้อมูลจากเจ้าหน้าที่</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(requestModifyInfo)}</div></Alert>)}
				{ejectRemark && ejectRemark !== '' && (<Alert severity="warning" className="mb-5 w-full" style={{ whiteSpace: "normal" }}><AlertTitle>รายละเอียดการไม่อนุมัติคำขอจากเจ้าหน้าที่</AlertTitle> <div className="w-full" style={{ whiteSpace: "pre-line", maxWidth: "1340px", flex: 1, flexWrap: 'wrap', wordWrap: 'break-word' }}>{makeLinksClickable(ejectRemark)}</div></Alert>)}
				<OrganizationDetailCardV3
					mountType={mountType}
					confirmationInfo={confirmationInfoStorage}
					onChangeCompanyInfo={onChangeCompanyInfo}
					onChangeSenderType={onChangeSenderType}
					senderState={currentSenderState}
				/>
				<BrandNameCard
					mountType={mountType}
					inputModel={inputModel}
					onChangeInputModel={onChangeInputModel}
				/>

				{inputEquipmentState.map((item, index) => {
					return (
						<EquipmentCard
							mountType={mountType}
							inputEquipmentState={item}
							seq={index + 1}
							onChangeInputEquipmentState={onChangeInputEquipmentState}
							equipmentMasterData={equipmentMasterData}
							onError={(errIdx, isError) =>
								onEquipmentCardError(errIdx, isError)
							}
							onDelete={() => onDeleteItem(index)}
							index={index}
							key={'EquipmentCard' + index + mountType}
						/>
					)
				})}

				{(!mountType || (mountType && mountType === "ModifyInfo")) && (
					<Grid className="flex flex-row justify-center items-center w-full gap-x-2 mb-5">
						<Button
							onClick={addMoreItem}
							className={`w-72`}
							color="primary"
							variant="contained">
							<Grid className={`text-xs`}>เพิ่มอุปกรณ์ย่านความถี่</Grid>
						</Button>
					</Grid>
				)}
				<DocumentAdditionCard
					sDOCID={sDOCID}
					mountType={mountType}
					additionalFiles={additionalFiles}
					tempFile={tempFile}
					onAddTemp={onAddTemp}
					onAddDocument={onAddDocument}
					onDeleteDocument={onDeleteDocument}
					onChangeAttachedFileName={onChangeAttachedFileName}
					onDeleteUploadedDocument={onDeleteUploadedDocument}
				/>

				{mountType !== 'readOnly' && (
					<Grid className="flex flex-row justify-end items-end w-full gap-x-2 my-10">
						<Button
							className={`w-2/12`}
							color="primary"
							variant="contained"
							onClick={onOpenSubmitEquipmentSureModal}
							disabled={canSubmit || (requestAddiotionFile && additionalFiles.length === 0) || (!requestAddiotionFile && additionalFiles.length < 2) || isProfileError}>
							ยื่นแบบ
						</Button>
					</Grid>
				)}
			</Grid>
			<SureModal
				open={submitEquipmentSureModal}
				title={submitTextModal.title}
				content={submitTextModal.content}
				handleButton={submitTextModal.handleButton}
				handleCancel={onOpenSubmitEquipmentSureModal}
				onClose={onOpenSubmitEquipmentSureModal}
				ProgressValue={uploadProgress}
			/>
			<SuccessModal
				open={openSubmitEquipmentSuccessModal}
				title="ยื่นแบบหนังสือรับรองตนเองสำเร็จ"
				handleButton={() => handleSubmitSuccess()}
			/>
		</>
	)
}
