const users = [
	
	{
		nationalCardIDNo: '3222222222220',
		email: 'supharerkt@gmail.com',
		firstname: 'ผู้ใช้งานที่ 1',
		lastname: 'QOS',
		phoneNumber: '0818262134',
	},
	{
		nationalCardIDNo: '3222222222221',
		email: 'supharerkt@gmail.com',
		firstname: 'ผู้ใช้งานที่ 2',
		lastname: 'QOS',
		phoneNumber: '0818262134',
	},
	{
		nationalCardIDNo: '3222222222222',
		email: 'supharerkt@gmail.com',
		firstname: 'ผู้ใช้งานที่ 3',
		lastname: 'QOS',
		phoneNumber: '0818262134',
	},
]

export { users }
