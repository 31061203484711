import API, { adminHeader } from '../config/axios'

const datasetRecordAPI = {
	getListAdmin: () => {
		return API.get('/DataSetRecords/admin', { headers: adminHeader })
			.then(res => {
				return res
			})
			.catch(err => {
				console.error(err)
				return false
			})
	},
	getlist: () => {
		return API.get('/DataSetRecords')
			.then(res => {
				return res
			})
			.catch(err => {
				console.error(err)
				return false
			})
	},
	getlistdetails: id => {
		return API.get(`/DataSetRecords/${id}`)
			.then(res => {
				return res.data
			})
			.catch(err => {
				console.error(err)
				return false
			})
	},
	getListDetailsAdmin: id => {
		return API.get(`/DataSetRecords/${id}`, { headers: adminHeader })
			.then(res => {
				return res.data
			})
			.catch(err => {
				console.error(err)
				return false
			})
	},
	changePersonRecordStatus: () => { },
	sendToApprove: (id, invalidAnswers) => {
		const form = new FormData()

		invalidAnswers.forEach(ans => {
			form.append(`form[${ans.dbID}][reason]`, ans.reason)
			form.append(`form[${ans.dbID}][solution]`, ans.solution)
			form.append(`form[${ans.dbID}][periodSolution]`, ans.periodSolution)
			form.append(`file[${ans.dbID}]`, ans.file)
		})

		return API({
			url: `/DataSetRecords/${id}/sendToApprove`,
			method: 'POST',
			data: form,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	sendToAdmin: (id, file) => {
		const form = new FormData()
		form.append('attach', file)


		return API({
			method: 'POST',
			url: `/DataSetReports/${id}/sendToAdmin`,
			data: form,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	changeStatusReportHead: (id, status, remark = '') => {
		return API.post(`/DataSetRecords/${id}/changeStatus/${status}`, { remark })
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	changeStatusReportHeadAdmin: (
		id,
		status,
		signature = null,
		rejectReason = ''
	) => {
		const body = {}

		if (signature) body['signature'] = signature

		if (
			rejectReason !== '' &&
			rejectReason !== null &&
			rejectReason !== ' ' &&
			rejectReason !== undefined
		)
			body['rejectReason'] = rejectReason

		return API.post(`/DataSetRecords/${id}/changeStatusAdmin/${status}`, body, {
			headers: adminHeader,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getCurrentHead: () => {
		return API.get('/DataSetRecords/currentHead')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	uploadAdditionalFile: (dataSetRecordHeadID, objFiles = {}) => {
		const form = new FormData()
		form.append('dataSetRecordHeadID', dataSetRecordHeadID)

		const keys = Object.keys(objFiles)

		keys.forEach(key => {
			const files = objFiles[key]
			files.forEach(file => form.append(key, file))
		})

		return API({
			url: '/DataSetRecords/uploadFile',
			method: 'POST',
			data: form,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	headDelete: id => {
		return API.delete(`/DataSetRecords/head/${id}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	downloadFile: url => {
		return API({
			url,
			method: 'GET',
			responseType: 'blob',
		})
			.then(res => res)
			.catch(err => {
				console.error(err.response)
				return err
			})
	},

	downloadExcel: id => {
		return API({
			url: `/DataSetRecords/head/${id}/ExportExcel`,
			method: 'POST',
			responseType: 'blob',
		})
			.then(res => res)
			.catch(err => {
				return err
			})
	},
}

export default datasetRecordAPI
