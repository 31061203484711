import { Card, Grid, Divider, InputBase } from '@material-ui/core'
import { Settings, CheckCircle, NoteAdd, Cancel } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'


export default function SDoCDetailCard({
	runningNo,
	sDoCNo
}) {
	return (

		<Card className={`border-t-4 border-card_top_border mb-5`}>
			<Grid className="flex flex-col justify-center items-start px-5 mb-5">
				{/* Header */}
				<Grid className="flex flex-row mt-4 mb-2">
					<Grid className="font-bold text-sm text-card_header_color">
						รายละเอียดแบบรับรองตนเอง
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>
				<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
					<Grid className="flex flex-row">
						<Grid>เลขที่คำขอ</Grid>
					</Grid>
					<Grid
						className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
						<InputBase
							className="w-full"
							name="runningNo pointer-events-none"
							readOnly
							disabled
							value={runningNo}
						/>
					</Grid>
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>SDoC NO.</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded h-10 w-full border border-gray-300`}>
							<InputBase
								className="w-full"
								name="sDoCNo"
								readOnly
								disabled
								value={sDoCNo}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	)
}
