import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useAppTypePath = () => {
	const [appTypePath, setAppTypePath] = useState('')
	const location = useLocation()

	useEffect(() => {
		const pathname = location.pathname

		if (pathname.includes('/admin/sdoc')) setAppTypePath('/admin/sdoc')
		else if (pathname.includes('/admin')) setAppTypePath('/admin')
		else if (pathname.includes('/sdoc')) setAppTypePath('/sdoc')
	}, [location.pathname])

	return { appTypePath }
}
