import API from '../config/axios'
import getBase64 from 'utils/getBase64'

const adminQoSAPI = {
	ldapAuth: username => {
		return API.post('/Admins/ldapAuth', {
			username,
		})
			.then(res => res)
			.catch(err => err)
	},
	getSignature: () => {
		return API.get('/Admins/SignatureAdmin').then(res => res.data)
	},
	addAdmin: async ({
		username,
		adminRoleID,
		adminRoleIDSDoc,
		adminRoleIDCentralAdmin,
		email,
		prefixname,
		position,
		adminStatusID,
		signature,
		mobile,
	}) => {
		const body = {
			username,
			email,
			prefixname,
			qosRoleID: adminRoleID === 'no-permission' ? null : adminRoleID,
			sDocRoleID: adminRoleIDSDoc === 'no-permission' ? null : adminRoleIDSDoc,
			adminRoleID:
				adminRoleIDCentralAdmin === 'no-permission'
					? null
					: adminRoleIDCentralAdmin,
			adminStatusID,
			position,
			signature: signature ? await getBase64(signature) : null,
			mobile,
		}

		return API.post('/Admins/storeV2', body)
			.then(res => {
				return res
			})
			.catch(err => {
				console.log('err', err)
				return false
			})
	},
	editAdmin: async ({
		username,
		email,
		prefixname,
		adminId,
		adminRoleID,
		adminRoleIDSDoc,
		adminRoleIDCentralAdmin,
		adminStatusID,
		position,
		signature,
		mobile,
	}) => {
		const body = {
			username,
			email,
			prefixname,
			adminStatusID,
			position,
			mobile: mobile ?? '',
			signature: signature ? await getBase64(signature) : null,
		}

		if (adminRoleID !== 'no-permission') {
			body['qosRoleID'] = adminRoleID
		}

		if (adminRoleIDSDoc !== 'no-permission') {
			body['sDocRoleID'] = adminRoleIDSDoc
		}

		if (adminRoleIDCentralAdmin !== 'no-permission') {
			body['adminRoleID'] = adminRoleIDCentralAdmin
		}

		return API.put(`/Admins/${adminId}`, body)
			.then(res => {
				return res
			})
			.catch(err => {
				console.log('err', err)
				return false
			})
	},
}

export default adminQoSAPI
