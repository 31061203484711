export const canceledListDetailNo = [
    "2.2.5.1",
    "2.2.5.2",
    "2.2.5.3",
    "2.2.5.4",
    "2.2.5.5",
    "2.2.5.6",
    "2.3.7",
    "2.3.8",
    "2.3.9",
    "2.3.10",
]