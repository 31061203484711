import { People, CheckCircle, AccessTime, Assignment } from '@material-ui/icons'

export const InfoBoxConfigs = [
	{
		name: 'stat1',
		label: 'จำนวนผู้ประกอบการ',
		unit: 'รายการ',
		icon: <People htmlColor="#FFF" style={{ fontSize: 48 }} />,
		iconBgColor: '#007500',
	},
	{
		name: 'stat2',
		label: 'จำนวนผู้ส่งข้อมูลแล้ว',
		unit: 'รายการ',
		icon: <CheckCircle htmlColor="#FFF" style={{ fontSize: 48 }} />,
		iconBgColor: 'rgba(17, 69, 86, 0.867)',
	},
	{
		name: 'stat3',
		label: 'ระหว่างดำเนินการ',
		unit: 'รายการ',
		icon: <AccessTime htmlColor="#FFF" style={{ fontSize: 48 }} />,
		iconBgColor: '#FAD02C',
	},
	{
		name: 'stat4',
		label: 'เกินกำหนด',
		unit: 'รายการ',
		icon: <Assignment htmlColor="#FFF" style={{ fontSize: 48 }} />,
		iconBgColor: '#DC143C',
	},
]
