import { forwardRef, useEffect, useState } from 'react'
import {
	Grid,
	Dialog,
	DialogContent,
	Slide,
	IconButton,
	DialogTitle,
	TextField,
	Divider,
	useMediaQuery,
} from '@material-ui/core'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers'
import { useTheme } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

import File from './file'

export default function ValidationModal({
	open = false,
	currentSelectedError = {
		dataSetItemID: '',
		reason: '',
		solution: '',
		periodSolution: new Date(),
		validateFileUrl: null,
	},
	onClose,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const [invalidInputMethod, setInvalidInputMethod] = useState('message')

	useEffect(() => {
		if (currentSelectedError.validateFileUrl) setInvalidInputMethod('attach')
		else setInvalidInputMethod('message')
	}, [currentSelectedError])

	return (
		<Dialog
			className="w-full px-3"
			open={open}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<Grid className="font-bold text-lg">ผลการประเมิน</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#000' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				{invalidInputMethod === 'message' && (
					<>
						<Grid className="grid grid-cols-12 gap-x-3 gap-y-10 w-full">
							{/* Reason */}
							<Grid className="col-span-2  text-black">เหตุผล*</Grid>
							<Grid className="col-span-10">
								<TextField
									fullWidth
									maxRows={3}
									minRows={3}
									multiline
									size="small"
									variant="outlined"
									placeholder=""
									style={{ backgroundColor: '#F8FAFC' }}
									name="reason"
									value={currentSelectedError.reason}
									readOnly
									className="pointer-events-none"
								/>
							</Grid>
							{/* How to solve */}
							<Grid className="col-span-2  text-black">วิธีการแก้ไข*</Grid>
							<Grid className="col-span-10">
								<TextField
									fullWidth
									maxRows={3}
									minRows={3}
									multiline
									size="small"
									variant="outlined"
									placeholder=""
									style={{ backgroundColor: '#F8FAFC' }}
									name="solution"
									value={currentSelectedError.solution}
									readOnly
									className="pointer-events-none"
								/>
							</Grid>
							{/* Duration */}
							<Grid className="col-span-2 text-black">ระยะเวลาการแก้ไข*</Grid>
							<Grid className="col-span-5">
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										variant="dialog"
										color="primary"
										margin="normal"
										views={['date', 'month', 'year']}
										format="dd/MM/yyyy"
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										name="periodSolution"
										value={currentSelectedError.periodSolution}
										readOnly
										className="pointer-events-none"
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						</Grid>
						<Grid className="my-5">
							<Divider />
						</Grid>
					</>
				)}
				{invalidInputMethod === 'attach' && (
					<Grid className="flex flex-col gap-y-10 mx-auto w-max py-5">
						<Grid className="flex flex-row gap-x-5 w-full mx-auto">
							<File
								originalFileName={`validateFile_${currentSelectedError.dataSetItemID}`}
								fileID={currentSelectedError.dataSetItemID}
								validateFileUrl={currentSelectedError.validateFileUrl}
							/>
						</Grid>
					</Grid>
				)}
			</DialogContent>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
