export const mobile_additional_files = [
	{
		key: 'attachmentBMARoad',
		title: 'เส้นทางในเขตกรุงเทพฯ และปริมณฑล',
	},
	{
		key: 'attachmentBMAFocusArea',
		title: 'พื้นที่เฝ้าระวัง',
	},
	{
		key: 'attachmentUPCRoad',
		title: 'สถานที่ทำการวัด เส้นทางสายหลักและสายรอง',
	},
	{
		key: 'attachmentUPCCommunity',
		title: 'เส้นทางในพื้นที่ชุมชน',
	},
]
