import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
	Grid,
	Typography,
	Step,
	Stepper,
	StepConnector,
	StepLabel,
	Button,
} from '@material-ui/core'
import {
	Description,
	Spellcheck,
	Send,
	Check,
	HighlightOff,
	SendOutlined,
} from '@material-ui/icons'

import { convertNumber } from 'utils/convertExcelNumber'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'

import SendingStandardReportModalSuccess from '../../../components/users/standard-report/success-modal'
import SendingStandardReportModalFailed from '../../../components/users/standard-report/failed-modal'
import SureModal from '../../../components/SureModal'
import StepContent from '../../../components/users/standard-report/index'
import EmptyFileModal from '../../../components/users/standard-report/empty-file-modal'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'


import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useStoreState } from '../../../store'
import { form, datasetRecord } from '../../../api/index'
import { useHistory } from 'react-router-dom'
import { fixed_general_details } from '../../../data/standard-reportV2/fixed-general'
import { fixed_info_details } from '../../../data/standard-reportV2/fixed-info'
import { fixed_sound_details } from '../../../data/standard-reportV2/fixed-sound'
import { mobile_general_details } from '../../../data/standard-reportV2/mobile-general'
import { mobile_info_details } from '../../../data/standard-reportV2/mobile-info'
import { mobile_sound_details } from '../../../data/standard-reportV2/mobile-sound'
import { mobile_additional_files } from '../../../data/standard-reportV2/ui/mobile-additional-files'

const nonFloatDataSetItemIDs = [
	337,
	338,
	339,
	340,
	341,
	342,
	343,
];
const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	non_approve_button: {
		backgroundColor: '#c33939',
		color: 'white',
		'&:hover': {
			backgroundColor: '#a33e3e',
		},
	},
}))

const Connector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundColor: '#a74345',
		},
	},
	completed: {
		'& $line': {
			backgroundColor: '#a74345',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector)

const useStepStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundColor: '#a74345',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundColor: '#a74345',
	},
})

function getSteps() {
	return [
		'รูปแบบการส่งรายงาน',
		'บันทึกข้อมูล',
		'หัวข้อที่ไม่ผ่านเกณฑ์และส่งรายงาน',
	]
}

function getStepsWithMobile() {
	return [
		'รูปแบบการส่งรายงาน',
		'บันทึกข้อมูล',
		'แนบไฟล์เพิ่มเติมสำหรับรายงานมาตรฐานแบบเคลื่อนที่',
		'หัวข้อที่ไม่ผ่านเกณฑ์และส่งรายงาน',
	]
}

const StandardReport = () => {
	const classes = useStyles()
	const history = useHistory()
	const steps = getSteps()
	const stepsMobile = getStepsWithMobile()
	const [activeStep, setActiveStep] = useState(0)
	const userOperatorProfile = useStoreState(
		s => s.userModel.userOperatorProfile
	)
	const [hasMounted, setHasMounted] = useState(false)
	const [loading, setLoading] = useState(false)

	// Types of Required Filled Standard Report Form
	const [hasMobile, setHasMobile] = useState(false)
	const [needToFillFormType, setNeedToFillFormType] = useState([
		'fixed-general',
		'fixed-sound',
		'fixed-info',
		'mobile-general',
		'mobile-sound',
		'mobile-info',
	])
	// Amount of Required Filled Standard Report Form
	const [filledFormAmount, setFilledFormAmount] = useState(0)
	// Current Page of Required Filled Standard Report Form
	const [currentFilledFormIndex, setCurrentFilledFormIndex] = useState(0)
	// Report Method State
	const [reportMethod, setReportMethod] = useState('web-form')
	const [fixedReportType, setFixedReportType] = useState(false)
	const [mobileReportType, setMobileReportType] = useState(false)
	// Standard Report Form Answers
	const [fixedGeneralAnswers, setFixedGeneralAnswers] = useState([])
	const [fixedInfoAnswers, setFixedInfoAnswers] = useState([])
	const [fixedSoundAnswers, setFixedSoundAnswers] = useState([])
	const [mobileGeneralAnswers, setMobileGeneralAnswers] = useState([])
	const [mobileInfoAnswers, setMobileInfoAnswers] = useState([])
	const [mobileSoundAnswers, setMobileSoundAnswers] = useState([])
	// Standard Report Submiting Modal Status
	const [openStandardReportSuccessModal, setOpenStandardReportSuccessModal] =
		useState(false)
	const [openStandardReportFailedModal, setOpenStandardReportFailedModal] =
		useState(false)
	const [sendingFailedMessage, setSendingFailedMessage] = useState('')
	// Sure Modal
	const [
		openSubmitStandardReportSureModal,
		setOpenSubmitStandardReportSureModal,
	] = useState(false)
	// Standard Report Excel File
	const [excelFile, setExcelFile] = useState(null)
	const [excelFileShow, setExcelFileShow] = useState('')
	// Mobile Report Additional Files
	const [mobileFiles, setMobileFiles] = useState({})

	// Upload Excel Status
	const [uploadExcelStatus, setUploadExcelStatus] = useState(null)

	// Has Ever Submit Form Status
	const [hasEverSubmitedForm, setHasEverSubmitedForm] = useState(false)
	// Continue Form Submitted ID
	const [continueFormSubmittedID, setContinueFormSubmittedID] = useState(null)
	// Continue Form Submmited Type
	const [continueFormSubmittedType, setContinueFormSubmittedType] = useState({
		isFixed: false,
		isMobile: false,
	})
	// Is continue From Submitted Form
	const [isNewForm, setIsNewForm] = useState('y')
	// Form Flags
	const [flags, setFlags] = useState({
		'2G': false,
		NVMO: false,
		payphone: false,
	})
	// Invalid Data Set Records
	const [invalidDataSetRecords, setInvalidDataSetRecords] = useState([])
	const [invalidAnswers, setInvalidAnswers] = useState([])
	// Invalid Input Method
	const [invalidInputMethod, setInvalidInputMethod] = useState('message')
	// Open Asking Validation Modal
	const [openAskingValidationModal, setOpenAskingValidationModal] =
		useState(false)
	// Current Validation Question Number
	const [currentValiationQuestionNumber, setCurrentValidationQuestionNumber] =
		useState('')
	// Current Selected Validation
	const [currentSelectedItem, setCurrentSelectedItem] = useState({
		id: '',
		question_number: '',
		label: '',
		errors: '',
		inputMethod: '',
		periodSolution: null,
	})
	// Filling Modal
	const [openFillingModal, setOpenFillingModal] = useState(false)

	const { userOperatorProfile: userProfile } = useStoreState(s => s.userModel)

	const isUseReport1_1_2 = useMemo(
		() => userProfile?.isUseReport1_1_2,
		[userProfile]
	)

	/** ---------------------------------------------- */

	/**  Main Stepper Handle Function */

	const handleNext = async () => {
		// Submit the form to approval

		if (
			activeStep === (hasMobile ? stepsMobile.length - 1 : steps.length - 1)
		) {
			if (invalidDataSetRecords.length !== 0) {
				const isPass = invalidAnswers.every(
					item =>
						(item.reason !== '' &&
							item.solution !== '' &&
							item.periodSolution !== null) ||
						item.file !== null
				)

				if (isPass) onOpenSubmitStandardReportSureModal()
				else alert('กรุณากรอกเหตุผลให้ครบถ้วน')
			} else if (invalidDataSetRecords.length === 0)
				onOpenSubmitStandardReportSureModal()
		} else if (activeStep === 2 && hasMobile) {
			setActiveStep(prevActiveStep => prevActiveStep + 1)
		} else if (activeStep === 1 && reportMethod === 'upload') {
			uploadExcel()
		}
		// Saves the form and Validate the form then Shows a modal status
		else if (
			activeStep === 1 &&
			currentFilledFormIndex === filledFormAmount - 1
		) {
			SubmitForm()
		}
		// Shows the next required filled standard report form
		else if (activeStep === 1 && currentFilledFormIndex < filledFormAmount - 1)
			onIncrementCurrentFilledFormIndex()
		else if (activeStep === 0 && isNewForm === 'n') {
			await onLoadDataSetRecordToContinue()
			setActiveStep(prevActiveStep => prevActiveStep + 1)
		} else if (activeStep !== steps.length - 1)
			setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		// Shows the previous required filled standard report form
		if (activeStep === 1 && currentFilledFormIndex === 0) setActiveStep(0)
		else if (activeStep === 1 && currentFilledFormIndex <= filledFormAmount - 1)
			onDecrementCurrentFilledFormIndex()
		/**
		 * General cases:
		 * - Step 2 -> Step 1 (To first required standard report form)
		 * - Step 3 -> Step 2 (To last required filled standard report form)
		 */ else setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	function StepperIcon(props) {
		const classes = useStepStyles()
		const { active, completed } = props

		const icons = {
			1: <Description />,
			2: <Spellcheck />,
			3: hasMobile ? <Send /> : <HighlightOff />,
			4: <HighlightOff />,
		}

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed,
				})}>
				{completed ? <Check /> : icons[String(props.icon)]}
			</div>
		)
	}

	StepperIcon.propTypes = {
		active: PropTypes.bool,
		completed: PropTypes.bool,
		icon: PropTypes.node,
	}

	/** ---------------------------------------------- */

	// Open Standard Report Success Modal Handler
	const onOpenStandardReportSuccessModal = () =>
		setOpenStandardReportSuccessModal(!openStandardReportSuccessModal)

	const onAgreeStandardReportSuccessModalCTA = () => {
		onOpenStandardReportSuccessModal()
		history.go(0)
	}

	// Open Standard Report Failed Modal Handler
	const onOpenStandardReportFailedModal = () => {
		setOpenStandardReportFailedModal(!openStandardReportFailedModal)
	}

	/** ---------------------------------------------- */

	// Modal Empty File
	const [openEmptyFileModal, setOpenEmptyFileModal] = useState(false)

	const onOpenEmptyFileModal = () => setOpenEmptyFileModal(!openEmptyFileModal)
	// <-------------------------------------

	/** Step 1 */

	const onChangeReportType = (type, checked) => {
		if (type === 1) setFixedReportType(checked)
		else if (type === 2) setMobileReportType(checked)
	}

	const onChangeRadio = e => {
		setReportMethod(e.target.value)
	}

	const onChangeIsContinueRadio = e => {
		if (e.target.value === 'n') {
			setFixedReportType(continueFormSubmittedType.isFixed)
			setMobileReportType(continueFormSubmittedType.isMobile)
		}

		setIsNewForm(e.target.value)
	}

	const onLoadDataSetRecordToContinue = async () => {
		const res = await datasetRecord.getlistdetails(continueFormSubmittedID)
		if (res) {
			const tempFixedGeneralAnswers = []
			const tempFixedSoundAnswers = []
			const tempFixedInfoAnswers = []
			const tempMobileGeneralAnswers = []
			const tempMobileSoundAnswers = []
			const tempMobileInfoAnswers = []

			res.data.forEach(item => {
				var newitem = {};
				if (nonFloatDataSetItemIDs.includes(item.dataSetItemID)) {
					newitem = {
						DataSetItemID: item.dataSetItemID,
						DataValueMonth1: item.dataValueMonth1
							? item.dataValueMonth1
							: '',
						DataValueMonth2: item.dataValueMonth2
							? item.dataValueMonth1
							: '',
						DataValueMonth3: item.dataValueMonth3
							? item.dataValueMonth3
							: '',
						DataValueMonthAvg: item.dataValueMonthAvg
							? item.dataValueMonthAvg
							: '',
						DataValueExtra: item.dataValueExtra
							? item.dataValueExtra
							: '',
					}
				} else {
					newitem = {
						DataSetItemID: item.dataSetItemID,
						DataValueMonth1: item.dataValueMonth1
							? convertNumber(item.dataValueMonth1, true)
							: '',
						DataValueMonth2: item.dataValueMonth2
							? convertNumber(item.dataValueMonth2, true)
							: '',
						DataValueMonth3: item.dataValueMonth3
							? convertNumber(item.dataValueMonth3, true)
							: '',
						DataValueMonthAvg: item.dataValueMonthAvg
							? convertNumber(item.dataValueMonthAvg, true)
							: '',
						DataValueExtra: item.dataValueExtra
							? item.dataValueExtra
							: '',
					}
				}

				if (item.dataSetItemID <= 10) tempFixedGeneralAnswers.push(newitem)
				else if (item.dataSetItemID <= 20) tempFixedSoundAnswers.push(newitem)
				else if (item.dataSetItemID <= 30) tempFixedInfoAnswers.push(newitem)
				else if (item.dataSetItemID <= 40)
					tempMobileGeneralAnswers.push(newitem)
				else if (item.dataSetItemID <= 200) tempMobileSoundAnswers.push(newitem)
				else if (item.dataSetItemID > 200) tempMobileInfoAnswers.push(newitem)
			})

			if (tempFixedGeneralAnswers.length !== 0)
				setFixedGeneralAnswers(tempFixedGeneralAnswers)
			if (tempFixedInfoAnswers.length !== 0)
				setFixedInfoAnswers(tempFixedInfoAnswers)
			if (tempFixedSoundAnswers.length !== 0)
				setFixedSoundAnswers(tempFixedSoundAnswers)
			if (tempMobileGeneralAnswers.length !== 0)
				setMobileGeneralAnswers(tempMobileGeneralAnswers)
			if (tempMobileInfoAnswers.length !== 0)
				setMobileInfoAnswers(tempMobileInfoAnswers)
			if (tempMobileSoundAnswers.length !== 0)
				setMobileSoundAnswers(tempMobileSoundAnswers)
		}
	}

	/** ---------------------------------------------- */

	/** Step 2 */

	const onFillForm = (form_type, id, answer_type, value) => {
		// Check Input have only number and .
		var pattern = /^[0-9.\b]+$/
		// if id is in nonFloatDataSetItemIDs, then set pattern to free input
		if (nonFloatDataSetItemIDs.includes(id)) {
			pattern = /^.*$/
		}
		if (answer_type === 'dataValueExtra' || answer_type === 'DataValueExtra') {
			pattern = /^.*$/
		}
		// limit length to 10
		if (value.length > 15) {
			return
		}

		// Sets new answers into answers state.
		if (pattern.test(value) || value === '') {
			if (form_type === 'fixed-general') {
				setFixedGeneralAnswers(prevState => {
					const newList = prevState.map((item, key) => {
						if (item.DataSetItemID === id) {
							return { ...prevState[key], [answer_type]: value }
						}
						return item
					})
					return newList
				})
			} else if (form_type === 'fixed-info') {
				setFixedInfoAnswers(prevState => {
					const newList = prevState.map((item, key) => {
						if (item.DataSetItemID === id) {
							return { ...prevState[key], [answer_type]: value }
						}
						return item
					})
					return newList
				})
			} else if (form_type === 'fixed-sound') {
				setFixedSoundAnswers(prevState => {
					const newList = prevState.map((item, key) => {
						if (item.DataSetItemID === id) {
							return { ...prevState[key], [answer_type]: value }
						}
						return item
					})
					return newList
				})
			} else if (form_type === 'mobile-general') {
				setMobileGeneralAnswers(prevState => {
					const newList = prevState.map((item, key) => {
						if (item.DataSetItemID === id) {
							return { ...prevState[key], [answer_type]: value }
						}
						return item
					})
					return newList
				})
			} else if (form_type === 'mobile-info') {
				setMobileInfoAnswers(prevState => {
					const newList = prevState.map((item, key) => {
						if (item.DataSetItemID === id) {
							return { ...prevState[key], [answer_type]: value }
						}
						return item
					})
					return newList
				})
			} else if (form_type === 'mobile-sound') {
				setMobileSoundAnswers(prevState => {
					const newList = prevState.map((item, key) => {
						if (item.DataSetItemID === id) {
							return { ...prevState[key], [answer_type]: value }
						}
						return item
					})
					return newList
				})
			}
		}
	}

	const onChangeNeedToFillFormType = () => {
		if (!hasMounted) return

		const temp = []

		if (fixedReportType) {
			temp.push('fixed-general')
			temp.push('fixed-sound')
			temp.push('fixed-info')
		}

		if (mobileReportType) {
			temp.push('mobile-general')
			temp.push('mobile-sound')
			temp.push('mobile-info')
			setHasMobile(true)
		} else setHasMobile(false)

		setNeedToFillFormType(temp)
	}

	const onIncrementCurrentFilledFormIndex = () =>
		setCurrentFilledFormIndex(currentFilledFormIndex + 1)

	const onDecrementCurrentFilledFormIndex = () =>
		setCurrentFilledFormIndex(currentFilledFormIndex - 1)

	const SubmitForm = async () => {
		let records = []
		setLoading(true)
		needToFillFormType.forEach(form_type => {
			if (form_type === 'fixed-general')
				records = [...records, ...fixedGeneralAnswers]
			if (form_type === 'fixed-info')
				records = [...records, ...fixedInfoAnswers]
			if (form_type === 'fixed-sound') {
				if (isUseReport1_1_2) {
					records = [...records, ...fixedSoundAnswers]
				}
			}
			if (form_type === 'mobile-general')
				records = [...records, ...mobileGeneralAnswers]
			if (form_type === 'mobile-sound')
				records = [...records, ...mobileSoundAnswers]
			if (form_type === 'mobile-info')
				records = [...records, ...mobileInfoAnswers]
		})

		const formTypes = []

		if (isNewForm === 'y') {
			if (mobileReportType) formTypes.push(1)
			if (fixedReportType) formTypes.push(2)
		} else {
			if (continueFormSubmittedType.isFixed) formTypes.push(2)
			if (continueFormSubmittedType.isMobile) formTypes.push(1)
		}

		const res = await form.addRecord(records, isNewForm, formTypes)

		if (res?.data?.status === 'Incorrect') {
			const tempInvalidDataSetRecords = res.data.validateResult.map(item => {
				const question = [
					...fixed_general_details,
					...fixed_info_details,
					...fixed_sound_details,
					...mobile_general_details,
					...mobile_info_details,
					...mobile_sound_details,
				].find(q => q.id === item.dataSetItemID)
				let label = question.label

				if (question.subLabel_2) {
					label = `${label} ${question.subLabel_1} (${question.subLabel_2})`
				} else if (question.subLabel_1) {
					label = `${label} ${question.subLabel_1}`
				}

				return {
					id: question.id,
					question_number: question.no,
					label: label,
					errors: item.errors.toString(),
					inputMethod: 'message',
				}
			})

			const tempInvalidAnswers = res.data.validateResult.map(item => {
				return {
					id: item.dataSetItemID,
					reason: '',
					solution: '',
					periodSolution: null,
					file: null,
					dbID: item.id,
					inputMethod: 'message',
				}
			})

			setInvalidDataSetRecords([...tempInvalidDataSetRecords])
			setInvalidAnswers([...tempInvalidAnswers])
		}
		setLoading(false)
		setActiveStep(prev => prev + 1)
	}

	/** Step 2 Upload File */
	const uploadExcel = async () => {
		setLoading(true)
		const resExcelFile = await form.excelUploading(excelFile, isNewForm)
		setLoading(false)

		// Wrong format
		if (resExcelFile?.response?.status === 422) {
			setUploadExcelStatus(false)
		} else {
			// Wrong answer. But correct format
			if (resExcelFile?.response?.status === 400) {
				const tempInvalidDataSetRecords = resExcelFile.response.data.map(
					item => {
						const question = [
							...fixed_general_details,
							...fixed_info_details,
							...fixed_sound_details,
							...mobile_general_details,
							...mobile_info_details,
							...mobile_sound_details,
						].find(q => q.id === item.dataSetItemID)

						let label = question.label

						if (question.subLabel_2) {
							label = `${label} ${question.subLabel_1} (${question.subLabel_2})`
						} else if (question.subLabel_1) {
							label = `${label} ${question.subLabel_1}`
						}

						return {
							id: question.id,
							question_number: question.no,
							label: label,
							errors: item.errors.toString(),
							inputMethod: 'message',
						}
					}
				)

				const tempInvalidAnswers = resExcelFile.response.data.map(item => {
					return {
						id: item.dataSetItemID,
						reason: '',
						solution: '',
						periodSolution: null,
						file: null,
						dbID: item.id,
						inputMethod: 'message',
					}
				})

				setInvalidDataSetRecords([...tempInvalidDataSetRecords])
				setInvalidAnswers([...tempInvalidAnswers])
			}

			setActiveStep(prev => prev + 1)

			setUploadExcelStatus(true)
		}
	}

	/** ---------------------------------------------- */

	/** Step 3 */

	const onOpenSubmitStandardReportSureModal = () => {
		setOpenSubmitStandardReportSureModal(!openSubmitStandardReportSureModal)
	}

	/** ---------------------------------------------- */

	const onInputAdditionalFile = (e, type) => {
		const { files } = e.target

		if (files.length !== 0) {
			setMobileFiles(prevState => ({
				...mobileFiles,
				[type]: [...prevState[type], files[0]],
			}))
		}
	}

	const onDeleteAdditionalFile = (index, type) => {
		setMobileFiles(prevState => {
			let filesArr = prevState[type]

			const tempList = filesArr.filter((_, idx) => idx !== index)

			return {
				...prevState,
				[type]: tempList,
			}
		})
	}

	const uploadAdditionalFile = async () => {
		const resCurrentHead = await datasetRecord.getCurrentHead()

		if (resCurrentHead && resCurrentHead?.status === 200) {
			const res = await datasetRecord.uploadAdditionalFile(
				resCurrentHead.data.id,
				mobileFiles
			)
			if (res) {
				if (res.status === 200) {
					setActiveStep(prevActiveStep => prevActiveStep + 1)
				} else if (res.status === 400) {
				}
			}
		}
	}

	/** ---------------------------------------------- */

	/** Step 4 */

	const onInputValidationReason = (e, id, isPeriodSolution) => {
		if (isPeriodSolution) {
			setInvalidAnswers(prevAnswers => {
				let newAnswers = prevAnswers.find(ans => ans.id === id)
				newAnswers['periodSolution'] = moment(e).format('yyyy-MM-DD')
				const filtered = prevAnswers.filter(ans => ans.id !== id)
				filtered.push(newAnswers)

				return filtered
			})
		} else {
			const { name, value, files } = e.target

			if (name === 'file') {
				setInvalidAnswers(prevAnswers => {
					let newAnswers = prevAnswers.find(ans => ans.id === id)
					newAnswers['file'] = files[0]

					const filtered = prevAnswers.filter(ans => ans.id !== id)
					filtered.push(newAnswers)

					return filtered
				})
			} else if (!isPeriodSolution) {
				setInvalidAnswers(prevAnswers => {
					let newAnswers = prevAnswers.find(ans => ans.id === id)
					newAnswers[name] = value

					const filtered = prevAnswers.filter(ans => ans.id !== id)
					filtered.push(newAnswers)

					return filtered
				})
			}
		}
	}

	const onChangeInvalidaInputMethod = val => setInvalidInputMethod(val)

	const onAskInvalidInputMethod = val => {
		setInvalidAnswers(prevState => {
			const invalid = invalidAnswers.find(
				item => item.id === currentSelectedItem.id
			)
			if (invalid) {
				invalid['inputMethod'] = val
			}

			const temp = prevState.filter(item => item.id !== currentSelectedItem.id)
			temp.push(invalid)
			setCurrentSelectedItem(invalid)

			return temp
		})
	}

	const onOpenAskingValidationModal = id => {
		const question = invalidAnswers.find(item => item.id === id)

		setCurrentSelectedItem(question)
		setCurrentValidationQuestionNumber(id)
		setOpenAskingValidationModal(true)
		setOpenFillingModal(true)
	}

	const onCloseAskingValidationModal = () => setOpenAskingValidationModal(false)

	const onCloseFillingModal = () => setOpenFillingModal(false)

	const onSubmitToApprove = async () => {
		const resCurrentHead = await datasetRecord.getCurrentHead()

		if (resCurrentHead) {
			const res = await datasetRecord.sendToApprove(
				resCurrentHead.data.id,
				invalidAnswers
			)
			if (res) {
				onOpenSubmitStandardReportSureModal()

				if (res.status === 200) {
					// onOpenStandardReportSuccessModal()
					Swal.fire({
						title: 'ส่งข้อมูลสำเร็จ',
						text: 'ท่านต้องการกรอกแบบประเมินความพึงพอใจหรือไม่?',
						icon: 'success',
						confirmButtonText: 'ต้องการ',
						showCancelButton: true,
						cancelButtonText: 'ไม่ต้องการ',
					}).then(result => {
						if (result.isConfirmed) {
							window.open(
								'https://docs.google.com/forms/d/e/1FAIpQLSd3Kh9APHngJ16OWX9QbijN36-h_4YV9DxM59X8E8zetJf7Iw/viewform?usp=sf_link',
								'_blank'
							)
						}
					})
				}
				else {
					setSendingFailedMessage(res.data?.message ?? '')
					onOpenStandardReportFailedModal()
				}
				// fire swal that will show link to do survey from google from



			}
		}
	}

	/** ---------------------------------------------- */

	/**  Main Rendering */

	const nextButtonGenerator = () => {
		// On last step
		if (loading) {
			return 'กรุณารอสักครู่'
		} else if (
			activeStep === (hasMobile ? stepsMobile.length - 1 : steps.length - 1)
		)
			return 'ส่งไปยังผู้อนุมัติ'
		// General cases
		else return 'ขั้นตอนถัดไป'
	}

	const buttonNextDisabled = useMemo(() => {
		if (activeStep === 2 && hasMobile) {
			let hasFile = false
			// eslint-disable-next-line
			for (const [key, value] of Object.entries(mobileFiles)) {
				if (value.length > 0) {
					hasFile = true
				}
			}
			// const keys = Object.keys(mobileFiles)
			// const isDisable = keys.every(item => mobileFiles[item].length > 0)

			return hasFile
		} else if (
			activeStep === (hasMobile ? stepsMobile.length - 1 : steps.length - 1)
		) {
			const isPass = invalidAnswers.some(item => {
				return (
					item.reason === '' &&
					item.solution === '' &&
					item.periodSolution === '' &&
					item.file === null
				)
			})

			return isPass && invalidAnswers.length !== 0
		}
		// When Input form and Method is Upload
		else if (activeStep === 1 && reportMethod === 'upload')
			return excelFileShow === ''
		// When Input form and Method is WebForm
		else if (activeStep === 1 && reportMethod === 'web-form') return false
		else if (activeStep === 0 && isNewForm === 'n') return false
		else return !fixedReportType && !mobileReportType
	}, [
		activeStep,
		excelFileShow,
		fixedReportType,
		hasMobile,
		invalidAnswers,
		isNewForm,
		mobileReportType,
		mobileFiles,
		reportMethod,
		steps.length,
		stepsMobile.length,
	])

	const uploadAdditionalFileButtonDisabled = () => {
		if (activeStep === 2 && hasMobile) {
			const keys = Object.keys(mobileFiles)
			const isDisable = keys.every(item => mobileFiles[item].length === 0)

			return isDisable
		}
	}

	const onGenerateAnswer = useCallback(() => {
		/**
		 * 	Answer Object Example
		 *
		 *   {
		 *  	DataSetItemID: Integer,
		 *      DataValueMonth1: Float | Integer,
		 *      DataValueMonth2: Float | Integer,
		 *      DataValueMonth3: Float | Integer,
		 *      DataValueMonthAvg: Float | Integer
		 *   }
		 *
		 */

		needToFillFormType.forEach(type => {
			if (type === 'fixed-general') {
				const temp = fixed_general_details.map(item => {
					return {
						DataSetItemID: item.id,
						DataValueMonth1: '',
						DataValueMonth2: '',
						DataValueMonth3: '',
						DataValueMonthAvg: '',
					}
				})
				setFixedGeneralAnswers(temp)
			} else if (type === 'fixed-info') {
				const temp = fixed_info_details.map(item => {
					return {
						DataSetItemID: item.id,
						DataValueMonth1: '',
						DataValueMonth2: '',
						DataValueMonth3: '',
						DataValueMonthAvg: '',
					}
				})
				setFixedInfoAnswers(temp)
			} else if (type === 'fixed-sound') {
				const temp = fixed_sound_details.map(item => {
					return {
						DataSetItemID: item.id,
						DataValueMonth1: '',
						DataValueMonth2: '',
						DataValueMonth3: '',
						DataValueMonthAvg: '',
					}
				})
				setFixedSoundAnswers(temp)
			} else if (type === 'mobile-general') {
				const temp = mobile_general_details.map(item => {
					return {
						DataSetItemID: item.id,
						DataValueMonth1: '',
						DataValueMonth2: '',
						DataValueMonth3: '',
						DataValueMonthAvg: '',
					}
				})
				setMobileGeneralAnswers(temp)
			} else if (type === 'mobile-info') {
				const temp = mobile_info_details.map(item => {
					return {
						DataSetItemID: item.id,
						DataValueMonth1: '',
						DataValueMonth2: '',
						DataValueMonth3: '',
						DataValueMonthAvg: '',
					}
				})
				setMobileInfoAnswers(temp)
			} else if (type === 'mobile-sound') {
				const temp = mobile_sound_details.map(item => {
					return {
						DataSetItemID: item.id,
						DataValueMonth1: '',
						DataValueMonth2: '',
						DataValueMonth3: '',
						DataValueMonthAvg: '',
						DataValueExtra: '',
					}
				})
				setMobileSoundAnswers(temp)
			}
		})

		setFilledFormAmount(needToFillFormType.length)
	}, [needToFillFormType])

	const onGetCurrentHead = async () => {
		let twoG = false
		let nvmo = false
		let payphone = false
		const res = await datasetRecord.getCurrentHead()

		if (res && res?.status === 200) {
			const data = res.data

			if (data.dataSetReport.operator) {
				// 2G Checking
				if (data.dataSetReport.operator?.is2G === 'y') twoG = true

				// NVMO Checking
				if (data.dataSetReport.operator?.ownerNVOM === 'y') nvmo = true

				// Payphone Checking
				if (data.dataSetReport.operator?.isPayphone === 'y') payphone = true

				setFlags({
					'2G': twoG,
					NVMO: nvmo,
					payphone: payphone,
				})
			}

			// if (data.sendMethod > 1) setHasEverSubmitedForm(true)
			if (data.sendMethod !== null) setHasEverSubmitedForm(true)

			setContinueFormSubmittedID(data.id)

			setContinueFormSubmittedType({
				isFixed: data.isFixed === 'y',
				isMobile: data.isMobile === 'y',
			})

			setHasMounted(true)
		}
	}

	useEffect(
		() => onChangeNeedToFillFormType(),
		// eslint-disable-next-line
		[fixedReportType, mobileReportType, isNewForm]
	)

	// On Mounting the page
	useEffect(() => {
		onGenerateAnswer()
	}, [onGenerateAnswer])

	useEffect(() => {
		onGetCurrentHead()
		// Swal.fire({
		// 	title: 'ส่งข้อมูลสำเร็จ',
		// 	text: 'ท่านต้องการกรอกแบบสำรวจหรือไม่?',
		// 	icon: 'success',
		// 	confirmButtonText: 'ต้องการ',
		// 	showCancelButton: true,
		// 	cancelButtonText: 'ไม่ต้องการ',
		// }).then(result => {
		// 	if (result.isConfirmed) {
		// 		window.open(
		// 			'https://docs.google.com/forms/d/e/1FAIpQLSd3Kh9APHngJ16OWX9QbijN36-h_4YV9DxM59X8E8zetJf7Iw/viewform?usp=sf_link',
		// 			'_blank'
		// 		)
		// 	}
		// })
	}, [])

	useEffect(() => {
		const obj = {}

		mobile_additional_files.forEach(item => {
			obj[item.key] = []
		})

		setMobileFiles(obj)
	}, [])

	return (
		<>
			<Grid className="min-h-screen flex flex-col pb-10 px-2 lg:px-10">
				<Grid className={`${classes.root} h-full`}>
					<Stepper
						style={{ backgroundColor: 'transparent' }}
						alternativeLabel
						activeStep={activeStep}
						connector={<Connector />}>
						{hasMobile && activeStep > 0
							? stepsMobile.map(label => (
								<Step alternativeLabel key={label}>
									<StepLabel StepIconComponent={StepperIcon}>
										{label}
									</StepLabel>
								</Step>
							))
							: steps.map(label => (
								<Step alternativeLabel key={label}>
									<StepLabel StepIconComponent={StepperIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
					</Stepper>
					<Grid>
						{activeStep === (hasMobile ? stepsMobile.length : steps.length) ? (
							<Grid>
								<Typography className={classes.instructions}>
									All steps completed - you&apos;re finished
								</Typography>
							</Grid>
						) : (
							<Grid
								container
								direction="row"
								justifyContent="center"
								className="pb-14 ">
								<Grid container item>
									<Grid
										className={`${classes.instructions} w-full pb-4`}
										component="div">
										<StepContent
											// Main State
											activeStep={activeStep}
											hasMobile={hasMobile}
											// Standard Report Form Handler State
											flags={flags}
											needToFillFormType={needToFillFormType}
											filledFormAmount={filledFormAmount}
											currentFilledFormIndex={currentFilledFormIndex}
											onFillForm={onFillForm}
											// Standard Report Answers
											fixedGeneralAnswers={fixedGeneralAnswers}
											fixedInfoAnswers={fixedInfoAnswers}
											fixedSoundAnswers={fixedSoundAnswers}
											mobileGeneralAnswers={mobileGeneralAnswers}
											mobileInfoAnswers={mobileInfoAnswers}
											mobileSoundAnswers={mobileSoundAnswers}
											// Step1
											userOperatorProfile={userOperatorProfile}
											reportMethod={reportMethod}
											onChangeRadio={onChangeRadio}
											fixedReportTypeRadioValue={fixedReportType}
											mobileReportTypeRadioValue={mobileReportType}
											onChangeReportType={onChangeReportType}
											hasEverSubmitedForm={hasEverSubmitedForm}
											isNewForm={isNewForm}
											onChangeIsContinueRadio={onChangeIsContinueRadio}
											continueFormSubmittedType={continueFormSubmittedType}
											// Step 2 Upload File
											excelFile={excelFile}
											setExcelFile={setExcelFile}
											excelFileShow={excelFileShow}
											setExcelFileShow={setExcelFileShow}
											// Step 3 Additional Files
											mobileFiles={mobileFiles}
											onInputAdditionalFile={onInputAdditionalFile}
											onDeleteAdditionalFile={onDeleteAdditionalFile}
											// Step 4 Validation
											invalidDataSetRecords={invalidDataSetRecords}
											invalidAnswers={invalidAnswers}
											onInputValidationReason={onInputValidationReason}
											invalidInputMethod={invalidInputMethod}
											onChangeInvalidaInputMethod={onChangeInvalidaInputMethod}
											openAskingValidationModal={openAskingValidationModal}
											onOpenAskingValidationModal={onOpenAskingValidationModal}
											onCloseAskingValidationModal={
												onCloseAskingValidationModal
											}
											onCloseFillingModal={onCloseFillingModal}
											onAskInvalidInputMethod={onAskInvalidInputMethod}
											currentValiationQuestionNumber={
												currentValiationQuestionNumber
											}
											openFillingModal={openFillingModal}
											currentSelectedItem={currentSelectedItem}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									direction="row"
									justifyContent="space-between"
									alignItems="baseline"
									className="w-full">
									<Grid item>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											variant="contained"
											className="w-36">
											ย้อนกลับ
										</Button>
									</Grid>
									<Grid item className="flex flex-row gap-x-5">
										{hasMobile && activeStep === 2 && (
											<Button
												disabled={uploadAdditionalFileButtonDisabled()}
												onClick={uploadAdditionalFile}
												variant="contained"
												color="secondary"
												className={`w-36`}>
												บันทึกเอกสารแนบ
											</Button>
										)}
										<Button
											disabled={buttonNextDisabled || loading}
											variant="contained"
											color="secondary"
											onClick={handleNext}
											className={`w-36`}>
											{nextButtonGenerator()}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>

			<SendingStandardReportModalSuccess
				open={openStandardReportSuccessModal}
				handleButton={onAgreeStandardReportSuccessModalCTA}
			/>
			<SendingStandardReportModalFailed
				open={openStandardReportFailedModal}
				message={sendingFailedMessage}
				handleButton={onOpenStandardReportFailedModal}
			/>

			{/* Upload Excel Failed Status Modal */}
			<SendingStandardReportModalFailed
				open={uploadExcelStatus === false}
				message={'format ของไฟล์ excel ไม่ถูกต้อง โปรดลองอีกครั้ง'}
				handleButton={() => {
					setUploadExcelStatus(null)
				}}
			/>

			<SureModal
				title="ส่งรายงานมาตรฐานคุณภาพ"
				content="คุณแน่ใจที่จะส่งรายงานมาตรฐานคุณภาพไปยังผู้อนุมัติหรือไม่"
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				open={openSubmitStandardReportSureModal}
				onClose={onOpenSubmitStandardReportSureModal}
				handleCancel={onOpenSubmitStandardReportSureModal}
				handleButton={onSubmitToApprove}
			/>
			<EmptyFileModal
				open={openEmptyFileModal}
				handleButton={onOpenEmptyFileModal}
			/>
		</>
	)
}

export default StandardReport
