import { forwardRef, useRef, useEffect } from 'react'
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Slide,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	TextField,
	Divider,
	FormControl,
	Select,
	MenuItem,
} from '@material-ui/core'
import 'date-fns'

import { Close, PersonAdd, Edit } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'

export default function OrganizationUserModal({
	open,
	handleButton,
	onClose,
	userModalType,
	// Adding States
	nationalCardIDNo,
	userInfo,
	activeDate,
	expireDate,
	fixedTypePermission,
	mobileTypePermission,
	// Editing States
	editNationalCardIDNo,
	editUserInfo,
	editActiveDate,
	editExpireDate,
	editFixedTypePermission,
	editMobileTypePermission,
	// Adding Handler
	onFillNationalCardIDNo,
	onCheckFormTypePermission,
	onChangeUserRoleID,
	onSelectActiveDate,
	onSelectExpireDate,
	organizationData,
	// Editing Handler
	onChangeEditUserRoleID,
	onFillEditNationalCardIDNo,
	onCheckEditFormTypePermission,
	onSelectEditActiveDate,
	onSelectEditExpireDate,
	onFillAddUserInfo,
	onFillEditUserInfo,
	onChangeEditUserStatus,
	// Save Button Logic
	saveButtonAdd,
	saveButtonEdit,
	// Admin Role
	adminRoles,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [open])

	const typeGenerator = _userModalType => {
		if (_userModalType === 'add')
			return {
				label: 'เพิ่มผู้ใช้งาน',
				icon: <PersonAdd htmlColor="#fff" className="mr-1" />,
			}
		if (_userModalType === 'edit')
			return {
				label: 'แก้ไขข้อมูลผู้ใช้งาน',
				icon: <Edit htmlColor="#fff" className="mr-1" />,
			}

		return { label: '', icon: '' }
	}

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle className="bg-card_top_border">
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center">
						{typeGenerator(userModalType).icon}
						<Typography variant="h6" className="text-white">
							{typeGenerator(userModalType).label}
						</Typography>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent className="h-full" dividers>
				<Grid className="my-3">
					<Typography>ข้อมูลเจ้าหน้าที่</Typography>
				</Grid>
				<Grid>
					<Divider />
				</Grid>
				<Grid className="flex flex-col mt-5">
					<Grid className="flex flex-col lg:flex-row items-center gap-x-8 gap-y-5 lg:gap-y-0">
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>ชื่อ</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="firstname"
									value={userInfo.firstname}
									onChange={onFillAddUserInfo}
								/>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="firstname"
									value={editUserInfo.firstname}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>นามสกุล</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="lastname"
									value={userInfo.lastname}
									onChange={onFillAddUserInfo}
								/>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="lastname"
									value={editUserInfo.lastname}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
					</Grid>
					{/* <Grid>
							<Divider />
						</Grid> */}
					<Grid className="flex flex-col lg:flex-row items-center gap-x-8 gap-y-5 lg:gap-y-0 mt-5">
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>ประเภทเจ้าหน้าที่</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							<Grid className="w-full py-2 mx-auto">
								<FormControl variant="outlined" fullWidth size="small">
									<Select
										value={
											userModalType === 'add'
												? userInfo.adminRoleID
												: editUserInfo.adminRoleID
										}
										onChange={
											userModalType === 'add'
												? onChangeUserRoleID
												: onChangeEditUserRoleID
										}>
										{Object.values(adminRoles).map((item, index) => {
											return (
												<MenuItem key={index} value={item.value}>
													{item.name}
												</MenuItem>
											)
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>อีเมล</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>
							{userModalType === 'add' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="email"
									value={userInfo.email}
									onChange={onFillAddUserInfo}
								/>
							)}
							{userModalType === 'edit' && (
								<TextField
									className="w-full"
									variant="outlined"
									size="small"
									name="email"
									value={editUserInfo.email}
									onChange={onFillEditUserInfo}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="flex-end" alignItems="center">
					<Grid item className="p-3 w-3/12">
						<Button
							fullWidth
							disabled={
								userModalType === 'add' ? saveButtonAdd : saveButtonEdit
							}
							onClick={handleButton}
							color="primary"
							variant="contained">
							บันทึก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
