import { Grid, Input, Button } from '@material-ui/core'
import { findReportQuestion } from '../../../../../../data/standard-reportV2/find-report-question'

export default function SubLabelType3({
	type,
	item,
	onFillForm,
	answers,
	onOpenValidationModal,
}) {
	const label_name = item.label_name
	const childs = item.childs

	return (
		<Grid className="col-span-12  text-center border flex flex-row ">
			{/* Region */}
			<Grid className="w-1/12 text-center flex justify-center items-center border-r font-bold">
				<Grid>{label_name}</Grid>
			</Grid>

			<Grid className="w-2/12  flex flex-col border-r">
				{childs.map((child, index) => (
					<Grid
						key={item.label_name + child + index}
						className="p-1 border-b h-full font-bold">
						{child.label}
					</Grid>
				))}
			</Grid>

			{/* Months */}
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.dataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index + 'input-month1'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
							value={answer?.dataValueMonth1}
							onChange={e =>
								onFillForm(type, child.id, 'dataValueMonth1', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.dataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index + 'input-month2'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
							value={answer?.dataValueMonth2}
							onChange={e =>
								onFillForm(type, child.id, 'dataValueMonth2', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.dataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index + 'input-month3'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
							value={answer?.dataValueMonth3}
							onChange={e =>
								onFillForm(type, child.id, 'dataValueMonth3', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>

			{/* Average */}
			<Grid className="w-1/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.dataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index.toString() + 'input_avg'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
							value={answer?.dataValueMonthAvg}
							onChange={e =>
								onFillForm(type, child.id, 'dataValueMonthAvg', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>

			{/* Validation */}
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.dataSetItemID === child.id)
					const question = findReportQuestion(answer?.dataSetItemID)

					return (
						<Grid
							key={answer?.dataValueMonth3 + index}
							className="border-b border-b-gray-200 border-r border-gray-500 p-1 font-bold text-center flex justify-center items-center self-center h-full w-full">
							{(!answer?.dataValueMonth1 ||
								!answer?.dataValueMonth2 ||
								!answer?.dataValueMonth3) &&
							!answer?.isError ? null : answer?.isError === 'y' ? (
								<Button
									variant="contained"
									color="secondary"
									onClick={() =>
										onOpenValidationModal(
											item.id,
											answer.reason,
											answer.solution,
											answer.periodSolution,
											answer?.validateFileUrl,
											question?.no,
											question?.label
										)
									}>
									ไม่ผ่านเกณฑ์
								</Button>
							) : (
								<Grid className="text-green-500 font-bold text-center ">
									ผ่านเกณฑ์
								</Grid>
							)}
						</Grid>
					)
				})}
			</Grid>
		</Grid>
	)
}
