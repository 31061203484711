import { thunk, action } from 'easy-peasy'
import { sdocUser } from '../../../api/index'

export const sdocUserModel = {
	userSDOC: null,
	userMESDOC: null,
	userLoginExpireDate: null,
	setUserSDOC: action((state, userSDOC) => {
		state.userSDOC = userSDOC
		if (state.userSDOC != null)
			state.userLoginExpireDate = new Date().getTime() + 3600000 * 8
		else
			state.userLoginExpireDate = null
	}),
	setUserMeSDOC: action((state, userMESDOC) => {
		state.userMESDOC = userMESDOC
	}),
	signInUser: thunk(async (actions, payload) => {
		const res = await sdocUser.signInSDOCUser(payload)
		if (res && res.status === 200) {
			const response = res.data
			actions.setUserSDOC(response)
			actions.getUserProfile()

			return response
		}
	}),
	signOutUser: thunk(actions => {
		actions.setUserSDOC(null)
		actions.setUserMeSDOC(null)
	}),
	getUserProfile: thunk(async actions => {
		const res = await sdocUser.getMe()
		if (res && res.status === 200) {
			actions.setUserMeSDOC(res.data)
		}
	}),
}
