import { useEffect } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ProtectedRoute from './protected-route'
import { useStoreActions } from '@store'

// Components
import SignIn from './sign-in'
import Home from './home'
import Manage from './manage'
import Form from './form'
import AdminSDOCUser from './user'
import Sent from './sent'
import SendDetails from './sent/[id]'
import RequestList from './requestlist'
import POAList from './poalist'

export default function SDOCAdminsRoute() {
	const { path } = useRouteMatch()
	const setAppType = useStoreActions(a => a.appModel.setAppType)

	useEffect(() => setAppType('sdoc-admin'))

	return (
		<Switch>
			<ProtectedRoute exact path={path}>
				<Home />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/manage`}>
				<Manage />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/requestlist`}>
				<RequestList />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/form/:id`}>
				<Form />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/user`}>
				<AdminSDOCUser />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/sent`}>
				<Sent />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/sent/:id`}>
				<SendDetails />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/poalist`}>
				<POAList />
			</ProtectedRoute>

			<Route path={`${path}/sign-in`} component={SignIn} />

			<Redirect from={`${path}/*`} to={path} />
		</Switch>
	)
}
