import React, { useState, useEffect } from 'react'

import { Grid, Card, Button, Tooltip } from '@material-ui/core'
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridOverlay } from '@mui/x-data-grid'
import { ListAlt, Menu, Description, SaveAlt, Refresh, GetApp, Build } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import { useStoreRehydrated } from '@store'

import { format } from 'date-fns'

import { sdoc, sdocAdmin, admin } from '@api'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default function POALIST() {
    const isRehydrated = useStoreRehydrated()
    const history = useHistory()
    const [rowsPOAList, setRowsPOAList] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [sortModel, setSortModel] = useState([
        {
            field: 'startDate',
            sort: 'desc',
        },
    ]);

    var columnsPOAList = [
        {
            field: 'nationalID',
            headerName: 'ข้อมูลผู้รับมอบอำนาจ',
            width: 300,
            headerAlign: 'left',
            align: 'center',
            sortable: true,
            editable: false,
            renderCell: (params) => {
                return (
                    <Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
                        {params.row.operatorUser?.prefix}{params.row.operatorUser?.firstname} {params.row.operatorUser?.lastname}<br />
                        ({params.row.nationalID})
                    </Grid>
                )
            },
            valueGetter: (params) => {
                return `${params.row.operatorUser?.prefix}${params.row.operatorUser?.firstname} ${params.row.operatorUser?.lastname} (${params.row.nationalID})`
            }
        },
        {
            field: 'juristicNo',
            headerName: 'ข้อมูลนิติบุคคล',
            width: 400,
            headerAlign: 'left',
            align: 'center',
            sortable: true,
            editable: false,
            renderCell: (params) => {
                return (
                    <Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
                        {params.row.operator?.operatorName || "ไม่พบข้อมูล"}<br />
                        ({params.row.juristicNo})
                    </Grid>
                )
            },
            valueGetter: (params) => {
                return `${params.row.operator?.operatorName || "ไม่พบข้อมูล"} (${params.row.juristicNo})`
            }
        },
        {
            field: 'startDate',
            headerName: 'วันที่เริ่มต้นมอบอำนาจ',
            width: 250,
            headerAlign: 'left',
            align: 'center',
            sortable: true,
            editable: false,
            renderCell: (params) => {
                return format(new Date(params.value), 'dd/MM/yyyy')
            }
        },
        {
            field: 'endDate',
            headerName: 'วันที่สิ้นสุดมอบอำนาจ',
            width: 250,
            headerAlign: 'left',
            align: 'center',
            sortable: true,
            editable: false,
            renderCell: (params) => {
                return format(new Date(params.value), 'dd/MM/yyyy')
            }
        },
        {
            field: 'status',
            headerName: 'สถานะ',
            width: 150,
            headerAlign: 'left',
            align: 'center',
            sortable: true,
            editable: false,
            renderCell: (params) => {
                // compare by startDate and endDate
                const startDate = new Date(params.row.startDate)
                const endDate = new Date(params.row.endDate)
                const today = new Date()
                if (today < startDate) {
                    return (
                        <Grid style={{ lineHeight: "normal", whiteSpace: "normal", color: "yellow" }}>
                            รอเริ่มต้น
                        </Grid>
                    )
                } else if (today > endDate) {
                    return (
                        <Grid style={{ lineHeight: "normal", whiteSpace: "normal", color: "gray" }}>
                            หมดอายุ
                        </Grid>
                    )
                } else {
                    return (
                        <Grid style={{ lineHeight: "normal", whiteSpace: "normal", color: "green" }}>
                            กำลังใช้งาน
                        </Grid>
                    )
                }
            },
            valueGetter: (params) => {
                const startDate = new Date(params.row.startDate)
                const endDate = new Date(params.row.endDate)
                const today = new Date()
                if (today < startDate) {
                    return "รอเริ่มต้น"
                } else if (today > endDate) {
                    return "หมดอายุ"
                } else {
                    return "กำลังใช้งาน"
                }
            }
        }
    ];

    const handleSortChange = (model) => {
        if (isRehydrated && model[0] !== sortModel[0])
            setSortModel(model);
    };

    const getPOAList = async () => {
        setIsDataLoading(true)
        const res = await sdocAdmin.getPOAList()
        if (res && res.status === 200) {
            const data = res.data
            if (data) {
                setRowsPOAList(data)
            }
        }
        setIsDataLoading(false)
    }

    useEffect(() => {
        if (isRehydrated) {
            getPOAList()
        }
        // eslint-disable-next-line
    }, [isRehydrated])

    return (
        <>
            <Grid className="flex flex-col">
                <Grid className="p-10">
                    <Card className={`border-t-4 border-card_top_border`}>
                        <Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
                            <Grid className="flex flex-row items-center mt-4 mb-3 px-5">
                                <Grid>
                                    <ListAlt style={{ fontSize: 22 }} className="mr-1" />
                                </Grid>
                                <Grid className="font-bold text-sm text-card_header_color">
                                    รายการมอบอำนาจภายในระบบ SDoC
                                </Grid>
                            </Grid>
                            <Grid className="md:px-5" style={{ height: '80vh', width: '100%' }}>
                                <DataGrid
                                    rows={rowsPOAList}
                                    columns={columnsPOAList}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    sortModel={sortModel}
                                    rowHeight={66}
                                    onSortModelChange={(model) => {
                                        handleSortChange(model)
                                    }}
                                    // localeText={{
                                    //     toolbarExport: 'ดาวน์โหลดข้อมูลจากตาราง',
                                    // }}
                                    components={{
                                        NoRowsOverlay: () => {
                                            if (!isDataLoading) {
                                                return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
                                            } else {
                                                return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
                                            }
                                        },
                                        // Toolbar: CustomToolbar,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>

    );
}