import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './pages/index'
import reportWebVitals from './reportWebVitals'

import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { Theme } from '../src/styles/theme'
import { StoreProvider } from 'easy-peasy'
import store from './store'

import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

ReactDOM.render(
	<Router history={history}>
		<Suspense fallback={null}>
			<ThemeProvider theme={Theme}>
				<CssBaseline>
					<StoreProvider store={store}>
						<App />
					</StoreProvider>
				</CssBaseline>
			</ThemeProvider>
		</Suspense>
	</Router>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
