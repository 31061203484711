import { createTheme } from '@material-ui/core/styles'

// import SarabunLight from '../assets/font/Sarabun-Light.ttf'
// import SarabunLightItalic from '../assets/font/Sarabun-LightItalic.ttf'
// import SarabunRegular from '../assets/font/Sarabun-Regular.ttf'
// import SarabunRegularItalic from '../assets/font/Sarabun-Italic.ttf'

// const sarabun_light = {
// 	fontFamily: 'Sarabun',
// 	fontStyle: 'normal',
// 	fontDisplay: 'swap',
// 	fontWeight: 300,
// 	unicodeRange:
// 		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// }

// const sarabun_light_italic = {
// 	fontFamily: 'Sarabun',
// 	fontStyle: 'italic',
// 	fontDisplay: 'swap',
// 	fontWeight: 300,
// 	unicodeRange:
// 		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// }

// const sarabun_regular = {
// 	fontFamily: 'Sarabun',
// 	fontStyle: 'normal',
// 	fontDisplay: 'swap',
// 	fontWeight: 400,
// 	unicodeRange:
// 		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// }

// const sarabun_regular_italic = {
// 	fontFamily: 'Sarabun',
// 	fontStyle: 'italic',
// 	fontDisplay: 'swap',
// 	fontWeight: 400,
// 	unicodeRange:
// 		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// }

export const Theme = createTheme({
	palette: {
		primary: {
			main: '#a74345',
			contrastText: '#fff',
		},
		secondary: {
			main: '#8f2b35',
			contrastText: '#fff',
		},
		alt1: {
			main: '#c8e8ff',
			contrastText: '#000',
		},
		alt2: {
			main: '#edf7fe',
			contrastText: '#000',
		},
		alt3: {
			main: '#e2efd9',
			contrastText: '#000',
		},
		error: {
			main: '#FF0000',
		},
	},
	typography: {
		fontFamily: ['Sarabun','sans-serif'].join(','),
	},
	// overrides: {
	// 	MuiCssBaseline: {
	// 		'@global': {
	// 			'@font-face': [
	// 				sarabun_light,
	// 				sarabun_light_italic,
	// 				sarabun_regular,
	// 				sarabun_regular_italic,
	// 			],
	// 		},
	// 	},
	// },
})
