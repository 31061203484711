import {
	AppBar,
	Grid,
	Toolbar,
	Typography,
	IconButton,
	Hidden,
} from '@material-ui/core'
import { Menu as HamBurger } from '@material-ui/icons'

import SideBar from '../components/SideBar'
import { makeStyles } from '@material-ui/core/styles'

import { useStoreActions, useStoreState } from '../store'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

import Kurt from '../assets/images/logo-wording.png'

const drawerWidth = 240

const useStyles = makeStyles(theme => {
	return {
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
			border: `2px solid ${theme.palette.primary.main}`,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
	}
})

export default function NavBar({ children }) {
	const [mobileOpen, setMobileOpen] = useState(false)
	const classes = useStyles()
	const sidebar = useStoreState(s => s.appModel.sidebar)
	const setSidebar = useStoreActions(a => a.appModel.setSideBar)
	const userOperatorProfile = useStoreState(s => s.userModel.user)
	const QosAdminProfile = useStoreState(s => s.QoSAdminModel.QosAdmin)
	const userSDOCProfile = useStoreState(s => s.sdocUserModel.userMESDOC)
	const adminSDOCProfile = useStoreState(s => s.sdocAdminModel.adminMESDOC)
	const mainAdminProfile = useStoreState(s => s.mainAdminModel.admin)
	const appType = useStoreState(s => s.appModel.app_type)
	const location = useLocation()
	const notAllowedPath = [
		`/sign-in`,
		`/registration`,
		`/client-api`,
		`/auth/identify`,
	]

	function onClose() {
		setSidebar(!sidebar)
	}

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	const isShowNavbar = () => {
		if (appType === 'users') return !notAllowedPath.includes(location.pathname)
		else if (appType === 'admin')
			return !notAllowedPath.includes(location.pathname.substring(6))
		else if (appType === 'sdoc-admin')
			return !notAllowedPath.includes(location.pathname.substring(11))
		else if (appType === 'sdoc')
			return !notAllowedPath.includes(location.pathname.substring(5))
		else if (appType === 'main-admin')
			return !notAllowedPath.includes(location.pathname.substring(11))
	}

	// eslint-disable-next-line
	useEffect(() => isShowNavbar(), [location.pathname])

	return isShowNavbar() ? (
		<>
			<AppBar position="sticky" color="inherit" className={classes.appBar}>
				<Toolbar className="w-full">
					{/* Mobile */}
					<Hidden lgUp>
						<Grid className="flex flex-col w-full items-center justify-center">
							<Grid container justifyContent="space-between" direction="row">
								<Grid className="flex flex-row items-center font-sans overflow-hidden">
									<IconButton onClick={handleDrawerToggle}>
										<HamBurger htmlColor="#000" />
									</IconButton>
									<img src={Kurt} alt="kurt" />
								</Grid>
								<Grid></Grid>
							</Grid>
						</Grid>
					</Hidden>
					{/* Desktop */}
					<Hidden mdDown>
						<Grid
							className="w-full"
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center">
							<Grid className="flex flex-row">
								<img src={Kurt} alt="kurt" />
							</Grid>
							<Grid item>
								<Typography variant="h6" noWrap className="text-black">
									{/* NBTC QoS */}
									{appType === 'users' &&
										userOperatorProfile?.firstname &&
										userOperatorProfile?.lastname
										? `${userOperatorProfile?.firstname} ${userOperatorProfile?.lastname}` +
										' (' +
										userOperatorProfile.roleTitle +
										')'
										: ''}
									{/* NBTC QoS Admin */}
									{appType === 'admin' && QosAdminProfile
										? QosAdminProfile.firstname +
										' ' +
										QosAdminProfile.lastname +
										' (' +
										QosAdminProfile.roleTitle +
										')'
										: ''}
									{/* User SDOC */}
									{appType === 'main-admin' && mainAdminProfile
										? mainAdminProfile.firstname +
										' ' +
										mainAdminProfile.lastname
										: ''}
									{/* User SDOC */}
									{appType === 'sdoc' && userSDOCProfile
										? userSDOCProfile.firstname +
										' ' +
										userSDOCProfile.lastname +
										' '
										: ''}
									{/* Admin SDOC */}
									{appType === 'sdoc-admin' && adminSDOCProfile
										? adminSDOCProfile.firstname +
										' ' +
										adminSDOCProfile.lastname +
										' (' +
										adminSDOCProfile.roleTitle +
										')'
										: ''}
								</Typography>
							</Grid>
						</Grid>
					</Hidden>
				</Toolbar>
			</AppBar>
			<SideBar
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
				isClose={sidebar}
				onClose={onClose}>
				{children}
			</SideBar>
		</>
	) : (
		<>{children}</>
	)
}
