import { useState, useEffect } from 'react'
import { useReduceForm } from '../../../../../utils/useReduceForm'
import {
	Grid,
	Card,
	CardContent,
	Divider,
	useMediaQuery,
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'

import TwoPointThreeSubHeader from './two-point-three-header'
import ExcelSubHeader from './sub-header'
import SubLabelType1 from './sub-label-1'
import SubLabelType2 from './sub-label-2'
import SubLabelType3 from './sub-label-3'
import ValidationModal from '../validation-modal'

import { fixed_general_details } from '../../../../../data/standard-reportV2/fixed-general'
import { fixed_info_details } from '../../../../../data/standard-reportV2/fixed-info'
import { fixed_sound_details } from '../../../../../data/standard-reportV2/fixed-sound'
import { mobile_general_details } from '../../../../../data/standard-reportV2/mobile-general'
import { mobile_info_details } from '../../../../../data/standard-reportV2/mobile-info'
import { mobile_sound_details } from '../../../../../data/standard-reportV2/mobile-sound'
import { canceledListDetailNo } from '../../../../../data/standard-reportV2/canceledList';

const useStyles = makeStyles(theme => ({
	form_container: {
		width: '700px',
		overflow: 'scroll',
	},
}))

export default function StandardReportForm({
	// Main Component's State
	flags,
	preField,
	type,
	onFillForm,
	// Standard Report Answers
	fixedGeneralAnswers,
	fixedInfoAnswers,
	fixedSoundAnswers,
	mobileGeneralAnswers,
	mobileInfoAnswers,
	mobileSoundAnswers,
	// Validation
	currentSelectedError,
	openValidationModal,
	onOpenValidationModal,
	onCloseValidationModal,
	// Flags
	operatorFlag,
}) {
	const classes = useStyles()
	const [report, setReport] = useState([])
	const [reportName, setReportName] = useState('')
	const [answers, setAnswers] = useState([])
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const { form } = useReduceForm(report)
	const nvmo_no = ['2.1.4', '2.1.5', '2.1.6']

	useEffect(() => {
		if (type === 'fixed-general') setReport(fixed_general_details)
		else if (type === 'fixed-sound') setReport(fixed_sound_details)
		else if (type === 'fixed-info') setReport(fixed_info_details)
		else if (type === 'mobile-general') setReport(mobile_general_details)
		else if (type === 'mobile-sound') setReport(mobile_sound_details)
		else if (type === 'mobile-info') setReport(mobile_info_details)

		if (type === 'fixed-general')
			setReportName('ส่วนที่ 1 ค่าชี้วัดคุณภาพบริการทั่วไป')
		else if (type === 'fixed-sound')
			setReportName('ส่วนที่ 2 ค่าชี้วัดคุณภาพบริการประเภทเสียง')
		else if (type === 'fixed-info')
			setReportName('ส่วนที่ 3 ค่าชี้วัดคุณภาพบริการประเภทข้อมูล')
		else if (type === 'mobile-general')
			setReportName('ส่วนที่ 1 ค่าชี้วัดคุณภาพบริการทั่วไป')
		else if (type === 'mobile-sound')
			setReportName('ส่วนที่ 2 ค่าชี้วัดคุณภาพบริการประเภทเสียง')
		else if (type === 'mobile-info')
			setReportName('ส่วนที่ 3 ค่าชี้วัดคุณภาพบริการประเภทข้อมูล')
	}, [type])

	useEffect(() => {
		const tempAnswers = [
			...fixedGeneralAnswers,
			...fixedInfoAnswers,
			...fixedSoundAnswers,
			...mobileGeneralAnswers,
			...mobileInfoAnswers,
			...mobileSoundAnswers,
		]
		setAnswers(tempAnswers)
	}, [
		fixedGeneralAnswers,
		fixedInfoAnswers,
		fixedSoundAnswers,
		mobileGeneralAnswers,
		mobileInfoAnswers,
		mobileSoundAnswers,
	])

	return (
		<>
			<Grid className="flex flex-col mt-5 gap-y-3 overflow-scroll">
				<Grid component="p">{reportName}</Grid>
				<Grid component="p" className="text-red-500">
					ไม่ต้องกำหนดข้อมูล ถ้าท่านไม่มีการให้บริการในข้อใด หรือ
					รายละเอียดของชุดข้อมูลนั้นๆ
				</Grid>
				<Card className={isMobile ? classes.form_container : ''}>
					<CardContent>
						{form.map((detail, index) => {
							const isNeedAnswer = detail.isNeedAnswer
							const sub_label_type = detail.sub_labels.sub_label_type
							const labels = detail.sub_labels.labels
							const isNVMO = flags?.NVMO && nvmo_no.includes(detail.no)
							const isPayPhone = operatorFlag?.isPayphone
							const is2G = operatorFlag?.is2G
							const isTwoPointThree = detail.no.includes('2.3.' || '2.2.')
							const canceledList = canceledListDetailNo
							// if detail no in cancledListDetailNo
							if (canceledList.includes(detail.no)) {
								return <></>
							}
							else if (detail.no !== '1.1.7')
								return (
									<Grid
										component="div"
										key={detail.label + index}
										className="grid grid-cols-12 gap-y-5 my-10">
										{/* Header */}
										<Grid className="col-span-1 font-bold">ข้อ</Grid>
										<Grid className="col-span-4 font-bold">
											ค่าชี้วัดคุณภาพบริการ
										</Grid>
										<Grid className="col-span-3 font-bold">ค่าเป้าหมาย</Grid>
										<Grid className="col-span-4 font-bold">
											เงื่อนไขการกรอกข้อมูล
										</Grid>
										{/* Divider */}
										<Divider className="col-span-12" />
										{/* Questions */}
										{/* ข้อ */}
										<Grid className="col-span-1">{detail.no}</Grid>
										{/* ค่าชี้วัดคุณภาพบริการ */}
										<Grid className="col-span-4" style={{ padding: '0 10px' }}>
											{detail.label}
										</Grid>
										{/* ค่าเป้าหมาย */}
										<Grid className="col-span-3" style={{ padding: '0 10px' }}>
											{detail.targeted_text.map((item, index) => {
												const matchedLabel = labels[index]

												if (sub_label_type === 2 && matchedLabel) {
													if (matchedLabel.label.includes('2G') && !is2G)
														return <></>
												}

												return <Grid key={item + index}>{item}</Grid>
											})}
										</Grid>
										{/* เงื่อนไชการกรอกข้อมูล */}
										<Grid className="col-span-4 text-red-500">
											<Grid>*{detail.filling_condition}</Grid>
											{isNVMO && <Grid>* มี MVNO</Grid>}
										</Grid>
										{/* รายละเอียดชุดข้อมูล */}
										<Grid className="col-span-12  grid grid-cols-12 my-5 rounded-md">
											{/* Header */}
											<Grid
												className="col-span-12 text-center border p-1 font-bold"
												style={{ backgroundColor: '#C8E8FF' }}>
												ผลการวัด
											</Grid>
											{/* Two Point Three Sub Header */}
											{isTwoPointThree && (
												<TwoPointThreeSubHeader
													sub_label_type={sub_label_type}
												/>
											)}
											{/* Sub Header */}
											{isNeedAnswer && (
												<ExcelSubHeader
													sub_label_type={sub_label_type}
													isTwoPointThree={isTwoPointThree}
												/>
											)}
											{/* Content */}

											{/* Sub Label Type 1 */}
											{isNeedAnswer && sub_label_type === 1 && (
												<SubLabelType1
													type={type}
													datasetId={detail.records[0].id}
													onFillForm={onFillForm}
													answers={answers}
													onOpenValidationModal={onOpenValidationModal}
												/>
											)}

											{/* Sub Label Type 2 */}
											{isNeedAnswer &&
												sub_label_type === 2 &&
												labels.map((item, index) => (
													<SubLabelType2
														key={index + 'type_2'}
														flags={flags}
														item={item}
														preField={preField}
														type={type}
														onFillForm={onFillForm}
														answers={answers}
														onOpenValidationModal={onOpenValidationModal}
													/>
												))}

											{/* Sub Label Type 3 */}
											{isNeedAnswer &&
												sub_label_type === 3 &&
												labels.map(item => (
													<SubLabelType3
														key={`${item.label_name}-${detail.label}-${detail.no}`}
														item={item}
														preField={preField}
														type={type}
														onFillForm={onFillForm}
														answers={answers}
														onOpenValidationModal={onOpenValidationModal}
													/>
												))}
										</Grid>
									</Grid>
								)

							if (detail.no === '1.1.7' && isPayPhone)
								return (
									<Grid
										component="div"
										key={detail.label + index}
										className="grid grid-cols-12 gap-y-5 my-10">
										{/* Header */}
										<Grid className="col-span-1 font-bold">ข้อ</Grid>
										<Grid className="col-span-4 font-bold">
											ค่าชี้วัดคุณภาพบริการ
										</Grid>
										<Grid className="col-span-3 font-bold">ค่าเป้าหมาย</Grid>
										<Grid className="col-span-4 font-bold">
											เงื่อนไขการกรอกข้อมูล
										</Grid>
										{/* Divider */}
										<Divider className="col-span-12" />
										{/* Questions */}
										{/* ข้อ */}
										<Grid className="col-span-1">{detail.no}</Grid>
										{/* ค่าชี้วัดคุณภาพบริการ */}
										<Grid className="col-span-4" style={{ padding: '0 10px' }}>
											{detail.label}
										</Grid>
										{/* ค่าเป้าหมาย */}
										<Grid className="col-span-3" style={{ padding: '0 10px' }}>
											{detail.targeted_text.map((item, index) => (
												<Grid key={item + index}>{item}</Grid>
											))}
										</Grid>
										{/* เงื่อนไชการกรอกข้อมูล */}
										<Grid className="col-span-4 text-red-500">
											<Grid>*{detail.filling_condition}</Grid>
											{isNVMO && <Grid>* มี NVMO</Grid>}
										</Grid>
										{/* รายละเอียดชุดข้อมูล */}
										<Grid className="col-span-12  grid grid-cols-12 my-5 rounded-md">
											{/* Header */}
											<Grid
												className="col-span-12 text-center border p-1 font-bold"
												style={{ backgroundColor: '#C8E8FF' }}>
												ผลการวัด
											</Grid>
											{/* Sub Header */}
											{isNeedAnswer && (
												<ExcelSubHeader sub_label_type={sub_label_type} />
											)}
											{/* Content */}

											{/* Sub Label Type 1 */}
											{isNeedAnswer && sub_label_type === 1 && (
												<SubLabelType1
													type={type}
													datasetId={detail.records[0].id}
													onFillForm={onFillForm}
													answers={answers}
													onOpenValidationModal={onOpenValidationModal}
												/>
											)}

											{/* Sub Label Type 2 */}
											{isNeedAnswer &&
												sub_label_type === 2 &&
												labels.map((item, index) => (
													<SubLabelType2
														key={index + 'type_2'}
														flags={flags}
														item={item}
														preField={preField}
														type={type}
														onFillForm={onFillForm}
														answers={answers}
														onOpenValidationModal={onOpenValidationModal}
													/>
												))}

											{/* Sub Label Type 3 */}
											{isNeedAnswer &&
												sub_label_type === 3 &&
												labels.map(item => (
													<SubLabelType3
														key={`${item.label_name}-${detail.label}-${detail.no}`}
														item={item}
														preField={preField}
														type={type}
														onFillForm={onFillForm}
														answers={answers}
														onOpenValidationModal={onOpenValidationModal}
													/>
												))}
										</Grid>
									</Grid>
								)

							return <></>
						})}
					</CardContent>
				</Card>
			</Grid>
			<ValidationModal
				currentSelectedError={currentSelectedError}
				open={openValidationModal}
				onClose={onCloseValidationModal}
			/>
		</>
	)
}
