import {
	Grid,
	// TextField,
	IconButton,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

export default function File({ originalFileName, onDelete, index = 0 }) {
	return (
		<Grid className="flex flex-row gap-x-3 w-full items-center">
			<Grid>{originalFileName}</Grid>
			<IconButton color="primary" onClick={onDelete}>
				<Delete />
			</IconButton>
		</Grid>
	)
}
