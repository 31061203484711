import { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'

import OrganizationDetailCardV3 from '@components/sdoc/organization-detail-cardV3'
import BrandNameCard from '@components/sdoc/brand-name-card'
import EquipmentCard from '@components/sdoc/equipment-card'
import DocumentAdditionCard from '@components/sdoc/document-addition-card'
import SDoCDetailCard from '@components/sdoc/sdoc-detail-card'
import SureModal from '@components/SureModal'
import SuccessModal from '@components/modal/SuccessModal'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import { sdoc, operators } from '@api'
import { useQuery } from 'utils/useQuery'

import { frequency, powerUnit, type_equipment } from 'data/sdoc'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'

const initEquipmentState = {
	ProductTypeText: '',
	FrequencyText: '',
	PowerText: '',
	PolicyText: '',
	SelectedPowerUnit: '',
	PolicyText_2: '',
	PolicyText_3: '',
	Flag: '',
}
const CONFIRMATION_INFO_STORAGE_INITIAL_STATE = {
	normal_person: {
		type: 'normal-person',
		name: '',
		cardNo: '',
		address: '',
	},
	organization: {
		type: 'organization',
		name: '',
		cardNo: '',
		address: '',
	},
}
// Page Description: ยื่นแบบหนังสือรับรองตนเอง
export default function SDOC() {
	const history = useHistory()
	const location = useLocation()
	const query = useQuery()


	const { id: sDOCID } = useParams()
	const mountType = query.get('mountType')
	// SDOC Id
	// eslint-disable-next-line
	const [sDOCId, setSDOCId] = useState(null)
	// Running NO / SDoC NO
	const [runningNo, setRunningNo] = useState('')
	const [sDoCNo, setSDoCNo] = useState('')

	const [currentSenderState, setCurrentSenderState] = useState('normal_person')
	// Confirmation Info. Status
	// eslint-disable-next-line
	const [confirmationInfoHasFetched, setConfirmationInfoHasFetched] = useState({
		normal_person: false,
		organization: false,
	})
	// eslint-disable-next-line
	const [confirmationInfoStorage, setConfirmationInfoStorage] = useState(CONFIRMATION_INFO_STORAGE_INITIAL_STATE)
	// Confirmation Info.
	const [confirmationInfo, setConfirmationInfo] = useState({
		type: 'normal-person',
		name: '',
		cardNo: '',
		address: '',
	})

	const [equipmentMasterData, setEquipmentMasterData] = useState({
		productType: [],
		typeEquipment: [],
		frequency: [],
		powerUnit: [],
	})
	const [inputModel, setInputModel] = useState({
		brandName: '',
		productModel: '',
	})
	// Input Equipment State
	const [inputEquipmentState, setInputEquipmentState] = useState([
		initEquipmentState,
	])
	// Additional Files
	const [tempFile, setTempFile] = useState({
		file: null,
		name: '',
		// filedate: '',
	})
	const [additionalFiles, setAdditionalFiles] = useState([])
	// Modal States
	const [submitEquipmentSureModal, setSubmitEquipmentSureModal] =
		useState(false)
	const [openSubmitEquipmentSuccessModal, setOpenSubmitEquipmentSuccessModal] =
		useState(false)
	const [submitTextModal, setSubmitTextModal] = useState({
		title: '',
		content: '',
		handleButton: null,
	})
	// Validation States
	// eslint-disable-next-line
	const [canSubmit, setCanSubmit] = useState(false)
	const [isEquipmentCardValid, setIsEquipmentCardValid] = useState(false)
	const [attachFileLength, setAttachFileLength] = useState(0)

	/** <---------------------------------------------------------------------> */

	const onOpenSubmitEquipmentSureModal = () => {
		if (mountType === 'attachFile')
			setSubmitTextModal({
				title: 'แนบเอกสารเพิ่มเติม',
				content:
					'คุณแน่ใจที่จะแนบเอกสารเพิิ่มเติมสำหรับยื่นแบบหนังสือรับรองตนเองหรือไม่?​',
				handleButton: () => onSubmitAddtionalFiles(sDOCID),
			})
		else
			setSubmitTextModal({
				title: 'ยื่นแบบหนังสือรับรองตนเอง',
				// content: 'คุณแน่ใจที่จะยื่นแบบหนังสือรับรองตนเอง​หรือไม่?',
				content: 'คุณกำลังยื่นแบบหนังสือรับรองตนเอง​ในนามของ \"' + (confirmationInfoStorage[currentSenderState].type == "organization" ? "นิติบุคคล" : "บุคคลธรรมดา") + '\" คุณต้องการดำเนินการต่อหรือไม่?',
				handleButton: onSubmitEquipment,
			})
		setSubmitEquipmentSureModal(!submitEquipmentSureModal)
	}

	const onOpenSubmitEquipmentSuccessModal = () =>
		setOpenSubmitEquipmentSuccessModal(!openSubmitEquipmentSuccessModal)

	const disabledRuleSubmitButton = () => {
		if (mountType === 'attachFile' && additionalFiles.length > attachFileLength)
			setCanSubmit(false)
		else if (isEquipmentCardValid && !mountType) setCanSubmit(false)

		setCanSubmit(true)
	}

	// case: mountType is 'readOnly'
	const getListAndPrefield = async () => {
		const res = await sdoc.getListDetail(sDOCID)
		console.log(res.data)
		if (res && res.status === 200) {
			const findedList = res.data

			if (findedList.senderType === 'organization') {
				const res2 = await operators.getOperatorInfo({ taxno: findedList.operatorTaxNo, branchno: findedList.operatorBranchNo });
				if (res2 && res2.status === 200 && res2?.data.status !== 'error') {
					var data = res2.data
				}
				setCurrentSenderState('organization')
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						organization: {
							type: 'organization',
							name: data.operators.operatorName ?? '',
							cardNo: data.operators.taxNo ?? '',
							prefix: data.operators.prefix ?? '',
							branchNo: data.operatorInfo.branchNo,
							address: data.operatorInfo.addr ?? '',
							email: data.operatorInfo.email ?? '',
							mobile: data.operatorInfo.mobile ?? '',

						},
					}
				})
			} else if (findedList.senderType === 'normal-person') {
				const res3 = await operators.getOperatorUserInfo({ cardNo: findedList.operatorTaxNo })
				if (res3 && res3.status === 200 && res3?.data.status !== 'error') {
					var data2 = res3.data
				}
				setCurrentSenderState('normal_person')
				setConfirmationInfoStorage(prevState => {
					return {
						...prevState,
						normal_person: {
							type: 'normal-person',
							// name: findedList.operatorTitle ?? '',
							name: (data2?.operatorUser?.prefix ?? '') + data2?.operatorUser?.firstname + ' ' + data2?.operatorUser?.lastname,
							cardNo: findedList.operatorTaxNo ?? '',
							branchNo: findedList.operatorBranchNo ?? '00000',
							address: data2?.operatorUser?.address ?? '',
							email: data2?.operatorUser?.email ?? '',
							mobile: data2?.operatorUser?.mobile ?? '',
						},
					}
				})
			}

			if (findedList) {
				const productModel = findedList.productModel
				const productDescription = findedList.productDescription
				const brandName = findedList.brandName
				const sDocItems = findedList.sDocItems
				const sDocAttachs = findedList.sDocAttachs
				const sDocStatusID = findedList.sDocStatusID

				setInputModel({
					brandName: brandName ?? '',
					productModel: productModel ?? '',
					productDescription: productDescription ?? '',
				})

				const newList = sDocItems.map(sdocItem => {
					const regexpSize = /^(\S+)\s(.+)/
					const match = sdocItem.powerText.match(regexpSize)

					const obj = {
						ProductTypeText: sdocItem.productTypeText,
						FrequencyText: sdocItem.frequencyText,
						PolicyText: sdocItem.policyText,
						PowerText: match ? match[1] : '',
						SelectedPowerUnit: match ? match[2] : '',
						isError: false,
						PolicyText_2: sdocItem.policyText_2,
						PolicyText_3: sdocItem.policyText_3,
						Flag: sdocItem.flag,
					}

					return obj
				})

				setInputEquipmentState(newList)

				setAttachFileLength(sDocAttachs.length)
				setAdditionalFiles(() => {
					const newList = sDocAttachs.map(sdocAttach => {
						return {
							fileID: sdocAttach.id,
							file: sdocAttach.fileName,
							name: sdocAttach.title,
							isfilelocked: sdocAttach.isFileLocked ?? false,
							// filedate: sdocAttach.fileDate,
						}
					})
					return newList
				})
				setRunningNo(findedList.runningNo)
				setSDoCNo(findedList.sDocNo?.length == 0 ? 'ยังไม่มีเลขที่ SDoC NO.' : findedList.sDocNo)
			}
		}
	}

	/** <---------------------------------------------------------------------> */

	// Organization Detail Function

	const onChangeConfirmationInfo = e => {
		const { name, value } = e.target
		setConfirmationInfo({ ...confirmationInfo, [name]: value })
	}

	/** <---------------------------------------------------------------------> */

	// Equipment Card

	const onChangeInputModel = e => {
		const { name, value } = e.target
		setInputModel({ ...inputModel, [name]: value })
	}

	const onChangeInputEquipmentState = (e, key) => {
		const { name, value } = e.target

		setInputEquipmentState(prevState => {
			const newState = [...prevState]
			newState[key] = { ...prevState[key], [name]: value }
			return newState
		})
	}

	const onFetchMasterdata = async () => {
		setEquipmentMasterData({
			productType: [],
			typeEquipment: type_equipment,
			frequency: frequency,
			powerUnit: powerUnit,
		})
		// const res = await something
	}
	// eslint-disable-next-line
	const addMoreItem = () => {
		setInputEquipmentState(prevState => [...prevState, initEquipmentState])
	}

	const onDeleteItem = index => {
		setInputEquipmentState(prevState => {
			const newList = prevState.filter((item, prevIndex) => prevIndex !== index)
			return newList
		})
	}

	const onSubmitEquipment = async () => {
		const items = inputEquipmentState.map(item => {
			return {
				PolicyText: item.PolicyText,
				FrequencyText: item.FrequencyText,
				PowerText: item.PowerText + ' ' + item.SelectedPowerUnit,
				policyText_2: item.PolicyText_2,
				PolicyText_3: item.PolicyText_3,
				Flag: item.Flag,
			}
		})

		const res = await sdoc.addList(
			inputModel.brandName,
			inputModel.productModel,
			null,
			items
		)
		if (res && res.status === 200) {
			setSDOCId(res.data.id)
			if (additionalFiles.length !== 0) {
				onSubmitAddtionalFiles(res.data.id)
			} else onOpenSubmitEquipmentSuccessModal()
		}
	}

	/** <---------------------------------------------------------------------> */

	// Document Additional Card

	const onAddTemp = (e, type) => {
		const { files, value } = e.target
		if (type === 'file' && files.length !== 0)
			setTempFile({ ...tempFile, file: files[0] })
		if (type === 'name') setTempFile({ ...tempFile, name: value })
		// if (type === 'filedate') setTempFile({ ...tempFile, filedate: value })
	}

	const onAddDocument = () => {
		if (tempFile.file.type !== 'application/pdf') {
			Swal.fire({
				icon: 'error',
				title: 'ชนิดไฟล์ไม่ถูกต้อง',
				text: 'ชนิดไฟล์ต้องเป็น PDF เท่านั้น',
			})
			return
		}
		// if (tempFile.file && tempFile.name !== '' && tempFile.filedate !== '') {
		if (tempFile.file && tempFile.name !== '') {
			setAdditionalFiles(prevState => [
				...prevState,
				// { file: tempFile.file, name: tempFile.name, filedate: tempFile.filedate },
				{ file: tempFile.file, name: tempFile.name },
			])
			// setTempFile({ file: null, name: '', filedate: '' })
			setTempFile({ file: null, name: '' })
		}
	}

	const onChangeAttachedFileName = (e, index) => {
		const { value } = e.target
		setAdditionalFiles(prevState => {
			const newList = prevState.map((item, itemIndex) => {
				if (itemIndex === index)
					return {
						file: item?.file,
						name: value,
						// filedate: item?.filedate,
					}

				return item
			})
			return newList
		})
	}

	const onDeleteDocument = index => {
		setAdditionalFiles(prevState => {
			const temp_list = prevState.filter((_, listInd) => {
				return listInd !== index
			})
			return temp_list
		})
	}

	const onSubmitAddtionalFiles = async id => {
		const currentAdditionalFileLength = additionalFiles.length
		let sendedFiles = []

		if (mountType && mountType === 'attachFile') {
			const amountOfNewFiles = currentAdditionalFileLength - attachFileLength

			additionalFiles.forEach((file, index) => {
				if (index > currentAdditionalFileLength - amountOfNewFiles - 1)
					sendedFiles.push(file)
			})
		} else sendedFiles = additionalFiles

		const res = await sdoc.attachAdditionalFiles(id, sendedFiles)
		if (res && res.status === 200) {
			onOpenSubmitEquipmentSuccessModal()
		}
	}

	const onDownloadDocument = async () => {
		const res = await sdoc.downloadAdditionalFile(21, '11')
		if (res && res.status === 200) {
		}
	}

	/** <---------------------------------------------------------------------> */

	// Trigger when change the confirmation type
	// useEffect(() => {
	// 	if (confirmationInfo.type === 'normal-person') {
	// 		if (confirmationInfoHasFetched.normal_person) {
	// 			setConfirmationInfo(prevState => {
	// 				return {
	// 					...prevState,
	// 					type: confirmationInfoStorage.normal_person.type,
	// 					name: confirmationInfoStorage.normal_person.name,
	// 					cardNo: confirmationInfoStorage.normal_person.nationalIDCardNo,
	// 					address: confirmationInfoStorage.normal_person.address,
	// 				}
	// 			})
	// 		} else getUserProfile()
	// 	} else if (confirmationInfo.type === 'organization') {
	// 		if (confirmationInfoHasFetched.organization) {
	// 			setConfirmationInfo(prevState => {
	// 				return {
	// 					...prevState,
	// 					type: confirmationInfoStorage.organization.type,
	// 					name: confirmationInfoStorage.organization.name,
	// 					cardNo: confirmationInfoStorage.organization.nationalIDCardNo,
	// 					address: confirmationInfoStorage.organization.address,
	// 				}
	// 			})
	// 		} else getOperatorProfile()
	// 	}
	// 	// eslint-disable-next-line
	// }, [confirmationInfo.type])

	// Equipment Validation
	useEffect(() => {
		const isValid = inputEquipmentState.every(
			item =>
				item.FrequencyText !== '' &&
				// (item.PolicyText !== '' || item.PolicyText_2 !== '' || item.PolicyText_3 !== '') &&
				item.PowerText !== '' &&
				item.SelectedPowerUnit !== ''
		)
		setIsEquipmentCardValid(isValid)
	}, [inputEquipmentState])

	// Submit Button Validation
	useEffect(
		() => disabledRuleSubmitButton(),
		// eslint-disable-next-line
		[inputEquipmentState, additionalFiles]
	)

	// Initial Fetch
	useEffect(() => {
		onFetchMasterdata()

		// eslint-disable-next-line
	}, [])

	// Initial Fetch from /manage
	useEffect(() => {
		if (sDOCID && mountType) {
			setSDOCId(sDOCID)
			getListAndPrefield()
		}
		// eslint-disable-next-line
	}, [sDOCID, mountType])

	// On Unmounting
	useEffect(() => {
		return () => (location.state = null)
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<Grid className="p-10">
				<SDoCDetailCard
					runningNo={runningNo}
					sDoCNo={sDoCNo}
				/>
				<OrganizationDetailCardV3
					mountType={mountType}
					confirmationInfo={confirmationInfoStorage}
					onChangeConfirmationInfo={onChangeConfirmationInfo}
					senderState={currentSenderState}
				/>
				<BrandNameCard
					mountType={mountType}
					inputModel={inputModel}
					onChangeInputModel={onChangeInputModel}
				/>

				{inputEquipmentState.map((item, index) => {
					return (
						<EquipmentCard
							mountType={mountType}
							inputEquipmentState={item}
							seq={index + 1}
							onChangeInputEquipmentState={onChangeInputEquipmentState}
							equipmentMasterData={equipmentMasterData}
							onDelete={() => onDeleteItem(index)}
							index={index}
							key={'EquipmentCard' + index}
						/>
					)
				})}

				<DocumentAdditionCard
					sDOCID={sDOCID}
					mountType={mountType}
					additionalFiles={additionalFiles}
					tempFile={tempFile}
					onAddTemp={onAddTemp}
					onAddDocument={onAddDocument}
					onDeleteDocument={onDeleteDocument}
					onChangeAttachedFileName={onChangeAttachedFileName}
					onDownloadDocument={onDownloadDocument}
				/>
			</Grid>
			<SureModal
				open={submitEquipmentSureModal}
				title={submitTextModal.title}
				content={submitTextModal.content}
				handleButton={submitTextModal.handleButton}
				handleCancel={onOpenSubmitEquipmentSureModal}
				onClose={onOpenSubmitEquipmentSureModal}
			/>
			<SuccessModal
				open={openSubmitEquipmentSuccessModal}
				title="ยื่นแบบหนังสือรับรองตนเองสำเร็จ"
				handleButton={() => history.go(0)}
			/>
		</>
	)
}
