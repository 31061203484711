import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState, useStoreRehydrated } from '../../../store'

export default function ProtectedRoute({ children, ...rest }) {
	const userSDOC = useStoreState(s => s.sdocUserModel.userSDOC)
	const isRehydrated = useStoreRehydrated()

	return (
		isRehydrated && (
			<Route
				{...rest}
				render={({ location }) =>
					userSDOC ? (
						children
					) : (
						<Redirect
							to={{ pathname: '/sdoc/sign-in', state: { from: location } }}
						/>
					)
				}
			/>
		)
	)
}
