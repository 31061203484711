import API, { adminHeader } from '../config/axios'

const sDOCAdmin = {
	// User API
	getMeAdmin: () => {
		return API.get('/SDocAdmins/me')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getAdminList: () => {
		return API.get('/SDocAdmins')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getRoleList: () => {
		return API.get('/SDocAdmins/getRole')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	addAdmin: (adminRoleID, email, firstname, lastname, adminStatusID) => {
		return API.post(
			'/SDocAdmins',
			{
				adminRoleID,
				email,
				firstname,
				lastname,
				adminStatusID,
			},
			{
				headers: adminHeader,
			}
		)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	editAdmin: (
		adminID,
		adminRoleID,
		email,
		firstname,
		lastname,
		adminStatusID
	) => {
		return API.put(
			`/SDocAdmins/${adminID}`,
			{
				adminRoleID,
				email,
				firstname,
				lastname,
				adminStatusID,
			},
			{
				headers: adminHeader,
			}
		)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	deleteAdmin: adminID => {
		return API.delete(`/SDocAdmins/${adminID}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},

	// SDOC API
	assignAdmin: (sDocID, adminID) => {
		return API.put(`/SDoc/${sDocID}/assignAdmin/${adminID}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	requestAddition: (sDocID, remark = '') => {
		return API.put(`/SDoc/${sDocID}/requestAddition`, { remark })
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	requestModifyInfo: (sDocID, remark = '') => {
		return API.put(`/SDoc/${sDocID}/requestModifyInfo`, { remark })
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	rejectJob: sDocID => {
		return API.put(`/SDoc/${sDocID}/rejectJob`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	approveSDoc: (sDocID, selectedSignature) => {
		return API.put(`/SDoc/${sDocID}/approve`, {
			signatureID: selectedSignature,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
			})
	},
	denySDoc: (sDocID, remark = '') => {
		return API.put(`/SDoc/${sDocID}/deny`, { remark })
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	sendToIssue: sDocID => {
		return API.put(`/SDoc/${sDocID}/sendToIssue`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	denyCheckerSDoc: (sDocID, remark = '') => {
		return API.put(`/SDoc/${sDocID}/denyChecker`, { remark })
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	changeFileLockStatus: (fileID) => {
		return API.put(`/SDoc/changeFileLockStatus/${fileID}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	updateCustomerInfo: (sDocID) => {
		return API.post(`/SDoc/UpdateCustomerInfo/${sDocID}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	billResent: (sDocID) => {
		return API.put(`/SDoc/${sDocID}/resentbill`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	backToPreapprove: (sDocID) => {
		return API.post(`/SDoc/${sDocID}/backToPreapprove`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	repairCredential: (sDocID) => {
		return API.put(`/SDoc/${sDocID}/repairCredential`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getTableauTicket: () => {
		return API.get(`/SDoc/TableauAuth`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},

	// adminGetInvoce: sDocID => {
	// 	return API.get(`/SDoc/${sDocID}/AdminGetInvoice`)
	// 		.then(res => res)
	// 		.catch(err => {
	// 			console.error(err)
	// 			return err
	// 		})
	// }
	getPOAList: () => {
		return API.get('/SDocAdmins/getPOAList')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	}
}

export default sDOCAdmin
