import { Grid, IconButton } from '@material-ui/core'
import { Archive } from '@material-ui/icons'
import { AuthenticatedLink } from 'components/AuthenticatedLink'

export default function File({ validateFileUrl , originalFileName }) {
	return (
		<Grid className="flex flex-row gap-x-3 items-center">
			<Grid className="text-xl">ดาวน์โหลดเอกสาร</Grid>
			<Grid>
				<AuthenticatedLink
					url={`${process.env.REACT_APP_PUBLIC_API_URL}${validateFileUrl}`}
					filename={originalFileName}>
					<IconButton color="primary">
						<Archive />
					</IconButton>
				</AuthenticatedLink>
			</Grid>
		</Grid>
	)
}
