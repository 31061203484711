import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions, useStoreState } from 'store'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	CircularProgress,
} from '@material-ui/core'
const useStyles = makeStyles({
	table: {
		width: '100%',
	},
})

export default function TablePOA(props) {
	const classes = useStyles()
	const { data } = useStoreState(state => state.poa)
	const selectPOA = useStoreActions(action => action.poa.setSelected)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		selectPOA(null)
		// eslint-disable-next-line
	}, [])

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>เลขประจำตัวผู้เสียภาษี</TableCell>
						<TableCell>วันเริ่มต้น</TableCell>
						<TableCell>วันสิ้นสุด</TableCell>
						<TableCell>ชื่อผู้ประกอบการ</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(row => (
						<TableRow key={row.juristicNo}>
							<TableCell component="td" scope="row">
								{row.juristicNo}
							</TableCell>
							<TableCell component="td" scope="row">
								{row.startDate}
							</TableCell>
							<TableCell component="td" scope="row">
								{row.expireDate}
							</TableCell>
							<TableCell component="td" scope="row">
								{row.operator ? row.operator.operatorName : 'ไม่พบในระบบ'}
							</TableCell>
							<TableCell align="right">
								<Button
									className="w-5/12"
									onClick={e => {
										selectPOA(row)
										setLoading(true)
									}}
									disabled={loading}
									color="primary"
									variant="contained">
									{loading && <CircularProgress size={25} />}
									{!loading && 'เลือก'}
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
