export const fixed_sound_details = [
	{
		id: 11,
		no: '1.2.1',
		label:
			'อัตราส่วนการเรียกสำเร็จ (Successful call ratio) กรณีที่เป็นการโทรศัพท์ภายในโครงข่ายของผู้ประกอบการเดียวกัน',
		targeted_text:
			'ไม่ต่ำกว่าร้อยละ 90 วัดเฉลี่ยตลอด 24 ชั่วโมง (ทุกช่วงเวลา) เฉลี่ยทุก 3 เดือน รายงานส่งสำนักงาน กสทช. ทุกไตรมาส',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 90,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 7,
		priority: 11,
	},
	{
		id: 12,
		no: '1.2.2',
		label:
			'อัตราส่วนการเรียกสำเร็จ (Successful call ratio) กรณีที่เป็นการโทรข้ามโครงข่ายต่างผู้ประกอบการ',
		targeted_text:
			'ไม่ต่ำกว่าร้อยละ 85 วัดเฉลี่ยตลอด 24 ชั่วโมง (ทุกช่วงเวลา) เฉลี่ยทุก 3 เดือน รายงานส่งสำนักงาน กสทช. ทุกไตรมาส',
		subLabel_1: null,
		subLabel_2: null,
		subLabel_3: null,
		subLabel_4: null,
		targetValue: 85,
		targetCondition: 'ge',
		filling_condition:
			'ข้อมูลที่กรอกจะต้องมีหน่วยเป็นร้อยละ (มีค่าได้ตั้งแต่ 0.00 - 100.00)',
		isNeedAnswer: true,
		records: {
			month_1: null,
			month_2: null,
			month_3: null,
			avg: null,
			note: null,
		},
		data_type: 'float',
		excelValueCol: ['D', 'E', 'F', 'G'],
		excelValueRow: 12,
		priority: 12,
	},
]
