import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState, useStoreRehydrated } from '../../store'

export default function ProtectedRoute({ children, ...rest }) {
	const QoSAdmin = useStoreState(s => s.QoSAdminModel.admin)
	const isRehydrated = useStoreRehydrated()

	return (
		isRehydrated && (
			<Route
				{...rest}
				render={({ location }) =>
					QoSAdmin ? (
						children
					) : (
						<Redirect
							to={{
								pathname: '/admin/sign-in',
								state: { from: location },
							}}
						/>
					)
				}
			/>
		)
	)
}
