import { useEffect } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ProtectedRoute from './protected-route'
import { useStoreActions } from '@store'

// Components
import Organization from './organization'
import StandardReport from './standard-report'
import StandardReportHistory from './standard-report-history'
import StandReportValidation from './standard-report-validation'
import StandardReportValidationDetails from './standard-report-validation/[id]'
import OrganizationUser from './organization-user'
import SignIn from './auth/signin'
import Identify from './auth/identify'
import ClientAPI from './client'
import Registration from './registration'
import StandardReportHistoryDetails from './standard-report-history/[id]'

export default function UsersRoute() {
	const { path } = useRouteMatch()
	const setAppType = useStoreActions(a => a.appModel.setAppType)

	useEffect(() => setAppType('users'))

	return (
		<Switch>
			<ProtectedRoute exact path={path}>
				<Organization />
			</ProtectedRoute>

			<ProtectedRoute path={`/organization-user`}>
				<OrganizationUser />
			</ProtectedRoute>

			<ProtectedRoute path={`/standard-report`}>
				<StandardReport />
			</ProtectedRoute>

			<ProtectedRoute path={`/standard-report-history/:id`}>
				<StandardReportHistoryDetails />
			</ProtectedRoute>

			<ProtectedRoute exact path={`/standard-report-history`}>
				<StandardReportHistory />
			</ProtectedRoute>

			<ProtectedRoute path={`/standard-report-validation/:id`}>
				<StandardReportValidationDetails />
			</ProtectedRoute>

			<ProtectedRoute exact path={`/standard-report-validation`}>
				<StandReportValidation />
			</ProtectedRoute>

			<Route path={`/client-api`} component={ClientAPI} />

			<Route path={`/sign-in`} component={SignIn} />
			<Route path={`/auth/identify`} component={Identify} />

			<Route path={`/registration`} component={Registration} />

			<Redirect from="*" to="/" />
		</Switch>
	)
}
