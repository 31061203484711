import React from 'react'
import {
	Button,
	Grid,
	Card,
	Divider,
} from '@material-ui/core'

import logo from 'assets/images/NBTC-logo.png'
import { makeStyles } from '@material-ui/core/styles'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import { API_BASE_END_POINT } from 'config'

const useStyles = makeStyles(theme => {
	return {
		AppDescription: {
			fontSize: '24px',
		},
		AppDescriptionSpan: {
			color: theme.palette.primary.main,
		},
	}
})
const FormSelectService = ({ onSelectService }) => {
	const classes = useStyles()
	const showUpdateTokenModal = () => {
		// fire Swal with input box to put new token and submit button POST method
		Swal.fire({
			title: 'อัพเดทข้อมูลการมอบอำนาจ',
			input: 'textarea',
			inputLabel: 'URL',
			inputPlaceholder: 'ใส่ URL ที่ได้รับจากการเข้าสู่ระบบ',
			showCancelButton: true,
			confirmButtonText: 'อัพเดท',
			showLoaderOnConfirm: true,
			// FromForm
			preConfirm: (newToken) => {
				const formData = new FormData();
				formData.append('newToken', newToken);

				return fetch(`${API_BASE_END_POINT}/api/OperatorUsers/UpdateSPLoginToken`, {
					method: 'POST',
					body: formData
				})
					.then(response => {
						if (!response.ok) {
							return response.json().then(data => {
								throw new Error(data.message);
							});
						}
						return response.json()
					})
					.catch(error => {
						Swal.showValidationMessage(`Request failed: ${error}`)
					})
			},
			inputAttributes: {
				autocomplete: 'off' // Disable autocomplete
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then(result => {
			if (result.isConfirmed) {
				Swal.fire('อัพเดทข้อมูลสำเร็จ')
			}
		})

	}

	return (
		<>
			<Card className="flex flex-col items-center justify-center px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5">
				<Grid className="">
					<img src={logo} alt="NBTC" width="140" />
				</Grid>

				<Grid
					className={`mt-4 text-sm md:text-base text-center ${classes.AppDescription}`} >
					<span className={classes.AppDescriptionSpan}>NBTC</span> DATA PORTAL <br /><br /> สำหรับ
					{/* ระบบกลางการเชื่อมต่อข้อมูลกับหน่วยงานภายนอกแบบอัตโนมัติ<br />(<span className={classes.AppDescriptionSpan}>NBTC</span> DATA PORTAL) */}
				</Grid>

				<Grid className={`mt-4 ${classes.AppDescription}`} style={{ fontSize: '16px' }}>การยื่นแบบรับรองตนเองของผู้ประกอบการ (SDoC)</Grid>


				<Grid className="mt-6 w-full">

					<Divider />


					<Grid className="mt-9 flex flex-row gap-x-3 w-full justify-center mx-auto py-5">
						<Button

							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="large"
							id="signin-button"
							onClick={e => onSelectService('sdoc')}
						>
							เลือกผู้ประกอบการ
						</Button>
						{/* <Button
						
							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="large"
							id="signin-button2"
							onClick={e=>onSelectService('sdoc')}
							>
							SDOC
						</Button> */}
					</Grid>
				</Grid>
				<Grid
					className={`mt-16 text-sm  text-center`}>
					ระบบกลางการเชื่อมต่อข้อมูลกับหน่วยงานภายนอกแบบอัตโนมัติ<br />(<span className={classes.AppDescriptionSpan}>NBTC</span> DATA PORTAL)
				</Grid>
				<Grid
					className={`mt-16 text-sm  text-center`}>

					{window.location.search.includes('_token=U1BMb2dpblRva2VuRm9yVG9vTG9uZ1JlcXVlc3Q=') ?
						<Button

							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="large"
							id="signin-button"
							onClick={e => showUpdateTokenModal()}
						>
							อัพเดทข้อมูลการมอบอำนาจ
						</Button>
						: null}
				</Grid>
			</Card>
		</>
	)
}

export default FormSelectService
