import API from '../config/axios'

const formAPI = {
	getRecords: () => {
		return API.get('/DataSetRecords')
			.then(res => res)
			.catch(err => err)
	},
	addRecord: (records, isNewForm, formTypes) => {
		return API.post('/DataSetRecords', {
			formTypes,
			isNewForm,
			answers: records,
		})
			.then(res => res)
	},
	updateRecord: records => {
		return API.post('/DataSetRecords', records)
			.then(res => res)
			.catch(err => err)
	},
	excelUploading: (files, isNewForm) => {
		const form = new FormData()
		form.append('isNewForm', isNewForm)
		form.append('fileUpload', files)

		return API({
			url: '/DataSetRecords/ImportExcel',
			method: 'POST',
			data: form,
		})
			.then(res => res)
			.catch(err => {
				return err
			})
	},
	APIExcelUploading: (file, token, isAPI = false) => {
		const form = new FormData()
		form.append('fileUpload', file)

		return API({
			url: `/DataSetRecords/ImportExcel${isAPI ? '?method=api' : ''}`,
			method: 'POST',
			data: form,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res)
			.catch(err => {
				return false
			})
	},
	APIExternalExcelUploading: (file, token, isAPI = false) => {
		const form = new FormData()
		form.append('fileUpload', file)

		return API({
			url: `https://168.1.38.48/chativitpnbtcgoth-sydneyspacedev/main-prod/api/organizations`,
			method: 'POST',
			data: form,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res)
			.catch(err => {
				return false
			})
	},

	
	downloadDocument: () => {
		return API.get('/Document/doc1')
			.then(res => res)
			.catch(err => err)
	},
}
export default formAPI
