import React, { useState, useEffect } from 'react'

import { Grid, Card, Button, useMediaQuery, TextField } from '@material-ui/core'
import { ListAlt, Menu, SearchOutlined } from '@material-ui/icons'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useStoreRehydrated } from '../../../store'
import { format } from 'date-fns'

import { datasetRecord } from '../../../api/'

const useStyles = makeStyles(theme => {
	return {
		card_styles: {
			height: '80vh',
		},
		type_of_report: {
			backgroundColor: '#c8e8ff',
			color: '#028BFF',
			borderColor: '#84BFF0',
		},
	}
})

export default function StandardReportSent() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isMobile2 = useMediaQuery('(min-width:425px)')
	const dataTypeOfReport = { mobile: 'เคลื่อนที่', fixed: 'ประจำที่' }

	// Work Status 0 = รอตรวจสอบ, 1 = ตรวจสอบแล้ว, 2 = ไม่อนุมัติ
	// eslint-disable-next-line
	const [statusIDList, setStatusIDList] = useState([])
	const [pageSize, setPageSize] = useState(8)
	const [columnsId, setColumnsId] = useState([130, 0])
	const [columnsName, setColumnsName] = useState([360, 0])
	const [columnsDataTypeList, setColumnsDataTypeList] = useState([200, 0])
	const [columnsSubmitDate, setColumnsSubmitDate] = useState([200, 0])
	const [columnsReportDetails, setColumnsReportDetails] = useState([200, 0])

	var columns = [
		{
			field: 'id',
			headerName: 'ลำดับ',
			width: columnsId[0],
			flex: columnsId[1],
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				return <Grid className="pl-4">{params.value}</Grid>
			},
		},
		{
			field: 'name',
			headerName: 'ชื่อ-นามสกุล',
			width: columnsName[0],
			flex: columnsName[1],
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'organization',
			headerName: 'ชื่อผู้ประกอบการ',
			width: columnsName[0],
			flex: columnsName[1],
			headerAlign: 'left',
			align: 'left',
			editable: false,
		},
		{
			field: 'dataTypeList',
			headerName: 'ประเภทรายงาน',
			sortable: true,
			width: columnsDataTypeList[0],
			flex: columnsDataTypeList[1],
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-center items-center">
						{params.value.map((e, index) => (
							<Grid
								key={e + index}
								className={`rounded-full py-1 px-4 m-0.5 font-bold text-xs border ${classes.type_of_report}`}>
								{dataTypeOfReport[e]}
							</Grid>
						))}
					</Grid>
				)
			},
		},
		{
			field: 'yearNQuarter',
			headerName: 'ปีและไตรมาส',
			width: columnsSubmitDate[0],
			flex: columnsSubmitDate[1],
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			sortable: true,
			renderCell: params => {
				return (
					<Grid className="">
						{params.value ? params.value : ''}
					</Grid>
				)
			}
		},
		{
			field: 'sendToAdminAt',
			headerName: 'วันที่ส่งข้อมูล',
			width: columnsSubmitDate[0],
			flex: columnsSubmitDate[1],
			// headerAlign: 'center',
			align: 'left',
			editable: false,
			sortable: true,
			renderCell: params => (
				<Grid className="">
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy') : ''}
				</Grid>
			),
		},
		{
			field: 'submitType',
			headerName: 'วิธีการส่งรายงาน',
			width: columnsSubmitDate[0],
			flex: columnsSubmitDate[1],
			align: 'left',
			editable: false,
		},
		{
			field: 'report_detials',
			headerName: 'ดูข้อมูลรายงาน',
			sortable: false,
			width: columnsReportDetails[0],
			flex: columnsReportDetails[1],
			headerAlign: 'center',
			align: 'left',
			editable: false,
			renderCell: params => {
				return (
					<Grid className="mx-auto">
						<Button
							onClick={() => {
								let tempIndex = 0
								for (tempIndex; tempIndex < rows.length; tempIndex++) {
									if (params.value === rows[tempIndex].id) {
										break
									}
								}
								history.push({
									pathname: `/admin/sent/${params.value}`,
									state: {
										rowData: rows[tempIndex],
										statusIDList: statusIDList,
									},
								})
							}}
							className={`w-28 `}
							color="secondary"
							variant="contained">
							<Menu style={{ fontSize: 20 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>
					</Grid>
				)
			},
		},
	]

	const [rows, setRows] = useState([
		// Work Status 0 = รอตรวจสอบ, 1 = ตรวจสอบแล้ว, 2 = ไม่อนุมัติ
		// Data Type List 0 = ประจำที่, 1 = เคลื่อนที่
	])
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [rowsData, setRowsData] = useState([])

	//  -------------------------------
	const getDatasetRecordList = async () => {
		setIsDataLoading(true)
		const res = await datasetRecord.getListAdmin()

		if (res) {
			if (res.status === 200) {
				setRowsData(res.data)

				const rowsTemp = []

				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].status === 5) {
						const qosType = []

						if (res.data[i].isFixed === 'y') qosType.push('fixed')
						if (res.data[i].isMobile === 'y') qosType.push('mobile')

						const tempObject = {
							id: res.data[i].id,
							name:
								(res.data[i].operatorApproveUser?.firstname ?? '') +
								' ' +
								(res.data[i].operatorApproveUser?.lastname ?? ''),
							organization: res.data[i].dataSetReport.operator.operatorName,
							submit_date: res.data[i].sendToAdminAt
								? format(new Date(res.data[i].sendToAdminAt), 'dd/MM/yyyy')
								: '',
							sendToAdminAt: res.data[i].sendToAdminAt,
							submitType: res.data[i].sendMethod,
							report_detials: res.data[i].id,
							dataTypeList: qosType,
							actions: res.data[i].id,
							yearNQuarter: res.data[i].dataSetReport.year + '/' + res.data[i].dataSetReport.quarter,
						}

						let tempStatusIDList = statusIDList
						tempStatusIDList[i] = {
							report_id: res.data[i].id,
							workStatus: res.data[i].dataSetRecordHeadStatus.id,
							workStatusName: res.data[i].dataSetRecordHeadStatus.statusName,
						}
						setStatusIDList(tempStatusIDList)
						rowsTemp.push(tempObject)
					}
				}
				setRows(rowsTemp)
			}
		}
		setIsDataLoading(false)
	}

	const onSearhResult = e => {
		const { value } = e.target
		const searchedName = rowsData.filter(row => {
			const name =
				row.operatorApproveUser?.firstname +
				' ' +
				row.operatorApproveUser?.lastname
			return name.includes(value)
		})
		const searchedOperatorName = rowsData.filter(row =>
			row.dataSetReport?.operator?.operatorName.includes(value)
		)

		const mergedSearched = [...searchedName, ...searchedOperatorName]
		const searchedResults = []

		mergedSearched.forEach(i => {
			const find = searchedResults.find(item => item.id === i.id)
			if (!find) {
				const qosType = []

				if (i.operatorUser.operatorUserQOSTypes)
					i.operatorUser.operatorUserQOSTypes.forEach(item => {
						if (item.qosTypeID === 1) qosType.push('mobile')
						if (item.qosTypeID === 2) qosType.push('fixed')
					})
				if (i.status === 5)
					searchedResults.push({
						id: i.id,
						name:
							i.operatorApproveUser?.firstname +
							' ' +
							i.operatorApproveUser?.lastname,
						organization: i.dataSetReport?.operator?.operatorName,
						submit_date: i.updatedAt
							? format(new Date(i.updatedAt), 'dd/MM/yyyy')
							: '',
						sendToAdminAt: i.sendToAdminAt,
						submitType: i.sendMethod,
						workStatus: {
							report_id: i.id,
							workStatus: i.dataSetRecordHeadStatus.id,
							workStatusName: i.dataSetRecordHeadStatus.statusName,
						},
						report_detials: i.id,
						dataTypeList: qosType,
						actions: i.id,
						yearNQuarter: i.dataSetReport.year + '/' + i.dataSetReport.quarter,
					})
			}
		})

		setRows(searchedResults)
	}

	useEffect(() => {
		if (isMobile) {
			setColumnsId([130, 0])
			setColumnsName([100, 0])
			setColumnsDataTypeList([200, 0])
			setColumnsSubmitDate([200, 0])
			setColumnsReportDetails([200, 0])
		} else {
			setColumnsId([0, 0.7])
			setColumnsName([0, 1.2])
			setColumnsDataTypeList([0, 1.2])
			setColumnsSubmitDate([0, 1])
			setColumnsReportDetails([0, 0.9])
		}
	}, [isMobile])

	useEffect(() => {
		if (isRehydrated) getDatasetRecordList()
		// eslint-disable-next-line
	}, [isRehydrated])

	return (
		<>
			<Grid className="lg:p-10">
				<Card
					className={`${classes.card_styles} lg:border-t-4 border-card_top_border`}>
					<Grid className="flex flex-col justify-center items-start overflow-auto">
						<Grid className="flex flex-row items-center justify-between mt-4 mb-3 px-5 w-full">
							<Grid className="flex flex-row items-center">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									รายชื่อรายงานที่ได้รับการอนุมัติแล้ว
								</Grid>
							</Grid>
							<TextField
								className="w-full lg:w-4/12"
								size="small"
								placeholder="ค้นหาโดย ชื่อ-นามสกุล, ชื่อผู้ประกอบการ"
								onChange={onSearhResult}
								label=""
								variant="outlined"
								InputProps={{
									startAdornment: (
										<SearchOutlined style={{ color: 'rgba(0,0,0,0.54)' }} />
									),
								}}
							/>
						</Grid>
						<Grid
							className="md:px-5"
							style={{ height: isMobile2 ? "65vh" : 350, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								disableSelectionOnClick
								rowsPerPageOptions={[5, 8, 10, 20]}
								pageSize={pageSize}
								onPageSizeChange={size => setPageSize(size)}
								components={{
									NoRowsOverlay: () => {
										if (!isDataLoading) {
											return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
										} else {
											return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</>
	)
}
