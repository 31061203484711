// Main Libraries
import React, { useEffect, useCallback } from 'react'
import { Grid } from '@material-ui/core'

// Components
import InfoBox from 'components/admin/dashboard/InfoBox'
import PieChart from 'components/admin/dashboard/piechart'

// Hooks and Functions
import { useStoreState, useStoreActions } from 'store'

// Configuration
import { InfoBoxConfigs } from './config'

const Dashboard = () => {
	const { adminStat, adminOperator } = useStoreState(s => s.mainAdminModel)
	const getDashboardStat = useStoreActions(
		a => a.mainAdminModel.getAdminDashboardStat
	)

	const initFetch = useCallback(() => {
		getDashboardStat()
	}, [getDashboardStat])

	useEffect(() => {
		initFetch()
	}, [initFetch])

	const Categories1 = ['ส่งข้อมูลแล้ว', 'ระหว่างดำเนินการ'];
	const Series1 = [
		{
			name: 'จำนวนรายงาน',
			colorByPoint: true,
			data: [
				{
					name: 'ส่งข้อมูลแล้ว',
					y: adminStat?.stat2 || 10,
				},
				{
					name: 'ระหว่างดำเนินการ',
					y: adminStat?.stat3 || 20,
				},
			],
		},
	]

	const Categories2 = ['ประจำที่', 'เคลื่อนที่', 'ประจำที่และเคลื่อนที่'];
	const Series2 = [
		{
			name: 'จำนวน',
			colorByPoint: true,
			data: [
				{
					name: 'ประจำที่',
					y: adminOperator?.ope1 || 10,
				},
				{
					name: 'เคลื่อนที่',
					y: adminOperator?.ope2 || 20,
				},
				{
					name: 'ประจำที่และเคลื่อนที่',
					y: adminOperator?.ope3 || 30,
				},
			],
		},
	]

	return (
		<Grid className="h-screen flex flex-col items-center py-20">
			<Grid className="flex flex-row w-full items-center justify-evenly gap-x-5 px-5">
				{InfoBoxConfigs.map((config, idx) => (
					<InfoBox
						key={config.label + '-' + idx}
						number={adminStat[config.name]}
						{...config}
					/>
				))}
			</Grid>
			<Grid className="w-full h-full mt-10">
				<PieChart
					Title={"อัตราส่วนผู้ประกอบการตามประเภทรายงาน"}
					Categories={Categories2}
					Series={Series2}
				/>
			</Grid>
			<Grid className="w-full h-full mt-10">
				<PieChart
					Title={"อัตราส่วนผู้ส่งรายงานแล้วและระหว่างดำเนินการในปีไตรมาสปัจจุบัน"}
					Categories={Categories1}
					Series={Series1}
				/>
			</Grid>
		</Grid>
	)
}

export default Dashboard
