import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState, useStoreRehydrated } from '../../store'

export default function ProtectedRoute({ children, ...rest }) {
	const admin = useStoreState(s => s.mainAdminModel.admin)
	const isRehydrated = useStoreRehydrated()

	return (
		isRehydrated && (
			<Route
				{...rest}
				render={
					({ location }) =>
						admin ? (
						children
					) : (
						<Redirect
							to={{
								pathname: '/main-admin/sign-in',
								state: { from: location },
							}}
						/>
					)
				}
			/>
		)
	)
}
