const getBase64 = file => {
	return new Promise(resolve => {
		let baseURL = ''
		// Make new FileReader
		let reader = new FileReader()
		console.log('typeof file',typeof file)
		if(typeof file === 'string'){
			resolve(null)
		}
		// Convert the file to base64 text
		reader.readAsDataURL(file)

		// on reader load somthing...
		reader.onload = e => {
			// Make a fileInfo Object
			baseURL = e.target.result
			resolve(baseURL)
		}
	})
}

export default getBase64
