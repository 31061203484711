import { Grid, Card, Divider, useMediaQuery, Button } from '@material-ui/core'
import { Assignment, InsertDriveFile } from '@material-ui/icons'
import { useParams } from 'react-router-dom'
import File from './file'
import { datasetRecord } from '@api'
import { mobile_additional_files } from 'data/standard-report/ui/mobile-additional-files'

export default function StandardReportStep1({
	rowData,
	dataSetFilesUpload = {},
}) {
	let { id } = useParams()
	const isNotTablet = useMediaQuery('(min-width:768px)')

	const handleDownloadExcel = async () => {
		const res = await datasetRecord.downloadExcel(id)
		if (res && res.status === 200 && res.data) {
			let blob = new Blob([res.data], { type: '' })
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.setAttribute('download', 'exportData.xlsx')
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} else {
			alert('ไม่สามาถดาวน์โหลดไฟล์ได้')
		}
	}

	return (
		<>
			{!isNotTablet ? (
				// Mobile and Tablet Layout
				<Grid className="flex flex-col justify-center items-start px-5">
					<Grid className="flex flex-row mt-4">
						<Assignment style={{ fontSize: 22 }} className="mr-1" />
						<Grid className="font-bold text-sm text-card_header_color">
							รายละเอียดการส่งรายงาน
						</Grid>
					</Grid>
					<Grid className="mt-3 mb-1 w-full">
						<Divider />
					</Grid>
					{/* Name */}
					<Grid className={`flex flex-col items-start my-2 w-full`}>
						<Grid
							className={`flex flex-row items-center justify-center rounded-t-md w-24 h-8 px-2 bg-card_top_border`}>
							<Grid className="text-white text-xs">ชื่อผู้ส่งรายงาน</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded-b rounded-tr h-10 w-full border border-grey-600 bg-text_field_bg`}>
							{rowData.name}
						</Grid>
					</Grid>
					{/* Type of Report */}
					<Grid className={`flex flex-col items-start my-2 w-full`}>
						<Grid
							className={`flex flex-row items-center justify-center rounded-t-md w-24 h-8 px-2 bg-card_top_border`}>
							<Grid className="text-white text-xs">ประเภทรายงาน</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded-b rounded-tr h-10 w-full border border-grey-600 bg-text_field_bg`}>
							{rowData?.dataTypeList?.map((e, index) => (
								<Grid key={e + index} className="text-sm mr-1">
									{index === 1 && rowData.dataTypeList.length === 2 ? ', ' : ''}
									{e}
								</Grid>
							))}
						</Grid>
					</Grid>
					{/* Submit Date */}
					<Grid className={`flex flex-col items-start my-2 w-full`}>
						<Grid
							className={`flex flex-row items-center justify-center rounded-t-md w-24 h-8 px-2 bg-card_top_border`}>
							<Grid className="text-white text-xs">วันที่ส่งข้อมูล</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded-b rounded-tr h-10 w-full border border-grey-600 bg-text_field_bg`}>
							{rowData.submit_date}
						</Grid>
					</Grid>
					{/* Status */}
					<Grid className={`flex flex-col items-start my-2 w-full`}>
						<Grid
							className={`flex flex-row items-center justify-center rounded-t-md w-24 h-8 px-2 bg-card_top_border`}>
							<Grid className="text-white text-xs">สถานะรายงาน</Grid>
						</Grid>
						<Grid
							className={`px-2 text-xs flex justify-start items-center rounded-b rounded-tr h-10 w-full border border-grey-600 bg-text_field_bg`}>
							{rowData.status}
						</Grid>
					</Grid>
					{/* Reject reason */}
					{rowData.isRejected === 'y' && (
						<Grid className={`flex flex-col items-start my-2 w-full`}>
							<Grid
								className={`flex flex-row items-center justify-center rounded-t-md w-24 h-8 px-2 bg-card_top_border`}>
								<Grid className="text-white text-xs">เหตุผล</Grid>
							</Grid>
							<Grid
								className={`px-2 text-xs flex justify-start items-center rounded-b rounded-tr h-10 w-full border border-grey-600 bg-text_field_bg`}>
								{rowData.rejectReason}
							</Grid>
						</Grid>
					)}
				</Grid>
			) : (
				<>
					{/* Computer Layout */}
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start px-5">
							<Grid className="flex flex-row mt-4">
								<Assignment style={{ fontSize: 22 }} className="mr-1" />
								<Grid className="font-bold text-sm text-card_header_color">
									รายละเอียดการส่งรายงาน
								</Grid>
							</Grid>
							<Grid className="mt-3 mb-1 w-full">
								<Divider />
							</Grid>
							{/* Name */}
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-10 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Grid className="text-white text-sm">ชื่อผู้ส่งรายงาน</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">{rowData.name}</Grid>
							</Grid>
							{/* Company Name */}
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-10 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Grid className="text-white text-sm">ชื่อบริษัท</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">{rowData.companyName}</Grid>
							</Grid>
							{/* Type of Report */}
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-10 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Grid className="text-white text-sm">ประเภทรายงาน</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex flex-row">
									{rowData?.dataTypeList?.map((e, index) => (
										<Grid key={e + index} className="text-sm mr-1">
											{index === 1 && rowData.dataTypeList.length === 2
												? ', '
												: ''}
											{e}
										</Grid>
									))}
								</Grid>
							</Grid>
							{/* Submit Date */}
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-10 my-2 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Grid className="text-white text-sm">วันที่ส่งข้อมูล</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">{rowData.submit_date}</Grid>
							</Grid>
							{/* Status */}
							<Grid
								className={`flex flex-row items-center rounded border border-grey-600 h-10 mt-2 mb-4 w-full bg-text_field_bg`}>
								<Grid
									className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
									<Grid className="text-white text-sm">สถานะรายงาน</Grid>
								</Grid>
								<Grid className="pl-4 text-sm flex">{rowData.status}</Grid>
							</Grid>

							{/* Status */}
							{rowData.isRejected === 'y' && (
								<Grid
									className={`flex flex-row items-center rounded border border-grey-600 h-10 mt-2 mb-4 w-full bg-text_field_bg`}>
									<Grid
										className={`flex flex-row items-center justify-center rounded w-52 h-full px-4 bg-card_top_border`}>
										<Grid className="text-white text-sm">เหตุผล</Grid>
									</Grid>
									<Grid className="pl-4 text-sm flex">
										{rowData.rejectReason}
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid className={'my-5 text-center '}>
							<Button
								onClick={() => {
									handleDownloadExcel()
								}}
								className={`h-8 `}
								color="success"
								style={{ backgroundColor: '#1DBF73', color: '#E9E9E9' }}
								size="small"
								variant="contained">
								<InsertDriveFile style={{ fontSize: 18 }} className="mr-1" />
								<Grid className={`text-xs`}>ดาวน์โหลด Excel</Grid>
							</Button>
						</Grid>
					</Card>
					<Card className={`border-t-4 border-card_top_border mt-10`}>
						<Grid className="flex flex-col justify-center items-start px-5">
							<Grid className="flex flex-row mt-4">
								<Assignment style={{ fontSize: 22 }} className="mr-1" />
								<Grid className="font-bold text-sm text-card_header_color">
									ไฟล์แนบเพิ่มเติมสำหรับรายงานประเภทเคลื่อนที่ (Mobile)
								</Grid>
							</Grid>
							<Grid className="mt-3 mb-1 w-full">
								<Divider />
							</Grid>
							<Grid className="flex flex-col my-5 gap-y-3">
								{mobile_additional_files.map((item, idx) => {
									const additionalFile = dataSetFilesUpload[item.key]

									return (
										<Grid
											className="flex flex-col ml-10 my-3 gap-y-3"
											key={'additionalFile-' + idx}>
											<Grid className="font-bold">
												{item.title}
												{additionalFile
													? `(${additionalFile.length} ไฟล์)`
													: '(0 ไฟล์)'}
											</Grid>
											<Grid className="flex flex-col  ml-5 my-1 gap-x-3">
												{additionalFile &&
													additionalFile.map((file, index) => (
														<File
															key={file.filePath + index}
															originalFileName={file.filePath}
															downloadUrl={file.url}
														/>
													))}
											</Grid>
										</Grid>
									)
								})}
							</Grid>
						</Grid>
					</Card>
				</>
			)}
		</>
	)
}
