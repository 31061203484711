import { useEffect } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ProtectedRoute from './protected-route'
import { useStoreActions } from '@store'

// Components
import Dashboard from './dashboard'
import StandardReport from './standard-report'
import StandardReportDetails from './standard-report/[id]'
import Organization from './organization'
import OrganizationDetails from './organization/[id]'
import StandardReportOrganization from './standard-report-organization'
import StandardReportSent from './sent'
import StandardReportSentDetail from './sent/[id]'
import AdminUser from './admin-user'
import SignIn from './sign-in'
import SentChart from './sent-chart'

export default function AdminsRoute() {
	const { path } = useRouteMatch()
	const setAppType = useStoreActions(a => a.appModel.setAppType)

	useEffect(() => setAppType('admin'))

	return (
		<Switch>
			<ProtectedRoute exact path={path}>
				<Dashboard />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/organization/:id`}>
				<OrganizationDetails />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/organization`}>
				<Organization />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/standard-report/:id`}>
				<StandardReportDetails />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/standard-report`}>
				<StandardReport />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/admin-user`}>
				<AdminUser />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/standard-report-organization`}>
				<StandardReportOrganization />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/sent`}>
				<StandardReportSent />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/sent/:id`}>
				<StandardReportSentDetail />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/sent-chart`}>
				<SentChart />
			</ProtectedRoute>

			<Route path={`${path}/sign-in`} component={SignIn} />

			<Redirect from={`${path}/*`} to={path} />
		</Switch>
	)
}
