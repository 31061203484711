import React, { useState } from 'react'
import { Grid, TextField, Card, Button, Divider } from '@material-ui/core'
import IncorrectIdModal from '../../../../components/sdoc/registration/modal/incorrect-id'
import RegisterFailModal from '../../../../components/sdoc/registration/modal/register-fail'
import RegisterSuccessModal from '../../../../components/sdoc/registration/modal/register-success'

import logo from '../../../../assets/images/NBTC-logo.png'

import { useHistory } from 'react-router-dom'

import { users as Users } from '../../../../data/sdoc/users'

import { sdocUser } from '../../../../api'

export default function SDOCRegistration() {
	const history = useHistory()

	// ------------------------------------->
	// Step which tell Personal Information Part or Company Part
	const onSubmitRegistration = async () => {
		// Corfirm the Registeration
		try {
			const res = await sdocUser.addUser(
				firstname,
				lastname,
				phoneNumber,
				citizenID,
				email
			)
			if (res) {
				if (res.status === 201) {
					onOpenStandardReportSuccessModal()
				}
			} else {
				onOpenRegisterFailModal()
			}
		} catch (err) {
			onOpenRegisterFailModal()
		}
		// else if (res.response.status === 400) onOpenRegisterFailModal()
	}
	const goBackToSignInPage = () => {
		// Go back to Sign In page
		history.push('/sdoc/sign-in')
	}

	// <-------------------------------------

	// ------------------------------------->
	// Create State and OnChange of CitizenID and TaxID
	const [citizenID, setCitizenID] = useState('')
	const onChangeCitizenID = e => {
		var pattern = /^[0-9\b]+$/
		if (pattern.test(e.target.value) || e.target.value === '') {
			setCitizenID(e.target.value)
			setValidSubmitButton(false)
			setFirstname('')
			setLastname('')
			setEmail('')
			setPhoneNumber('')
		}
	}
	// <-------------------------------------

	// ------------------------------------->

	const cleanDataFromIncorrectId = () => {
		setValidSubmitButton(false)
		// Set default data
		setFirstname('')
		setLastname('')
		setEmail('')
		setPhoneNumber('')
		onOpenInCorrectIdModal()
	}

	// <-------------------------------------

	// ------------------------------------->
	const [validSubmitButton, setValidSubmitButton] = useState(false)
	// <-------------------------------------

	// ------------------------------------->
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	// <-------------------------------------

	// ------------------------------------->
	// Modal Success Manage
	const [openStandardReportSuccessModal, setOpenStandardReportSuccessModal] =
		useState(false)

	const onOpenStandardReportSuccessModal = () =>
		setOpenStandardReportSuccessModal(!openStandardReportSuccessModal)

	const onAgreeStandardReportSuccessModalCTA = () => {
		onOpenStandardReportSuccessModal()
		// Confirm Registeration
		history.push({
			pathname: '/sdoc/sign-in',
		})
	}
	// <-------------------------------------

	// Modal Register Fail
	const [openRegisterFailModal, setOpenRegisterFailModal] = useState(false)

	const onOpenRegisterFailModal = () =>
		setOpenRegisterFailModal(!openRegisterFailModal)
	// <-------------------------------------

	// Modal Incorrect ID
	const [openInCorrectIdModal, setOpenInCorrectIdModal] = useState(false)

	const onOpenInCorrectIdModal = () =>
		setOpenInCorrectIdModal(!openInCorrectIdModal)
	// <-------------------------------------

	// ------------------------------------->
	// Searching Users
	const onSearchUser = () => {
		const user = Users.find(item => item.nationalCardIDNo === citizenID)
		if (user && citizenID !== '') {
			setValidSubmitButton(true)
			setFirstname(user.firstname)
			setLastname(user.lastname)
			setEmail(user.email)
			setPhoneNumber(user.phoneNumber)
		} else {
			cleanDataFromIncorrectId()
		}

		// checkSearchError()
	}

	return (
		<>
			<Grid className="h-screen flex flex-col items-center justify-center sign-in-container p-3">
				<Card
					className="flex flex-col items-center py-6 px-6 h-full w-full 2xl:w-1/4 lg:w-96 sm:w-96 sm:h-4/5"
					style={{ minHeight: 700 }}>
					<Grid className="">
						<img src={logo} alt="NBTC" width="140" />
					</Grid>
					<Grid className="secondary text-2xl" style={{ color: '#a74345' }}>
						ลงทะเบียนระบบ Data Portal
					</Grid>
					<Grid className="w-full mt-6">
						<TextField
							fullWidth
							type="number"
							label="หมายเลขบัตรประจำตัวประชาชน"
							autoFocus={true}
							variant="filled"
							size="small"
							onChange={onChangeCitizenID}
							value={citizenID}
						/>
					</Grid>
					<Grid className="w-full mt-2">
						<Button
							className="text-white w-full"
							variant="contained"
							color="secondary"
							size="medium"
							onClick={onSearchUser}>
							ค้นหา
						</Button>
					</Grid>
					<Grid className="w-full my-4">
						<Divider />
					</Grid>
					<Grid className="w-full h-72">
						<>
							<TextField
								fullWidth
								type="text"
								label="ชื่อ*"
								variant="outlined"
								size="small"
								disabled={true}
								value={firstname}
								className="w-full h-14 mt-2"
							/>
							<TextField
								fullWidth
								type="text"
								label="นามสกุล*"
								variant="outlined"
								size="small"
								disabled={true}
								value={lastname}
								className="w-full h-14 mt-2"
							/>
							<TextField
								fullWidth
								type="text"
								label="อีเมล*"
								variant="outlined"
								size="small"
								disabled={true}
								value={email}
								className="w-full h-14 mt-2"
							/>
							<TextField
								fullWidth
								type="text"
								label="เบอร์โทรศัพท์*"
								variant="outlined"
								size="small"
								disabled={true}
								value={phoneNumber}
								className="w-full h-14 mt-2"
							/>
						</>
					</Grid>

					<Grid className="flex sm:flex-row flex-col items-center justify-between w-full mt-4 gap-y-4">
						<Grid className="sm:w-5/12 w-full">
							<Button
								className="text-white w-full"
								variant="outlined"
								color="secondary"
								size="medium"
								onClick={goBackToSignInPage}>
								ย้อนกลับ
							</Button>
						</Grid>
						<Grid className="sm:w-5/12 w-full">
							<Button
								className="text-white w-full"
								variant="contained"
								color="secondary"
								size="medium"
								disabled={!validSubmitButton}
								onClick={() => {
									onSubmitRegistration()
								}}>
								{'ยืนยันการสมัคร'}
							</Button>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<RegisterSuccessModal
				open={openStandardReportSuccessModal}
				handleButton={onAgreeStandardReportSuccessModalCTA}
			/>
			<IncorrectIdModal
				open={openInCorrectIdModal}
				handleButton={onOpenInCorrectIdModal}
			/>
			<RegisterFailModal
				open={openRegisterFailModal}
				handleButton={onOpenRegisterFailModal}
			/>
		</>
	)
}
