import { forwardRef, useRef, useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	IconButton,
	Slide,
	useMediaQuery,
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export function ChangeStatusModal({
	handleCloseDialog,
	statusDialogOpen,
	name,
	statusLabelTemp,
	onChangeStatusTemp,
	handleStatusTempChange,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	useEffect(() => {
		if (statusDialogOpen) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [statusDialogOpen])

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			onClose={handleCloseDialog}
			open={statusDialogOpen}
			TransitionComponent={Transition}>
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<Grid className="text-white text-lg">
							เปลี่ยนสถานะรายงานของ {name}
						</Grid>
					</Grid>
					<IconButton onClick={handleCloseDialog} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<Grid className="w-full">
					<RadioGroup
						aria-label="select-status"
						defaultValue={statusLabelTemp}
						value={statusLabelTemp}
						onChange={e => onChangeStatusTemp(e)}
						name="radio-buttons-group">
						<FormControlLabel
							value="รอตรวจสอบ"
							control={<Radio />}
							label="รอตรวจสอบ"
							className="bg-gray-100 w-full py-2 my-1"
						/>
						<FormControlLabel
							value="ตรวจสอบแล้ว"
							control={<Radio />}
							label="ตรวจสอบแล้ว"
							className="bg-gray-100 w-full py-2 my-1"
						/>
						<FormControlLabel
							value="ไม่อนุมัติ"
							control={<Radio />}
							label="ไม่อนุมัติ"
							className="bg-gray-100 w-full py-2 my-1"
						/>
					</RadioGroup>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-3/12" component="div">
						<Button
							fullWidth
							onClick={handleStatusTempChange}
							color="secondary"
							variant="contained">
							บันทึก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
