import { Grid, Input, Button } from '@material-ui/core'
import { findReportQuestion } from '../../../../../../data/standard-reportV2/find-report-question'

export default function SubLabelType2({
	type,
	flags,
	item,
	onFillForm,
	answers,
	onOpenValidationModal,
}) {
	const answer = answers.find(ans => ans.dataSetItemID === item.id)
	const is2G = item.label.includes('2G') ? true : false
	const question = findReportQuestion(answer?.dataSetItemID)
	const is5G = item.label.includes('5G')
	const is5GArea = item.label.includes('5G (พื้นที่)')
	const isFiveInput = item.label.includes('-ภาค')
	// const is2G = item.label.includes('2G') ? true : false
	console.log('answer', answer);

	return (
		<>
			{isFiveInput ? (
				<Grid className="col-span-12 text-center border flex flex-row">
					<Grid className="w-3/12 text-center border flex flex-row">
						<Grid className="border-r w-6/12 p-1 font-bold text-left">
							{item.label}
						</Grid>
						<Input
							className={`border-r border-gray-500 w-6/12 p-1 font-bold text-center bg-yellow_input_bg`}
							value={answer?.dataValueExtra}
							onChange={e =>
								onFillForm(type, item.id, 'dataValueExtra', e.target.value)
							}
							type="text"
							readOnly
						/>
					</Grid>
					{/* Months */}
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonth1}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth1', e.target.value)
						}
						type="text"
						readOnly
					/>
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonth2}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth2', e.target.value)
						}
						type="text"
						readOnly
					/>
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonth3}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth3', e.target.value)
						}
						type="text"
						readOnly
					/>
					{/* Average */}
					<Input
						className="border-r border-gray-500 w-1/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
						value={answer?.dataValueMonthAvg}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonthAvg', e.target.value)
						}
						type="text"
						readOnly
					/>
					{/* Validation */}
					<Grid className="border-r border-b border-gray-500 w-2/12 p-1 font-bold text-center flex justify-center items-center self-center h-full">
						{(
							(
								((!answer?.dataValueMonth1 || !answer?.dataValueMonth2 || !answer?.dataValueMonth3) && !is5G)
								|| ((!answer?.dataValueMonth1 || !answer?.dataValueMonth2) && is5G)
							)
							&& !answer?.isError) ? null : (answer?.isError === 'y' ? (
								<Button
									variant="contained"
									color="secondary"
									onClick={() =>
										onOpenValidationModal(
											item.id,
											answer.reason,
											answer.solution,
											answer.periodSolution,
											answer?.validateFileUrl
										)
									}>
									ไม่ผ่านเกณฑ์
								</Button>
							) : (
								<Grid className="text-green-500 font-bold text-center ">
									ผ่านเกณฑ์
								</Grid>
							))}
					</Grid>
				</Grid>
			) : !is2G ? (
				<Grid className="col-span-12 text-center border flex flex-row">
					<Grid className="border-r w-3/12 p-1 font-bold text-left">
						{item.label}
					</Grid>
					{/* Months */}
					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center ${is5GArea ? "" : "pointer-events-none"} ${is5GArea ? "bg-yellow_input_bg" : "bg-green_input_bg"}`}
						value={answer?.dataValueMonth1}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth1', e.target.value)
						}
						type="text"
						readOnly
					/>
					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center ${is5GArea ? "" : "pointer-events-none"} ${is5GArea ? "bg-yellow_input_bg" : "bg-green_input_bg"}`}
						value={answer?.dataValueMonth2}
						onChange={e =>
							onFillForm(type, item.id, 'dataValueMonth2', e.target.value)
						}
						type="text"
						readOnly
					/>
					{!is5G ? (
						<Input
							className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
							value={answer?.dataValueMonth3}
							onChange={e =>
								onFillForm(type, item.id, 'dataValueMonth3', e.target.value)
							}
							type="text"
							readOnly
						/>
					) : (
						<Input
							className="border-r border-gray-500 w-2/12 p-1 font-bold text-center pointer-events-none"
							value={null}
							disabled
							readOnly

						/>
					)}
					{/* Average */}
					{!is5G ? (
						<Input
							className="border-r border-gray-500 w-1/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
							value={answer?.dataValueMonthAvg}
							onChange={e =>
								onFillForm(type, item.id, 'dataValueMonthAvg', e.target.value)
							}
							type="text"
							readOnly
						/>
					) : (
						<Input
							className="border-r border-gray-500 w-1/12 p-1 font-bold text-center pointer-events-none"
							value={null}
							disabled
							readOnly

						/>
					)}
					{/* Validation */}
					<Grid className="border-r border-b border-gray-500 w-2/12 p-1 font-bold text-center flex justify-center items-center self-center h-full">
						{(
							(
								((!answer?.dataValueMonth1 || !answer?.dataValueMonth2 || !answer?.dataValueMonth3) && !is5G)
								|| ((!answer?.dataValueMonth1 || !answer?.dataValueMonth2) && is5G)
							)
							&& !answer?.isError) ? null : (answer?.isError === 'y' ? (
								<Button
									variant="contained"
									color="secondary"
									onClick={() =>
										onOpenValidationModal(
											item.id,
											answer.reason,
											answer.solution,
											answer.periodSolution,
											answer?.validateFileUrl
										)
									}>
									ไม่ผ่านเกณฑ์
								</Button>
							) : (
								<Grid className="text-green-500 font-bold text-center ">
									{is5GArea ? "ครบถ้วน" : "ผ่านเกณฑ์"}

								</Grid>
							))}
					</Grid>
				</Grid>
			) : (
				<></>
			)}
		</>
	)
}
