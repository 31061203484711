import { useEffect } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ProtectedRoute from './protected-route'
import { useStoreActions } from '@store'

// Views
import MainAdminAuth from './auth'
import MainAdminUserManagement from './user-management'

// import Dashboard from 'pages/sdoc/admin/dashboard/index'

export default function MainAdminRoutes() {
	const { path } = useRouteMatch()
	const setAppType = useStoreActions(a => a.appModel.setAppType)

	useEffect(() => setAppType('main-admin'))

	return (
		<Switch>
			<Route path={`${path}/sign-in`}>
				<MainAdminAuth />
			</Route>

			<ProtectedRoute exact path={`${path}/report`}>
				<div style={{textAlign:"center",fontSize:22, paddingTop:"25vh"}}>อยู่ระหว่างดำเนินการ</div>
			</ProtectedRoute>
			<ProtectedRoute exact path={`${path}/dashboard`}>
			<div style={{textAlign:"center",fontSize:22, paddingTop:"25vh"}}>อยู่ระหว่างดำเนินการ</div>
				{/* <Dashboard /> */}
			</ProtectedRoute>

			<ProtectedRoute exact path={path}>
				<MainAdminUserManagement />
			</ProtectedRoute>

			<Redirect from={`${path}/*`} to={path} />
		</Switch>
	)
}
