import {
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Hidden,
	Grid,
	Divider,
	Typography,
	Badge,
} from '@material-ui/core'
import {
	Description,
	SupervisedUserCircle,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	Pageview,
	RecentActors,
	Dashboard,
	ExitToApp,
	AssignmentTurnedIn,
	AssignmentLate,
	AccountCircle,
	Storage,
	Home,
	GetApp,
	MenuBook,
} from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useMemo } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from '../store'
import { useLoaded } from '../utils/useLoaded'
import { useAppTypePath } from '../utils/useAppTypePath'
import { auth } from '../api'

const drawerWidth = 300
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
	},
	drawerClose: {
		[theme.breakpoints.up('md')]: {
			width: 50,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerMobile: {
		width: drawerWidth,
	},
	drawerPaperOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	},
	drawerPaperClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(8) + 1,
		},
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	iconSelected: {
		color: '#a74345',
	},
	iconUnSelected: {
		color: '#9BA7B7',
	},
	textSelected: {
		color: '#a74345',
	},
	textUnSelected: {
		color: '5E5E5E',
	},
	listItemSelected: {
		backgroundColor: '#F4F4F5',
	},
	nbtc: {
		color: '#A52B28',
		fontSize: '10px',
	},
	versionNumber: {
		textAlign: 'center',
		position: 'absolute',
		width: '100%',
		bottom: '10px',
	},
	customBadge: {
		backgroundColor: '#00AFD7',
	},
	normalStatusBadge: {
		backgroundColor: '#d71600',
	},
	lateStatusBadge: {
		backgroundColor: '#56d700',
	},
}))

/**
 * USER Authorization:
 * 1) (1) no `standard-report` and `standard-report-validation`, but `organization-user`
 * 2) (2) no `standard-report-validation` and `organization-user`, but `standard-report`
 * 3) (3) no `standard-report` and `organization-user`, but `standard-report-validation`
 */

/**
 * ADMIN Authorization:
 * (temporary): every roles can see every pages except `admin-user` for (1)
 */

export default function SideBar({
	children,
	mobileOpen,
	handleDrawerToggle,
	isClose,
	onClose,
}) {
	const classes = useStyles()
	const theme = useTheme()
	const location = useLocation()
	const history = useHistory()
	const user = useStoreState(s => s.userModel.user)
	const { adminMESDOC } = useStoreState(s => s.sdocAdminModel)
	const adminSignOut = useStoreActions(a => a.QoSAdminModel.adminSignout)
	const userSDOCSignOut = useStoreActions(a => a.sdocUserModel.signOutUser)
	const adminSDOCSignOut = useStoreActions(a => a.sdocAdminModel.signOutAdmin)
	const { appTypePath } = useAppTypePath()
	const loaded = useLoaded()

	const sdocAdminRoleId = useMemo(() => adminMESDOC?.roleID, [adminMESDOC])

	// App Types
	const app_type = useStoreState(s => s.appModel.app_type)

	function isSelected(path) {
		return location.pathname === path
	}

	function onSignOut() {
		if (app_type === 'users') {
			auth.signout()
			history.push('/sign-in')
		} else if (app_type === 'admin') {
			adminSignOut()
			history.push('/admin/sign-in')
		} else if (app_type === 'sdoc') {
			userSDOCSignOut()
			history.push('/sdoc/sign-in')
		} else if (app_type === 'sdoc-admin') {
			adminSDOCSignOut()
			history.push('/admin/sdoc/sign-in')
		} else if (app_type === 'main-admin') {
			history.push('/main-admin/sign-in')
		}
	}

	return (
		loaded && (
			<div className={classes.root}>
				{/* -------------------------------USER SIDEBAR------------------------------- */}

				{/* Mobile */}
				{app_type === 'users' && (
					<Hidden mdUp implementation={`css`}>
						<Drawer
							variant={`temporary`}
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{ paper: classes.drawerPaperMobile }}
							ModalProps={{
								keepMounted: true,
							}}>
							<List className={`mt-20 font`}>
								<ListItem
									className={
										isSelected(`/`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={() => {
										return {
											pathname: `${appTypePath}/`,
											state: location.state,
										}
									}}>
									<ListItemIcon>
										<Home
											className={
												isSelected(`/`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หน้าแรก'}
									/>
								</ListItem>
								{/* ผู้เตรียมข้อมูล */}
								{(user?.roleID === 2 || user?.roleID === 1) && (
									<ListItem
										className={
											isSelected(`/standard-report`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										button
										component={Link}
										to={`${appTypePath}/standard-report`}>
										<ListItemIcon>
											<RecentActors
												className={
													isSelected(`/standard-report`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/standard-report`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'ส่งรายงานมาตรฐานคุณภาพ'}
										/>
									</ListItem>
								)}
								{/* ผู้เตรียมข้อมูล */}
								{(user?.roleID === 3 || user?.roleID === 1) && (
									<ListItem
										className={
											isSelected(`/standard-report-validation`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										button
										component={Link}
										to={`${appTypePath}/standard-report-validation`}>
										<ListItemIcon>
											<AssignmentTurnedIn
												className={
													isSelected(`/standard-report-validation`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/standard-report-validation`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'ตรวจสอบรายงานมาตรฐาน'}
										/>
									</ListItem>
								)}
								<ListItem
									className={
										isSelected(`/organization`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={() => {
										return {
											pathname: `${appTypePath}/organization`,
											state: location.state,
										}
									}}>
									<ListItemIcon>
										<Pageview
											className={
												isSelected(`/organization`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/organization`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ข้อมูลผู้ประกอบการ'}
									/>
								</ListItem>
								{/* ผู้ควบคุม */}
								{user?.roleID === 1 && (
									<ListItem
										className={
											isSelected(`/organization-user`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										button
										component={Link}
										to={`${appTypePath}/organization-user`}>
										<ListItemIcon>
											<SupervisedUserCircle
												className={
													isSelected(`/organization-user`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/organization-user`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'กำหนดผู้ใช้งาน'}
										/>
									</ListItem>
								)}
								<ListItem
									className={classes.listItemUnSelected}
									component="a"
									href={`/doc/QoS_template_Current%20v4.3.xlsx`}
									button>
									<ListItemIcon>
										<Description
											className={classes.iconUnSelected}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.textUnSelected}
										primary={'ดาวน์โหลดแบบฟอร์มรายงาน'}
									/>
								</ListItem>
								<ListItem
									button
									component="a"
									href="https://drive.google.com/drive/folders/1c9hAjtefpSSkda4Ksa68ZN9outUWApWK"
									target="_blank"
								>
									<ListItemIcon>
										<GetApp />
									</ListItemIcon>
									<ListItemText primary="ดาวน์โหลดคู่มือการใช้งานระบบ" />
								</ListItem>
								<ListItem
									className={
										isSelected(`/standard-report-history`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`${appTypePath}/standard-report-history`}>
									<ListItemIcon>
										<Description
											className={
												isSelected(`/standard-report-history`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/standard-report-history`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ประวัติการส่งรายงาน'}
									/>
								</ListItem>

								<ListItem button onClick={onSignOut}>
									<ListItemIcon>
										<ExitToApp htmlColor={classes.iconUnSelected} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
							</List>
						</Drawer>
					</Hidden>
				)}
				{/* Desktop */}
				{app_type === 'users' && (
					<Hidden mdDown implementation={`css`}>
						<Drawer
							classes={{
								paper: isClose
									? classes.drawerPaperClose
									: classes.drawerPaperOpen,
							}}
							className={`${classes.drawer}
            ${isClose ? classes.drawerPaperClose : classes.drawerPaperOpen}`}
							variant={`permanent`}
							open>
							<div className={`${classes.toolbar} bg-navBarColor border-0`} />
							<Typography
								variant="h5"
								gutterBottom
								component="div"
								style={{ textAlign: 'center', paddingTop: 10 }}>
								QoS
							</Typography>
							<Divider />
							<List>
								<ListItem
									className={
										isSelected(`/`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`${appTypePath}/`}
									button>
									<ListItemIcon>
										<Home
											className={
												isSelected(`/`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หน้าแรก'}
									/>
								</ListItem>
								{/* ผู้เตรียมข้อมูล */}
								{user && (user.roleID === 2 || user.roleID === 1) && (
									<ListItem
										className={
											isSelected(`/standard-report`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										component={Link}
										to={`${appTypePath}/standard-report`}
										button>
										<ListItemIcon>
											<RecentActors
												className={
													isSelected(`/standard-report`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/standard-report`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'ส่งรายงานมาตรฐานคุณภาพ'}
										/>
									</ListItem>
								)}
								{/* ผู้อนุมัติการส่งข้อมูล */}
								{(user?.roleID === 3 || user?.roleID === 1) && (
									<ListItem
										className={
											isSelected(`/standard-report-validation`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										button
										component={Link}
										to={`${appTypePath}/standard-report-validation`}>
										<ListItemIcon>
											<Badge
												badgeContent={4}
												classes={{ badge: classes.customBadge }}
												variant="dot">
												<AssignmentTurnedIn
													className={
														isSelected(`/standard-report-validation`)
															? classes.iconSelected
															: classes.iconUnSelected
													}
												/>
											</Badge>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/standard-report-validation`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'ตรวจสอบรายงานมาตรฐาน'}
										/>
									</ListItem>
								)}
								<ListItem
									className={
										isSelected(`/organization`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`${appTypePath}/organization`}
									button>
									<ListItemIcon>
										<Badge
											badgeContent={4}
											classes={{
												badge: true
													? classes.lateStatusBadge
													: classes.normalStatusBadge,
											}}
											variant="dot">
											<Pageview
												className={
													isSelected(`/organization`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</Badge>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/organization`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ข้อมูลผู้ประกอบการ'}
									/>
								</ListItem>
								{/* ผู้ควบคุม */}
								{user?.roleID === 1 && (
									<ListItem
										className={
											isSelected(`/organization-user`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										component={Link}
										to={`${appTypePath}/organization-user`}
										button>
										<ListItemIcon>
											<SupervisedUserCircle
												className={
													isSelected(`/organization-user`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/organization-user`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'กำหนดผู้ใช้งาน'}
										/>
									</ListItem>
								)}
								<ListItem
									className={classes.listItemUnSelected}
									component="a"
									href={`/doc/QoS_template_Current%20v4.3.xlsx`}
									button>
									<ListItemIcon>
										<Description
											className={classes.iconUnSelected}
										/>
									</ListItemIcon>
									<ListItemText
										className={classes.textUnSelected}
										primary={'ดาวน์โหลดแบบฟอร์มรายงาน'}
									/>
								</ListItem>

								<ListItem
									button
									component="a"
									href="https://drive.google.com/drive/folders/1c9hAjtefpSSkda4Ksa68ZN9outUWApWK"
									target="_blank"
								>
									<ListItemIcon>
										<GetApp />
									</ListItemIcon>
									<ListItemText primary="ดาวน์โหลดคู่มือการใช้งานระบบ" />
								</ListItem>
								{user?.roleID !== 2 && (
									<ListItem
										className={
											isSelected(`/standard-report-history`)
												? classes.listItemSelected
												: classes.listItemUnSelected
										}
										component={Link}
										to={`${appTypePath}/standard-report-history`}
										button>
										<ListItemIcon>
											<Description
												className={
													isSelected(`/standard-report-history`)
														? classes.iconSelected
														: classes.iconUnSelected
												}
											/>
										</ListItemIcon>
										<ListItemText
											className={
												isSelected(`/standard-report-history`)
													? classes.textSelected
													: classes.textUnSelected
											}
											primary={'ประวัติการส่งรายงาน'}
										/>
									</ListItem>
								)}

								<ListItem onClick={onSignOut} button>
									<ListItemIcon>
										<ExitToApp htmlColor={`#9BA7B7`} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem button onClick={onClose}>
									<ListItemIcon>
										{isClose ? (
											<KeyboardArrowRight htmlColor={`#9BA7B7`} />
										) : (
											<KeyboardArrowLeft htmlColor={`#9BA7B7`} />
										)}
									</ListItemIcon>
									<ListItemText primary={'ซ่อนเมนู'} />
								</ListItem>
							</List>
							<div className={classes.versionNumber}>
								v.{process.env.REACT_APP_VERSION}
							</div>
						</Drawer>
					</Hidden>
				)}

				{/* -------------------------------USER SIDEBAR------------------------------- */}

				{/* -------------------------------ADMIN SIDEBAR------------------------------- */}

				{/* Mobile */}
				{app_type === 'main-admin'}

				{app_type === 'admin' && (
					<Hidden mdUp implementation={`css`}>
						<Drawer
							variant={`temporary`}
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{ paper: classes.drawerPaperMobile }}
							ModalProps={{
								keepMounted: true,
							}}>
							<div className={`${classes.toolbar} bg-navBarColor border-0`} />
							<Typography
								variant="h5"
								gutterBottom
								component="div"
								style={{ textAlign: 'center', paddingTop: 10 }}>
								Admin
							</Typography>
							<Divider />
							<List className={`mt-20 font`}>
								<ListItem
									className={
										isSelected(`/admin`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin`}>
									<ListItemIcon>
										<Home
											className={
												isSelected(`/admin`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หน้าแรก'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/standard-report`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/standard-report`}>
									<ListItemIcon>
										<RecentActors
											className={
												isSelected(`/admin/standard-report`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/standard-report`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ตรวจรับรายงาน'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/standard-report-organization`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/standard-report-organization`}>
									<ListItemIcon>
										<AssignmentLate
											className={
												isSelected(`/admin/standard-report-organization`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/standard-report-organization`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายงานที่ยังไม่ส่ง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/organization`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/organization`}>
									<ListItemIcon>
										<Pageview
											className={
												isSelected(`/admin/organization`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/organization`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ข้อมูลผู้ประกอบการ'}
									/>
								</ListItem>
								{/* ผู้ควบคุม */}

								<ListItem
									className={
										isSelected(`/admin/sent`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sent`}>
									<ListItemIcon>
										<AssignmentTurnedIn
											className={
												isSelected(`/admin/sent`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sent`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ส่งรายงานสำเร็จ'}
									/>
								</ListItem>
								<ListItem button onClick={onSignOut}>
									<ListItemIcon>
										<ExitToApp htmlColor={classes.iconUnSelected} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
							</List>
						</Drawer>
					</Hidden>
				)}
				{/* Desktop */}
				{app_type === 'main-admin' && (
					<Hidden mdDown implementation={`css`}>
						<Drawer
							classes={{
								paper: isClose
									? classes.drawerPaperClose
									: classes.drawerPaperOpen,
							}}
							className={`${classes.drawer}
            ${isClose ? classes.drawerPaperClose : classes.drawerPaperOpen}`}
							variant={`permanent`}
							open>
							<div className={`${classes.toolbar} bg-navBarColor border-0`} />
							<Typography
								variant="h5"
								gutterBottom
								component="div"
								style={{ textAlign: 'center', paddingTop: 10 }}>
								Admin
							</Typography>
							<Divider />
							<List>
								<ListItem component={Link} to={`/main-admin/dashboard`} button>
									<ListItemIcon>
										<Dashboard className={classes.iconUnSelected} />
									</ListItemIcon>
									<ListItemText
										className={classes.textUnSelected}
										primary={'Dashboard'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/main-admin`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/main-admin`}
									button>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/main-admin`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/main-admin`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'กำหนดผู้ใช้งาน'}
									/>
								</ListItem>

								<ListItem component={Link} to={`/main-admin/report`} button>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/main-admizzn`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/main-admizzn`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายงาน'}
									/>
								</ListItem>

								<ListItem onClick={onSignOut} button>
									<ListItemIcon>
										<ExitToApp htmlColor={`#9BA7B7`} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>

								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem button onClick={onClose}>
									<ListItemIcon>
										{isClose ? (
											<KeyboardArrowRight htmlColor={`#9BA7B7`} />
										) : (
											<KeyboardArrowLeft htmlColor={`#9BA7B7`} />
										)}
									</ListItemIcon>
									<ListItemText primary={'ซ่อนเมนู'} />
								</ListItem>
							</List>
							<div className={classes.versionNumber}>
								v.{process.env.REACT_APP_VERSION}
							</div>
						</Drawer>
					</Hidden>
				)}

				{app_type === 'admin' && (
					<Hidden mdDown implementation={`css`}>
						<Drawer
							classes={{
								paper: isClose
									? classes.drawerPaperClose
									: classes.drawerPaperOpen,
							}}
							className={`${classes.drawer}
            ${isClose ? classes.drawerPaperClose : classes.drawerPaperOpen}`}
							variant={`permanent`}
							open>
							<div className={`${classes.toolbar} bg-navBarColor border-0`} />
							<Typography
								variant="h5"
								gutterBottom
								component="div"
								style={{ textAlign: 'center', paddingTop: 10 }}>
								Admin
							</Typography>
							<Divider />
							<List>
								<ListItem
									className={
										isSelected(`/admin`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin`}
									button>
									<ListItemIcon>
										<Home
											className={
												isSelected(`/admin`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หน้าแรก'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/standard-report`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/standard-report`}
									button>
									<ListItemIcon>
										<RecentActors
											className={
												isSelected(`/admin/standard-report`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/standard-report`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ตรวจรับรายงาน'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/standard-report-organization`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/standard-report-organization`}>
									<ListItemIcon>
										<AssignmentLate
											className={
												isSelected(`/admin/standard-report-organization`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/standard-report-organization`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายงานที่ยังไม่ส่ง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/organization`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/organization`}
									button>
									<ListItemIcon>
										<Pageview
											className={
												isSelected(`/admin/organization`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/organization`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ข้อมูลผู้ประกอบการ'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/sent`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/sent`}
									button>
									<ListItemIcon>
										<AssignmentTurnedIn
											className={
												isSelected(`/admin/sent`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sent`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายงาน QOS'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/sent-chart`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/sent-chart`}
									button>
									<ListItemIcon>
										<AssignmentTurnedIn
											className={
												isSelected(`/admin/sent-chart`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sent-chart`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายงาน QOS แบบกราฟ'}
									/>
								</ListItem>


								<ListItem onClick={onSignOut} button>
									<ListItemIcon>
										<ExitToApp htmlColor={`#9BA7B7`} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem button onClick={onClose}>
									<ListItemIcon>
										{isClose ? (
											<KeyboardArrowRight htmlColor={`#9BA7B7`} />
										) : (
											<KeyboardArrowLeft htmlColor={`#9BA7B7`} />
										)}
									</ListItemIcon>
									<ListItemText primary={'ซ่อนเมนู'} />
								</ListItem>
							</List>
							<div className={classes.versionNumber}>
								v.{process.env.REACT_APP_VERSION}
							</div>
						</Drawer>
					</Hidden>
				)}

				{/* -------------------------------ADMIN SIDEBAR------------------------------- */}

				{/* -------------------------------SDOC USER SIDEBAR------------------------------- */}

				{/* Mobile */}
				{app_type === 'sdoc' && (
					<Hidden mdUp implementation={`css`}>
						<Drawer
							variant={`temporary`}
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{ paper: classes.drawerPaperMobile }}
							ModalProps={{
								keepMounted: true,
							}}>
							<List className={`mt-20 font`}>
								<ListItem
									className={
										isSelected(`/sdoc/profile`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={{
										pathname: `/sdoc/profile`,
									}}>
									<ListItemIcon>
										<AccountCircle
											className={
												isSelected(`/sdoc/profile`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/sdoc/profile`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ข้อมูลผู้ใช้งาน'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/sdoc`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={{
										pathname: `/sdoc`,
									}}>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/sdoc`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/sdoc`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ยื่นหนังสือแบบรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/sdoc/manage`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/sdoc/manage`}>
									<ListItemIcon>
										<RecentActors
											className={
												isSelected(`/sdoc/manage`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/sdoc/manage`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'สถานะการยื่นแบบรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									button
									component="a"
									href="https://drive.google.com/drive/folders/1f8MYNpnjSw4yrswqhIlurPvTCl7nVnT0"
									target="_blank"
								>
									<ListItemIcon>
										<GetApp />
									</ListItemIcon>
									<ListItemText primary="ดาวน์โหลดคู่มือการใช้งานระบบ" />
								</ListItem>

								<ListItem button onClick={onSignOut}>
									<ListItemIcon>
										<ExitToApp htmlColor={classes.iconUnSelected} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
							</List>
						</Drawer>
					</Hidden>
				)}
				{/* Desktop */}
				{app_type === 'sdoc' && (
					<Hidden mdDown implementation={`css`}>
						<Drawer
							classes={{
								paper: isClose
									? classes.drawerPaperClose
									: classes.drawerPaperOpen,
							}}
							className={`${classes.drawer}
            ${isClose ? classes.drawerPaperClose : classes.drawerPaperOpen}`}
							variant={`permanent`}
							open>
							<div className={`${classes.toolbar} bg-navBarColor border-0`} />
							<Typography
								variant="h5"
								gutterBottom
								component="div"
								style={{ textAlign: 'center', paddingTop: 10 }}>
								SDoc
							</Typography>
							<Divider />
							<List>
								<ListItem
									className={
										isSelected(`/sdoc/profile`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={{
										pathname: `/sdoc/profile`,
									}}>
									<ListItemIcon>
										<AccountCircle
											className={
												isSelected(`/sdoc/profile`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/sdoc/profile`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ข้อมูลผู้ใช้งาน'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/sdoc`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={{
										pathname: `/sdoc`,
									}}
									button>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/sdoc`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/sdoc`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'ยื่นหนังสือแบบรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/sdoc/manage`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/sdoc/manage`}
									button>
									<ListItemIcon>
										<RecentActors
											className={
												isSelected(`/sdoc/manage`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/sdoc/manage`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'สถานะการยื่นแบบรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									button
									component="a"
									href="https://drive.google.com/drive/folders/1f8MYNpnjSw4yrswqhIlurPvTCl7nVnT0"
									target="_blank"
								>
									<ListItemIcon>
										<GetApp />
									</ListItemIcon>
									<ListItemText primary="ดาวน์โหลดคู่มือการใช้งานระบบ" />
								</ListItem>

								<ListItem onClick={onSignOut} button>
									<ListItemIcon>
										<ExitToApp htmlColor={`#9BA7B7`} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem button onClick={onClose}>
									<ListItemIcon>
										{isClose ? (
											<KeyboardArrowRight htmlColor={`#9BA7B7`} />
										) : (
											<KeyboardArrowLeft htmlColor={`#9BA7B7`} />
										)}
									</ListItemIcon>
									<ListItemText primary={'ซ่อนเมนู'} />
								</ListItem>
							</List>
							<div className={classes.versionNumber}>
								v.{process.env.REACT_APP_VERSION}
							</div>
						</Drawer>
					</Hidden>
				)}

				{/* -------------------------------SDOC USER SIDEBAR------------------------------- */}

				{/* -------------------------------SDOC ADMIN SIDEBAR------------------------------- */}

				{/* Mobile */}
				{app_type === 'sdoc-admin' && (
					<Hidden mdUp implementation={`css`}>
						<Drawer
							variant={`temporary`}
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{ paper: classes.drawerPaperMobile }}
							ModalProps={{
								keepMounted: true,
							}}>
							<List className={`mt-20 font`}>
								<ListItem
									className={
										isSelected(`/admin/sdoc`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc`}>
									<ListItemIcon>
										<Home
											className={
												isSelected(`/admin/sdoc`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หน้าแรก'}
									/>
								</ListItem>
								<ListItem
									className={
										isSelected(`/admin/sdoc/manage`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc/manage`}>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/admin/sdoc/manage`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/manage`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'แบบหนังสือรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/sdoc/requestlist`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc/requestlist`}>
									<ListItemIcon>
										<Storage
											className={
												isSelected(`/admin/sdoc/requestlist`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/requestlist`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายการคำร้องขอหนังสือรับรอง'}
									/>
								</ListItem>


								<ListItem
									className={
										isSelected(`/admin/sdoc/sent`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc/sent`}>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/admin/sdoc/sent`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/sent`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หนังสือรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/sdoc/poalist`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc/poalist`}>
									<ListItemIcon>
										<MenuBook
											className={
												isSelected(`/admin/sdoc/poalist`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/poalist`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายการมอบอำนาจภายในระบบ'}
									/>
								</ListItem>

								<ListItem button onClick={onSignOut}>
									<ListItemIcon>
										<ExitToApp htmlColor={classes.iconUnSelected} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
							</List>
						</Drawer>
					</Hidden>
				)}
				{/* Desktop */}
				{app_type === 'sdoc-admin' && (
					<Hidden mdDown implementation={`css`}>
						<Drawer
							classes={{
								paper: isClose
									? classes.drawerPaperClose
									: classes.drawerPaperOpen,
							}}
							className={`${classes.drawer}
            ${isClose ? classes.drawerPaperClose : classes.drawerPaperOpen}`}
							variant={`permanent`}
							open>
							<div className={`${classes.toolbar} bg-navBarColor border-0`} />
							<Divider />
							<List>
								<ListItem
									className={
										isSelected(`/admin/sdoc`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/sdoc`}
									button>
									<ListItemIcon>
										<Home
											className={
												isSelected(`/admin/sdoc`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หน้าแรก'}
									/>
								</ListItem>
								<ListItem
									className={
										isSelected(`/admin/sdoc/manage`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/sdoc/manage`}
									button>
									<ListItemIcon>
										<Dashboard
											className={
												isSelected(`/admin/sdoc/manage`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/manage`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'แบบหนังสือรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/sdoc/requestlist`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc/requestlist`}>
									<ListItemIcon>
										<Storage
											className={
												isSelected(`/admin/sdoc/requestlist`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/requestlist`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายการคำร้องขอหนังสือรับรอง'}
									/>
								</ListItem>



								<ListItem
									className={
										isSelected(`/admin/sdoc/sent`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									component={Link}
									to={`/admin/sdoc/sent`}
									button>
									<ListItemIcon>
										<SupervisedUserCircle
											className={
												isSelected(`/admin/sdoc/sent`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/sent`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'หนังสือรับรองตนเอง'}
									/>
								</ListItem>

								<ListItem
									className={
										isSelected(`/admin/sdoc/poalist`)
											? classes.listItemSelected
											: classes.listItemUnSelected
									}
									button
									component={Link}
									to={`/admin/sdoc/poalist`}>
									<ListItemIcon>
										<MenuBook
											className={
												isSelected(`/admin/sdoc/poalist`)
													? classes.iconSelected
													: classes.iconUnSelected
											}
										/>
									</ListItemIcon>
									<ListItemText
										className={
											isSelected(`/admin/sdoc/poalist`)
												? classes.textSelected
												: classes.textUnSelected
										}
										primary={'รายการมอบอำนาจภายในระบบ'}
									/>
								</ListItem>

								<ListItem onClick={onSignOut} button>
									<ListItemIcon>
										<ExitToApp htmlColor={`#9BA7B7`} />
									</ListItemIcon>
									<ListItemText primary={'ออกจากระบบ'} />
								</ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem></ListItem>
								<ListItem button onClick={onClose}>
									<ListItemIcon>
										{isClose ? (
											<KeyboardArrowRight htmlColor={`#9BA7B7`} />
										) : (
											<KeyboardArrowLeft htmlColor={`#9BA7B7`} />
										)}
									</ListItemIcon>
									<ListItemText primary={'ซ่อนเมนู'} />
								</ListItem>
							</List>
							<div className={classes.versionNumber}>
								v.{process.env.REACT_APP_VERSION}
							</div>
						</Drawer>
					</Hidden>
				)}

				{/* -------------------------------SDOC ADMIN SIDEBAR------------------------------- */}

				<Grid className={`w-full`}>{children}</Grid>
			</div>
		)
	)
}
