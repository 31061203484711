import { action, thunk } from 'easy-peasy'
import { admin, dashboard } from '../../../api'

export const mainAdmin = {
	admin: null,
	adminStat: {
		stat1: 0,
		stat2: 0,
		stat3: 0,
		stat4: 0,
	},
	adminOperator: {
		ope1: 0,
		ope2: 0,
		ope3: 0,
	},
	setAdmin: action((state, admin) => {
		state.admin = admin
	}),
	setAdminStat: action((state, stat) => {
		state.adminStat = stat
	}),
	setAdminOperator: action((state, stat) => {
		state.adminOperator = stat
	}),
	signin: thunk(async (actions, { username, password }) => {
		const res = await admin.signin(username, password, 3)
		if (res && res.status === 200) {
			actions.setAdmin(res.data)
		}
	}),
	getAdminDashboardStat: thunk(async actions => {
		const res = await dashboard.getDashboardStat()
		if (res && res.status === 200) {
			const { data, ope } = res.data

			actions.setAdminStat(data)
			actions.setAdminOperator(ope)
		}
	}),
}
