import { useEffect } from 'react'
import { Grid, Card, Divider, Button, IconButton } from '@material-ui/core'
import { PlaylistAddCheck, Delete } from '@material-ui/icons'
import { mobile_additional_files } from '../../../../data/standard-reportV2/ui/mobile-additional-files'

function FileList({ name, onDeleteAdditionalFile }) {
	return (
		<Grid className="flex flex-row items-center gap-x-4">
			<Grid className="text-base">• {name}</Grid>
			<IconButton color="primary" onClick={onDeleteAdditionalFile}>
				<Delete />
			</IconButton>
		</Grid>
	)
}

export default function StandardReportAdditionalFileStep({
	mobileFiles = {},
	onInputAdditionalFile,
	onDeleteAdditionalFile,
}) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid className="flex items-center justify-center ">
			<Card className="flex flex-col h-auto p-4 w-full gap-y-2 border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0">
				<Grid className="flex flex-row font-bold text-sm items-center">
					<PlaylistAddCheck style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="text-card_header_color">
						แนบไฟล์เพิ่มเติมสำหรับรายงานมาตรฐานแบบเคลื่อนที่
					</Grid>
				</Grid>
				<Grid>
					<Divider />
				</Grid>
				<Grid className="mt-4 font-bold">
					* อัพโหลดไฟล์ข้อมูลใน format .xlsx .xls .docx .doc .pdf .jpeg  
					.png .jpg เท่านั้น *
				</Grid>

				{mobile_additional_files.map(item => {
					const additionalFile = mobileFiles[item.key]

					return (
						<div key={item.key}>
							<Grid className="flex flex-col ml-10 my-3 gap-y-3">
								<Grid className="font-bold">{item.title}</Grid>
								<Grid className="flex flex-row items-center ml-5 my-1 gap-x-3">
									<Button variant="contained" component="label" size="small">
										เลือกไฟล์
										<input
											id="attachmentBMARoadButton"
											type="file"
											hidden
											accept=".xlsx, .xls, .docx, .doc, .pdf, .jpeg, .png, .jpg"
											onChange={e => onInputAdditionalFile(e, item.key)}
										/>
									</Button>
									<Grid>จำนวนไฟล์ที่อัพโหลด {additionalFile.length} ไฟล์</Grid>
								</Grid>
							</Grid>
							<Grid className="flex flex-col gap-y-2 ml-16">
								{additionalFile.map((file, index) => (
									<FileList
										key={file?.name + index}
										name={file?.name}
										onDeleteAdditionalFile={() =>
											onDeleteAdditionalFile(index, item.key)
										}
									/>
								))}
							</Grid>
						</div>
					)
				})}
			</Card>
		</Grid>
	)
}
