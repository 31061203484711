import { Grid, Input } from '@material-ui/core'
export default function SubLabelType2({
	type,
	flags,
	item,
	onFillForm,
	answers,
}) {
	const answer = answers.find(ans => ans.DataSetItemID === item.id)
	console.log('answer', answer)
	const is2G = item.label.includes('2G') && flags['2G'] === false
	const is5G = item.label.includes('5G')
	const is5GArea = item.label.includes('5G (พื้นที่)')
	const isFiveInput = item.label.includes('-ภาค')
	// const is2G = item.label.includes('2G') ? true : false

	return (
		<>
			{isFiveInput ? (
				<Grid className="col-span-12 text-center border flex flex-row justify-around">
					<Grid className="border-r w-2/12 p-1 font-bold text-left">
						{item.label}
					</Grid>
					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-yellow_input_bg`}
						value={answer?.DataValueExtra}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueExtra', e.target.value)
						}
						type="text"
					/>
					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg`}
						value={answer?.DataValueMonth1}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueMonth1', e.target.value)
						}
						type="text"
					/>
					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg`}
						value={answer?.DataValueMonth2}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueMonth2', e.target.value)
						}
						type="text"
					/>
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg"
						value={answer?.DataValueMonth3}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueMonth3', e.target.value)
						}
						type="text"
					/>
					<Input
						className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg"
						value={answer?.DataValueMonthAvg}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueMonthAvg', e.target.value)
						}
						type="text"
					/>
				</Grid>
			) : !is2G ? (
				<Grid className="col-span-12 text-center border flex flex-row justify-around">
					<Grid className="border-r w-4/12 p-1 font-bold text-left">
						{item.label}
					</Grid>
					{/* Months */}
					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center ${is5GArea ? "bg-yellow_input_bg" : "bg-green_input_bg"}`}
						value={answer?.DataValueMonth1}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueMonth1', e.target.value)
						}
						type="text"
					/>

					<Input
						className={`border-r border-gray-500 w-2/12 p-1 font-bold text-center ${is5GArea ? "bg-yellow_input_bg" : "bg-green_input_bg"}`}
						value={answer?.DataValueMonth2}
						onChange={e =>
							onFillForm(type, item.id, 'DataValueMonth2', e.target.value)
						}
						type="text"
					/>
					{!is5G ? (
						<Input
							className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg"
							value={answer?.DataValueMonth3}
							onChange={e =>
								onFillForm(type, item.id, 'DataValueMonth3', e.target.value)
							}
							type="text"
						/>
					) : (
						<Input
							className="border-r border-gray-500 w-2/12 p-1 font-bold text-center"
							value={null}
							disabled
							readOnly

						/>
					)}
					{/* Average */}
					{!is5G ? (
						<Input
							className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg"
							value={answer?.DataValueMonthAvg}
							onChange={e =>
								onFillForm(type, item.id, 'DataValueMonthAvg', e.target.value)
							}
							type="text"
						/>
					) : (
						<Input
							className="border-r border-gray-500 w-2/12 p-1 font-bold text-center"
							value={null}
							disabled
							readOnly
						/>
					)}
				</Grid>
			) : (
				<></>
			)}
		</>
	)
}
