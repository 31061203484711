import { useEffect, useState } from 'react'
import {
	Grid,
	Card,
	Divider,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
} from '@material-ui/core'
import React from 'react'

import { PlaylistAddCheck } from '@material-ui/icons'
import { useStoreState } from 'store/index'
export default function StandardReportStep1({
	reportMethod,
	onChangeRadio,
	fixedReportTypeRadioValue,
	mobileReportTypeRadioValue,
	onChangeReportType,
	userOperatorProfile,
	hasEverSubmitedForm,
	isNewForm,
	onChangeIsContinueRadio,
	continueFormSubmittedType = {
		isFixed: true,
		isMobile: true,
	},
}) {
	const user = useStoreState(s => s.userModel.user)
	const [formPerType, setFormPerType] = useState({
		fixed: true,
		mobile: true,
	})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		let temp = {
			fixed: false,
			mobile: false,
		}
		user.operatorUserQOSType &&
			user.operatorUserQOSType.forEach(item => {
				if (item.qosTypeID === 1) temp.mobile = true
				if (item.qosTypeID === 2) temp.fixed = true
			})
		setFormPerType(temp)
	}, [user.operatorUserQOSType])

	return (
		<Grid className="flex items-center justify-center ">
			<Card className="flex flex-col h-auto p-4 w-full gap-y-2 border border-t-4 border-card_top_border border-l-0 border-r-0 border-b-0">
				<Grid className="flex flex-row font-bold text-sm items-center">
					<PlaylistAddCheck style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="text-card_header_color">
						เลือกรูปแบบการส่งรายงาน
					</Grid>
				</Grid>
				<Grid>
					<Divider />
				</Grid>
				<Grid>รูปแบบการส่งรายงาน</Grid>
				<RadioGroup
					row
					value={reportMethod}
					onChange={onChangeRadio}
					defaultChecked={false}>
					<FormControlLabel
						value="web-form"
						control={<Radio />}
						label="Web Form"
					/>
					<FormControlLabel value="upload" control={<Radio />} label="Upload" />
				</RadioGroup>
				<Grid>ชุดข้อมูล</Grid>
				<Grid className="grid grid-cols-1 md:grid-cols-2 w-5/5 md:w-3/5 xl:w-2/5">
					{formPerType.fixed && (
						<FormControlLabel
							checked={
								isNewForm === 'y'
									? fixedReportTypeRadioValue
									: continueFormSubmittedType.isFixed
							}
							disabled={isNewForm === 'n'}
							control={<Checkbox />}
							onChange={e => onChangeReportType(1, e.target.checked)}
							label={'ชุดข้อมูลแบบประจำที่'}
						/>
					)}
					{formPerType.mobile && (
						<FormControlLabel
							checked={
								isNewForm === 'y'
									? mobileReportTypeRadioValue
									: continueFormSubmittedType.isMobile
							}
							onChange={e => onChangeReportType(2, e.target.checked)}
							disabled={isNewForm === 'n'}
							control={<Checkbox />}
							label={'ชุดข้อมูลแบบเคลื่อนที่'}
						/>
					)}
				</Grid>
				{hasEverSubmitedForm && (
					<Grid className="text-red-500">
						ระบบตรวจพบว่า คุณเคยกรอกรายงานมาตรฐาน
						ต้องการที่จะดำเนินการต่อหรือไม่?
					</Grid>
				)}
				{hasEverSubmitedForm && (
					<RadioGroup row value={isNewForm} onChange={onChangeIsContinueRadio}>
						<FormControlLabel
							value={'n'}
							control={<Radio />}
							label="ดำเนินการต่อ"
						/>
						<FormControlLabel
							value={'y'}
							control={<Radio />}
							label="กรอกใหม่"
						/>
					</RadioGroup>
				)}
			</Card>
		</Grid>
	)
}
