import { Grid } from '@material-ui/core'
import { useWidthExcelInputWithValidation } from 'utils/useWidthExcelInputWithValidation'
import { useTimeQuarter } from 'utils/useTimeQuarter'

export default function ExcelSubHeader({ sub_label_type, isTwoPointThree }) {
	const { quarter } = useTimeQuarter()
	const { valueWidth, validationWidth, avgWidth } =
		useWidthExcelInputWithValidation(sub_label_type)

	return (
		<Grid className="col-span-12 text-center border border-b-0 flex flex-row justify-around">
			{(sub_label_type === 3 || sub_label_type === 2) && (
				<Grid className="border-r w-3/12 p-1 font-bold">{isTwoPointThree?null:'ภูมิภาค'}</Grid>
			)}
			<Grid
				className={`border-r ${valueWidth} p-1 font-bold bg-month_table_blue_bg`}>
				{isTwoPointThree ? 'เส้นทางสายหลักและสายรอง' : quarter.month1}
			</Grid>
			<Grid className={`border-r ${valueWidth} p-1 font-bold`}>
				{isTwoPointThree ? 'เส้นทางในบริเวณพื้นที่ชุมชน' : quarter.month2}
			</Grid>
			<Grid
				className={`border-r ${valueWidth} p-1 font-bold bg-month_table_blue_bg`}>
				{isTwoPointThree ? 'เส้นทางในเขตกรุงเทพฯ และปริมณฑล' : quarter.month3}
			</Grid>
			<Grid className={`border-r ${avgWidth} p-1 font-bold`}>
				{isTwoPointThree
					? 'พื้นที่เฝ้าระวังอื่นที่กำหนดในแต่ละไตรมาส**'
					: 'ค่าเฉลี่ย'}
			</Grid>
			<Grid
				className={`border-r ${validationWidth} p-1 font-bold bg-month_table_blue_bg`}>
				ผลการประเมิน
			</Grid>
		</Grid>
	)
}
