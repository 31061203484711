import { Grid, Input } from '@material-ui/core'
export default function SubLabelType1({
	type,
	onFillForm,
	datasetId,
	answers,
}) {
	const answer = answers.find(item => item.DataSetItemID === datasetId)
	// const is5G = item.label.includes('5G');
	const is5G = false;

	return (
		<Grid className="col-span-12 text-center border flex flex-row justify-around">
			{/* Months */}
			<Input
				className="border-r border-gray-500 w-3/12 p-1 font-bold text-center bg-green_input_bg"
				value={answer?.DataValueMonth1}
				onChange={e => {
					onFillForm(type, datasetId, 'DataValueMonth1', e.target.value)
				}}
				type="text"
			/>
			<Input
				className="border-r border-gray-500 w-3/12 p-1 font-bold text-center bg-green_input_bg"
				value={answer?.DataValueMonth2}
				onChange={e => {
					onFillForm(type, datasetId, 'DataValueMonth2', e.target.value)
				}}
				type="text"
			/>
			{!is5G ? (
				<Input
					className="border-r border-gray-500 w-3/12 p-1 font-bold text-center bg-green_input_bg"
					value={answer?.DataValueMonth3}
					onChange={e => {
						onFillForm(type, datasetId, 'DataValueMonth3', e.target.value)
					}}
					type="text"
				/>
			) : (
				<></>
			)}
			{/* Average */}
			{!is5G ? (
				<Input
					className="border-r border-gray-500 w-3/12 p-1 font-bold text-center bg-green_input_bg"
					value={answer?.DataValueMonthAvg}
					onChange={e => {
						onFillForm(type, datasetId, 'DataValueMonthAvg', e.target.value)
					}}
					type="text"
				/>
			) : (
				<></>
			)}
		</Grid>
	)
}
