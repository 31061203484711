import { persist } from 'easy-peasy'

import { userModel } from './user/index'
import { QoSAdminModel } from './admin-qos'
import { mainAdmin } from './main-admin'
import { sdocAdminModel } from './sdoc-admin'
import { sdocUserModel } from './sdoc-user'
import { operatorModel } from './operator'
import { poaModel } from './poa'
import { appModel } from './app-state'

export const storeModel = persist(
	{
		userModel: userModel,
		QoSAdminModel: QoSAdminModel,
		mainAdminModel: mainAdmin,
		appModel: appModel,
		sdocAdminModel: sdocAdminModel,
		sdocUserModel: sdocUserModel,
		poa: poaModel,
		operatorModel: operatorModel,
	},
	{
		allow: [
			'userModel',
			'QoSAdminModel',
			'mainAdminModel',
			'appModel',
			'sdocAdminModel',
			'sdocUserModel',
		],
		storage: 'localStorage',
	}
)
