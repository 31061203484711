import { Grid, IconButton } from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'

export default function OrgActions({
	id,
	setCurrentEditUserID,
	onOpenUserModal,
	onFindUserToEdit,
	setUserModalType,
	onOpenDeleteUserModal,
}) {
	return (
		<Grid className="flex flex-row justify-evenly">
			<IconButton
				onClick={() => {
					setCurrentEditUserID(id)
					setUserModalType('edit')
					onOpenUserModal()
				}}>
				<Edit />
			</IconButton>
			<IconButton
				onClick={() => {
					setCurrentEditUserID(id)
					onOpenDeleteUserModal()
				}}>
				<Delete />
			</IconButton>
		</Grid>
	)
}
