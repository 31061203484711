import { thunk, action } from 'easy-peasy'
import {  admin } from '../../../api/index'

export const sdocAdminModel = {
	adminSDOC: null,
	adminMESDOC: null,
	setAdminSDOC: action((state, adminSDOC) => {
		state.adminSDOC = adminSDOC
	}),
	setAdminMeSDOC: action((state, adminMESDOC) => {
		state.adminMESDOC = adminMESDOC;
	}),
	signInAdmin: thunk(async (actions, { username, password }) => {
		const res = await admin.signin(username, password, 2)

		if (res && res.status === 200) {
			const response = res.data
			actions.setAdminSDOC(response)
			actions.getAdminProfile()
		}
	}),
	signOutAdmin: thunk(actions => {
		actions.setAdminSDOC(null)
	}),
	getAdminProfile: thunk(async actions => {
		const res = await admin.me()
		if (res && res.status === 200) {
			actions.setAdminMeSDOC(res.data)
		}
	}),
}
