import { action, thunk } from 'easy-peasy'
import { auth, users } from '../../../api/index'

export const userModel = {
	// States
	user: null,
	userFromServicePortal: null,
	userOperatorProfile: null,
	servicePortalToken: null,
	formPermissionType: null,
	// Setters
	set: action((state, user) => {
		state.user = user
	}),
	setServicePortalToken: action((state, token) => {
		state.servicePortalToken = token
	}),
	setFormPermissionType: action((state, permission) => {
		state.formPermissionType = permission
	}),
	setUserFromServicePortal: action((state, payload) => {
		state.userFromServicePortal = payload
	}),
	setUserOperatorProfile: action((state, userOpeProfile) => {
		state.userOperatorProfile = userOpeProfile
	}),
	// Custom Function
	signin: thunk(async (actions, payloads) => {
		const res = await auth.signin(payloads)
		if (res && res.status === 200) {
			actions.set(res.data)
			return res.data;
		}
	}),
	signinQosWithRegisterCompany: thunk(async (actions, payloads) => {
		await auth.signinQosWithRegisterCompany(payloads).then(res => {
			if (res && res.status === 200) {
				actions.set(res.data)
				return res.data;
			} else {
				console.error("signinQosWithRegisterCompany error")
			}
		});

	}),


	identify: thunk(async (actions, payloads) => {
		const { _token } = payloads;
		const res = await auth.identify(_token)
		if (res && res.status === 200) {

			actions.setUserFromServicePortal(res.data)
			return res.data;
		}
	}),


	getUserOperatorProfile: thunk(async actions => {
		const res = await users.getMyProfile()
		if (res) {
			actions.setUserOperatorProfile(res.data)
		}
	}),
	registration: thunk(
		async (
			actions,
			payload
		) => {
			const { citizenID,
				phoneNumber,
				email,
				firstname,
				lastname,
				companyID } = payload;
			const res = await auth.register(
				citizenID,
				phoneNumber,
				email,
				firstname,
				lastname,
				companyID
			)
			return res
		}
	),
}
