/* eslint-disable no-unused-vars */
import { frequency } from './frequency'

const SPACE_NAME_WHITELIST = [
	'wireless microphone',
	'cordless telephone',
	'on-site paging (Pocket Unit)',
	'Fix link',
	'เครื่องส่งสัญญาณภาพ หรือเครื่องส่งสัญญาณภาพและเสียง',
	'เครื่องส่งสัญญาณเสียง',
]

const spaceNameField = {
	'wireless microphone': 'wireless_microphone',
	'cordless telephone': 'cordless_telephone',
	'on-site paging (Pocket Unit)': 'on_site_paging',
	'Fix link': 'fix_link',
	'เครื่องส่งสัญญาณภาพ หรือเครื่องส่งสัญญาณภาพและเสียง': 'image_generator',
	เครื่องส่งสัญญาณเสียง: 'sound_generator',
}

export const type_equipment = frequency.reduce((total, val) => {
	val.policy.forEach(p => {
		if (total[p.tech_type] === undefined) {
			total[p.tech_type] = []
		}

		if (!total[p.tech_type].includes(val.name)) {
			total[p.tech_type].push(val.name)
		}
	})

	return total
}, {})
