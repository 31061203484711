import { forwardRef, useRef,useState } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	IconButton,
	Button,
	useMediaQuery,
	FormControl,
	Select,
	MenuItem
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export default function ConfirmSendNotifyModal({
	open,
	onClose,
	title,
	header_icon,
	content,
	handleCancel,
	handleButton,
	listSignature,
	selectSignature,
	isSetToProcess
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)
	const [selected, setSelected] = useState('');
	const handleChange = (event) => {
		setSelected(event.target.value);
		selectSignature(event.target.value);
	  };

	return (
		<Dialog
			className="w-full"
			open={open}
			onClose={handleCancel}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						{header_icon}
						<Grid className="text-white">{title}</Grid>
					</Grid>
					<IconButton onClick={handleCancel} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					ref={descriptionElementRef}>
						{!isSetToProcess && (<Grid className="w-full text-center py-10">
					<FormControl  className="mt-1 w-full ">
								<Select
									value={selected}
									displayEmpty
									onChange={handleChange}
									inputProps={{
										name: 'signature',
										id: 'select-signature',
									}}>
									<MenuItem value="">-- กรุณาเลือกผู้มีอำนาจลงนาม --</MenuItem>
									{listSignature.map(e=>(<MenuItem  value={e.id}>{e.firstname ?? ''} {e.lastname ?? ''}</MenuItem>))}
									
								</Select>
							</FormControl>
					</Grid>)}

					{isSetToProcess && (<Grid className="w-full py-10 text-center">{content}</Grid>)};

						

					
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					className="w-8/12 mx-auto flex flex-row justify-between items-center"
					component="div">
					<Button
						className="w-5/12"
						onClick={handleCancel}
						color="secondary"
						variant="outlined">
						ยกเลิก
					</Button>
					<Button
						className="w-5/12"
						onClick={handleButton}
						color="primary"
						variant="contained">
						ตกลง
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
