import { useMemo, useState, useRef } from 'react'
import {
	Card,
	Grid,
	Divider,
	FormControl,
	Select,
	MenuItem,
	InputBase,
	IconButton,
} from '@material-ui/core'
import { Settings, Close } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'
import { months } from '../../../../node_modules/moment/moment'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => {
	return {
		disabled_text_field: {
			color: '#000',
		},
	}
})

const ErrorPower = () => {
	return (<div>***ข้อมูลกำลังคลื่นความถี่ หรือกำลังส่งไม่ถูกต้อง โปรดกรอกกำลังส่งหรือหน่วยให้ถูกต้อง โดยท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่ <a target={`_blank`} href="https://standard1.nbtc.go.th/getattachment/บริการออนไลน์/คัดสำเนาออนไลน์-(1)/SDoC-Online/frequcysdoc.pdf.aspx?lang=th-TH">https://standard1.nbtc.go.th/getattachment/บริการออนไลน์/คัดสำเนาออนไลน์-(1)/SDoC-Online/frequcysdoc.pdf.aspx?lang=th-TH</a>
		หรือสามารถสอบถามข้อมูลเพิ่มเติมได้ที่ email : SDoC@nbtc.go.th</div>)
}



export default function EquipmentCard({
	mountType,
	inputEquipmentState = {
		ProductTypeText: '',
		FrequencyText: '',
		PowerText: '',
		PolicyText: '',
		SelectedPowerUnit: '',
		PolicyText_2: '',
		PolicyText_3: '',
		Flag: '',
	},
	onChangeInputEquipmentState,
	seq,
	equipmentMasterData = {
		typeEquipment: [],
		frequency: [],
		powerUnit: [],
	},
	onDelete,
	index,
	onError = () => { },
}) {
	const classes = useStyles()

	const inputRef = useRef(null);

	const [isError, setIsError] = useState(false)



	const possibleFreq = useMemo(() => {
		const availableFreq = Object.keys(equipmentMasterData.typeEquipment).find(
			x => x.toLowerCase() === inputEquipmentState.ProductTypeText.toLowerCase()
		)

		return equipmentMasterData.typeEquipment[availableFreq] ?? []
	}, [equipmentMasterData, inputEquipmentState])

	const possibleAnnoucement = useMemo(() => {
		if (
			inputEquipmentState.FrequencyText.length === 0 ||
			inputEquipmentState.ProductTypeText.length === 0
		) {

			return []
		}

		const findedMasterItem = equipmentMasterData.frequency.find(f => {
			const isMatchedName =
				f.name.toLowerCase() === inputEquipmentState.FrequencyText.toLowerCase()

			const isMatchedTechType = f.policy.find(
				pf =>
					pf.tech_type.toLowerCase() ===
					inputEquipmentState.ProductTypeText.toLowerCase()
			)

			return isMatchedName && isMatchedTechType
		})

		if (findedMasterItem) {
			const findedPolicy = findedMasterItem.policy.find(
				pf2 =>
					pf2.tech_type.toLowerCase() ===
					inputEquipmentState.ProductTypeText.toLowerCase()
			)

			return findedPolicy.title
		}

		return []
	}, [inputEquipmentState, equipmentMasterData])

	const possibleAnnoucementFlag = useMemo(() => {
		if (
			inputEquipmentState.FrequencyText.length === 0 ||
			inputEquipmentState.ProductTypeText.length === 0
		) {

			return []
		}

		const findedMasterItem = equipmentMasterData.frequency.find(f => {
			const isMatchedName =
				f.name.toLowerCase() === inputEquipmentState.FrequencyText.toLowerCase()

			const isMatchedTechType = f.policy.find(
				pf =>
					pf.tech_type.toLowerCase() ===
					inputEquipmentState.ProductTypeText.toLowerCase()
			)

			return isMatchedName && isMatchedTechType
		})

		if (findedMasterItem) {
			const findedPolicy = findedMasterItem.policy.find(
				pf2 =>
					pf2.tech_type.toLowerCase() ===
					inputEquipmentState.ProductTypeText.toLowerCase()
			)

			return findedPolicy.flag
		}

		return []
	}, [inputEquipmentState, equipmentMasterData])

	const filterFrequency = (e, filterFrequencyIndex) => {
		onChangeInputEquipmentState('FrequencyText', '', filterFrequencyIndex)
		onChangeInputEquipmentState('PowerText', '', filterFrequencyIndex)
	}

	const filterFrequency2 = (e, filterFrequencyIndex) => {
		onChangeInputEquipmentState('PowerText', '', filterFrequencyIndex)
	}


	const isValidPowerText = useMemo(() => {
		const checking_whitelist = [
			'FrequencyText',
			'ProductTypeText',
			'PowerText',
			'SelectedPowerUnit',
		]
		if (checking_whitelist.some(cw => inputEquipmentState[cw].length === 0)) {
			return true
		}

		const finded = equipmentMasterData.frequency.find(
			item =>
				item.name.toLowerCase() ===
				inputEquipmentState.FrequencyText.toLowerCase()
		)

		if (finded) {
			const findedPolicy = finded.policy.find(
				p =>
					p.tech_type.toLowerCase() ===
					inputEquipmentState.ProductTypeText.toLowerCase()
			)
			// console.log('findedPolicy',findedPolicy.power,inputEquipmentState.PowerText,inputEquipmentState.SelectedPowerUnit.toLowerCase())
			// console.log('findedPolicy', findedPolicy)
			if (findedPolicy) {
				const findedPower = findedPolicy.power.find(
					item =>
						parseFloat(item.value) >= parseFloat(inputEquipmentState.PowerText) &&
						item.unit.toLowerCase() ===
						inputEquipmentState.SelectedPowerUnit.toLowerCase()
						&& parseFloat(inputEquipmentState.PowerText) >= 0
				)

				// onError(index, findedPower !== undefined || findedPower !== null)

				return findedPower !== undefined
			}
		}

		return true
	}, [equipmentMasterData, inputEquipmentState])

	useEffect(() => {
		// console.log(inputEquipmentState.isError);
		setIsError(inputEquipmentState.isError)
	}, [inputEquipmentState.isError])


	// const handleChange = (event) => {
	// 	setSelected(event.target.value === props.value);
	// };

	return (
		<Card className={`border-t-4 border-card_top_border mb-5`}>
			<Grid className="flex flex-col justify-center items-start px-5 pb-3">
				{/* Header */}
				<Grid className="flex flex-row justify-between items-center w-full">
					<Grid className="flex flex-row mt-4 mb-2">
						<Settings style={{ fontSize: 22 }} className="mr-1" />
						<Grid className="font-bold text-sm text-card_header_color">
							อุปกรณ์ย่านความถี่ {seq}
						</Grid>
					</Grid>
					{(!mountType || mountType === "ModifyInfo") && (
						<IconButton color="primary" onClick={onDelete}>
							<Close />
						</IconButton>
					)}
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>
				<Grid className="flex flex-row gap-x-2 w-full justify-center">
					{/* Type of Equipment */}
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>ประเภทมาตรฐานเทคโนโลยี</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<FormControl variant="outlined" fullWidth size="small">
							<Select
								value={inputEquipmentState.ProductTypeText}
								name="ProductTypeText"
								disabled={(mountType && mountType !== "ModifyInfo")}
								onChange={e => {
									onChangeInputEquipmentState(
										e.target.name,
										e.target.value,
										index
									)
									filterFrequency(e, index)
								}}
								className={`${(mountType && mountType !== "ModifyInfo") ? classes.disabled_text_field : ''}`}>
								<MenuItem key={'defaultValueTypeEquipment'} value="" />
								{Object.keys(equipmentMasterData.typeEquipment).map(
									(item, index) => {
										return (
											<MenuItem key={index} value={item}>
												{item}
											</MenuItem>
										)
									}
								)}
							</Select>
						</FormControl>
					</Grid>
				</Grid>

				{/* Frequency and Transmitted Power */}
				<Grid className="flex flex-row gap-x-2 w-full justify-center">
					{/* Frequency */}
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>คลื่นความถี่</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<FormControl variant="outlined" fullWidth size="small">
							{(!mountType || mountType == "ModifyInfo") ? (
								<Select
									disabled={(mountType && mountType !== "ModifyInfo")}
									name="FrequencyText"
									value={inputEquipmentState.FrequencyText}
									onChange={e => {
										onChangeInputEquipmentState(
											e.target.name,
											e.target.value,
											index
										)
										filterFrequency2(e, index)
									}
									}
									className={`${(mountType && mountType !== "ModifyInfo") ? classes.disabled_text_field : ''}`}>
									<MenuItem key={'defaultValueFrequencyText'} value={''} />

									{possibleFreq.map((item, index) => {
										return (
											<MenuItem key={index} value={item}>
												{item}
											</MenuItem>
										)
									})}
								</Select>
							) : (
								<Grid
									className={`text-xs flex justify-start items-center h-10 rounded w-full border border-gray-300`}>
									<InputBase
										disabled={(mountType && mountType !== "ModifyInfo")}
										name="FrequencyText"
										value={inputEquipmentState.FrequencyText}
										readonly
										className={`${(mountType && mountType !== "ModifyInfo")
									? classes.disabled_text_field
									: ''
									} w-full h-full px-2`}
									/>
								</Grid>

							)}
						</FormControl>
					</Grid>
					{/* Transmitted Power */}
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>กำลังส่ง</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<Grid
							className={`text-xs flex justify-start items-center h-10 rounded w-full border border-gray-300`}>
							<InputBase
								disabled={inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")}
								name="PowerText"
								type="text"
								placeholder="ตัวเลขเท่านั้น"
								value={inputEquipmentState.PowerText}
								onChange={e => {
									e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/[e-]/ig, '');
									onChangeInputEquipmentState(
										e.target.name,
										e.target.value,
										index
									)
									possibleAnnoucement.map((item, indexs) => {
										onChangeInputEquipmentState(
											indexs > 0 ? "PolicyText_" + (indexs + 1) : 'PolicyText',
											item,
											index
										)
									})
									onChangeInputEquipmentState(
										"Flag",
										possibleAnnoucementFlag,
										index
									)
								}}
								onBlur={e => {
									onChangeInputEquipmentState(
										e.target.name,
										e.target.value,
										index
									)
									possibleAnnoucement.map((item, indexs) => {
										onChangeInputEquipmentState(
											indexs > 0 ? "PolicyText_" + (indexs + 1) : 'PolicyText',
											item,
											index
										)
									})
									onChangeInputEquipmentState(
										"Flag",
										possibleAnnoucementFlag,
										index
									)
								}}
								className={`${inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")
									? classes.disabled_text_field
									: ''
									} w-full h-full px-2`}
							/>
						</Grid>
					</Grid>
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>หน่วย</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<FormControl variant="outlined" fullWidth size="small">
							<Select
								className={`${inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")
									? classes.disabled_text_field
									: ''
									}`}
								disabled={inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")}
								name="SelectedPowerUnit"
								value={inputEquipmentState.SelectedPowerUnit}
								onChange={e => {
									onChangeInputEquipmentState(
										e.target.name,
										e.target.value,
										index
									)
									possibleAnnoucement.map((item, indexs) => {
										onChangeInputEquipmentState(
											indexs > 0 ? "PolicyText_" + (indexs + 1) : 'PolicyText',
											item,
											index
										)
									})
									onChangeInputEquipmentState(
										"Flag",
										possibleAnnoucementFlag,
										index
									)
								}}
								onBlur={e => {
									onChangeInputEquipmentState(
										e.target.name,
										e.target.value,
										index
									)
									possibleAnnoucement.map((item, indexs) => {
										onChangeInputEquipmentState(
											indexs > 0 ? "PolicyText_" + (indexs + 1) : 'PolicyText',
											item,
											index
										)
									})
									onChangeInputEquipmentState(
										"Flag",
										possibleAnnoucementFlag,
										index
									)
								}}
							>
								<MenuItem key={'defaultValueSelectedPowerUnit'} value={''} />
								{equipmentMasterData.powerUnit.map((item, index) => {
									return (
										<MenuItem key={item + index} value={item}>
											{item}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				{/* Technical Standard and Related Annoucement */}
				<Grid className="flex flex-row gap-x-2 w-full justify-center">
					{/* Related Annoucement */}
					<Grid className={`flex flex-col items-start my-2 w-full gap-y-3`}>
						<Grid className="flex flex-row">
							<Grid>ประกาศที่เกี่ยวข้อง</Grid>
							<Grid className="text-red-500">*</Grid>
						</Grid>
						<FormControl variant="outlined" fullWidth size="small">
							{mountType === 'readOnly' ? (
								// <InputBase
								// 	className={`text-xs flex justify-start items-center rounded h-10  w-full border border-gray-300 px-2 ${mountType ? classes.disabled_text_field : ''
								// 		}`}
								// 	name="PolicyText"
								// 	value={inputEquipmentState.PolicyText}
								// 	disabled={mountType}
								// />
								<Grid>
									{inputEquipmentState.PolicyText ? <Grid>- {inputEquipmentState.PolicyText}</Grid> : ''}
									{inputEquipmentState.PolicyText_2 ? <Grid>- {inputEquipmentState.PolicyText_2}</Grid> : ''}
									{inputEquipmentState.PolicyText_3 ? <Grid>- {inputEquipmentState.PolicyText_3}</Grid> : ''}
								</Grid>
							) : (
								possibleAnnoucement.map((item, indexs) => {
									return (
										<Grid>
											- {item}
										</Grid>
									)
								})
							)}
						</FormControl>
						{/* <FormControl variant="outlined" fullWidth size="small">
							{mountType === 'readOnly' ? (<InputBase
								className={`text-xs flex justify-start items-center rounded h-10  w-full border border-gray-300 px-2 ${mountType ? classes.disabled_text_field : ''
									}`}
								name="PolicyText_2"
								value={inputEquipmentState.PolicyText_2}
								disabled={mountType}
							/>) : (
								<Select
									disabled={inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")}
									name="PolicyText_2"
									value={inputEquipmentState.PolicyText_2}
									onChange={e => {
										onChangeInputEquipmentState(
											e.target.name,
											e.target.value,
											index
										)
									}}
									className={`${inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")
										? classes.disabled_text_field
										: ''
										}`}>
									<MenuItem value={''}>
										โปรดระบุลักษณะทางเทคนิคที่แสดงความสอดคล้อง
									</MenuItem>
									{possibleAnnoucement.map((item, index) => {
										return (
											<MenuItem key={item + index} value={item}>
												{item}
											</MenuItem>
										)
									})}
								</Select>
							)}
						</FormControl>
						<FormControl variant="outlined" fullWidth size="small">
							{mountType === 'readOnly' ? (<InputBase
								className={`text-xs flex justify-start items-center rounded h-10  w-full border border-gray-300 px-2 ${mountType ? classes.disabled_text_field : ''
									}`}
								name="PolicyText_3"
								value={inputEquipmentState.PolicyText_3}
								disabled={mountType}
							/>) : (
								<Select
									disabled={inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")}
									name="PolicyText_3"
									value={inputEquipmentState.PolicyText_3}
									onChange={e => {
										onChangeInputEquipmentState(
											e.target.name,
											e.target.value,
											index
										)
									}}
									className={`${inputEquipmentState.FrequencyText === '' || (mountType && mountType !== "ModifyInfo")
										? classes.disabled_text_field
										: ''
										}`}>
									<MenuItem value={''}>
										โปรดระบุลักษณะทางเทคนิคที่แสดงความสอดคล้อง
									</MenuItem>
									{possibleAnnoucement.map((item, index) => {
										return (
											<MenuItem key={item + index} value={item}>
												{item}
											</MenuItem>
										)
									})}
								</Select>
							)}
						</FormControl> */}
					</Grid>
				</Grid>
				<Grid className=" text-red-500 text-lg">
					{!(mountType && mountType !== "ModifyInfo") &&
						!isValidPowerText ? (inputEquipmentState.isError = true) : (inputEquipmentState.isError = false)}
					{!isValidPowerText && (<ErrorPower />)}
				</Grid>
			</Grid>
		</Card>
	)
}
