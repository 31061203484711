import { forwardRef, useRef } from 'react'
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
	IconButton,
	Divider,
	TextField,
	Button,
	useMediaQuery,
} from '@material-ui/core'
import { Close, BusinessCenter } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

export default function EditInfoModal({
	open,
	onClose,
	email,
	telephone,
	handleButton,
	onValueChange,
	validSubmitButton,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	return (
		<Dialog
			className="w-full"
			open={open}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle style={{ backgroundColor: '#a74345' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center" component="div">
						<BusinessCenter
							style={{ fontSize: 20 }}
							className="mr-1 h-full text-white"
						/>
						<Grid className="text-white">แก้ไขข้อมูลผู้ประกอบการ</Grid>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText
					className="h-full"
					tabIndex={-1}
					ref={descriptionElementRef}>
					{/* Email */}
					<Grid className="my-3" component="div">
						<Grid>อีเมลที่ใช้ติดต่อ</Grid>
					</Grid>
					<Grid className="my-3" component="div">
						<Divider />
					</Grid>
					<Grid className="flex flex-row mt-5 gap-x-5 w-full">
						<Grid className="w-full">
							<Grid className="text-sm lg:text-base">อีเมลที่ใช้ติดต่อ</Grid>
							<TextField
								size="small"
								variant="outlined"
								value={email}
								onChange={e => {
									onValueChange(e.target.value, 'email')
								}}
								className="w-1/3"
							/>
						</Grid>
					</Grid>
					{/* Phone Number */}
					<Grid className="my-3" component="div">
						<Grid>เบอร์โทรศัพท์ที่ใช้ติดต่อ</Grid>
					</Grid>
					<Grid className="my-3" component="div">
						<Divider />
					</Grid>
					<Grid className="flex flex-row mt-5 gap-x-5 w-full">
						<Grid className="w-full">
							<Grid className="text-sm lg:text-base">
								เบอร์โทรศัพท์ที่ใช้ติดต่อ
							</Grid>
							<TextField
								size="small"
								variant="outlined"
								value={telephone}
								onChange={e => {
									onValueChange(e.target.value, 'telephone')
								}}
								// defaultValue={usePhoneNumberConverter('0123456789')}
								className="w-1/3"
							/>
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					component="div">
					<Grid item className="p-3 w-3/12" component="div">
						<Button
							fullWidth
							onClick={handleButton}
							disabled={validSubmitButton}
							color="secondary"
							variant="contained">
							บันทึก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
