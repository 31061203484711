import { Grid, Input, Button } from '@material-ui/core'

export default function SubLabelType1({
	type,
	onFillForm,
	datasetId,
	answers,
	onOpenValidationModal,
}) {
	const answer = answers.find(item => item.dataSetItemID === datasetId)

	return (
		<>
			<Grid className="col-span-12 text-center border flex flex-row justify-around">
				{/* Months */}
				<Input
					className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
					value={answer?.dataValueMonth1}
					onChange={e => {
						onFillForm(type, datasetId, 'dataValueMonth1', e.target.value)
					}}
					readOnly
					type="text"
				/>
				<Input
					className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
					value={answer?.dataValueMonth2}
					onChange={e => {
						onFillForm(type, datasetId, 'dataValueMonth2', e.target.value)
					}}
					readOnly
					type="text"
				/>
				<Input
					className="border-r border-gray-500 w-2/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
					value={answer?.dataValueMonth3}
					onChange={e => {
						onFillForm(type, datasetId, 'dataValueMonth3', e.target.value)
					}}
					readOnly
					type="text"
				/>
				{/* Average */}
				<Input
					className="border-r border-gray-500 w-3/12 p-1 font-bold text-center bg-green_input_bg pointer-events-none"
					value={answer?.dataValueMonthAvg}
					onChange={e => {
						onFillForm(type, datasetId, 'dataValueMonthAvg', e.target.value)
					}}
					readOnly
					type="text"
				/>
				{/* Validation */}
				<Grid className="border-r border-b border-gray-500 w-3/12 p-1 font-bold text-center flex justify-center items-center self-center h-full">
				{((!answer?.dataValueMonth1 || !answer?.dataValueMonth2 || !answer?.dataValueMonth3) && !answer?.isError) ? null : (answer?.isError ==='y' ? (
						<Button
							variant="contained"
							color="secondary"
							onClick={() =>
								onOpenValidationModal(
									datasetId,
									answer.reason,
									answer.solution,
									answer.periodSolution,
									answer?.validateFileUrl
								)
							}>
							ไม่ผ่านเกณฑ์
						</Button>
					) : (
						<Grid className="text-green-500 font-bold text-center ">
							ผ่านเกณฑ์
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	)
}
