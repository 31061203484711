import { fixed_general_details } from './fixed-general'
import { fixed_info_details } from './fixed-info'
import { fixed_sound_details } from './fixed-sound'
import { mobile_general_details } from './mobile-general'
import { mobile_info_details } from './mobile-info'
import { mobile_sound_details } from './mobile-sound'

const REPORT = [
	...fixed_general_details,
	...fixed_info_details,
	...fixed_sound_details,
	...mobile_general_details,
	...mobile_info_details,
	...mobile_sound_details,
]

export function findReportQuestion(datasetId) {
	return REPORT.find(r => r.id === datasetId)
}
