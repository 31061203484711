import { Grid, Input } from '@material-ui/core'
export default function SubLabelType3({ type, item, onFillForm, answers }) {
	const label_name = item.label_name
	const childs = item.childs

	return (
		<Grid className="col-span-12  text-center border flex flex-row ">
			{/* Region */}
			<Grid className="w-1/12 text-center flex justify-center items-center border-r font-bold">
				<Grid>{label_name}</Grid>
			</Grid>

			<Grid className="w-3/12  flex flex-col border-r">
				{childs.map((child, index) => (
					<Grid
						key={item.label_name + child + index}
						className="p-1 border-b h-full font-bold">
						{child.label}
					</Grid>
				))}
			</Grid>

			{/* Months */}
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.DataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index + 'input-month1'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg"
							value={answer?.DataValueMonth1}
							onChange={e =>
								onFillForm(type, child.id, 'DataValueMonth1', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.DataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index + 'input-month2'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg"
							value={answer?.DataValueMonth2}
							onChange={e =>
								onFillForm(type, child.id, 'DataValueMonth2', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.DataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index + 'input-month3'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg"
							value={answer?.DataValueMonth3}
							onChange={e =>
								onFillForm(type, child.id, 'DataValueMonth3', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>

			{/* Average */}
			<Grid className="w-2/12 flex flex-col">
				{childs.map((child, index) => {
					const answer = answers.find(ans => ans.DataSetItemID === child.id)

					return (
						<Input
							key={item.label_name + index.toString() + 'input_avg'}
							className="border-r border-gray-500 p-1 font-bold text-center bg-green_input_bg"
							value={answer?.DataValueMonthAvg}
							onChange={e =>
								onFillForm(type, child.id, 'DataValueMonthAvg', e.target.value)
							}
							type="text"
						/>
					)
				})}
			</Grid>
		</Grid>
	)
}
