// Page Descrition: Admin's user management page
import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core/'

// import SearchBox from '../../components/organization-user/search-box'
import OrgSearchResult from 'components/admin/admin-user/search-result/index'
import OrganizationUserModal from 'components/admin/admin-user/user-modal/index'
import { DeleteUserModal } from 'components/admin/admin-user/delete-user-modal'
import NotFoundModal from 'components/admin/admin-user/not-found-modal'
import AddingSuccessModal from 'components/admin/admin-user/adding-success-modal'
import AddingFailedModal from 'components/admin/admin-user/adding-failed-modal'
import EditingSuccessModal from 'components/admin/admin-user/editing-success-modal'
import EditingFailedModal from 'components/admin/admin-user/editing-failed-modal'
import DeletedSuccessModal from 'components/admin/admin-user/deleted-success-modal'
import DeletedFailedModal from 'components/admin/admin-user/deleted-failed-modal'

import { useStoreRehydrated } from 'store'
// import { format } from 'date-fns'
import { useLoaded } from 'utils/useLoaded'
import { admin } from 'api/'

// import { users as Users } from 'data/registration/users'
import { org_status } from 'data/organization-user/organization-user-status'

export default function AdminUser() {
	const loaded = useLoaded()
	const isRehydrated = useStoreRehydrated()
	// Main Users Data
	const [user, setUser] = useState([])
	const [userData, setUserData] = useState([])
	// Modal State
	const [currentEditUserID, setCurrentEditUserID] = useState(null)
	const [openUserInfoModal, setOpenUserInfoModal] = useState(false)

	const [openUserModal, setOpenUserModal] = useState(false)
	const [userModalType, setUserModalType] = useState('add')
	const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
	const [openAddingSuccessModal, setOpenAddingSuccessModal] = useState(false)
	const [openAddingFailedModal, setOpenAddingFailedModal] = useState(false)
	const [openNotFoundModal, setOpenNotFoundModal] = useState(false)
	const [openEditingSuccessModal, setOpenEditingSuccessModal] = useState(false)
	const [openEditingFailedModal, setOpenEditingFailedModal] = useState(false)
	const [openDeletedSuccessModal, setOpenDeletedSuccessModal] = useState(false)
	const [openDeletedFailedModal, setOpenDeletedFailedModal] = useState(false)
	// Adding User State
	const [nationalIDCardNo, setNationalIDCardNo] = useState('')
	const [userInfo, setUserInfo] = useState({
		adminRoleID: 1,
		email: '',
		firstname: '',
		lastname: '',
		adminStatusID: 1,
	})
	const [activeDate, setActiveDate] = useState(null)
	const [expireDate, setExpireDate] = useState(null)
	const [fixedTypePermission, setFixedTypePermission] = useState(false)
	const [mobileTypePermission, setMobileTypePermission] = useState(false)
	// const [organizationData, setOrganizationData] = useState(null)
	// Editing User State
	const [editNationalIDCardNo, setEditNationalIDCardNo] = useState('')
	const [editUserInfo, setEditUserInfo] = useState({
		adminRoleID: 1,
		email: '',
		firstname: '',
		lastname: '',
		adminStatusID: 1,
	})
	const [editActiveDate, setEditActiveDate] = useState(null)
	const [editExpireDate, setEditExpireDate] = useState(null)
	const [editFixedTypePermission, setEditFixedTypePermission] = useState(false)
	const [editMobileTypePermission, setEditMobileTypePermission] =
		useState(false)
	// Save Button Logic
	const [saveButtonAdd, setSaveButtonAdd] = useState(true)
	const [saveButtonEdit, setSaveButtonEdit] = useState(true)

	// Admin Roles
	const [adminRoles, setAdminRoles] = useState([])

	/** -----------------------------------------------------------> */

	// Main Function

	const onOpenUserInfoModal = () => {
		setOpenUserInfoModal(!openUserInfoModal)
	}
	const onOpenUserModal = () => {
		setOpenUserModal(!openUserModal)
	}

	const onOpenDeleteUserModal = () => {
		setOpenDeleteUserModal(!openDeleteUserModal)
	}

	const onOpenNotFoundModal = () => {
		setOpenNotFoundModal(!openNotFoundModal)
	}

	const onOpenAddingSuccessModal = () => {
		setOpenAddingSuccessModal(!openAddingSuccessModal)
	}

	const onOpenAddingFailedModal = () => {
		setOpenAddingFailedModal(!openAddingFailedModal)
	}

	const onOpenEditingSuccessModal = () => {
		setOpenEditingSuccessModal(!openEditingSuccessModal)
	}

	const onOpenEditingFailedModal = () => {
		setOpenEditingFailedModal(!openEditingFailedModal)
	}

	const onOpenDeletedSuccessModal = () => {
		setOpenDeletedSuccessModal(!openDeletedSuccessModal)
	}

	const onOpenDeletedFailedModal = () => {
		setOpenDeletedFailedModal(!openDeletedFailedModal)
	}

	// const onSearhResult = e => {
	// 	const { value } = e.target
	// 	const searchedName = userData.filter(user => {
	// 		const name = user.firstname + ' ' + user.lastname
	// 		return name.includes(value)
	// 	})
	// 	const searchedCitizenID = userData.filter(user =>
	// 		user.nationalIDCardNo.includes(value)
	// 	)

	// 	const mergedSearched = [...searchedName, ...searchedCitizenID]
	// 	const searchedResults = []
	// 	let searchID = 1
	// 	mergedSearched.forEach(i => {
	// 		const find = searchedResults.find(item => item.id === i.id)
	// 		if (!find)
	// 			searchedResults.push({
	// 				id: searchID++,
	// 				userId: i.id ?? '',
	// 				name: i.firstname + ' ' + i.lastname,
	// 				email: i.email ?? '',
	// 				nationalIDCardNo: i.nationalIDCardNo ?? '',
	// 				mobile: i.mobile ?? '',
	// 				userRoleID: i.userRoleID ?? '',
	// 				status: parseInt(i.userStatus.id) ?? '',
	// 			})
	// 	})

	// 	setUser(searchedResults)
	// }
	const getAdminRoles = async () => {
		const resRoles = await admin.getAdminRoles()
		if (resRoles) {
			if (resRoles.status === 200) {
				const fetchedAdminRoles = resRoles.data
				let tempAdminRoles = []
				fetchedAdminRoles.forEach((item, index) => {
					if (item.isActive === '1') {
						tempAdminRoles.push({
							name: item.adminRoleName,
							value: item.id,
						})
					}
				})
				setAdminRoles(tempAdminRoles)
				return tempAdminRoles
			}
		}
	}
	const onLanding = async () => {
		getAdminRoles()
		const res = await admin.getAdmins()
		if (res) {
			if (res.status === 200) {
				const fetchedAdmin = res.data
				const preprocessedArr = fetchedAdmin.map((item, index) => {
					return {
						id: index + 1,
						adminId: item.id ?? '',
						name: item.firstname + ' ' + item.lastname,
						// email: item.email ?? '',
						// nationalIDCardNo: item.nationalIDCardNo ?? '',
						// mobile: item.mobile ?? '',
						adminRoleID: item.adminRoleID ?? '',
						// periodActivate: `${
						// 	item.activeDate
						// 		? format(new Date(item.activeDate), 'dd/MM/yyyy')
						// 		: ''
						// }  ${
						// 	item.expireDate
						// 		? '- ' + format(new Date(item.expireDate), 'dd/MM/yyyy')
						// 		: ''
						// } `,
						status: parseInt(item.adminStatusID) ?? '',
					}
				})

				setUserData(fetchedAdmin)
				setUser(preprocessedArr)
			}
		}
	}

	/** <----------------------------------------------------------- */

	// Adding User

	const onFillNationalCardIDNo = e => {
		var pattern = /^[0-9\b]+$/
		const { value } = e.target
		if (pattern.test(value) || value === '') {
			setNationalIDCardNo(value)
			setSaveButtonAdd(true)
		}
	}

	const onCheckFormTypePermission = (type, checked) => {
		if (type === 1) setFixedTypePermission(checked)
		else if (type === 2) setMobileTypePermission(checked)
	}

	const onChangeUserRoleID = e => {
		const { value } = e.target
		setUserInfo({ ...userInfo, adminRoleID: value })
	}

	const onSelectActiveDate = val => setActiveDate(val)

	const onSelectExpireDate = val => setExpireDate(val)

	const onAddUser = async () => {
		const res = await admin.addAdmin(
			userInfo.adminRoleID,
			userInfo.email,
			userInfo.firstname,
			userInfo.lastname,
			userInfo.adminStatusID
		)
		if (res) {
			if (res.status !== 400) {
				onOpenAddingSuccessModal()
				setSaveButtonAdd(true)
				setUserInfo({
					adminRoleID: userInfo.adminRoleID,
					email: '',
					firstname: '',
					lastname: '',
					adminStatusID: userInfo.adminStatusID,
				})
			} else onOpenAddingFailedModal()
		}
	}

	// Editing User

	const onFindUserToEdit = () => {
		const finded = userData.find(item => item.id === currentEditUserID)
		if (finded) {
			setEditUserInfo({
				adminRoleID: finded.adminRoleID,
				firstname: finded.firstname,
				lastname: finded.lastname,
				email: finded.email,
				adminStatusID: finded.adminStatusID,
			})
			// setEditNationalIDCardNo(finded.nationalIDCardNo)

			// finded.operatorUserQOSTypes.forEach(item => {
			// 	if (item.qosTypeID === 1) setEditMobileTypePermission(true)
			// 	if (item.qosTypeID === 2) setEditFixedTypePermission(true)
			// })
			if (finded.activeDate) setEditActiveDate(new Date(finded.activeDate))
			else setEditActiveDate(null)
			if (finded.expireDate) setEditExpireDate(new Date(finded.expireDate))
			else setEditExpireDate(null)
		}
	}

	const onFillEditNationalCardIDNo = e => {
		var pattern = /^[0-9\b]+$/
		const { value } = e.target
		if (pattern.test(value) || value === '') {
			setEditNationalIDCardNo(value)
		}
	}

	const onFillAddUserInfo = e => {
		const { name, value } = e.target
		setUserInfo({ ...userInfo, [name]: value })
	}

	const onFillEditUserInfo = e => {
		const { name, value } = e.target
		setEditUserInfo({ ...editUserInfo, [name]: value })
	}

	const onChangeEditUserRoleID = e => {
		const { value } = e.target
		setEditUserInfo({ ...editUserInfo, adminRoleID: value })
	}

	const onChangeEditUserStatus = e => {
		const { value } = e.target
		const findedStatus = Object.keys(org_status).find(
			(item, index) => item === value
		)

		if (findedStatus)
			setEditUserInfo({
				...editUserInfo,
				userStatus: {
					id: findedStatus,
					name: org_status[findedStatus],
				},
			})
	}

	const onCheckEditFormTypePermission = (type, checked) => {
		if (type === 1) setEditMobileTypePermission(checked)
		else if (type === 2) setEditFixedTypePermission(checked)
	}

	const onSelectEditActiveDate = val => setEditActiveDate(val)

	const onSelectEditExpireDate = val => setEditExpireDate(val)

	const onEditUser = async () => {
		const res = await admin.editAdmin(
			currentEditUserID,
			editUserInfo.adminRoleID,
			editUserInfo.email,
			editUserInfo.firstname,
			editUserInfo.lastname,
			editUserInfo.adminStatusID
		)
		if (res) {
			if (res.status !== 400) onOpenEditingSuccessModal()
			else onOpenEditingFailedModal()
		}
	}

	// Delete User
	const deleteUserID = async () => {
		onOpenDeleteUserModal()
		const finded = userData.find(item => item.id === currentEditUserID)
		if (finded) {
			const res = await admin.deleteAdmin(currentEditUserID)
			const status = res.status
			if (status === 200) {
				onOpenDeletedSuccessModal()
			} else {
				onOpenDeletedFailedModal()
			}
		} else {
			onOpenDeletedFailedModal()
		}
	}

	// Get Admin Role from ID
	const getAdminRoleFromID = id => {
		for (let i = 0; i < adminRoles.length; i++) {
			if (adminRoles[i].value === id) return adminRoles[i].name
		}
	}

	/** -----------------------------------------------------------> */

	// Main Rendering Function

	// eslint-disable-next-line
	useEffect(() => onFindUserToEdit(), [currentEditUserID])

	useEffect(() => {
		if (isRehydrated) onLanding()
		// eslint-disable-next-line
	}, [isRehydrated])

	useEffect(() => {
		if (openUserModal)
			if (userModalType === 'add') {
				if (
					!userInfo.firstname ||
					!userInfo.lastname ||
					!userInfo.email ||
					!userInfo.adminRoleID ||
					!userInfo.adminStatusID
				) {
					setSaveButtonAdd(true)
				} else {
					setSaveButtonAdd(false)
				}
			}
	}, [openUserModal, userModalType, userInfo])

	useEffect(() => {
		if (openUserModal)
			if (userModalType === 'edit') {
				if (
					!editUserInfo.adminRoleID ||
					!editUserInfo.firstname ||
					!editUserInfo.lastname ||
					!editUserInfo.adminStatusID ||
					!editUserInfo.email
				) {
					setSaveButtonEdit(true)
				} else {
					setSaveButtonEdit(false)
				}
			}
	}, [openUserModal, userModalType, editUserInfo])

	/** <----------------------------------------------------------- */

	return (
		loaded && (
			<>
				<Grid className="min-h-screen flex flex-col px-3 lg:px-10 pb-10">
					{/* <SearchBox /> */}
					<OrgSearchResult
						user={user}
						onOpenUserModal={onOpenUserInfoModal}
						setUserModalType={setUserModalType}
						onOpenDeleteUserModal={onOpenDeleteUserModal}
						setCurrentEditUserID={setCurrentEditUserID}
						getAdminRoleFromID={getAdminRoleFromID}
					/>

					<OrganizationUserModal
						open={openUserModal}
						onClose={onOpenUserModal}
						userModalType={userModalType}
						// Adding States
						nationalCardIDNo={nationalIDCardNo}
						userInfo={userInfo}
						activeDate={activeDate}
						expireDate={expireDate}
						fixedTypePermission={fixedTypePermission}
						mobileTypePermission={mobileTypePermission}
						// Adding Handler
						onFillNationalCardIDNo={onFillNationalCardIDNo}
						onCheckFormTypePermission={onCheckFormTypePermission}
						onChangeUserRoleID={onChangeUserRoleID}
						onSelectActiveDate={onSelectActiveDate}
						onSelectExpireDate={onSelectExpireDate}
						// organizationData={organizationData}
						// Edit States
						editNationalCardIDNo={editNationalIDCardNo}
						editUserInfo={editUserInfo}
						editActiveDate={editActiveDate}
						editExpireDate={editExpireDate}
						editFixedTypePermission={editFixedTypePermission}
						editMobileTypePermission={editMobileTypePermission}
						// Editing Handler
						onFillEditNationalCardIDNo={onFillEditNationalCardIDNo}
						onCheckEditFormTypePermission={onCheckEditFormTypePermission}
						onSelectEditActiveDate={onSelectEditActiveDate}
						onSelectEditExpireDate={onSelectEditExpireDate}
						onFillAddUserInfo={onFillAddUserInfo}
						onFillEditUserInfo={onFillEditUserInfo}
						onChangeEditUserRoleID={onChangeEditUserRoleID}
						onChangeEditUserStatus={onChangeEditUserStatus}
						// Main Handler
						handleButton={userModalType === 'add' ? onAddUser : onEditUser}
						// Save Button Logic
						saveButtonAdd={saveButtonAdd}
						saveButtonEdit={saveButtonEdit}
						// Admin Roles
						adminRoles={adminRoles}
					/>
					<DeleteUserModal
						open={openDeleteUserModal}
						onClose={onOpenDeleteUserModal}
						onDelete={deleteUserID}
					/>
					<NotFoundModal
						open={openNotFoundModal}
						handleButton={onOpenNotFoundModal}
					/>
					<AddingSuccessModal
						open={openAddingSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenAddingSuccessModal()
							onOpenUserModal()
						}}
					/>
					<AddingFailedModal
						open={openAddingFailedModal}
						handleButton={onOpenAddingFailedModal}
						message="เพิ่มผู้ใช้งานไม่สำเร็จ โปรดลองอีกครั้ง"
					/>
					<EditingSuccessModal
						open={openEditingSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenEditingSuccessModal()
							onOpenUserModal()
						}}
					/>
					<EditingFailedModal
						open={openEditingFailedModal}
						handleButton={onOpenEditingFailedModal}
						message="แก้ไขข้อมูลผู้ใช้ไม่สำเร็จ โปรดลองอีกครั้ง"
					/>
					<DeletedSuccessModal
						open={openDeletedSuccessModal}
						handleButton={() => {
							onLanding()
							onOpenDeletedSuccessModal()
							// onOpenUserModal()
						}}
					/>
					<DeletedFailedModal
						open={openDeletedFailedModal}
						handleButton={onOpenDeletedFailedModal}
						message="ลบข้อมูลผู้ใช้ไม่สำเร็จ โปรดลองอีกครั้ง"
					/>
				</Grid>
			</>
		)
	)
}
