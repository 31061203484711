import { useState, useEffect } from 'react'
import {
	Grid,
	Card,
	CardContent,
	Typography,
	Button,
	Divider,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { ViewComfy, PersonAdd } from '@material-ui/icons'
import OrganizationModal from '../../../components/admin/admin-user/company-modal/index'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import { operators } from '../../../api/'
import { useStoreRehydrated } from '../../../store'

const useStyles = makeStyles(theme => ({
	card_styles: {
		height: '80vh',
	},
	table_styles: {
		marginTop: '20px',
		height: '60vh',
	},
}))

const Organization = () => {
	const isRehydrated = useStoreRehydrated()
	const [pageSize, setPageSize] = useState(8)
	// const dataTypeOfReport = ['ประจำที่', 'เคลื่อนที่']
	const classes = useStyles()
	const history = useHistory()
	const [organization, setOrganization] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	// const [organizationData, setOrganizationData] = useState([])
	const [openCRUDModal, setOpenCRUDModal] = useState(false)

	const columns = [
		{
			field: 'id',
			headerName: 'ลำดับ',
			flex: 0.8,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'operatorName',
			headerName: 'ชื่อผู้หน่วยงาน',
			flex: 1,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'typesOfReports',
			headerName: 'ประเภทรายงาน',
			flex: 1,
			headerAlign: 'left',
			align: 'left',
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-center items-center">
						{params.value.map((e, index) => (
							<Grid
								key={e + index}
								className={`rounded-full py-1 px-4 m-0.5 font-bold text-xs border ${classes.type_of_report}`}>
								{e.qosTypeName}
							</Grid>
						))}
					</Grid>
				)
			},
		},
		{
			field: 'actions',
			headerName: 'การกระทำ',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: params => (
				<>
					<Grid
						className="text-center text-blue_header cursor-pointer"
						
						onClick={() =>
							history.push({
								pathname: `/admin/organization/${params.row.id}`,
								// state: {
								// 	operatorsID: params.row.id,
								// },
							})
						}>
						ดูรายละเอียด
					</Grid>
					<Divider orientation="vertical" flexItem style={{margin:"16px"}} />
					<Grid
						
						className="text-center text-blue_header cursor-pointer"
						style={{cursor: "not-allowed", color: "rgba(0,0,0,0.26)"}}
						tiptitle="ยังไม่เปิดให้ใช้งาน"
						// onClick={() =>
						// 	history.push({
						// 		pathname: `/admin/organization/editprofile/${params.row.id}`,
						// 		// state: {
						// 		// 	operatorsID: params.row.id,
						// 		// },
						// 	})
						// }
						>
						จัดการข้อมูล
					</Grid>
				</>
			),
		},
	]

	/** ------------------------------------ */

	const onLanding = async () => {
		setIsDataLoading(true)
		const res = await operators.getOperators()
		if (res) {
			if (res.status === 200) {
				const fetchedOrg = res.data
				const preprocessedArr = fetchedOrg.map(item => {
					return {
						id: item.id ?? '',
						operatorName: item.operatorName ?? '',
						typesOfReports: item.operatorQOS,
					}
				})

				// setOrganizationData(fetchedOrg)
				setOrganization(preprocessedArr)
			}
		}
		setIsDataLoading(false)
	}

	/** ------------------------------------ */

	// Main Rendering

	useEffect(() => {
		if (isRehydrated) onLanding()
	}, [isRehydrated])

	return (
		<Grid className="flex flex-col p-10">
			<Card
				className={`${classes.card_styles} border-card_top_border border-t-4`}>
				<CardContent className="flex flex-col">
					<Grid className="flex flex-row gap-x-3">
						<ViewComfy />
						<Typography>รายชื่อผู้ประกอบการ</Typography>
					</Grid>
					<Grid className="my-3">
						<Divider />
					</Grid>
					<Grid className="flex flex-row justify-end gap-x-3 my-4">
						{/* <TextField
						className="w-full lg:w-4/12"
						size="small"
						placeholder="ค้นหาโดย ชื่อ-นามสกุล, เลขประจำตัวประชาชน"
						onChange={onSearch}
						label=""
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SearchOutlined style={{ color: 'rgba(0,0,0,0.54)' }} />
							),
						}}
					/> */}
						<Button
							variant="contained"
							color="secondary"
							size="large"
							onClick={() => setOpenCRUDModal(true)}>
							<PersonAdd className="mr-1" />
							<Typography variant="button">เพิ่ม</Typography>
						</Button>
					</Grid>
					<Grid className={classes.table_styles}>
						<DataGrid
							disableSelectionOnClick
							disableColumnMenu
							rows={organization}
							columns={columns}
							rowsPerPageOptions={[5, 8, 10, 20]}
							pageSize={pageSize}
							onPageSizeChange={size => setPageSize(size)}
							components={{
								NoRowsOverlay: () => {
									if (!isDataLoading) {
										return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
									} else {
										return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
									}
								}
							}}
						/>
					</Grid>
				</CardContent>
			</Card>

			<OrganizationModal
				open={openCRUDModal}
				onClose={e => setOpenCRUDModal(false)}
				userModalType={`add`}
			// Adding States

			// Save Button Logic
			/>
		</Grid>
	)
}

export default Organization
