import API, { adminHeader } from '../config/axios'

const operatorsAPI = {
	getOrganizationData: () => {
		return API.get('/Operators/me')
			.then(res => res)
	},


	getOrganizationDataID: id => {
		return API.get(`/Operators/${id}`)
			.then(res => res)
			.catch(err => err)
	},
	resetAPIToken: () => {
		return API.put('/Operators/createAPIToken')
			.then(res => res)
			.catch(err => err)
	},
	getOperators: () => {
		return API.get('/Operators', { headers: adminHeader })
			.then(res => res)
			.catch(err => err)
	},
	editOperatorContactInfo: (mobile, email) => {
		return API.put('/Operators/me', { mobile, email })
			.then(res => res)
			.catch(err => err)
	},
	searchDBD: (payload) => {
		return API.post('/Operators/searchDBD', payload)
			.then(res => res)
			.catch(err => err)
	},
	getOperatorInfoDBD: (payload) => {
		const { taxno } = payload;
		return API.post('/Operators/searchDBD', { taxno: taxno })
			.then(res => res)
	},
	getOperatorInfo: async (payload) => {
		const { taxno,branchno } = payload;
		const form = new FormData()
		form.append('taxNo', taxno)
		form.append('branchNo', branchno)
		return API({
			url: `/Operators/OperatorInfo`,
			method: 'POST',
			data: form,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getOperatorUserInfo: async (payload) => {
		const { cardNo } = payload;
		const form = new FormData()
		form.append('cardNo', cardNo)
		return API({
			url: `/Operators/OperatorUserInfo`,
			method: 'POST',
			data: form,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	}
}
export default operatorsAPI
