import { useMemo } from 'react'
import { Grid } from '@material-ui/core'

export default function TwoPointThreeSubHeader({ sub_label_type }) {
	const subHeaderWidth = useMemo(() => {
		if (sub_label_type === 3 || sub_label_type === 2) {
			return 'w-4/12'
		}

		return 'w-6/12'
	}, [sub_label_type])

	return (
		<Grid className="col-span-12 text-center border border-b-0 flex flex-row justify-around">
			{(sub_label_type === 3 || sub_label_type === 2) && (
				<Grid className="border-r w-4/12 p-1 font-bold" />
			)}
			<Grid
				className={`border-r ${subHeaderWidth} p-1 font-bold bg-month_table_blue_bg`}>
				สถานที่ในส่วนภูมิภาค
			</Grid>
			<Grid className={`border-r ${subHeaderWidth} p-1 font-bold`}>
				สถานที่ในเขตกรุงเทพฯ และ ปริมณฑล
			</Grid>
		</Grid>
	)
}
