import { useEffect } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ProtectedRoute from './protected-route'
import { useStoreActions } from '@store'

// Components
import Form from './form'
import Manage from './manage'
import Registration from './registration'
import SignIn from './auth/signin'
import Identify from './auth/identify'
import Profile from './profile'

export default function SDOCUsersRoute() {
	const { path } = useRouteMatch()
	const setAppType = useStoreActions(a => a.appModel.setAppType)

	useEffect(() => setAppType('sdoc'))

	return (
		<Switch>
			<ProtectedRoute exact path={`${path}/profile`}>
				<Profile />
			</ProtectedRoute>

			<ProtectedRoute exact path={path}>
				<Form />
			</ProtectedRoute>

			<ProtectedRoute path={`${path}/manage`}>
				<Manage />
			</ProtectedRoute>

			<ProtectedRoute exact path={`${path}/form/:id`}>
				<Form />
			</ProtectedRoute>

			<Route path={`${path}/registration`} component={Registration} />
			<Route path={`${path}/sign-in`} component={SignIn} />

		
			<Route path={`${path}/auth/identify`} component={Identify} />



			<Redirect from={`${path}/*`} to={path} />
		</Switch>
	)
}
